export const SAVE_TRAVEL_QUESTIONS = "SAVE_TRAVEL_QUESTIONS";
export const SAVE_USER_TRAVEL_ANSWERS = "SAVE_USER_TRAVEL_ANSWERS";
export const SAVE_OPENAI_QUESTIONS = "SAVE_OPENAI_QUESTIONS";
export const SAVE_USER_OPENAI_ANSWERS = "SAVE_USER_OPENAI_ANSWERS";
export const SAVE_TRAVEL_QUIZ_CURRENT_STEPS = "SAVE_TRAVEL_QUIZ_CURRENT_STEPS";
export const SAVE_PAIDITINERARY_DETAILS = "SAVE_PAIDITINERARY_DETAILS";
export const SAVE_UNPAIDITINERARY_DETAILS = "SAVE_UNPAIDITINERARY_DETAILS";
export const DELETE_OPENAI_QUES_AND_ANS = "DELETE_OPENAI_QUES_AND_ANS";
export const DELETE_ALL_TRAVEL_QUES_DATA = "DELETE_ALL_TRAVEL_QUES_DATA";
export const SAVE_PRE_TRAVEL_QUIZ_DATA = "SAVE_PRE_TRAVEL_QUIZ_DATA";
export const GROUP_ITINERARY_STATUS = "GROUP_ITINERARY_STATUS";
export const DELETE_ALL_ITINERARIES = "DELETE_ALL_ITINERARIES";
export const UPDATE_ITINERARY_CREATE_PAYLOAD = "UPDATE_ITINERARY_CREATE_PAYLOAD";
