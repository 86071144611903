import React from "react";
import Footer from "../../components/common/footer";
import Header from "../../components/common/header";

export default function HomeLayout({ children }) {
  return (
    <div>
      <div className="res-wrapper-flow">
        <Header isHomeScreen={true} />
        <div>{children}</div>
        <Footer isHomeScreen={true} />
      </div>
    </div>
  );
}
