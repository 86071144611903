import React from "react";
import { Carousel, Col, Container, Row } from "react-bootstrap";
import { testimonials } from "../../../utils/mockData/carousalItem";

function Testimonial() {
  return (
    <div className="home-wrapper__Testimonial" id="reviews">
      <Container>
        <Row>
          <Col md="10" className="offset-md-1">
            <Carousel
              data-bs-theme="dark"
              indicators={true}
              controls={false}
              interval={2500}
            >
              {testimonials?.map((index, ind) => (
                <Carousel.Item key={`carousal-item-${ind}`}>
                  <h5>{index.title}</h5>
                  <div className="text-center">
                    <p className="mb-0"> {index.name} </p>
                    <span> {index.post}</span>
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Testimonial;
