import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Container, Row, Col, Navbar, Nav } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  FacebookIcon,
  InstagramIcon,
  BlackLogo,
  LogoIconBlue,
  TiktokIcon,
  YouTubeIcon,
} from "../../../utils/svg";
import { FooterStyled } from "./style";
import Button from "../../common/button";
import {
  emailValidation,
  handleScroll,
  handleRedirectionToQuizPage,
  getUserId,
} from "../../../utils/utilityFunctions";
import useSubscribers from "../../../services/subscribers";
import {
  FACEBOOK_URL,
  INSTAGRAM_URL,
  MSG,
  TIKTOK_URL,
  YOUTUBE_LINK,
} from "../../../utils/constants";
import { ROUTES } from "../../../utils/constants/routes";
import { useNavigate } from "react-router-dom";

export default function Footer({ isHomeScreen, isDashboard }) {
  const navigate = useNavigate();
  const { subscribersUser } = useSubscribers();
  const [isLoading, setIsLoading] = useState(false);
  const initialValues = {
    email: "",
  };
  const handleSubmit = (values, { resetForm }) => {
    setIsLoading(true);
    subscribersUser(values).then((response) => {
      if (response?.isSuccess) {
        toast.success(MSG.ON_SUBSCRIBERS_SUCCESS);
        resetForm();
      }
    });
    setIsLoading(false);
  };

  return (
    <FooterStyled>
      <div
        className={`${isHomeScreen ? `footer-wrapper` : `after-login-footer`} ${
          isDashboard ? "footer-dashboard" : ""
        }`}
      >
        <Container>
          {isDashboard ? (
            <>
              <Row className="align-items-center">
                <Col md="3">
                  <Navbar.Brand href="/">
                    <BlackLogo />
                  </Navbar.Brand>
                </Col>
                <Col md="6" className="text-center">
                  <p className="text-center copyright-text mb-0">
                    @2023 Ethoventures. All rights reserved.
                  </p>
                </Col>
                <Col md="3">
                  <ul className="social-icon">
                    <li>
                      <a href={FACEBOOK_URL} target="_blank">
                        <FacebookIcon />
                      </a>
                    </li>
                    <li>
                      <a href={INSTAGRAM_URL} target="_blank">
                        <InstagramIcon />
                      </a>
                    </li>
                    <li>
                      <a href={TIKTOK_URL} target="_blank">
                        <TiktokIcon />
                      </a>
                    </li>
                    <li>
                      <a href={YOUTUBE_LINK} target="_blank">
                        <YouTubeIcon />
                      </a>
                    </li>
                  </ul>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row>
                <Col md="8" className="offset-md-2 text-center">
                  <h1> Stay up to speed on the latest </h1>
                  <p>
                    Join our newsletter to stay up to date on features and
                    releases.
                  </p>

                  <div className="footer-wrapper__newsletter">
                    <Formik
                      initialValues={initialValues}
                      onSubmit={handleSubmit}
                    >
                      <Form>
                        <div className="d-lg-flex d-sm-flex">
                          <Field
                            placeholder="Enter your email"
                            className="form-control"
                            validate={emailValidation}
                            id="email"
                            name="email"
                          />
                          <Button
                            type="submit"
                            text="Subscribe"
                            className="submit-btn"
                            disabled={isLoading}
                            loading={isLoading}
                          />
                        </div>
                        <ErrorMessage
                          name="email"
                          component="p"
                          className="error-msg"
                        />
                      </Form>
                    </Formik>
                  </div>
                  <span>
                    By clicking Subscribe you're confirming that you agree with
                    our Terms and Conditions.
                  </span>
                </Col>
              </Row>
              <Row className="mt-5 align-items-center res-text-center">
                <Col md="3">
                  <Navbar.Brand href="/">
                    <LogoIconBlue />
                  </Navbar.Brand>
                </Col>
                <Col md="6" className="text-center">
                  <Nav>
                    <Nav.Link onClick={() => handleScroll("about")}>
                      About
                    </Nav.Link>
                    <Nav.Link
                      onClick={() => handleRedirectionToQuizPage(navigate)}
                    >
                      Take the quiz
                    </Nav.Link>
                    <Nav.Link
                      onClick={() =>  navigate(ROUTES?.terms)}
                    >
                      Terms
                    </Nav.Link>
                    {!getUserId && <Nav.Link href="/signin">Login</Nav.Link>}
                  </Nav>
                </Col>
                <Col md="3">
                  <ul className="d-flex justify-content-end mb-0 d-none d-md-flex">
                    <li>
                      <a href={FACEBOOK_URL} target="_blank">
                        <FacebookIcon />
                      </a>
                    </li>
                    <li>
                      <a href={INSTAGRAM_URL} target="_blank">
                        <InstagramIcon />
                      </a>
                    </li>
                    <li>
                      <a href={TIKTOK_URL} target="_blank">
                        <TiktokIcon />
                      </a>
                    </li>
                    <li>
                      <a href={YOUTUBE_LINK} target="_blank">
                        <YouTubeIcon />
                      </a>
                    </li>
                  </ul>
                </Col>
              </Row>
              <hr />
              <p className="text-center copyright-text mb-0">
                @2023 Ethoventures. All rights reserved.
              </p>
            </>
          )}
        </Container>
      </div>
    </FooterStyled>
  );
}
