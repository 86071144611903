import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Quizimg, Quizmobimg } from "../../../assets/images";
import { ROUTES } from "../../../utils/constants/routes";
import { QuizInnerBox } from "./formStyle";
import Image from "../../../components/common/image";

const PersonalityQuizSuccess = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      navigate(ROUTES?.personalityoverview);
    }, 6000);
  }, [navigate]);

  return (
    <QuizInnerBox className="quiz-main-wrapper">
      <Container fluid>
        <Row>
          <Col lg={6} md={12} sm={12} className="p-0">
            <div className="quiz-img-wrap">
              <div
                className="quiz-img-bg"
                style={{ backgroundImage: `url(${Quizimg})` }}
              ></div>
            </div>
          </Col>
          <Col lg={6} md={12} sm={12} className="p-0">
            <div className="quiz-content-wrap">
              <div className="progress-wrapper d-none d-md-block">
                <h1> Are you ready for your next adventure ! </h1>
              </div>
              <div className="quiz-mobile-content-wrap">
                <Image src={Quizmobimg} alt="" className="quiz-mob-img" />
                <div className="quiz-mobile-head-area justify-content-center">
                  <div className="quiz-img-title-mobile quiz-suc-title-mobile">
                    Are you ready for your next adventure !
                  </div>
                </div>
              </div>
              <div className="quiz-question">
                Reviewing your answers. Your travel personality is coming in one
                moment
              </div>
              <div className="quiz-item-wrap"></div>
            </div>
          </Col>
        </Row>
      </Container>
    </QuizInnerBox>
  );
};

export default PersonalityQuizSuccess;
