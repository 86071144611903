import { useFormik } from "formik";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CheckIcon } from "../../../../assets/images/checkIcon";
import Button from "../../../../components/common/button";
import Input from "../../../../components/common/input";
import Modal from "../../../../components/common/modals";
import useSignup from "../../../../services/auth";
import { MSG } from "../../../../utils/constants";
import { ROUTES } from "../../../../utils/constants/routes";
import {
  containsSpecialChars,
  isContainOneNumber,
  setEmailForSendVerificationMail,
  getPersonalityId,
} from "../../../../utils/utilityFunctions";
import { validate } from "./validation";

const SignUpForm = ({ loadingSocialSignup }) => {
  const { signupUser } = useSignup();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const personalityId = getPersonalityId();

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  };

  const dataToSend = (values) => {
    const payload = {
      email: values?.email,
      password: values?.password,
      firstName: values?.firstName,
      lastName: values?.lastName
    }
    if(personalityId) {
      payload.personalityId = personalityId;
    }
    return payload;
  };

  const onSubmit = (values, { resetForm }) => {
    const { email, password, firstName, lastName } = values;
    if (
      email !== "" &&
      password !== "" &&
      firstName !== "" &&
      lastName !== ""
    ) {
      setLoading(true);
      signupUser(dataToSend(values)).then((res) => {
        if (!res?.isSuccess) {
          resetForm(initialValues);
          return setLoading(false);
        }
        setEmailForSendVerificationMail(email);
        toast.success(MSG?.ON_VERIFICATION_EMAIL_SENT);
        // navigate(ROUTES?.home);
        resetForm(initialValues);
        setLoading(false);
        navigate(ROUTES?.postSignup, {state: email});
      });
    }
  };

  const { values, errors, handleBlur, handleChange, handleSubmit, touched } =
    useFormik({
      initialValues,
      onSubmit,
      validate,
    });
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <div>
          <Input
            required
            placeholder={"First Name"}
            maxLength={"20"}
            label="First Name"
            id="firstName"
            disabled={loading || loadingSocialSignup}
            name="firstName"
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.firstName && touched.firstName && errors.firstName}
          />
        </div>
        <div>
          <Input
            required
            maxLength={"20"}
            placeholder={"Last Name"}
            label="Last Name"
            id="lastName"
            disabled={loading || loadingSocialSignup}
            name="lastName"
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.lastName && touched.lastName && errors.lastName}
          />
        </div>
        <div>
          <Input
            required
            maxLength={"256"}
            placeholder={"Email"}
            label="Email"
            type="email"
            id="email"
            disabled={loading || loadingSocialSignup}
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.email && touched.email && errors.email}
          />
        </div>
        <div>
          <Input
            required
            maxLength={"20"}
            disabled={loading || loadingSocialSignup}
            label="Password"
            type="password"
            placeholder="Please enter password (Test@123)"
            value={values.password}
            id="password"
            name="password"
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.password && touched.password && errors.password}
          />
        </div>
        <div className="mt-1">
          <ul>
            <li>
              <CheckIcon
                fill={
                  !values?.password || values?.password?.length < 8
                    ? "#D0D5DD"
                    : "#008000"
                }
              />
              Must be at least 8 characters{" "}
            </li>
            <li>
              <CheckIcon
                fill={
                  !values?.password || !containsSpecialChars(values?.password)
                    ? "#D0D5DD"
                    : "#008000"
                }
              />
              Must contain one special character{" "}
            </li>
            <li>
              <CheckIcon
                fill={
                  !values?.password || !isContainOneNumber(values?.password)
                    ? "#D0D5DD"
                    : "#008000"
                }
              />
              Must contain at least one number{" "}
            </li>
          </ul>
        </div>
        <div>
          <Button
            type="submit"
            text={"Get started"}
            loading={loading || loadingSocialSignup}
            disabled={
              loading ||
              loadingSocialSignup ||
              values?.firstName === "" ||
              values?.lastName === "" ||
              values?.password === "" ||
              values?.email === ""
            }
            borderbtn={loading || loadingSocialSignup}
          />
          <p className="text-center link-text">
            {" "}
            Already have an account?{" "}
            <Link to="/signin" className="link-btn">
              {" "}
              Log in{" "}
            </Link>
          </p>
        </div>
      </Form>
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        Title="Forgot Password"
        modalSize="sm"
      />
    </>
  );
};

export default SignUpForm;
