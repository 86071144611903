import styled from "styled-components";

export const SocialButtonStyled = styled.div`
    .social-login-btn {
        button {
            border-radius: 8px !important;
            border: 1px solid #D0D5DD !important;
            background: #fff !important;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
            color: #344054 !important;
            font-family: var(--secondary-font) !important;
            font-size: 14px !important;
            font-style: normal;
            font-weight: 600!important;
            line-height: 24px!important;
            margin: 0 0 15px !important;
            display: flex !important;
            justify-content: center;
            align-items: center;
            height: 45px !important;
            transition: all 0.4s ease-in-out ;
            &:hover {
                background: rgb(222, 226, 230,0.23)!important;
            }
            > div {
                width: 200px;
                margin-left: 30px;
            }
        }
        svg {
            width: 20px;
        }
        .facebook-icon {
            svg {
                path {
                    fill:#1877F2;
                }
            }
        }
    }
`;