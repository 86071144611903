import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Logo } from "../../../assets/icons";
import Loader from "../../../components/common/loader";
import useSignup from "../../../services/auth";
import { MSG } from "../../../utils/constants";
import { ROUTES } from "../../../utils/constants/routes";
import { getCode } from "../../../utils/utilityFunctions";

const VerifyEmail = () => {
  const { verifyUser } = useSignup();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const verifyEmail = () => {
    setLoading(true);
    const verifyCode = getCode();
    if (verifyCode) {
      const dataToSend = { params: { code: `${verifyCode}` } };
      verifyUser(dataToSend).then((res) => {
        if (res?.isSuccess) {
          if (res?.data?.data?.response) {
            setLoading(false);
            toast.success(MSG?.ON_VERIFICATION_SUCCESS);
            navigate(ROUTES?.login);
          }
        } else {
          setLoading(false);
          navigate(ROUTES?.signup);
        }
      });
      return;
    }
    setLoading(false);
  };

  useEffect(() => {
    verifyEmail();
  }, []);

  return (
    <Container fluid>
      <Row>
        <div className="col-md-6 left-content">
          <div className="px-4 mt-3">
            <Link to="/">
              <Logo />
            </Link>
          </div>
          <div className="Login-content Form-content">
            <h1>Welcome To Ethoventures</h1>
            <div className="Form-content__GoogleLogin">
              {loading && (
                <Loader
                  size="md"
                  dimension="row"
                  message={"Verifying email..."}
                />
              )}
            </div>
          </div>
          <div className="px-4">
            <p> © Ethoventures 2023</p>
          </div>
        </div>
        <div className="col-md-6 Login-wrapper"></div>
      </Row>
    </Container>
  );
};

export default VerifyEmail;
