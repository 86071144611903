import styled from "styled-components";
import { EditProfileBanner } from "../../../assets/images";

export const EditprofileSection = styled.section`
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__input-container {
    .form-control {
      height: 43px;
      font-size: ${({ theme }) => theme.fontSizes.font14};
      padding: 0 14px; 
      &::placeholder {
        color: #ccc;
      }
    }
  }
  .invalid-input {
    border: 1px solid rgba(255, 0, 0, 1);
  }
  .invalid {
    color: red;
    font-size: ${({ theme }) => theme.fontSizes.font12};
    line-height: 24px;
    height: 20px;
  }
  .tr-page-head-banner {
    background-image: url(${EditProfileBanner});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100% 50%;
    width: 100%;
    height: 240px;
    @media (max-width: 767px) {
      display: none;
    }
  }
  .tr-inline-buttons-wrap {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 12px;
    margin: 0;
  }
  .btn {
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    max-width: 298px;
    width: 100%;
    padding: 8px 14px;
    font-family: var(--secondary-font);
    font-size: ${({ theme }) => theme.fontSizes.font14};
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-transform: capitalize;
    &:hover {
      opacity: 0.8;
    }
  }
  .btn-cancel {
    border: 1px solid ${({ theme }) => theme.colors.border.bdrPrimaryClr};
    background-color: transparent; 
    color: ${({ theme }) => theme.colors.light.titleClr};;
  }
  .btn-save {
    border: 1px solid ${({ theme }) => theme.colors.light.PrimaryThemeClr};
    background: ${({ theme }) => theme.colors.light.PrimaryThemeClr};
    color: ${({ theme }) => theme.colors.light.textwhiteClr};
  }
  .tr-edit-profile-upper-content-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin-top: -40px;
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    }
    @media (max-width: 767px) {
      display: block;
      max-width: 320px;
      margin: 80px auto 0;
    }
    .tr-user-img-wrap {
      border-radius: 200px;
      border: 4px solid ${({ theme }) => theme.colors.light.textwhiteClr};
      background: ${({ theme }) => theme.colors.light.iconBg};
      background-image: ${({ imageurl, picture }) =>
        imageurl ? `url(${imageurl})` : picture ? `url(${picture})` : "none"};
      background-size: cover;
      box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.03),
        0 12px 16px -4px rgba(16, 24, 40, 0.08);
      width: 160px;
      height: 160px;
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      @media (max-width: 767px) {
        width: 48px;
        height: 48px;
        box-shadow: 0 1.2000000476837158px 1.8000000715255737px -0.6000000238418579px
            rgba(16, 24, 40, 0.03),
          0 3.6000001430511475px 4.800000190734863px -1.2000000476837158px rgba(16, 24, 40, 0.08);
        border: 1.2px solid ${({ theme }) => theme.colors.light.textwhiteClr};
        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
    .tr-edit-icon {
      position: absolute;
      top: -25px;
      right: -25px;
      cursor: pointer;
      @media (max-width: 767px) {
        top: -13px;
        right: -10px;
        svg {
          width: 16px;
          height: 16px;
          path {
            stroke: black;
          }
        }
      }
    }
    .tr-user-details-wrap {
      width: 100%;
      display: flex;
      justify-content: space-between;
      @media (max-width: 767px) {
        display: none;
      }
      .tr-username {
        color: ${({ theme }) => theme.colors.light.titleClr};
        font-family: var(--secondary-font);
        font-size: ${({ theme }) => theme.fontSizes.font30};
        font-style: normal;
        font-weight: 600;
        line-height: 38px;
        text-transform: capitalize;
      }
    }
  }
  .tr-edit-profile-bottom-content-box {
    margin-top: 48px;
    margin-bottom: 96px;
    @media (max-width: 767px) {
      margin-top: 20px;
      margin-bottom: 54px;
    }
    .tr-edit-profile-form-box {
      max-width: 640px;
      width: 100%;
      margin: 0 auto;
      @media (max-width: 767px) {
        max-width: 320px;
      }
      .tr-spacer {
        display: block;
        width: 100%;
        height: 1px;
        background: ${({ theme }) => theme.colors.border.bdrLightClr};
        margin: 15px 0;
        @media (max-width: 767px) {
          display: none;
        }
      }
      .tr-have-icon {
        position: relative;
        .form-control {
          padding-left: 42px;
        }
        .tr-form-icon {
          position: absolute;
          top: 50%;
          transform: translateY(-33%);
          left: 14px;
          opacity: 0.5;
        }
      }
      .tr-select-box {
        .css-13cymwt-control,
        .css-t3ipsp-control {
          opacity: 1;
          border-radius: 8px;
          padding: 2.5px 6px;
          border: 1px solid ${({ theme }) => theme.colors.border.bdrPrimaryClr};
          font-size: ${({ theme }) => theme.fontSizes.font14};
          background: ${({ theme }) => theme.colors.light.textwhiteClr};
          box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
        }
      }
      .tr-form-row {
        .tr-inline-buttons-wrap {
          justify-content: end;
          @media (max-width: 767px) {
            justify-content: space-between;
            margin-top: 48px;
            .tr-inline-button-li {
              width: 50%;
            }
          }
        }
        .form-label {
          color: ${({ theme }) => theme.colors.light.titleClr};
          font-family: var(--secondary-font);
          font-size: ${({ theme }) => theme.fontSizes.font14};
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
        .form-label.tr-optional {
          font-weight: 400;
          margin-top: 5px;
        }
      }
    }
  }
  .tr-edit-profile-mobile-title {
    display: none;
  }
  .mobile-hidden {
    display: block;
  }
  .hidden-input {
    display: none;
  }
  @media (max-width: 767px) {
    .tr-edit-profile-mobile-title {
      margin: 33px 0;
      color: ${({ theme }) => theme.colors.light.titleClr};
      font-family: var(--secondary-font);
      font-size: ${({ theme }) => theme.fontSizes.font20};
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
      display: block;
      text-align: center;
    }
    .mobile-hidden {
      display: none !important;
    }
  }
  .email-icon {
      position: absolute;
      top: 40px;
      padding: 0 14px;
  }
`;
