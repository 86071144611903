import React from "react";
import Spinner from "react-bootstrap/Spinner";
import PropTypes from "prop-types";
import { LoaderStyled } from "./style";

const Loader = ({
  size = "sm",
  variant,
  message,
  className = "",
  dimension = "row",
  animation = "border",
  messageColorClass = "text-secondary",
  centerAlign = true,
}) => {
  return (
    <LoaderStyled
      className={`p-1 d-flex flex-${dimension} ${
        centerAlign && "justify-content-center align-items-center"
      } ${
        !centerAlign && dimension === "row"
          ? "align-items-center"
          : "justify-content-center"
      } ${className}`}
    >
      <Spinner
        animation={animation}
        variant={variant}
        size={size}
        className={`${dimension === "row" ? "me-2" : ""}`}
      />
      {message && (
        <div
          style={{ fontSize: ".8rem" }}
          className={`bold ${messageColorClass}`}
        >
          {message}
        </div>
      )}
    </LoaderStyled>
  );
};

Loader.propTypes = {
  variant: PropTypes.string,
  size: PropTypes.string,
  message: PropTypes.string,
  className: PropTypes.string,
  dimension: PropTypes.string,
  animation: PropTypes.string,
  messageColorClass: PropTypes.string,
  centerAlign: PropTypes.bool,
};

Loader.defaultProps = {
  variant: "primary",
  size: "sm",
  message: "Loading...",
  className: "",
  dimension: "row",
  animation: "border",
  centerAlign: true,
};

export default Loader;
