import React, { useEffect, useState } from "react";
import useItinerary from "../../../services/itinerary";
import { ItineraryWrapper } from "./style";
import { Col, Container, Row } from "react-bootstrap";
import BackButton from "../../../components/common/backbutton";
import Singleitinerary from "./singleItinerary";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../components/common/loader";
import { TEXT, MSG } from "../../../utils/constants";
import {
  getUserId,
  setGuestRoutePublicId,
  isCheckAllItineraryGenerated,
} from "../../../utils/utilityFunctions";
import Itineraryslider from "../itinerary/slider";
import ItineraryOverview from "../itinerary/overview";
import { ItineraryTextItem } from "../itinerary/style";
import { ROUTES } from "../../../utils/constants/routes";
import ItineraryLayoutPdf from "../itinerary/itineraryDetailsPdf";
import { PDFDownloadLink } from "@react-pdf/renderer";
import MyDocument from "../itinerary/myPdfDocument";
import { DownloadIcon } from "../../../assets/images/checkIcon";
import { StarIcon } from "../../../assets/icons";
const GuestItineraryDetails = () => {
  const { getGuestItineraryDetails } = useItinerary();
  const [loading, setLoading] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(true);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const publicId = searchParams.get("publicId");
  const sessionId = searchParams.get("session_id");
  const [guestItineraryResponse, setGuestItineraryResponse] = useState(null);
  const [isDownloadingPdf, setDownloadingPdf] = useState(false);
  const navigate = useNavigate();

  const isAllItineraryGenerated = isCheckAllItineraryGenerated(
    guestItineraryResponse
  );

  const fetchGuestItineraryData = async (
    publicId,
    sessionId,
    isShowLoading
  ) => {
    if (isShowLoading) {
      setLoading(true);
    }
    try {
      const res = await getGuestItineraryDetails(publicId, sessionId);
      if (res?.isSuccess) {
        const { data } = res?.data;
        if (!data?.hasOwnProperty("itinerary") || data?.itinerary === null) {
          if (!getUserId()) {
            setGuestRoutePublicId(publicId);
            return navigate(ROUTES?.login);
          } else {
            return navigate(`${ROUTES?.groupItinerary}?publicId=${publicId}`);
          }
        } else {
          setGuestItineraryResponse(res?.data?.data);
        }
      } else {
        return navigate(ROUTES?.home);
      }
    } catch (error) {
      return navigate(ROUTES?.home);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setGuestRoutePublicId(null);
    fetchGuestItineraryData(publicId, sessionId, true);
  }, [publicId, sessionId]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchGuestItineraryData(publicId, sessionId, false);
    }, 10000);
    if (isAllItineraryGenerated && guestItineraryResponse?.isPaid) {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  });

  useEffect(() => {
    setTimeout(() => {
      setLoadingPdf(false);
    }, 15000);
  }, []);

  if (loading) {
    return (
      <div className="web-spinner-loader">
        <Loader
          size="lg"
          dimension="row"
          message={TEXT?.ON_LOAD_ITINERARY}
          variant={"secondary"}
        />
      </div>
    );
  }

  if (!location?.search || !guestItineraryResponse) {
    return (
      <ItineraryWrapper>
        <Container>
          <h1 className="text-secondary text-center mt-5">No data found</h1>
        </Container>
      </ItineraryWrapper>
    );
  }

  return (
    <>
      <ItineraryWrapper>
        <Container>
          <div className="d-flex align-items-center justify-content-between">
            <BackButton path={"/"} text="Back" btnClass="Back-btn" />
            <div className="download-btn-grp">
              {guestItineraryResponse?.isPaid && isAllItineraryGenerated && (
                <div className="">
                  {loadingPdf ? (
                    <>
                      <div className="download-btn-item disabled">
                        <div className="Download-btn d-flex align-items-center">
                          Generating PDF
                        </div>
                        <DownloadIcon className="ml-2" />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="download-btn-item">
                        <PDFDownloadLink
                          className="Download-btn"
                          document={
                            <MyDocument item={guestItineraryResponse} />
                          }
                          fileName="Ethovenures customised trip itinerary.pdf"
                        >
                          {({ blob, url, loading, error }) => {
                            // return loading ? "Generating PDF" : "Download PDF";
                            return "Download PDF";
                          }}
                        </PDFDownloadLink>
                        <DownloadIcon />
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </Container>

        <Container>
          <Row>
            <Col>
              <Itineraryslider
                item={guestItineraryResponse}
                loading={loading}
              />
              <ItineraryOverview
                item={guestItineraryResponse}
                id="overview-pdf"
              />
              {!guestItineraryResponse?.isPaid &&
                guestItineraryResponse?.itinerary?.itinerary?.map(
                  (item, index) => (
                    <Singleitinerary
                      ClassName={
                        index === 0
                          ? "tr-itinerary-active"
                          : "tr-itinerary-blur"
                      }
                      item={item}
                      key={`${index}_tr-itinerary-blur`}
                      stripeLink={guestItineraryResponse?.paymentLink}
                      status={guestItineraryResponse?.isPaid}
                      userId={guestItineraryResponse?.userId}
                      response={guestItineraryResponse}
                      index={index}
                    />
                  )
                )}
              {guestItineraryResponse?.isPaid &&
                guestItineraryResponse?.itinerary?.itinerary?.map(
                  (item, index) => (
                    <Singleitinerary
                      ClassName={"tr-itinerary-active"}
                      item={item}
                      key={`${index}_tr-itinerary-active`}
                      stripeLink={guestItineraryResponse?.paymentLink}
                      status={guestItineraryResponse?.isPaid}
                      userId={guestItineraryResponse?.userId}
                      response={guestItineraryResponse}
                    />
                  )
                )}

              {!isAllItineraryGenerated && guestItineraryResponse?.isPaid && (
                <div className="tr-spinner-loader">
                  <Loader
                    size="lg"
                    dimension="row"
                    message={MSG?.CREATING_ITINERARY_MSG}
                    // variant={"secondary"}
                  />
                </div>
              )}

              <ItineraryTextItem>
                <Col>
                  <div className="tr-card-grp">
                    {guestItineraryResponse?.itinerary?.hidden_gems && (
                      <div className="tr-card-grp-item">
                        <div className="tr-card-item">
                          Hidden Gem <StarIcon />
                        </div>
                        <div className="tr-user-desc">
                          {guestItineraryResponse?.itinerary?.hidden_gems}
                        </div>
                      </div>
                    )}
                    {guestItineraryResponse?.itinerary?.helpful_tips && (
                      <div className="tr-card-grp-item">
                        <div className="tr-card-item">Helpful Tips</div>
                        <ul>
                          {guestItineraryResponse?.itinerary?.helpful_tips?.map(
                            (tip, index) => (
                              <li
                                key={`${index}-helpful_tips_guest`}
                                className="tr-user-desc"
                              >
                                {tip}
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    )}
                  </div>
                </Col>
              </ItineraryTextItem>
            </Col>
          </Row>
        </Container>

        <div style={{ display: isDownloadingPdf ? "block" : "none" }}>
          {guestItineraryResponse?.isPaid && (
            <ItineraryLayoutPdf itineraryResponse={guestItineraryResponse} />
          )}
        </div>
      </ItineraryWrapper>
    </>
  );
};
export default GuestItineraryDetails;
