import React from "react";
import { useState } from "react";
import { defaultContextSchema } from "./defaultContextSchema";
//==================================================================
// default app conext creater.
//==================================================================
export const defaultContext = React.createContext({});
//==================================================================
// Default context wrapper
//==================================================================
export default function DefaultContext({ children }) {
  const [contextState, setContextState] = useState(defaultContextSchema);
  const defaultValue = { contextState, setContextState };
  return (
    <defaultContext.Provider value={defaultValue}>
      {children}
    </defaultContext.Provider>
  );
}
