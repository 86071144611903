import {
  SAVE_TRAVEL_QUESTIONS,
  SAVE_USER_TRAVEL_ANSWERS,
  SAVE_OPENAI_QUESTIONS,
  SAVE_USER_OPENAI_ANSWERS,
  SAVE_TRAVEL_QUIZ_CURRENT_STEPS,
  SAVE_PRE_TRAVEL_QUIZ_DATA,
  SAVE_PAIDITINERARY_DETAILS,
  SAVE_UNPAIDITINERARY_DETAILS,
  DELETE_OPENAI_QUES_AND_ANS,
  DELETE_ALL_TRAVEL_QUES_DATA,
  GROUP_ITINERARY_STATUS,
  DELETE_ALL_ITINERARIES,
  UPDATE_ITINERARY_CREATE_PAYLOAD,
} from "./constants";

const initialState = {
  travelQuestions: [],
  openaAIQuestions: [],
  userTravelAnswers: [],
  userOpenAIAnswers: [],
  currentSteps: 0,
  preTravelQuizData: {
    travelDays: 1,
  },
  paidItinerary: [],
  unpaidItinerary: [],
  groupItineraryStatus: false,
  itineraryCreatePayload: null,
};

const TravelReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_TRAVEL_QUESTIONS:
      return { ...state, travelQuestions: action.payload };

    case SAVE_USER_TRAVEL_ANSWERS:
      const { payload } = action;
      const updatedAnswers = [...state.userTravelAnswers];
      const itemIndex = updatedAnswers.findIndex(
        (item) => item.questionId === payload.questionId
      );
      itemIndex !== -1
        ? (updatedAnswers[itemIndex] = payload)
        : updatedAnswers.push(payload);
      return { ...state, userTravelAnswers: updatedAnswers };

    case SAVE_OPENAI_QUESTIONS:
      return { ...state, openaAIQuestions: action.payload };

    case SAVE_USER_OPENAI_ANSWERS:
      const payloadData = action.payload;
      const updatedAIAnswers = [...state.userOpenAIAnswers];
      const index = updatedAIAnswers.findIndex(
        (item) => item.question === payloadData.question
      );
      index !== -1
        ? (updatedAIAnswers[index] = payloadData)
        : updatedAIAnswers.push(payloadData);

      return { ...state, userOpenAIAnswers: updatedAIAnswers };

    case SAVE_TRAVEL_QUIZ_CURRENT_STEPS:
      return { ...state, currentSteps: action.payload };

    case SAVE_PRE_TRAVEL_QUIZ_DATA:
      return {
        ...state,
        preTravelQuizData: {
          ...state.preTravelQuizData,
          ...action.payload,
        },
      };
    case SAVE_PAIDITINERARY_DETAILS:
      return { ...state, paidItinerary: action.payload };

    case SAVE_UNPAIDITINERARY_DETAILS:
      return { ...state, unpaidItinerary: action.payload };

    case DELETE_OPENAI_QUES_AND_ANS:
      return { ...state, openaAIQuestions: [], userOpenAIAnswers: [] };

    case DELETE_ALL_TRAVEL_QUES_DATA:
      return {
        ...state,
        travelQuestions: [],
        userTravelAnswers: [],
        openaAIQuestions: [],
        userOpenAIAnswers: [],
        currentSteps: 0,
        preTravelQuizData: {
          travelDays: 1,
        },
        groupItineraryStatus: false,
      };
    case GROUP_ITINERARY_STATUS:
      return {
        ...state,
        groupItineraryStatus: action.payload,
      };

    case DELETE_ALL_ITINERARIES:
      return {
        ...state,
        paidItinerary: [],
        unpaidItinerary: [],
      };

    case UPDATE_ITINERARY_CREATE_PAYLOAD:
      return { ...state, itineraryCreatePayload: action.payload };

    default:
      return state;
  }
};

export default TravelReducer;
