import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { toast_configuration } from "./configs/config";
import DefaultContext from "./states/context";
import Theme from "./components/common/theme/theme";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "../src/state/store";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import ReactGA from "react-ga4";

const root = ReactDOM.createRoot(document.getElementById("root"));

ReactGA.initialize(`${process.env.REACT_GOOGLE_ANALYTICS_ID}`);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Theme>
          <BrowserRouter basename="">
            <DefaultContext>
              <ToastContainer {...toast_configuration} />
              <App />
            </DefaultContext>
          </BrowserRouter>
        </Theme>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

ReactGA.send({ hitType: "pageview", page: window.location.pathname });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
