import Modal from "react-bootstrap/Modal";
import Button from "../button";

const CustomModal = (props) => {
  const {
    showModal,
    Title,
    contentTitle,
    contentText,
    className,
    modalSize,
    isTravelTestModal = false,
    handlePopupResponse,
    setShowModal,
    primaryButtonText,
    secondaryButtonText
  } = props;

  const handleClose = () => {
    setShowModal && setShowModal(false);
    handlePopupResponse && handlePopupResponse(false, 'close');
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        className={className}
        size={modalSize}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center"> {Title} </Modal.Title>
        </Modal.Header>

        {isTravelTestModal ? (
          <div className="travel-modal">
            <Modal.Body>
              <h3> {contentTitle }</h3>
              <p className="text-start">{contentText} </p>
            </Modal.Body>
            <Modal.Footer>
{/* 
              <Button
                variant="primary"
                onClick={() => handlePopupResponse(true)}
                text={primaryButtonText || "Go Back"}
                btnClass=""
              />
              <Button
                variant="secondary"
                onClick={() => handlePopupResponse(false)}
                text={secondaryButtonText || "Cancel"}
              /> */}
              <Button
                variant="primary"
                onClick={() => handlePopupResponse(false)}
                text={secondaryButtonText || "Go Back"}
                btnClass=""
              />
              <Button
                variant="secondary"
                onClick={() => handlePopupResponse(true)}
                text={primaryButtonText || "Cancel"}
              />
            </Modal.Footer>
          </div>
        ) : (
          <>
            <Modal.Body>
              <p className="text-center">{contentText} </p>
              <div>
                <label className="mb-2"> Email Id * </label> <br />
                <input type="email" name="email" className="form-control" />
              </div>
            </Modal.Body>

            <Modal.Footer className="text-end">
              <Button type="submit" text={"Send Link"} borderbtn />
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
}

export default CustomModal;
