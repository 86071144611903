import { SAVE_PERSONALITY_QUESTIONS, SAVE_USER_ANSWERS, SAVE_CURRENT_STEPS, DELETE_ALL_PERSONALITY_QUES_DATA } from './constants';

export const savePersonalityQuestions = (payload) => {
  return {
    type: SAVE_PERSONALITY_QUESTIONS,
    payload,
  };
};

export const saveUserAnswers = (payload) => {
  return {
    type: SAVE_USER_ANSWERS,
    payload,
  };
};

export const saveCurrentSteps = (payload) => {
  return {
    type: SAVE_CURRENT_STEPS,
    payload,
  };
};


export const deleteAllPersonalityQuesData = () => {
  return {
    type: DELETE_ALL_PERSONALITY_QUES_DATA,
  };
};