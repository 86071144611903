import React from "react";
import { useSelector } from "react-redux";
import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  EmailShareButton,
  TelegramShareButton,
} from "react-share";
import {
  SocialShareIcon1,
  SocialShareIcon2,
  SocialShareIcon3,
  SocialShareIcon4,
  SocialShareIcon5,
} from "../../../utils/svg";
const SocialShare = ({ setShow }) => {
  const { userDetails } = useSelector((state) => state?.user);
  const personalityId = userDetails?.personalityType?._id;
  const onlyUrl = `${process.env.REACT_APP_REDIRECT_URL}/personality-overview/${personalityId}`;
  //   const shareUrl = `${process.env.REACT_APP_REDIRECT_URL}/personality-overview/${personalityId}
  // I found my travel personality, you can also try Ethoventures for free today and identify what kind of traveller you are!`;

  const shareUrl = `${process.env.REACT_APP_REDIRECT_URL}/personality-overview/${personalityId}
I've just completed my Travel Personality quiz. Do you agree with my results?`;

  const fbShareText =
    "I've just completed my Travel Personality quiz. Do you agree with my results?";
  return (
    <div className="container">
      <TelegramShareButton url={shareUrl}>
        <SocialShareIcon1 />
      </TelegramShareButton>
      <FacebookShareButton url={onlyUrl} quote={fbShareText}>
        <SocialShareIcon2 />
      </FacebookShareButton>
      <EmailShareButton
        subject={"Hey Check Out Your Unique Travel Persona From Ethoventures!"}
        url={onlyUrl}
        body={
          "I found my travel personality, you can also try Ethoventures for free today and identify what kind of traveller you are!"
        }
      >
        <SocialShareIcon3 />
      </EmailShareButton>
      <WhatsappShareButton url={shareUrl}>
        <SocialShareIcon4 />
      </WhatsappShareButton>
      <TwitterShareButton url={shareUrl}>
        <SocialShareIcon5 />
      </TwitterShareButton>
    </div>
  );
};

export default SocialShare;
