import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import BackButton from '../../../components/common/backbutton'
import { PaymentCancelBg } from '../../../assets/images'
import Button from '../../../components/common/button'
import { PaymentStyled } from './style'

function PaymentCanceled() {
    return (
        <PaymentStyled>
            <Container fluid>
                <Row className="Overview-wrapper">
                    <Col lg={6} md={12} sm={12} className='Overview-content'>
                        <div className='Payment-status'> <p> <span className='Payment-status-icon cancel-icon'></span> Payment Canceled </p> </div>
                        <h1> Uh oh, that payment did not go through. </h1>
                        <p> Sorry, your payment did not go through please try again.</p>

                        <div className='btn-grp'>
                            <BackButton path={"/"} btnClass="Back-btn" text='Go back' />
                            <Button
                                text={"Try again"}
                                btnClass="primary-btn"
                            />
                        </div>
                    </Col>

                    <Col lg={6} md={12} sm={12} className="p-lg-0">
                        <div className="quiz-img-wrap" style={{ backgroundImage: `url(${PaymentCancelBg})` }}></div>
                    </Col>
                </Row>
            </Container>
        </PaymentStyled>
    )
}

export default PaymentCanceled
