import { useDispatch } from "react-redux";
import { Success, generateError } from "../useCommonSerivce";
import {
  QUESTIONS_PERSONALITY_QUIZ,
  QUESTION_PERSONALITY_SCORES,
  GENERATE_OPEN_AI_QUESTIONS,
  GENERATE_ITINERAIES,
  GENERATE_ITINERARY_FOR_GROUP,
  GENERATE_ITINERARY_FOR_INVITED_USER,
  GROUP_INVITE_LINK_CHECK,
} from "../../network/restAPI/apiRoutes";
import apiClient from "../../network/restAPI";
import { deleteAllTravelQuesData } from "../../state/travel/actions";
import {
  getUserId,
  removeInvitedUserDetails,
  setIsInvitedUserTravelQuizCompleted,
} from "../../utils/utilityFunctions";

export default function useQuiz() {
  const isLoggedIn = getUserId();
  const dispatch = useDispatch();

  const getQuizQuestions = async (isTravel) => {
    return new Promise(async (resolve) => {
      try {
        const data = await apiClient.get(QUESTIONS_PERSONALITY_QUIZ(isTravel));
        resolve(Success(data));
      } catch (error) {
        resolve(generateError(error));
      }
    });
  };

  const personalityScores = async (params) => {
    return new Promise(async (resolve) => {
      try {
        const data = await apiClient.post(QUESTION_PERSONALITY_SCORES, params);
        resolve(Success(data));
      } catch (error) {
        resolve(generateError(error));
      }
    });
  };

  const generateOpenAIQuestions = async (params) => {
    return new Promise(async (resolve) => {
      try {
        const data = await apiClient.post(GENERATE_OPEN_AI_QUESTIONS, params);
        resolve(Success(data));
      } catch (error) {
        resolve(generateError(error));
      }
    });
  };

  const generateItineraries = async (params, ...rest) => {
    return new Promise(async (resolve) => {
      try {
        const data = await apiClient.post(GENERATE_ITINERAIES, params);
        dispatch(deleteAllTravelQuesData());
        resolve(Success(data));
      } catch (error) {
        resolve(generateError(error));
      }
    });
  };

  const generateItinerariesForGroupTravellers = async (
    params,
    itineraryId,
    ...rest
  ) => {
    return new Promise(async (resolve) => {
      try {
        const data = await apiClient.put(
          GENERATE_ITINERARY_FOR_GROUP(itineraryId),
          params
        );
        dispatch(deleteAllTravelQuesData());
        resolve(Success(data));
      } catch (error) {
        resolve(generateError(error));
      }
    });
  };

  const generateItinerariesForInvitedUser = async (
    params,
    itineraryId,
    userId
  ) => {
    return new Promise(async (resolve) => {
      try {
        const data = await apiClient.put(
          GENERATE_ITINERARY_FOR_INVITED_USER(itineraryId, userId),
          params
        );
        dispatch(deleteAllTravelQuesData());
        if (!isLoggedIn) {
          setIsInvitedUserTravelQuizCompleted(true);
        } else {
          removeInvitedUserDetails();
        }

        resolve(Success(data));
      } catch (error) {
        resolve(generateError(error));
      }
    });
  };

  const checkInviteLinkExpire = async (invitedItineraryId, invitedUserId) => {
    return new Promise(async (resolve) => {
      try {
        const data = await apiClient.get(
          GROUP_INVITE_LINK_CHECK(invitedItineraryId, invitedUserId)
        );
        resolve(Success(data));
      } catch (error) {
        resolve(generateError(error));
      }
    });
  };

  return {
    getQuizQuestions,
    personalityScores,
    generateOpenAIQuestions,
    generateItineraries,
    generateItinerariesForGroupTravellers,
    generateItinerariesForInvitedUser,
    checkInviteLinkExpire,
  };
}
