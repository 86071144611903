import { useFormik } from "formik";
import React, { useState, useContext, useEffect } from "react";
import { Container, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Logo, Mail } from "../../../assets/icons";
import BackButton from "../../../components/common/backbutton";
import Button from "../../../components/common/button";
import AuthScreenHeading from "../../../components/common/heading";
import OtpInputHOC from "../../../components/common/otpinput";
import useSignup from "../../../services/auth";
import { defaultContext } from "../../../states/context";
import { MSG } from "../../../utils/constants";
import { ROUTES } from "../../../utils/constants/routes";
import { validate } from "./validation";
import { isUserExist } from "../../../utils/utilityFunctions";
import { useSelector } from "react-redux";

const VerifyOtp = () => {
  const { setContextState, contextState } = useContext(defaultContext);
  const { verifyOtpEmail, forgetPassword } = useSignup();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const initialValues = { otp: "" };
  const { forgetPasswordEmail } = useSelector((state) => state?.user);

  useEffect(() => {
    isUserExist(navigate);
  }, [navigate]);

  const dataToSend = (values) => {
    return {
      email: contextState.forgetPasswordEmail,
      otp: values?.otp,
    };
  };

  const resendOtp = (resetForm) => {
    setLoading(true);
    if (forgetPasswordEmail) {
      forgetPassword({ email: forgetPasswordEmail }).then((response) => {
        if (response?.isSuccess) {
          toast.info(MSG?.ON_OTP_SUCCESS);
          setLoading(false);
        } else {
          setLoading(false);
          resetForm(initialValues);
        }
      });
      return;
    }
    toast.info(MSG?.ON_EMAIL_NOT_FOUND);
    setLoading(false);
    navigate(ROUTES?.forgetPassword);
  };

  const onSubmit = (values, resetForm, validateForm, setErrors, setTouched) => {
    validateForm(values).then((result) => {
      if (Object.keys(result).length === 0) {
        setContextState((s) => ({ ...s, forgetPasswordOTP: values?.otp }));
        setLoading(true);
        if (forgetPasswordEmail && values?.otp) {
          verifyOtpEmail(dataToSend(values)).then((response) => {
            if (response?.isSuccess) {
              toast.success(MSG?.ON_OTP_VERIFY_SUCCESS);
              setTimeout(() => {
                setLoading(false);
                navigate(ROUTES?.resetPassword);
              }, 3000);
              return;
            }
            setLoading(false);
            resetForm(initialValues);
          });
        } else {
          toast.info(MSG?.ON_EMAIL_NOT_FOUND);
          setLoading(false);
          navigate(ROUTES?.forgetPassword);
        }
      } else {
        setErrors(result);
        setTouched(result);
      }
    });
  };
  const getCode = (code, setFieldValue) => {
    setFieldValue("otp", code);
  };

  const {
    values,
    errors,
    setFieldValue,
    handleSubmit,
    touched,
    resetForm,
    setTouched,
    validateForm,
    setErrors,
  } = useFormik({
    initialValues,
    onSubmit,
    validate,
  });
  return (
    <Container fluid>
      <Row>
        <div className="col-md-6 left-content">
          <div className="px-4 mt-3">
            <Link to="/">
              {" "}
              <Logo />
            </Link>
          </div>
          <div className="Login-content Form-content login-otp">
            <AuthScreenHeading
              Logo={Mail}
              mainHeading="Check your email"
              subHeading={`We sent a four-digit code to ${forgetPasswordEmail} .Enter the code to reset your password`}
            />
            <div className="Otp-form-grp">
              <Form onSubmit={handleSubmit}>
                <OtpInputHOC
                  code={values?.otp}
                  handleChange={(code) => getCode(code, setFieldValue)}
                  error={errors.otp && touched.otp && errors.otp}
                />
                <Button
                  text={"Verify"}
                  btnClass="mt-1"
                  onClick={() =>
                    onSubmit(
                      values,
                      resetForm,
                      validateForm,
                      setErrors,
                      setTouched
                    )
                  }
                  loading={loading}
                  disabled={loading || !values?.otp}
                />
              </Form>
              <div className="text-center link-text">
                Didn’t receive the email?
                <span
                  className="link-btn ml-2"
                  onClick={() => resendOtp(resetForm)}
                >
                  Click to resend
                </span>
              </div>
              <div className="text-center">
                <BackButton text="Back" path={ROUTES?.login} />
              </div>
            </div>
          </div>
          <div className="px-4">
            <p> © Ethoventures 2023</p>
          </div>
        </div>
        <div className="col-md-6 SignUp-wrapper"></div>
      </Row>
    </Container>
  );
};

export default VerifyOtp;
