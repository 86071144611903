import styled from "styled-components";
import {InvitUserBG} from "../../../assets/images";

export const InviteUserStyled = styled.section`
    .inviteuser-mob img {
        display: none;
        @media (max-width:767px) {
            height: 250px;
            width: 250px;
            border-radius: 100%;
            margin: 30px auto 0;
            text-align: center;
            display: flex;
            justify-content: center;
        }
    }
    .invite-user {
        background: url(${InvitUserBG});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center right;
        padding: 0;
        img {
            min-height: 100%;
            object-fit: cover;
            width: 100%;
            @media (max-width:767px) {
                display: none;
            }
        }
    }
    .invite-user-grp {
        @media (max-width:1023px) {
            margin-top: 50px;
        }
        h4 {
            color: ${({ theme }) => theme.colors.light.titleClr};
            font-size: ${({ theme }) => theme.fontSizes.font24};
            font-style: normal;
            font-weight: ${({ theme }) => theme.fontWeight.fontWeight700};
            line-height: 30px; 
            margin-bottom: 45px;
        }
        .inner-item {
            margin-bottom: 25px;
            h5 {
            color: ${({ theme }) => theme.colors.light.titleClr};
            font-size: ${({ theme }) => theme.fontSizes.font20};
            font-style: normal;
            font-weight: ${({ theme }) => theme.fontWeight.fontWeight600};
            line-height: 30px; 
            margin-bottom: 0;
            @media (max-width:767px) {
                font-size: ${({ theme }) => theme.fontSizes.font18};
            }
            }
            .invite-count {
                background-color: #8BC5E5;
                display: inline-block;
                padding: 3px 15px;
                color: ${({ theme }) => theme.colors.light.textwhiteClr};
                font-size: ${({ theme }) => theme.fontSizes.font16};
                font-weight: ${({ theme }) => theme.fontWeight.fontWeight600};
                letter-spacing: 1.5px;
                margin-bottom: 15px;
                border-radius: 100px;
            }
            p {
                color: #777E90;
                font-size: ${({ theme }) => theme.fontSizes.font16};
                font-weight: ${({ theme }) => theme.fontWeight.fontWeight400};
                line-height: 30px;
                @media (max-width:767px) {
                    font-size: ${({ theme }) => theme.fontSizes.font12};
                    line-height: 24px;
                }
            }
        }    
    }
    .secondrey-btn {
        background-color: #fff;
        color: ${({ theme }) => theme.colors.light.titleClr};
        font-size: ${({ theme }) => theme.fontSizes.font16};
        font-weight: ${({ theme }) => theme.fontWeight.fontWeight600};
    }
`;