import styled from "styled-components";
import { ExplainerBanner } from "../../../assets/images";

export const QuizMainSection = styled.section`
  background: ${({ theme }) => theme.colors.light.textwhiteClr};
  position: relative;
  .step-form {
    position: relative;
  }
`;

export const ExplainationBox = styled.section`
  background: ${({ theme }) => theme.colors.light.textwhiteClr};
  padding: 0 0;
  .explainer-section-one {
    background: var(--gray-50, #f9fafb);
    padding: 50px 0;
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      padding: 96px 25px 160px 25px;
    }
    @media (max-width: 767px) {
      padding: 30px;
      background: transparent;
    }
  }
  .explainer-section-two {
    padding: 0 0 96px 0;
  }
  .Banner-Img {
    background: url(${ExplainerBanner});
    background-repeat: no-repeat;
    height: calc(100vh - 34em);
    position: absolute;
    width: 97%;
    top: -70px;
    background-size: cover;
    @media (max-width: 767px) {
      display: none;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      top: -12em;
    }
  }
  .explainer-banner {
    display: block;
    margin: -120px auto 0;
    width: 100%;
  }
  .exp-content-area {
    @media (max-width: 767px) {
      text-align: center;
      margin: 20px;
    }
    .exp-title-lg {
      color: ${({ theme }) => theme.colors.light.titleClr};
      font-family: var(--secondary-font);
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 44px; /* 122.222% */
      letter-spacing: -0.72px;
      @media (max-width: 767px) {
        font-size: 24px;
        line-height: 32px;
      }
    }
    .exp-desc-lg {
      color: var(--gray-600, #475467);
      font-family: var(--secondary-font);
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 150% */
      margin: 20px 0;
      @media (max-width: 767px) {
        color: ${({ theme }) => theme.colors.light.titleClr};
        text-align: center;
        font-size: 12px;
        line-height: normal;
        letter-spacing: 0.12px;
        margin: 8px 0 0;
      }
    }
  }

  .exp-title-sm {
    color: var(--gray-900, #101828);
    font-family: var(--secondary-font);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    @media (max-width: 767px) {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
    }
  }

  .exp-desc-sm {
    color: var(--gray-600, #475467);
    font-family: var(--secondary-font);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-top: 5px;
  }

  .exp-number-area {
    display: block;
    width: 48px;
    height: 48px;
    line-height: 1;
    padding: 12px 0;
    border-radius: 10px;
    background: var(--primary-600, #155eef);
    color: ${({ theme }) => theme.colors.light.textwhiteClr};
    font-size: 22px;
    font-weight: 600;
    font-family: var(--secondary-font);
    text-align: center;
    @media (max-width: 767px) {
      font-weight: 700;
      font-size: 27px;
      padding: 11px 0;
    }
  }

  .exp-btn {
    border-radius: 8px;
    border: 1px solid var(--primary-600, #155eef);
    background: var(--primary-600, #155eef);
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    color: ${({ theme }) => theme.colors.light.textwhiteClr};
    font-family: var(--secondary-font);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    padding: 10px 16px;
    width: auto;
    margin-top: 0;
    a {
      color: ${({ theme }) => theme.colors.light.textwhiteClr};
      text-decoration: none;
    }
    @media (max-width: 767px) {
      line-height: 1;
      padding: 8px 14px;
      width: 100%;
      height: 36px;
    }
  }

  .exp-content-area-inline {
    display: flex;
    gap: 16px;
    margin-bottom: 48px;
    @media (max-width: 767px) {
      display: flex;
      padding: 20px;
      flex-direction: column;
      align-items: flex-start;
      gap: 48px;
      align-self: stretch;
      border-radius: 8px;
      border: 1px solid var(--gray-200, #eaecf0);
      background: var(--gray-50, #f9fafb);
      margin-bottom: 24px;
    }
  }

  .exp-content-area-inline-inner {
    width: calc(100% - 48px);
  }
  .exp-img-mobile-area {
    display: none;
  }
  .desktop-hidden {
    display: none;
  }
  @media (max-width: 767px) {
    .mobile-hidden {
      display: none;
    }
    .desktop-hidden {
      display: block;
    }
    .exp-img-mobile-area {
      display: block;
      text-align: center;
    }
  }
`;

export const OverviewStyled = styled.section`
    .Overview-share-wrapper {
      height:100vh;
      margin-top:0 !important;   
      .Overview-card {
        padding:0 !important;
      }
    }
  .Overview-wrapper {
    background-color: #fafafa;
    min-height: calc(100vh - 64px);
    margin-top: -50px;
    @media (max-width: 767px) {
      padding: 50px 20px 50px;
    }
    .quiz-img-wrap {
      position: relative;
      height: 100%;
      background-repeat: no-repeat;
      width: 100%;
      background-size: cover;
      background-position: bottom center;
    }
    .Overview-card-grp {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      @media (max-width: 767px) {
        margin-top: 30px;
      }
      @media (min-width: 1200px) and (max-width: 1366px) {
        height: 100%;
      }
    }
    .Overview-card {
      @media (min-width: 768px) {
        width: 70%;
        margin: 0 auto;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 90px 0 0px;
      }
      @media (min-width: 1200px) and (max-width: 1366px) {
        padding-bottom: 50px;
      }
      .Overview-card-item {
        background-color: ${({ theme }) => theme.colors.light.textwhiteClr};
        border-radius: 8px;
        @media (min-width: 768px) {
          display: flex;
          flex-direction: row-reverse;
        }
        img {
          width: 100%;
          border-radius: 8px 8px 0px 0px;
          height: 400px;
          object-fit: cover;
          @media (min-width: 768px) {
            margin-left: -1px;
            max-width: 100%;
            height: 100%;
            border-radius: 0px 8px 8px 0px;
          }
        }
        .card-description {
          padding: 20px;
          border: 1px solid ${({ theme }) => theme.colors.border.bdrPrimaryClr};
          border-radius: 0px 0px 8px 8px;
          border-top: 0;
          @media (min-width: 768px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: ${({ theme }) => theme.colors.light.textwhiteClr};
            background-color: ${({ theme }) =>
              theme.colors.light.lightBlueText};
            flex: 1;
            border-radius: 8px 0px 0px 8px;
          }
        }
        .card-media {
          @media (min-width: 768px) {
            flex: 1;
          }
        }
        h1 {
          font-family: var(--secondary-font);
          font-size: ${({ theme }) => theme.fontSizes.font30};
          font-style: normal;
          font-weight: ${({ theme }) => theme.fontWeight.fontWeight600};
          line-height: 44px;
          letter-spacing: -0.72px;
        }
        p {
          /* height: auto;
          overflow-y: auto;
          max-height: 300px;
          padding-right: 5px; */
          margin-bottom: 0;
        }
      }
      .card-text {
        color: ${({ theme }) => theme.colors.light.lightDarktext};
        font-size: ${({ theme }) => theme.fontSizes.font14};
        font-weight: ${({ theme }) => theme.fontWeight.fontWeight400};
        text-align: center;
        line-height: 32px;
        margin: 20px 0 0;
      }
      .share-btn {
        background-color: ${({ theme }) => theme.colors.light.lightBlueClr1};
        color: ${({ theme }) => theme.colors.light.lightBlueText};
        font-size: ${({ theme }) => theme.fontSizes.font16};
        font-weight: 600 !important;
        border: 1px solid #b2ccff;
      }
      .Overview-top-header {
        margin-bottom:10px;
        text-align:center;
        @media (max-width: 767px) {
          text-align:left;
          .card-text {
            text-align:left;
            line-height: 18px;
            margin: 10px 0 !important;
          }
        }
          h4 {
            color: ${({ theme }) => theme.colors.light.titleClr};
            font-family: var(--secondary-font);
            font-size: 26px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            margin-bottom:0px;
            @media (max-width: 767px) {
              color: ${({ theme }) => theme.colors.light.quizQusClr};
              font-size: 16px;
              line-height: 136.6%;
            }
          }
      }
    }
    .share-icon-wrapper {
      min-height: 50px;
      align-items: center;
      display: flex;
      justify-content: center;
      width: 100%;
      @media (max-width: 767px) {
        min-height: 74px;
      }
      .share-icon {
        text-align: center;
        margin: 0;
        button {
          margin: 0 10px;
        }
      }
    }
    .Overview-btn-grp {
      display: flex;
      justify-content: space-around;
      flex-direction: column;
    }
  }
`;
