import styled from "styled-components";
export const QuizInnerBox = styled.div`
  /* margin-top: 67px; */
  .quiz-content-wrap {
    height: 100vh;
    padding: 100px 80px 0;
    @media (max-width: 767px) {
      padding: 26px;
      height: auto;
      margin-top: 50px;
    }
    @media (min-width: 1023px) and (max-width: 1366px) {
      padding: 90px 80px 0;
    }
    .quiz-question {
      color: ${({ theme }) => theme.colors.light.titleClr};
      font-family: var(--secondary-font);
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      margin-top: 50px;
      @media (max-width: 767px) {
        color: ${({ theme }) => theme.colors.light.quizQusClr};
        text-align: center;
        font-size: 20px;
        margin: 0px;
        padding: 0 10px;
      }
      @media (min-width: 1023px) and (max-width: 1366px) { 
        margin-top: 30px;
      }
    }
    .res-invite {
      @media (max-width: 767px) {
        display:none;
      }
    }
  }
  .tr-loader-wrap {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .spinner-loader {
      .spinner-border {
        border-color: ${({ theme }) =>
          theme.colors.light.PrimaryThemeClr} !important;
      }
      .text-secondary {
        color: ${({ theme }) => theme.colors.light.PrimaryThemeClr} !important;
      }
    }
    .tr-loader-title {
      text-align: center;
      margin: 15px 0;
      color: #344054;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: 40px;
      letter-spacing: -0.32px;
    }
  }
  .tr-loader-wrap-item {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media (max-width: 767px) {
      position: fixed;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      position: unset;
      transform: none;
      height: 50vh;
    }
  }
  .quiz-img-wrap {
    position: relative;
    @media (max-width: 767px) {
      display: none;
    }
    .quiz-img-bg {
      min-height: 100vh;
      background-image: url(${(props) => props.image});
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      &:before {
        content: "";
        background: linear-gradient(
          153deg,
          rgba(0, 0, 0, 0.97) 0,
          rgba(0, 0, 0, 0.97) 62.45%,
          rgba(30, 21, 154, 0.97) 100%
        );
        width: 100%;
        display: block;
        height: 100vh;
        position: absolute;
        bottom: 0;
        top: auto;
        opacity: 0.3;
      }
      @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
        /* min-height: 370px; */
        min-height: 500px;
        background-position: center center;
      }
    }
    .quiz-img-title {
      color: ${({ theme }) => theme.colors.light.textwhiteClr};
      font-family: var(--primary-font);
      font-size: 76px;
      font-style: normal;
      font-weight: 500;
      line-height: 74px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      padding-top: 100px;
      text-align: center;
      height: 240px;
      background: linear-gradient(
        180deg,
        rgba(0, 8, 188, 0.72) 13.1%,
        rgba(12, 13, 56, 0) 100%
      );
      @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
        padding-top: 170px;
      }
      @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
        padding-top: 60px;
        font-size: 53px;
      }
    }
    img {
      width: 100%;
      height: 100vh;
    }
  }
  .progress-wrapper {
    width: 100%;
    display: flex;
    gap: 4px;
    margin: 0 0 20px 0;
    @media (max-width: 767px) {
      margin: 0 0 10px 0;
    }
    .progress-item {
      border-radius: 5px;
      height: 6px;
      flex: 1 0 0;
      display: block;
      background: #eaecf0;
    }
    .progress-item.active {
      background: var(--primary-500, #2970ff);
    }
  }

  @media screen and (min-height: 600px) {
    .quiz-item-wrap {
      height: calc(100vh - 390px);
      overflow-y: auto;
    }
  }
  .quiz-item-wrap {
    margin-top: 20px;
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      max-height: 680px;
    }
    @media (max-width: 767px) {
      margin-top: 32px;
      margin-bottom: 120px;
      overflow-y: hidden;
      height:100%;
    }
    @media (min-width: 1023px) and (max-width: 1366px) {
      height: 420px;
      max-height: calc(100vh - 340px);
      overflow-y: auto;
      margin-bottom: 0;
    }
    .logged-travel-style {
      .quiz-single-item {
        min-height: 130px;
      }
    }
    .quiz-single-item {
      padding: 16px 15px;
      border-radius: 6px;
      border: 1px solid var(--primary-100, #d1e0ff);
      background: var(--primary-25, #f5f8ff);
      margin-bottom: 16px;
      cursor: pointer;
      width: 98%;
      display: block;
      @media (max-width: 767px) {
        width: 100%;
      }
      .radio-ui-box {
        margin: 0;
        input[type="radio"] {
          position: absolute;
          opacity: 0;
          + label {
            opacity: 1;
            &:before {
              content: "";
              background-color: ${({ theme }) =>
                theme.colors.light.textwhiteClr};
              border-radius: 100%;
              border: 0 solid #c1c7cd;
              display: inline-block;
              width: 25px;
              height: 25px;
              position: relative;
              top: 2px;
              vertical-align: top;
              cursor: pointer;
              text-align: center;
              transition: all 250ms ease;
              box-shadow: inset 0 0 0 6px #c1c7cd;
            }
          }
          &:checked {
            + label {
              &:before {
                background-color: ${({ theme }) =>
                  theme.colors.light.uiClrBlue};
                box-shadow: inset 0 0 0 5px
                  ${({ theme }) => theme.colors.light.textwhiteClr};
                border: 1px solid ${({ theme }) => theme.colors.light.uiClrBlue};
              }
            }
          }
          &:focus {
            + label {
              &:before {
                outline: none;
              }
            }
          }
          &:disabled {
            + label {
              &:before {
                box-shadow: inset 0 0 0 4px #f4f4f4;
                border-color: darken(#f4f4f4, 25%);
                background: darken(#f4f4f4, 25%);
              }
            }
          }
          + label {
            &:empty {
              &:before {
                margin-right: 0;
              }
            }
          }
        }
      }
      label {
        color: #505050;
        font-family: var(--secondary-font);
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: 0.1px;
        opacity: 1;
        cursor: pointer;
      }
      &:hover {
        border-color: ${({ theme }) => theme.colors.light.uiClrBlue};
        box-shadow: 0 2px 4px -2px rgba(16, 24, 40, 0.06),
          0 4px 8px -2px rgba(16, 24, 40, 0.1);
        label {
          opacity: 1;
        }
      }
      &.active {
        border-color: ${({ theme }) => theme.colors.light.uiClrBlue};
        box-shadow: 0 2px 4px -2px rgba(16, 24, 40, 0.06),
          0 4px 8px -2px rgba(16, 24, 40, 0.1);
        label {
          opacity: 1;
        }
      }
      & > div {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }
  .quiz-mobile-content-wrap {
    display: none;
  }
  @media (max-width: 767px) {
    .quiz-mobile-content-wrap {
      display: block;
      .quiz-mobile-head-area {
        display: flex;
        align-items: center;
        gap: 15px;
        margin-bottom: 16px;
        .quiz-img-title-mobile {
          color: ${({ theme }) => theme.colors.light.titleClr};
          text-align: center;
          font-family: var(--secondary-font);
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: 56px;
          width: 100%;
        }
        .quiz-suc-title-mobile {
          font-size: 30px;
          line-height: 34px;
        }
        .quiz-mobile-back-btn {
          margin-left: -15px;
          position: absolute;
        }
      }
      .quiz-mob-img {
        display: block;
        margin: 24px auto;
        max-width: 200px;
        width: 100%;
      }
    }
  }
  .step-form-footer {
    width: 50%;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0px 82px 30px;
    text-align: right;
    z-index: 99;
    background: ${({ theme }) => theme.colors.light.textwhiteClr};
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      width: 100%;
      position: fixed;
      padding: 15px 82px;
    }
    @media (max-width: 767px) {
      width: 100%;
      position: fixed;
      padding: 15px 82px;
    }
    .step-form-footer-inner {
      display: flex;
      gap: 10px;
      justify-content: end;
      @media (max-width: 767px) {
        display: flex;
      }
    }
    .step-btn {
      border-radius: 8px;
      padding: 7px 16px;
      font-family: var(--secondary-font);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      height: 40px;
      width: auto;
      @media (max-width: 767px) {
        width: 100%;
        margin: 5px 0;
      }
    }
    .step-btn-back {
      border-radius: 8px;
      border: 1px solid var(--gray-300, #d0d5dd);
      background: ${({ theme }) => theme.colors.light.textwhiteClr};
      box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
      color: ${({ theme }) => theme.colors.light.titleClr};
    }
    .step-btn-next {
      border: 1px solid var(--primary-600, #155eef);
      background: var(--primary-600, #155eef);
      box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
      color: ${({ theme }) => theme.colors.light.textwhiteClr};
    }
  }
`;
