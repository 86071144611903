import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PreTravelQuizHOC from ".";
import { TravelStyleImg } from "../../../assets/images";
import Button from "../../../components/common/button";
import RadioButtonHOC from "../../../components/HOCs/radiobutton";
import { ROUTES } from "../../../utils/constants/routes";
import {
  travelStyleData,
  travelStyleDataGuest,
} from "../../../utils/mockData/travelStyle";
import { getUserId } from "../../../utils/utilityFunctions";
import { savePreTravelQuizData } from "../../../state/travel/actions";
import useItinerary from "../../../services/itinerary";
import Image from "../../../components/common/image";

const TravelStyle = (mobileViewImage, image) => {
  const userId = getUserId();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { preTravelQuizData } = useSelector((state) => state?.travel);
  const [selected, setSelected] = useState(
    !userId
      ? {
          _id: 1,
          text: "Traveling solo allows for self-discovery, independence, and the flexibility to tailor your journey to your personal interests.",
          heading: "Solo traveller",
          // label: "solo",
          value: "solo",
        }
      : null || preTravelQuizData?.travelStyle
  );
  const [loading, setLoading] = useState(false);
  const { getInviteLink } = useItinerary();
  const getData = (data) => {
    setSelected(data);
  };

  const handleGenerateInviteLink = () => {
    setLoading(true);
    getInviteLink()
      .then((res) => {
        if (res?.isSuccess && res?.data?.data?.shortUrl) {
          dispatch(
            savePreTravelQuizData({
              inviteLink: res?.data?.data?.shortUrl,
              groupItineraryId: res?.data?.data?.id,
            })
          );
          return navigate(ROUTES?.travelSelect);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleContinue = () => {
    dispatch(savePreTravelQuizData({ travelStyle: selected }));
    if (userId && selected?.value === "group") {
      handleGenerateInviteLink();
      return;
    }
    navigate(ROUTES?.travelDays);
  };
  return (
    <PreTravelQuizHOC
      label={"Choose your Travel Plan"}
      image={TravelStyleImg}
      path={userId ? ROUTES.dashboard : ROUTES?.home}
    >
      <div className="quiz-mobile-content-wrap">
        <Image src={TravelStyleImg} alt="" className="quiz-mob-img" />
      </div>
      <div className={`${userId ? "logged-travel-style" : ""}`}>
        <RadioButtonHOC
          uid="travelStyleList"
          name="travelStyle"
          data={userId ? travelStyleData : travelStyleDataGuest}
          getSelected={getData}
          selectedItem={selected}
          isGusetMode={!userId ? true : false}
        />
      </div>
      {!userId && (
        <div>If you want to create a group trip plan, please sign up.</div>
      )}
      <Button
        onClick={handleContinue}
        disabled={!selected ? true : false}
        text={"Continue"}
        loading={loading}
      />
      {!userId && (
        <Button
          text={"Sign Up"}
          onClick={() => navigate(ROUTES?.signup)}
          btnClass="light-blue-btn"
        />
      )}
    </PreTravelQuizHOC>
  );
};

export default TravelStyle;
