import {
  UPDATE_USER_DETAILS,
  CLEAR_USER_DETAILS,
  SET_USER_LOGIN,
  SET_FORGET_PASSWORD_EMAIL,
  SET_VERIFICATION_LINK,
  SET_SELECTED_TAB,
  MENU_BAR_TOGGLE_STATE
} from "./constants";

export const updateUserDetails = (payload) => {
  return {
    type: UPDATE_USER_DETAILS,
    payload,
  };
};

export const clearUserDetails = () => {
  return {
    type: CLEAR_USER_DETAILS,
  };
};

export const setUserLogin = (payload) => {
  return {
    type: SET_USER_LOGIN,
    payload,
  };
};
export const setForgetPasswordEmail = (payload) => {
  return {
    type: SET_FORGET_PASSWORD_EMAIL,
    payload,
  };
};

export const setVerificationLink = (payload) => {
  return {
    type: SET_VERIFICATION_LINK,
    payload,
  };
};

export const setSelectedTab = (payload) => {
  return {
    type: SET_SELECTED_TAB,
    payload,
  };
};

export const menuBarToggleState = (payload) => {
  return {
    type: MENU_BAR_TOGGLE_STATE,
    payload,
  };
};
