import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Logo, ResetPasswordSuccess } from "../../../../assets/icons";
import Button from "../../../../components/common/button";
import AuthScreenHeading from "../../../../components/common/heading";
import { ROUTES } from "../../../../utils/constants/routes";
import { isUserExist } from "../../../../utils/utilityFunctions";

const Resetpasswordsuccess = () => {
  const navigate = useNavigate();

  useEffect(() => {
    isUserExist(navigate);
  }, [navigate]);
  return (
    <Container fluid>
      <Row>
        <div className="col-md-6 left-content">
          <div className="px-4 mt-3">
            <Link to="/">
              <Logo />
            </Link>
          </div>
          <div className="Login-content Form-content">
            <AuthScreenHeading
              Logo={ResetPasswordSuccess}
              mainHeading={"Password reset"}
              subHeading={`Your password has been successfully reset. Click below to log in.`}
            />
            <Button text={"Login"} onClick={() => navigate(ROUTES?.login)} />
          </div>
          <div className="px-4">
            <p> © Ethoventures 2023</p>
          </div>
        </div>
        <div className="col-md-6 SignUp-wrapper"></div>
      </Row>
    </Container>
  );
};

export default Resetpasswordsuccess;
