import React from "react";
import { ItinerariesWrapper, PageNotFound } from "./style";
import { Col, Container, Row } from "react-bootstrap";
import { ItinerariesPageNot, PageNot } from "../../../assets/images";
import Button from "../../../components/common/button";
import { useNavigate } from "react-router";
import { ROUTES } from "../../../utils/constants/routes";

export default function NotFoundComponent() {
  const navigate = useNavigate();
  return (
    <>
      <PageNotFound>
        <Container>
          <div className="web-layout-body page-not-wrapper">
            <Row>
              <Col xl="12">
                <img src={PageNot} alt="Not Found" className="img-fluid" />
                <h1> Page not found </h1>
                <p>The page you are looking for doesn't exist.</p>
                {/* <Button
                  onClick={() => navigate(ROUTES?.dashboard)}
                  text="Back to dashboard"
                /> */}
              </Col>
            </Row>
          </div>
        </Container>
      </PageNotFound>
    </>
  );
}
