import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Slider from "react-slick";
import { ChevLeft, NextIconBlue, PrevIconBlue } from "../../../assets/icons";
import { getAnOrA } from "../../../utils/utilityFunctions";
import { ItinerarysliderInner } from "./style";
import { appendQueryParametersInImage } from "../../../utils/utilityFunctions";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../utils/constants/routes";
import Loader from "../../../components/common/loader";
import { travelType } from "../../../utils/constants/itineraryConstants";
import ImageComponent from "../../../components/common/image";
const Itineraryslider = ({ item }) => {
  const [isLoading, setLoading] = useState(true);
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "30px",
    slidesToShow: 1.05,
    speed: 500,
    dots: true,
    nextArrow: <NextIconBlue />,
    prevArrow: <PrevIconBlue />,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          autoplay: true,
        },
      },
    ],
  };

  const [imageUrls, setImageUrls] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    if (item?.itinerary?.itinerary) {
      const imageURLs = item?.itinerary?.itinerary?.map((element) => {
        const url = element?.image?.url;
        return appendQueryParametersInImage(url, 80);
      });
      const limitedImages = imageURLs?.slice(0, 5);
      setImageUrls(limitedImages);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [item]);

  const overviewImage = item?.itinerary?.overview_image?.url;

  return (
    <ItinerarysliderInner>
      <div className="tr-slider-wrapper position-relative">
        <Col>
          <div className="tr-slider-info-area">
            <div className="tr-slider-back-mob">
              <ChevLeft onClick={() => navigate(ROUTES?.dashboard)} />
            </div>
            {item?.generated === false && (
              <p>It's taking a bit longer than planned - we'll be in touch.</p>
            )}
            {item?.type === travelType?.group ? (
              <span className="tr-slider-title">
                Your group trip in{" "}
                {item?.itinerary?.title_destination ||
                  item?.itinerary?.destination ||
                  " "}
              </span>
            ) : (
              <span className="tr-slider-title">
                {getAnOrA(item?.personality[0])}
                {item?.personality} in{" "}
                {item?.itinerary?.title_destination ||
                  item?.itinerary?.destination}
              </span>
            )}
          </div>
        </Col>

        {isLoading ? ( // Render loader while loading
          <div className="container my-5 iti-slider">
            <Loader size={"lg"} dimension={"row"} variant={"secondary"} />
          </div>
        ) : item?.isPaid ? (
          <>
            {imageUrls?.length === 1 ? (
              <div className="container my-5">
                <div className="slider-img-box slider-img-box--mob">
                  <ImageComponent
                    item={item}
                    src={
                      appendQueryParametersInImage(overviewImage, 50) ||
                      imageUrls[0]
                    }
                    alt="Loading.."
                    width={"100%"}
                    className="img-fluid img-wrapper-box"
                  />
                </div>
              </div>
            ) : (
              <div className="tr-itinerary-slider-box">
                <Row>
                  <Col>
                    <div className="tr-itinerary-slider-box-inner">
                      <Slider {...settings}>
                        {imageUrls?.map((image, index) => (
                          <div key={`${index}_tr-itinerary-box`}>
                            <div className="slider-img-box">
                              <ImageComponent
                                src={image}
                                alt={`Hero ${index + 1}`}
                                item={item}
                              />
                            </div>
                            <div className="slider-img-box slider-img-box--mob">
                              <ImageComponent
                                item={item}
                                src={image}
                                alt="Loading.."
                              />
                            </div>
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </Col>
                </Row>
              </div>
            )}
          </>
        ) : (
          <div className="container my-5">
            <div className="slider-img-box slider-img-box--mob">
              <img
                src={
                  appendQueryParametersInImage(overviewImage, 50) ||
                  imageUrls[0]
                }
                alt="Loading.."
                className="img-fluid img-wrapper-box"
              />
              {item?.itinerary?.overview_image?.photographer &&
                item?.itinerary?.overview_image?.service === "unsplash" && (
                  <div className="unsplash-text">
                    {`Photo by`}
                    &nbsp;
                    <a
                      href={
                        item?.itinerary?.overview_image?.photographer_url + '?utm_source=Ethoventures&utm_medium=referral'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      className="ml-2"
                    >
                      {item?.itinerary?.overview_image?.photographer || ""}
                    </a>
                    <span className="mx-2">on</span>
                    <a
                      href="https://unsplash.com?utm_source=Ethoventures&utm_medium=referral'"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Unsplash
                    </a>
                  </div>
                )}
            </div>
          </div>
        )}
      </div>
    </ItinerarysliderInner>
  );
};

export default Itineraryslider;
