import React, { useState, useEffect } from "react";
import Loader from "../loader";
import { LoaderStyledWrapper } from "./style";

const ImageComponent = (props) => {
  const [isImageReady, setIsImageReady] = useState(false);
  const { src, alt, className, item, isHideName } = props;

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setIsImageReady(true);
    };
    img.src = src;
  }, []);

  if (!isImageReady) {
    return (
      <div>
        <LoaderStyledWrapper>
          <Loader size="lg" dimension="row" className="spinner-loader" />
        </LoaderStyledWrapper>
      </div>
    );
  } else {
    return (
      <div className="position-relative">
        <img src={src} alt={alt} className={className} />
        {!isHideName && src?.includes("unsplash.com") && (
          <div className="unsplash-text unsplash-slider-text">
            {`Photo by`}
            &nbsp;
            <a
              href={
                (item?.itinerary?.overview_image?.photographer_url ||
                item?.image?.photographer_url)+'?utm_source=Ethoventures&utm_medium=referral'
              }
              target="_blank"
              rel="noopener noreferrer"
              className="ml-2"
            >
              {item?.itinerary?.overview_image?.photographer ||
                item?.image?.photographer ||
                ""}
            </a>
            <span className="mx-2">on</span>
            <a
              href="https://unsplash.com?utm_source=Ethoventures&utm_medium=referral"
              target="_blank"
              rel="noopener noreferrer"
            >
              Unsplash
            </a>
          </div>
        )}
      </div>
    );
  }
};

export default ImageComponent;
