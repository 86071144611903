import React from "react";
import { useLocation } from "react-router-dom";
import Header from "../../components/common/header";
import { ROUTES } from "../../utils/constants/routes";
import { LoginWrapperStyled } from "./style";
import Footer from "../../components/common/footer";
import { checkNotFoundPage } from "../../utils/utilityFunctions";

const AuthLayout = ({ children }) => {
  const location = useLocation();
  let isShowHeader = false;
  let isShowNotFoundPage = false;
  if (
    [
      ROUTES.travelStyle,
      ROUTES.travelInvite,
      ROUTES.travelSelect,
      ROUTES?.travelBudget,
      ROUTES?.travelDays,
      ROUTES?.whySignup,
      ROUTES?.travelQuiz,
      ROUTES?.travelLocation,
      ROUTES?.personalityTest,
      ROUTES?.personalitySuccess,
      ROUTES?.editProfile,
    ].includes(location.pathname)
  ) {
    isShowHeader = true;
  }
  if (checkNotFoundPage(location?.pathname)) {
    isShowNotFoundPage = true;
  }
  return (
    <LoginWrapperStyled>
      <div
        className={`${
          isShowHeader ? "d-flex flex-column res-wrapper-flow" : "wrapper-auth-flow"
        }  `}
      >
        {(isShowHeader || isShowNotFoundPage) && <Header isDashboard={true} />}
        <div>{children}</div>
        {isShowNotFoundPage && <Footer isDashboard={true} />}
      </div>
    </LoginWrapperStyled>
  );
};

export default AuthLayout;
