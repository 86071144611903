import React from "react";
import { useNavigate } from "react-router-dom";
import { BackButtonStyled } from "./style";
import { BackArrowIcon } from "../../../assets/images/checkIcon";

const BackButton = ({
  state,
  text = "Back",
  path,
  previousBtn,
  disabled,
  btnClass,
}) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(path);
  };
  return (
    <BackButtonStyled
      onClick={handleClick}
      className={`previousbtn ${btnClass}`}
    >
      <BackArrowIcon />
      <span>{text}</span>
    </BackButtonStyled>
  );
};

BackButton.defaultProps = {
  text: "Back",
  path: "/",
  state: "",
};
export default BackButton;
