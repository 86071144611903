/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  ItineraryInprogressInner,
  ItineraryOverviewInner,
  ItineraryTextItem,
  PdfWrapper,
} from "./style";
import Itineraryslider from "./pdfOverviewImage";
import ItineraryOverview from "./overview";
import Singleitinerary from "./singleitinerary";
import { Col, Container, Row } from "react-bootstrap";
import { UserIconPlace } from "../../../assets/images";
import { useCallback } from "react";
import { extractInitialsFromMate } from "../../../utils/utilityFunctions";
import { StarIcon } from "../../../assets/icons";

const ItineraryLayoutPdf = ({ itineraryResponse }) => {
  const [base64Images, setBase64Images] = useState([]);

  const tripMatesPicturesArray = itineraryResponse?.tripMatesDetails?.map(
    (item) => {
      return {
        picture: item.picture || null,
      };
    }
  );
  const fetchAndConvertToBase64 = useCallback(async () => {
    tripMatesPicturesArray?.forEach(async (item, index) => {
      if (item.picture) {
        try {
          const response = await fetch(item.picture);
          const blob = await response.blob();
          const reader = new FileReader();
          reader.readAsDataURL(blob);

          reader.onloadend = () => {
            const base64String = reader.result.split(",")[1];
            setBase64Images((prev) => {
              const updatedArray = [...prev];
              updatedArray[index] = "data:image/jpeg;base64," + base64String;
              return updatedArray;
            });
          };
        } catch (error) {
          console.error("Error fetching or converting the image:", error);
          setBase64Images((prev) => {
            const updatedArray = [...prev];
            updatedArray[index] = null;
            return updatedArray;
          });
        }
      } else {
        setBase64Images((prev) => {
          const updatedArray = [...prev];
          updatedArray[index] = null;
          return updatedArray;
        });
      }
    });
  }, [tripMatesPicturesArray, setBase64Images]);

  useEffect(() => {
    fetchAndConvertToBase64();
    return () => setBase64Images([]);
  }, []);

  return (
    <PdfWrapper>
      <Container className="PdfWrapper" id="pdf-content">
        <Row>
          <Col>
            <div>
              <Itineraryslider item={itineraryResponse} myPdf={true} />
            </div>
            <Container>
              <ItineraryOverviewInner>
                <span className="tr-overview-title">Overview</span>
                <span className="tr-overview-desc">
                  {itineraryResponse?.itinerary?.overview}
                </span>
              </ItineraryOverviewInner>
              <ItineraryInprogressInner>
                <Row className="tr-itinerary-inprogress-wrap ">
                  {itineraryResponse?.tripMatesDetails?.map((item, index) => (
                    <Col
                      lg={4}
                      md={6}
                      xs={12}
                      key={`${index}-tripmate-details-pdf`}
                      className="mt-2"
                    >
                      <div className="tr-itinerary-inprogress-card">
                        <span
                          className={`tr-label ${
                            item.status === "Finished"
                              ? "tr-label-finished"
                              : "tr-label-sent"
                          }`}
                        >
                          {item?.status === "Finished"
                            ? "Finished"
                            : "In Progress"}
                        </span>
                        <div className="tr-user-info-area">
                          <div className="tr-user-img">
                            {base64Images[index] ? (
                              <img
                                src={base64Images[index]}
                                alt=""
                                width="64px"
                              />
                            ) : (
                              <div className="user-profile-text">
                                {extractInitialsFromMate(item) || (
                                  <img
                                    src={UserIconPlace}
                                    alt=""
                                    width="64px"
                                  />
                                )}
                              </div>
                            )}
                          </div>
                          <div className="tr-user-info">
                            <span className="tr-username">
                              {item?.firstName || " "} {item?.lastName || " "}
                            </span>
                            <span className="tr-user-location">
                              {itineraryResponse?.itinerary
                                ?.title_destination ||
                                itineraryResponse?.itinerary?.destination ||
                                " "}
                            </span>
                          </div>
                        </div>
                        <div className="tr-user-desc">
                          {item?.personality_description || " "}
                        </div>
                      </div>
                    </Col>
                  ))}
                  <div className="overView-pdfcard">
                    <ItineraryOverview item={itineraryResponse} ispdf={true} />
                  </div>

                  <div className="PdfItinerary-card">
                    {itineraryResponse?.isPaid &&
                      itineraryResponse?.itinerary?.itinerary?.map(
                        (item, index) => (
                          <Singleitinerary
                            ClassName="tr-itinerary-active"
                            item={item}
                            status={itineraryResponse?.isPaid}
                            key={`${index}-itinerary-paid-pdf`}
                          />
                        )
                      )}
                    {!itineraryResponse?.isPaid &&
                      itineraryResponse?.itinerary?.itinerary?.map(
                        (item, index) => (
                          <>
                            <Singleitinerary
                              ClassName={
                                index === 0
                                  ? "tr-itinerary-active"
                                  : "tr-itinerary-blur"
                              }
                              item={item}
                              status={itineraryResponse?.isPaid}
                              inviteLink={itineraryResponse?.inviteLink}
                              paymentLink={itineraryResponse?.paymentLink}
                              key={`${index}-itinerary-unpaid-pdf`}
                              userId={itineraryResponse?.userId}
                            />
                          </>
                        )
                      )}
                  </div>
                  <ItineraryTextItem>
                    <Col>
                      <div className="tr-card-grp pt-5">
                        {itineraryResponse?.itinerary?.hidden_gems && (
                          <div className="tr-card-grp-item">
                            <div className="tr-card-item">
                              Hidden Gem <StarIcon />
                            </div>
                            <div className="tr-user-desc">
                              {itineraryResponse?.itinerary?.hidden_gems}
                            </div>
                          </div>
                        )}
                        {itineraryResponse?.itinerary?.helpful_tips && (
                          <div className="tr-card-grp-item">
                            <div className="tr-card-item">Helpful Tips</div>
                            <div>
                              <ul>
                                {itineraryResponse?.itinerary?.helpful_tips?.map(
                                  (tip, index) => (
                                    <li
                                      key={`${index}-itinerary-unpaid-tip-pdf`}
                                      className="tr-user-desc"
                                    >
                                      {tip}
                                    </li>
                                  )
                                )}
                              </ul>
                              <div className="blank-space"></div>
                            </div>
                          </div>
                        )}
                      </div>
                    </Col>
                  </ItineraryTextItem>
                </Row>
              </ItineraryInprogressInner>
            </Container>
          </Col>
        </Row>
      </Container>
    </PdfWrapper>
  );
};

export default ItineraryLayoutPdf;
