import React from "react";
import { ComingSoonStyled } from "./style";

const ComingSoon = () => {
  return (
    <ComingSoonStyled>
      <h1>Coming Soon</h1>
    </ComingSoonStyled>
  );
};

export default ComingSoon;
