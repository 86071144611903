import React from "react";
import { LabelStyled } from "./style";

const Label = ({ label, labelClass, required }) => {
  return (
    <LabelStyled className={` label ${labelClass}`}>
      {label}
      <span className="ms-1">{required ? <span className="text-danger">*</span> : ""}</span>
    </LabelStyled>
  );
};

export default Label;
