import styled from "styled-components";

export const TravelSection = styled.section`
    padding:50px 0;
    .tr-nearly-wrap {
        display: block;
        text-align: center;
        margin: 80px auto;
        .tr-nearly-content-box{
            .tr-title {
                color: ${({ theme }) => theme.colors.light.titleClr};
                text-align: center;
                font-family: Inter;
                font-size: ${({ theme }) => theme.fontSizes.font24};
                font-style: normal;
                font-weight: 700;
                line-height: 32px;
                display: block;
                margin: 16px 0 8px;
            }
            .tr-desc {
                color: ${({ theme }) => theme.colors.light.titleClr};
                text-align: center;
                font-family: Inter;
                font-size: ${({ theme }) => theme.fontSizes.font12};
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.12px;
                display: block;
                margin-bottom: 16px;
            }
        }
        form{
            max-width: 269px;
            margin: 0 auto;
            .btn-outline {
                margin: 16px 0 0;
                border-radius: 8px;
                border: 1px solid  #98A2B3;
                background: ${({ theme }) => theme.colors.light.textwhiteClr};
                box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
                color: ${({ theme }) => theme.colors.light.inputLabelClr};
                font-family: Inter;
                font-size: ${({ theme }) => theme.fontSizes.font14};
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
            }
        }
    }
`