import React from "react";
import { ThemeProvider } from "styled-components";

const theme = {
  colors: {
    dark: {},
    light: {
      PrimaryThemeClr:"#155EEF",
      lightBlueText: "#004EEB",
      lightBlueClr: "#00bffb",
      lightBlueClr1: "#EFF4FF",
      SecondaryClr: "#090e21",
      textwhiteClr: "#ffffff",
      lightBlue: "#AFDBD2",
      labeltext:"rgba(52, 64, 84, 1)",
      titleClr: "#344054",
      lightDarktext: "rgba(71, 84, 103, 1)", 
      errorClr:"red",
      blackClr:"#000",
      headingText: "#344054",
      contentText:"#475467",
      bgClr1:"#F9FAFB",
      inputLabelClr: "#667085",
      iconBg: "#F2F4F7",
      CtaText:"#045577",
      Textlight:"rgba(0, 0, 0, 0.2)",
      uiClrBlue:"#155eef",
      headingClr: "rgba(16, 24, 40, 1)",
      greenClr:"#17B26A",
      redBgClr:"#F04438",
      quizQusClr:"#1E4B78",
      MenuItemClr1:"#777E90",
      MenuItemClr:"#1D2939",

    },
    border: {
       bdrPrimaryClr: "#D0D5DD",
       errorClr:"rgba(255,0,0,1)",
       bdrDarkClr:"#155EEF",
       bdrLightClr:"#EAECF0",
       bdrLightBlue:"#B2CCFF",
    },
  },
  fonts: ["sans-serif", "Roboto"],

  fontSizes: {
    small: "1em",
    medium: "2em",
    large: "3em",
    font10: "10px",
    font12: "12px",
    font14: "14px",
    font16: "16px",
    font18: "18px",
    font20: "20px",
    font23: "23px",
    font24: "24px",
    font28: "28px",
    font26: "26px",
    font30: "30px",
    font36: "36px",
    font32: "32px",
    font42: "42px",
    font46: "46px",
    font48: "48px",
    font52: "52px",
    font60: "60px",
  },
  fontWeight: {
    fontWeight200: "200",
    fontWeight300: "300",
    fontWeight400: "400",
    fontWeight500: "500",
    fontWeight600: "600",
    fontWeight700: "700",
  },
  borderRadius: {
    bradius1: "6px",
    bradius4: "4px",
    bradius8: "8px",
    bradius20: "20px",
    bradius26: "26px",
    bradius32: "32px",
    bradius40: "40px",
  },
};

const Theme = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Theme;
