import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Link,
} from "@react-pdf/renderer";
import { travelType } from "../../../utils/constants/itineraryConstants";
import {
  capitalizeFirstLetter,
  getAnOrA,
} from "../../../utils/utilityFunctions";
import { Image } from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import { extractInitialsFromMate } from "../../../utils/utilityFunctions";
import { UserIconPlace } from "../../../assets/images";
import { appendQueryParametersInImage } from "../../../utils/utilityFunctions";
import fontInter from "../../../assets/Font/Inter-Bold.ttf";
import fontInterLight from "../../../assets/Font/Inter-Regular.ttf";
import fontInterMedium from "../../../assets/Font/Inter-Medium.ttf";
import fontInterExtLight from "../../../assets/Font/Inter-Light.ttf";
import LOGOImage from "../../../assets/images/logo.png";
import PDFCover from "../../../assets/images/etho-bg.png";
import PDFThankyou from "../../../assets/images/thankyouimg.png";
import { TEXT } from "../../../utils/constants";
import {
  AccommodationIcon,
  ActivityIcon,
  RestaurantsIcon,
} from "../../../assets/images";
import StarIcon from "../../../assets/icons/star.png";

Font.register({
  family: "Inter",
  src: fontInter,
});

Font.register({
  family: "Inter-Regular",
  src: fontInterLight,
});

Font.register({
  family: "Inter-Medium",
  src: fontInterMedium,
});

Font.register({
  family: "Inter-Light",
  src: fontInterExtLight,
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    padding: "20px",
    fontWeight: "light",
    fontFamily: "Inter-Regular",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  pdfHeading: {
    fontSize: "28px",
    marginBottom: "20px",
    color: "#344054",
    fontWeight: "700",
    fontFamily: "Inter",
  },
  text: {
    fontSize: "10px",
    marginBottom: "20px",
    color: "#777E90",
    fontWeight: "light",
    fontFamily: "Inter-Light",
  },
  profileImage: {
    width: "30px",
    height: "30px",
    borderRadius: "6px",
    border: "2px solid #0061ea85",
    marginRight: "10px",
    fontSize: "12px",
    padding: "2px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "700",
    fontFamily: "Inter",
  },
  flexBoxItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  cardCount: {
    backgroundColor: "#155EEF",
    borderRadius: "6px",
    height: "30px",
    width: "30px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    fontSize: "14px",
    color: "#fff",
    fontWeight: "700",
    fontFamily: "Inter",
  },
  mainTripMateItem: {
    // flex: '0 0 100%',
    borderRadius: "8px",
    border: "1px solid #eaecf0",
    background: "#F9FAFB",
    padding: "10px",
    position: "relative",
    marginBottom: "24px",
    maxWidth: "100%",
    marginRight: "15",
    marginBottom: "10px",
  },
  tripLabelText: {
    display: "flex",
    padding: "2px 8px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "16px",
    textAlign: "center",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: "light",
    position: "absolute",
  },
  tripDays: {
    position: "absolute",
    right: "0",
    backgroundColor: "#f5f8ff",
    left: "0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "6px",
  },
  tripDaysItem: {
    borderRadius: "6px",
    backgroundColor: "#f5f8ff",
    color: "#000",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: "Bold",
    padding: "15px",
    textTransform: "uppercase",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    alignContent: "center",
    justifyContent: "center",
  },
  signalCardGrpItem: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  signalCardGrp: {
    flex: "0 0 46%",
    padding: "24px",
    width: "46%",
    borderRadius: "8px",
    backgroundColor: "#F9FAFB",
    borderRadius: "10px",
    Height: "200px",
    margin: "10px",
  },
  signalCardTitle: {
    fontSize: "28px",
    marginBottom: "20px",
    color: "#344054",
    fontWeight: "700",
    fontFamily: "Inter",
  },
  imageBrowser: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
  bullet: {
    marginRight: "5px",
    backgroundColor: "#777E90",
    height: "7px",
    width: "7px",
    position: "absolute",
    left: "0",
    borderRadius: "50px",
    top: "3px",
    display: "block",
  },
  footer: {
    position: "absolute",
    bottom: "0",
    right: "20px",
    marginTop: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  header: {
    width: "100%",
    height: "60px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    textAlign: "center",
    width: "206px",
    height: "auto",
    paddingBottom: "10px",
  },
  backgroundCover: {
    height: "100%",
    objectFit: "cover",
    width: "100%",
    backgroundColor: "#000",
  },
  backgroundImg: {
    position: "absolute",
  },
  tripmateCard: {
    borderRadius: "8px",
    border: "1px solid #EAECF0",
    background: "#F9FAFB",
    padding: "10px",
    marginRight: "24px",
    maxWidth: "32%",
    width: "100%",
  },
  imageBox: {
    height: "250px",
    width: "100%",
    objectFit: "cover",
  },
  overviewImage: {
    height: "300px",
    width: "100%",
    objectFit: "cover",
  },
  CoverDetails: {
    position: "absolute",
    top: "50px",
    left: "25%",
    textAlign: "center",
    // opacity:'0',
    // height:'0',
  },
});

const Page1DetailsComponent = ({ item }) => {
  const updatedUrl = appendQueryParametersInImage(
    item?.itinerary?.overview_image?.url
      ? item?.itinerary?.overview_image?.url
      : item?.itinerary?.itinerary[0]?.image?.url
  );

  return (
    <Page style={styles?.page} size="A4">
      <View style={styles.header} fixed>
        <Image style={[styles.image, { width: "120px" }]} src={LOGOImage} />
      </View>
      <View>
        {item?.type === travelType?.group ? (
          <Text style={styles.pdfHeading}>
            Your group trip in{" "}
            {item?.itinerary?.title_destination ||
              item?.itinerary?.destination ||
              " "}
          </Text>
        ) : (
          <Text style={styles.pdfHeading}>
            {getAnOrA(item?.personality)}
            {item?.personality} in{" "}
            {item?.itinerary?.title_destination ||
              item?.itinerary?.destination ||
              " "}
          </Text>
        )}
      </View>
      <View style={styles.overviewImageContainer}>
        <View style={styles.sliderImgBox}>
          <Image src={updatedUrl} style={styles.overviewImage} />
        </View>
      </View>
      <View style={styles.overviewTextContainer}>
        <Text style={[styles.pdfHeading, { marginTop: "10px" }]}>Overview</Text>
        <Text style={styles.text}>
          {item?.itinerary?.overview?.replace(/\s{2,}/g, " ")?.trim()}
        </Text>
      </View>

      <View style={styles.footer} fixed>
        <Text
          style={[styles.text, { color: "#000" }]}
          render={({ pageNumber, totalPages }) =>
            `Ethoventures Itinerary Page  ${pageNumber - 1} of ${
              totalPages - 2
            }`
          }
          fixed
        />
      </View>
    </Page>
  );
};
const OverViewDetailsComponent = ({ item }) => {
  return (
    <Page style={styles?.page} size="A4">
      <View style={styles.header} fixed>
        <Image style={[styles.image, { width: "120px" }]} src={LOGOImage} />
      </View>
      <View style={styles.signalCardGrpItem}>
        <View style={styles.signalCardGrp}>
          <Text style={[styles.cardCount, { padding: "7px 0 0" }]}> 1 </Text>
          <Text
            style={[
              styles.pdfHeading,
              {
                fontSize: "20px",
                textTransform: "capitalize",
                margin: "10px 0",
              },
            ]}
          >
            Vacation Type
          </Text>
          <Text style={styles.text}>{item?.itinerary?.vacation_type}</Text>
        </View>
        <View style={styles.signalCardGrp}>
          <Text style={[styles.cardCount, { padding: "7px 0 0" }]}> 2 </Text>
          <Text
            style={[
              styles.pdfHeading,
              {
                fontSize: "20px",
                textTransform: "capitalize",
                margin: "10px 0",
              },
            ]}
          >
            Travel Personality
          </Text>
          <Text style={styles.text}>{item?.itinerary?.personality}</Text>
        </View>
        <View style={styles.signalCardGrp}>
          <Text style={[styles.cardCount, { padding: "7px 0 0" }]}> 3 </Text>
          <Text
            style={[
              styles.pdfHeading,
              {
                fontSize: "20px",
                textTransform: "capitalize",
                margin: "10px 0",
              },
            ]}
          >
            Travel Season
          </Text>
          <Text style={styles.text}>{item?.itinerary?.vacation_season}</Text>
        </View>
        <View style={styles.signalCardGrp}>
          <Text style={[styles.cardCount, { padding: "7px 0 0" }]}> 4 </Text>
          <Text
            style={[
              styles.pdfHeading,
              {
                fontSize: "20px",
                textTransform: "capitalize",
                margin: "10px 0",
              },
            ]}
          >
            Full Trip Budget
          </Text>
          <Text style={styles.text}>{item?.metaData[0]?.total_budget}</Text>
        </View>
      </View>
      <View style={styles.footer} fixed>
        <Text
          style={[styles.text, { color: "000" }]}
          render={({ pageNumber, totalPages }) =>
            `Ethoventures Itinerary Page  ${pageNumber - 1} of ${
              totalPages - 2
            }`
          }
          fixed
        />
      </View>
    </Page>
  );
};
const MyDocument = ({ item }) => {
  const [imageUrl, setImageUrl] = useState([]);
  const status = item?.isPaid;
  useEffect(() => {
    const processedUrls = item?.itinerary?.itinerary?.map((item) => {
      const url = item?.image?.url;
      const updatedUrl = appendQueryParametersInImage(url);
      return updatedUrl;
    });
    setImageUrl(processedUrls);
  }, []);

  const generateTripMateComponent = (tripMate, index) => {
    // <Page style={styles?.page} size="A4">

    return (
      <View key={index}>
        <View style={[styles.mainTripMateItem]}>
          <View>
            <View>
              {tripMate?.status === "Finished" ? (
                <Text
                  style={[
                    styles.tripLabelText,
                    {
                      backgroundColor: "#abefc6",
                      color: "#079455",
                      right: "10px",
                    },
                  ]}
                >
                  Finished
                </Text>
              ) : (
                <Text
                  style={[
                    styles.tripLabelText,
                    {
                      backgroundColor: "#f9f5ff",
                      color: "#6941c6",
                      right: "10px",
                    },
                  ]}
                >
                  In Progress
                </Text>
              )}
            </View>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View>
                {tripMate?.picture ? (
                  <Image
                    src={tripMate?.picture}
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "4px",
                      marginRight: "5px",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <View
                    style={[
                      styles.profileImage,
                      {
                        backgroundColor: "#155EEF",
                        color: "#fff",
                        borderColor: "#155EEF",
                      },
                    ]}
                  >
                    <View style={{ FontSize: "12px", backgroundColor: "#fff" }}>
                      {extractInitialsFromMate(tripMate) ? (
                        <Text
                          style={{
                            FontSize: "12px",
                            backgroundColor: "#155EEF",
                            border: "0",
                          }}
                        >
                          {extractInitialsFromMate(tripMate)}
                        </Text>
                      ) : (
                        <View>
                          <Image
                            src={UserIconPlace}
                            style={{
                              width: "30px",
                              height: "30px",
                              borderRadius: "4px",
                              marginLeft: "-4px",
                              marginRight: "5px",
                              objectFit: "cover",
                              backgroundColor: "transparent",
                            }}
                          />
                        </View>
                      )}
                    </View>
                  </View>
                )}
              </View>

              <View>
                <Text
                  style={{
                    fontSize: "12px",
                    textTransform: "capitalize",
                    marginBottom: "2px",
                    fontWeight: "600",
                    fontFamily: "Inter",
                  }}
                >
                  {tripMate?.firstName
                    ? tripMate?.firstName || " " + tripMate?.lastName || ""
                    : tripMate?.email}
                </Text>
                <Text
                  style={[styles.text, { fontSize: "10px", color: "#101828" }]}
                >
                  {item?.itinerary?.title_destination ||
                    item?.itinerary?.destination ||
                    " "}
                </Text>
              </View>
            </View>
          </View>
          <View>
            <Text
              style={[
                styles.text,
                {
                  fontSize: "10px",
                  fontWeight: "ultralight",
                  fontFamily: "Inter-Light",
                },
              ]}
            >
              {tripMate.personality_description || " "}
            </Text>
          </View>
        </View>
      </View>
    );
    // </Page>
  };

  const myCustomItinerary = (item, index) => {
    return (
      <Page style={styles?.page} size="A4">
        <View style={styles.header} fixed>
          <Image style={[styles.image, { width: "120px" }]} src={LOGOImage} />
        </View>
        <View key={index}>
          <View>
            <Image src={imageUrl[index]} style={styles.imageBox} />
          </View>
          {/* <Text
            style={[
              styles.tripDays,
              {
                top: "15px",
                left: "15px",
                height: "30px",
                width: "40%",
                paddingTop: "10px",
                fontWeight: "semibold",
                fontFamily: "Inter",
              },
            ]}
          >
            <Text style={[styles.tripDaysItem]}>{item?.title} </Text>
          </Text> */}

          <View style={{ marginTop: "20px" }}>
            <Text style={[styles.pdfHeading, { fontSize: "16px" }]}>
              {item?.title}
            </Text>
            <Text style={styles.text}>{item?.description} </Text>
            <View
              style={[styles.flexBoxItem, { justifyContent: "flex-start" }]}
            >
              {item?.trip_advisor_suggestions?.map((data, index) => {
                let linkText = "";
                let icons = "";
                let color = "";
                if (data?.category === "accommodation") {
                  linkText = TEXT.BOOK_STAY;
                  icons = AccommodationIcon;
                  color = "#DD2590";
                } else if (data?.category === "restaurants") {
                  linkText = TEXT.BOOK_TABLE;
                  icons = RestaurantsIcon;
                  color = "#5720B7";
                } else if (data?.category === "activities") {
                  linkText = TEXT.BOOK_ACTIVITY;
                  icons = ActivityIcon;
                  color = "#DC6803";
                }
                return (
                  <View style={styles.tripmateCard}>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: "3px",
                      }}
                    >
                      <Image
                        src={icons}
                        alt=""
                        style={{ width: "20px", marginRight: "5px" }}
                      />
                      <Text
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          fontFamily: "Inter",
                        }}
                      >
                        {capitalizeFirstLetter(data?.category)}
                      </Text>
                    </View>
                    <Link
                      src={data?.web_url || "#"}
                      style={{ fontSize: "10px" }}
                    >
                      {linkText}
                    </Link>
                  </View>
                );
              })}
            </View>
          </View>
        </View>
        <View style={styles.footer} fixed>
          <Text
            style={[styles.text, { color: "#000" }]}
            render={({ pageNumber, totalPages }) =>
              `Ethoventures Itinerary Page  ${pageNumber - 1} of ${
                totalPages - 2
              }`
            }
            fixed
          />
        </View>
      </Page>
    );
  };

  const getTripOwnerName = () => {
    let name = capitalizeFirstLetter(item?.userFirstName || "");
    if (item?.tripMatesDetails?.length && name) {
      name = `${name}'s group`;
    }
    return name && name !== undefined ? `${name}.` : `you.`;
  };

  const GemsAndTips = ({ item }) => {
    return (
      <Page style={styles?.page} size="A4">
        <View style={styles.header} fixed>
          <Image style={[styles.image, { width: "120px" }]} src={LOGOImage} />
        </View>
        {item?.itinerary?.hidden_gems && (
          <View>
            <Text style={styles.pdfHeading}>
              Hidden Gem{" "}
              <Image style={{ width: "25px", height: "25px" }} src={StarIcon} />
            </Text>
            <Text style={styles.text}>{item?.itinerary?.hidden_gems}</Text>
          </View>
        )}
        <View>
          {item?.itinerary?.helpful_tips && (
            <View>
              <Text style={styles.pdfHeading}> Helpful Tips </Text>
              {item.itinerary.helpful_tips.map((tip, index) => (
                <View
                  key={`${index}-itinerary-unpaid-tip-details`}
                  style={{ paddingLeft: "20px" }}
                >
                  <Text style={styles.bullet}>•</Text>
                  <Text style={styles.text}>{tip}</Text>
                </View>
              ))}
            </View>
          )}
        </View>
        <View style={styles.footer} fixed>
          <Text
            style={[styles.text, { color: "000" }]}
            render={({ pageNumber, totalPages }) =>
              `Ethoventures Itinerary Page  ${pageNumber - 1} of ${
                totalPages - 2
              }`
            }
            fixed
          />
        </View>
      </Page>
    );
  };
  return (
    <Document>
      <Page
        size="A4"
        orientation="portrait"
        style={{ margin: "0", padding: "0" }}
      >
        <View>
          <View style={{ position: "relative" }}>
            <Image src={PDFCover} style={[styles.backgroundCover]} />
          </View>
          <View style={[styles.CoverDetails]}>
            <View style={{ marginLeft: "90px" }}>
              <Image
                style={[styles.image, { width: "120px" }]}
                src={LOGOImage}
              />
            </View>
            {/* <Text style={[styles.pdfHeading,{fontSize:'24px', width:'50%'}]}> Personalized itinerary tailored for you </Text> */}
            <Text
              style={[styles.pdfHeading, { fontSize: "20px", width: "50%" }]}
            >{`Personalized itinerary tailored for ${getTripOwnerName()}`}</Text>
          </View>
        </View>
      </Page>
      <Page1DetailsComponent item={item} />
      {item?.tripMatesDetails?.length > 0 && (
        <Page style={styles?.page} size="A4">
          <View style={styles.header} fixed>
            <Image style={[styles.image, { width: "120px" }]} src={LOGOImage} />
          </View>

          {item?.tripMatesDetails?.map((tripMate, index) =>
            generateTripMateComponent(tripMate, index)
          )}
          <View style={styles.footer} fixed>
            <Text
              style={[styles.text, { color: "000" }]}
              render={({ pageNumber, totalPages }) =>
                `Ethoventures Itinerary Page  ${pageNumber - 1} of ${
                  totalPages - 2
                }`
              }
              fixed
            />
          </View>
        </Page>
      )}
      <OverViewDetailsComponent item={item} />
      {item?.itinerary?.itinerary?.map((item, index) =>
        myCustomItinerary(item, index)
      )}
      <GemsAndTips item={item} />
      <Page style={styles?.page} size="A4" orientation="portrait">
        <View style={styles.header} fixed>
          <Image style={[styles.image, { width: "120px" }]} src={LOGOImage} />
        </View>
        <Image
          src={PDFThankyou}
          alt=""
          style={{
            height: "150px",
            width: "150px",
            margin: "150px auto 10px",
            borderRadius: "50%",
          }}
        />
        <Text
          style={[styles.pdfHeading, { fontSize: "16px", textAlign: "center" }]}
        >
          {" "}
          Thank you{" "}
        </Text>
      </Page>
    </Document>
  );
};

export default MyDocument;
