import React, { useRef, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import RadioButtonHOC from "../../../components/HOCs/radiobutton";
import { Quizmobimg } from "../../../assets/images";
import { QuizInnerBox } from "./formStyle";
import Loader from "../../../components/common/loader";
import { ChevLeft } from "../../../assets/icons";
import Image from "../../../components/common/image";

const PersonalityForm = (props) => {
  const {
    image,
    mobileViewImage,
    questionData,
    selected,
    getData,
    totalQuestions,
    currentSteps,
    isShowLoading,
    handleBackButton,
    isMobile
  } = props;

  const containerRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (containerRef.current?.scrollTop) {
        if (currentSteps + 1 !== totalQuestions) {
          containerRef.current.scrollTop = 0;
        }
      }
    }, isMobile ? 980 : 100);
  }, [currentSteps]);

  return (
    <>
      <QuizInnerBox className="quiz-main-wrapper" image={image}>
        <Container fluid>
          <Row>
            <Col lg={6} md={12} sm={12} className="p-0">
              <div className="quiz-img-wrap">
                <div className="quiz-img-bg"></div>
                <div className="quiz-img-title">Personality Quiz</div>
              </div>
            </Col>
            {isShowLoading ? (
              <Loader size="sm" dimension="row" className="spinner-loader" />
            ) : (
              <Col lg={6} md={12} sm={12} className="p-0">
                <div className="quiz-content-wrap">
                  <div className="progress-wrapper">
                    {Array(totalQuestions)
                      .fill()
                      .map((_, index) => (
                        <span
                          key={`step-personality-${index}`}
                          className={`progress-item ${
                            index <= currentSteps ? "active" : ""
                          }`}
                        ></span>
                      ))}
                  </div>
                  <div className="quiz-mobile-content-wrap">
                    <div className="quiz-mobile-head-area">
                      <div className="quiz-mobile-back-btn">
                        {currentSteps > 0 && (
                          <ChevLeft onClick={() => handleBackButton()} />
                        )}
                      </div>
                      <div className="quiz-img-title-mobile">
                        Personality quiz
                      </div>
                    </div>
                    <Image
                      src={mobileViewImage || Quizmobimg}
                      alt=""
                      className="quiz-mob-img"
                    />
                  </div>
                  <div className="quiz-question">{questionData?.label}</div>
                  <div className="quiz-item-wrap" ref={containerRef}>
                    <RadioButtonHOC
                      uid="personalityOptions"
                      name="personality"
                      data={questionData?.options}
                      getSelected={getData}
                      selectedItem={selected}
                    />
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </QuizInnerBox>
    </>
  );
};

export default PersonalityForm;
