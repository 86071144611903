import React, { useRef } from "react";
import { useSelector } from "react-redux";
import PreTravelQuizHOC from ".";
import { LABELS, TEXT } from "../../../utils/constants";
import { GroupTravel } from "../../../assets/images";
import { Copy } from "../../../assets/icons";
import { toast } from "react-toastify";
import copy from "copy-to-clipboard";
import Button from "../../../components/common/button";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../utils/constants/routes";
import Image from "../../../components/common/image";

const Invitefriend = () => {
  const navigate = useNavigate();
  const { preTravelQuizData } = useSelector((state) => state?.travel);

  const textRef = useRef();
  const copyToClipboard = () => {
    let copyText = textRef?.current?.value;
    let isCopy = copy(copyText);
    if (isCopy) {
      toast.info("Copied to Clipboard");
    }
  };

  const handleContinue = () => {
    navigate(ROUTES?.travelDays);
  };

  return (
    <PreTravelQuizHOC
      description={TEXT.ON_INVITE_FRIEND}
      label={LABELS.INVITE_FRIEND}
      image={GroupTravel}
      path={ROUTES?.travelSelect}
    >
      <div className="quiz-mobile-content-wrap">
        <div className="quiz-question"> Group Getaway </div>
        <Image src={GroupTravel} alt="" className="quiz-mob-img" />
        <div className="quiz-question">{LABELS.INVITE_FRIEND}</div>
        <div className="description quiz-mobile-description">
          {TEXT.ON_INVITE_FRIEND}
        </div>
      </div>
      <label className="mt-3 mb-2">Invite your friends</label>
      <div className="quiz-copy-option">
        <input
          ref={textRef}
          value={preTravelQuizData?.inviteLink}
          disabled
          type={"text"}
        />
        <span className="ms-2" onClick={copyToClipboard}>
          <Copy />
        </span>
      </div>
      <Button text={"Continue"} onClick={handleContinue} />
    </PreTravelQuizHOC>
  );
};

export default Invitefriend;
