import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  deleteAllTravelQuesData,
  updateItineraryCreatePayload,
} from "../../state/travel/actions";
import useQuiz from "../../services/quiz";
import { ROUTES } from "../constants/routes";
import {
  removeInvitedUserDetails,
  getInvitedUserId,
  getItineraryId,
} from "../../utils/utilityFunctions";
import useSignup from "../../services/auth";
import useUserData from "../../services/user";

const useItineraryOperations = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { generateItineraries, generateItinerariesForInvitedUser } = useQuiz();
  const { updateUserProfile } = useSignup();
  const { getUser } = useUserData();

  const invitedUserId = getInvitedUserId() || "";
  const invitedItineraryId = getItineraryId() || "";
  const { itineraryCreatePayload } = useSelector((state) => state.travel);

  const handleGenerateItineraries = (payloadData = null) => {
    setIsLoading(true);
    let createItineraries = generateItineraries;
    if (invitedUserId) {
      createItineraries = generateItinerariesForInvitedUser;
    }
    createItineraries(
      payloadData || itineraryCreatePayload,
      invitedItineraryId,
      invitedUserId
    )
      .then((response) => {
        dispatch(updateItineraryCreatePayload(null));
        dispatch(deleteAllTravelQuesData());
        removeInvitedUserDetails();
        if (response?.isSuccess) {
          navigate(ROUTES?.travelSuccess);
        } else {
          navigate(ROUTES?.home);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleUpdatePersonality = (userId) => {
    setIsLoading(true);
    updateUserProfile(userId, { personalityType: itineraryCreatePayload?.personalityId })
      .then((response) => {
        if (response?.isSuccess) {
          getUser(userId);
          handleGenerateItineraries();
          return;
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return { isLoading, handleGenerateItineraries, handleUpdatePersonality };
};

export default useItineraryOperations;
