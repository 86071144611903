import {
  SAVE_PERSONALITY_QUESTIONS,
  SAVE_USER_ANSWERS,
  SAVE_CURRENT_STEPS,
  DELETE_ALL_PERSONALITY_QUES_DATA,
} from "./constants";

const initialState = {
  allQuestions: [],
  userAnswers: [],
  currentSteps: 0,
};

const personalityReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_PERSONALITY_QUESTIONS:
      return {
        ...state,
        allQuestions: action.payload,
        userAnswers: [],
        currentSteps: 0,
      };

    case SAVE_USER_ANSWERS:
      const { payload } = action;
      const updatedAnswers = [...state.userAnswers];
      const itemIndex = updatedAnswers.findIndex(
        (item) => item.questionId === payload.questionId
      );
      itemIndex !== -1
        ? (updatedAnswers[itemIndex] = payload)
        : updatedAnswers.push(payload);

      return { ...state, userAnswers: updatedAnswers };

    case SAVE_CURRENT_STEPS:
      return { ...state, currentSteps: action.payload };

    case DELETE_ALL_PERSONALITY_QUES_DATA:
      return {
        ...state,
        allQuestions: [],
        userAnswers: [],
        currentSteps: 0,
      };
    default:
      return state;
  }
};

export default personalityReducer;
