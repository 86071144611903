import styled from "styled-components";

export const CheckboxStyle = styled.div`
    .radio-ui-box {
        margin: 0;
        input[type="checkbox"] {
        position: absolute;
        opacity: 0;
        + label {
            opacity: 1;
            &:before {
            content: "";
            background-color: ${({ theme }) =>theme.colors.light.textwhiteClr};
            /* border-radius: 100%; */
            border: 0 solid #c1c7cd;
            display: inline-block;
            width: 25px;
            height: 25px;
            position: relative;
            top: 0;
            vertical-align: top;
            cursor: pointer;
            text-align: center;
            transition: all 250ms ease;
            box-shadow: inset 0 0 0 6px #c1c7cd;
            }
        }
        &:checked {
            + label {
            &:before {
                background-color: ${({ theme }) =>theme.colors.light.uiClrBlue};
                box-shadow: inset 0 0 0 5px
                ${({ theme }) => theme.colors.light.textwhiteClr};
                border: 1px solid ${({ theme }) => theme.colors.light.uiClrBlue};
            }
            }
        }
        &:focus {
            + label {
            &:before {
                outline: none;
            }
            }
        }
        &:disabled {
            + label {
            &:before {
                box-shadow: inset 0 0 0 4px #f4f4f4;
                border-color: darken(#f4f4f4, 25%);
                background: darken(#f4f4f4, 25%);
            }
            }
        }
        + label {
            &:empty {
            &:before {
                margin-right: 0;
            }
            }
        }
        }
    }
`;