import React from "react";
import { ItineraryOverviewInner } from "./style";
import { Col, Row } from "react-bootstrap";

const ItineraryOverview = ({ item, ispdf }) => {
  return (
    <ItineraryOverviewInner>
      {
        ispdf ? null : <>

          <span className="tr-overview-title">Overview</span>
          <span className="tr-overview-desc">{item?.itinerary?.overview}</span>
        </>
      }

      <div className="tr-overview-cards-wrap">
        <Row>
          <Col lg={3} md={6} xs={12}>
            <div className="tr-overview-single-card">
              <span className="tr-count">1</span>
              <span className="tr-card-title">Type of vacation</span>
              <span className="tr-card-desc">
                {item?.itinerary?.vacation_type || " "}
              </span>
            </div>
          </Col>
          <Col lg={3} md={6} xs={12}>
            <div className="tr-overview-single-card">
              <span className="tr-count">2</span>
              <span className="tr-card-title">Travel Personality</span>
              <span className="tr-card-desc">
                {item?.itinerary?.personality || " "}
              </span>
            </div>
          </Col>
          <Col lg={3} md={6} xs={12}>
            <div className="tr-overview-single-card">
              <span className="tr-count">3</span>
              <span className="tr-card-title">Travel Season</span>
              <span className="tr-card-desc">
                {item?.itinerary?.vacation_season || " "}
              </span>
            </div>
          </Col>
          <Col lg={3} md={6} xs={12}>
            <div className="tr-overview-single-card">
              <span className="tr-count">4</span>
              <span className="tr-card-title">Full Trip Budget</span>
              <span className="tr-card-desc">
                {item?.metaData[0]?.total_budget}
              </span>
            </div>
          </Col>
        </Row>
      </div>
    </ItineraryOverviewInner>
  );
};

export default ItineraryOverview;
