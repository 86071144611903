import styled from "styled-components";
import { PostSignupBg, WhySignupBg } from "../../../assets/images";

export const TravelQuizInnerBox = styled.div`
  @media(max-width:767px) {
    .quiz-mobile-back-btn {
          position: absolute;
          top: calc(100% - 89.5vh);
        }
  }
  @media(min-width:767px) {
    .quiz-mobile-back-btn {
      display:none;
    }
  }
  .previousbtn {
    position: absolute;
    top: calc(100% - 90%);
    padding-left: 80px;
    color: ${({ theme }) => theme.colors.light.PrimaryThemeClr};
    svg {
      path {
        stroke: ${({ theme }) => theme.colors.light.PrimaryThemeClr};
      }
    }
    @media (max-width:767px) {
      display:none;
    }
    @media (min-width:768px) and (max-width:1023px) {
       top:auto;
    }
  }
  .quiz-content-wrap {
    height: 100vh;
    padding: 30px 80px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 767px) {
      padding: 26px;
      height: auto;
      margin-top: 70px;
    }
    @media (min-width:768px) and (max-width:1023px) { height: auto ; margin-top : 50px}
    .quiz-question {
      color: ${({ theme }) => theme.colors.light.titleClr};
      font-family: var(--secondary-font);
      font-size: ${({ theme }) => theme.fontSizes.font32};
      font-weight: ${({ theme }) => theme.fontWeight.fontWeight700};
      line-height: 32px;
      margin-bottom: 40px;
      @media (max-width: 767px) {
        color: ${({theme }) => theme.colors.light.quizQusClr};
        text-align: center;
        font-size: ${({ theme }) => theme.fontSizes.font20};
        line-height: 136.6%;
        margin-bottom: 20px;
        padding: 0 10px;
      }
    }
    .res-invite {
      @media (max-width: 767px) {
        display:none;
      }
    }
    .quiz-copy-option {
      border: 1px solid #ddd;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      border-radius: 8px;
      input {
        background-color: #fff;
        border: 0;
        width:100%;
        &:active, &:focus {
          box-shadow: none;
          outline:none;
        }
      }
      span {
        cursor: pointer;
      }
    }
    .quiz-mobile-description {
      @media (max-width: 767px) {
        display: block !important;
        text-align: center;
      }
    }
    .description {
      @media (max-width: 767px) {
        display: none;
      }
    }
    .light-blue-btn {
        background-color:${({ theme }) => theme.colors.light.lightBlueClr1};
        border: 1px solid ${({ theme }) => theme.colors.border.bdrLightBlue};
        color: ${({ theme }) => theme.colors.light.PrimaryThemeClr};
        font-weight: ${({ theme }) => theme.fontWeight.fontWeight700};
    }
  }
  .quiz-img-wrap {
    position: relative;
    @media (max-width: 767px) {
      display: none;
    }
    .quiz-img-bg {
      min-height: 100vh;
      background-image: url(${(props) => props.image});
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 100% top;
      &:before {
        content: "";
        background: linear-gradient(
          153deg,
          rgba(0, 0, 0, 0.97) 0,
          rgba(0, 0, 0, 0.97) 62.45%,
          rgba(30, 21, 154, 0.97) 100%
        );
        width: 100%;
        display: block;
        height: 100vh;
        position: absolute;
        bottom: 0;
        top: auto;
        opacity: 0.3;
      }
      @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
        /* min-height: 370px; */
        min-height: 500px;
        background-position: center center;
      }
    }
    .quiz-img-title {
      color: ${({ theme }) => theme.colors.light.textwhiteClr};
      font-family: var(--primary-font);
      font-size: 76px;
      font-style: normal;
      font-weight: 500;
      line-height: 74px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      padding-top: 46px;
      text-align: center;
      height: 240px;
      background: linear-gradient(
        180deg,
        rgba(0, 8, 188, 0.72) 13.1%,
        rgba(12, 13, 56, 0) 100%
      );
      @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
        padding-top: 170px;
      }
      @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
        padding-top: 60px;
        font-size: 53px;
      }
    }
    img {
      width: 100%;
      height: 100vh;
    }
  }
  .progress-wrapper {
    width: 100%;
    display: flex;
    gap: 4px;
    margin: 0 0 20px 0;
    @media (max-width: 767px) {
      margin: 0 0 10px 0;
    }
    .progress-item {
      border-radius: 5px;
      height: 6px;
      flex: 1 0 0;
      display: block;
      background: #eaecf0;
    }
    .progress-item.active {
      background: var(--primary-500, #2970ff);
    }
  }

  .quiz-item-wrap {
    .logged-travel-style {
        .quiz-single-item {
            min-height: 130px;
        }
    }
    .quiz-single-item {
      padding: 16px 15px;
      border-radius: 6px;
      border: 1px solid var(--primary-100, #d1e0ff);
      background: var(--primary-25, #f5f8ff);
      margin-bottom: 16px;
      cursor: pointer;
      width: 98%;
      display: block;
      @media (max-width: 767px) {
        width: 100%;
      }
      .radio-ui-box {
        margin: 0;
        input[type="radio"] {
          position: absolute;
          opacity: 0;
          + label {
            opacity: 1;
            &:before {
              content: "";
              background-color: ${({ theme }) =>theme.colors.light.textwhiteClr};
              border-radius: 100%;
              border: 0 solid #c1c7cd;
              display: inline-block;
              width: 25px;
              height: 25px;
              position: relative;
              top: -0.2em;
              vertical-align: top;
              cursor: pointer;
              text-align: center;
              transition: all 250ms ease;
              box-shadow: inset 0 0 0 6px #c1c7cd;
            }
          }
          &:checked {
            + label {
              &:before {
                background-color: ${({ theme }) =>theme.colors.light.uiClrBlue};
                box-shadow: inset 0 0 0 5px
                  ${({ theme }) => theme.colors.light.textwhiteClr};
                border: 1px solid ${({ theme }) => theme.colors.light.uiClrBlue};
              }
            }
          }
          &:focus {
            + label {
              &:before {
                outline: none;
              }
            }
          }
          &:disabled {
            + label {
              &:before {
                box-shadow: inset 0 0 0 4px #f4f4f4;
                border-color: darken(#f4f4f4, 25%);
                background: darken(#f4f4f4, 25%);
              }
            }
          }
          + label {
            &:empty {
              &:before {
                margin-right: 0;
              }
            }
          }
        }
      }
      label {
        color: #505050;
        font-family: var(--secondary-font);
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: 0.1px;
        opacity: 1;
        display: flex;
        align-items: center;
        gap: 20px;
        cursor: pointer;
        .radio-label-content {
          flex:1;
        }
        h3 {
          color: var(--gray-700, #344054);
          font-size: ${({ theme }) => theme.fontSizes.font20};
          font-weight: ${({ theme }) => theme.fontWeight.fontWeight500};
          font-style: normal;
          line-height: 30px; 
        }
      }
      &:hover {
        border-color: ${({ theme }) => theme.colors.light.uiClrBlue};
        box-shadow: 0 2px 4px -2px rgba(16, 24, 40, 0.06),
          0 4px 8px -2px rgba(16, 24, 40, 0.1);
        label {
          opacity: 1;
        }
      }
      &.active {
        border-color: ${({ theme }) => theme.colors.light.uiClrBlue};
        box-shadow: 0 2px 4px -2px rgba(16, 24, 40, 0.06),
          0 4px 8px -2px rgba(16, 24, 40, 0.1);
        label {
          opacity: 1;
        }
      }
      & .radio-ui-box {
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .travel-grp {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    .quiz-single-item-disable {
      opacity: 0.6;
    }
    .quiz-numbers {
      display: flex;
      align-items: center;
      button {
        background-color: transparent;
        border: 0;
         &:disabled {
          opacity: 0.3;
         }
         .quiz-numbers-item {
          border: 1px solid ${({ theme }) => theme.colors.light.contentText};
          background-color: ${({ theme }) => theme.colors.light.textwhiteClr};
          border-radius: 50%;   
          height: 25px;
          width: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 20px;
          span {
            margin-top: -3px;
          }
         }
      }
      .quiz-number-text {
        color: ${({ theme }) => theme.colors.light.contentText};
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        margin: 0 15px; 
      }
    }
  }
  .quiz-mobile-content-wrap {
    display: none;
  }
  @media (max-width: 767px) {
    .quiz-mobile-content-wrap {
      display: block;
      .quiz-mobile-head-area {
        display: flex;
        align-items: center;
        gap: 15px;
        margin-bottom: 16px;
        .quiz-img-title-mobile {
          color: ${({ theme }) => theme.colors.light.titleClr};
          text-align: center;
          font-family: var(--secondary-font);
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: 56px;
          width:100%;
        }
        .quiz-suc-title-mobile {
          font-size: 30px;
          line-height: 34px;
        }
      }
      .quiz-mob-img {
        display: block;
        margin: 0 auto 25px;
        max-width: 200px;
        width: 100%;
        max-height: 200px;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }
  .step-form-footer {
    width: 50%;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0px 82px 30px;
    text-align: right;
    z-index: 99;
    background: ${({ theme }) => theme.colors.light.textwhiteClr};
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      width: 100%;
      position:fixed;
      padding: 15px 82px;
    }
    @media (max-width: 767px) {
      width: 100%;
      position: fixed;
      padding: 15px 82px;
    }
    .step-form-footer-inner {
      display: flex;
      gap: 10px;
      justify-content: end;
      @media (max-width: 767px) {
        display: flex;
      }
    }
    .step-btn {
      border-radius: 8px;
      padding: 7px 16px;
      font-family: var(--secondary-font);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      height: 40px;
      width: auto;
      @media (max-width: 767px) {
        width: 100%;
        margin: 5px 0;
      }
    }
    .step-btn-back {
      border-radius: 8px;
      border: 1px solid var(--gray-300, #d0d5dd);
      background: ${({ theme }) => theme.colors.light.textwhiteClr};
      box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
      color: ${({ theme }) => theme.colors.light.titleClr};
    }
    .step-btn-next {
      border: 1px solid var(--primary-600, #155eef);
      background: var(--primary-600, #155eef);
      box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
      color: ${({ theme }) => theme.colors.light.textwhiteClr};
    }
  }
`;

export const WhySignUpStyled = styled.div`
    .why-signup-grp {
      @media (max-width: 767px) {
        /* margin-top: 80px; */
      }
      .signup-item {
        h4 {
          color: ${({ theme }) => theme.colors.light.titleClr};
          font-size: ${({ theme }) => theme.fontSizes.font30};
          font-style: normal;
          font-weight: ${({ theme }) => theme.fontWeight.fontWeight600};
          line-height: 38px; 
        }
        h5 {
          color: ${({ theme }) => theme.colors.light.titleClr};
          font-size: ${({ theme }) => theme.fontSizes.font20};
          font-style: normal;
          font-weight: ${({ theme }) => theme.fontWeight.fontWeight600};
          line-height: 30px; 
          margin-bottom: 0;
        }
      }
      .guest-btn  {
        background-color:${({ theme }) => theme.colors.light.lightBlueClr1};
        border: 1px solid ${({ theme }) => theme.colors.border.bdrLightBlue};
        color: ${({ theme }) => theme.colors.light.PrimaryThemeClr};
        font-weight: ${({ theme }) => theme.fontWeight.fontWeight700};
      }
      .signup-inner-item {
          margin: 25px 0;
          img {
            margin-bottom: 10px;
            width:60px;
          }
      }
    }
    .signup-bg-img {
        min-height: 100vh;
        background: url(${WhySignupBg});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center right;
        @media(max-width:767px) {
            display: none;
        }
      }
      .post-signup-grp {
          .post-signup-inner {
             .content {
                color: #000;
                text-align: center;
                font-family: var(--primary-font);
                font-size: ${({ theme }) => theme.fontSizes.font18};
                font-style: normal;
                font-weight: ${({ theme }) => theme.fontWeight.fontWeight400};
                line-height: 24px;
                margin: 40px 0;
                @media (max-width:767px) {
                  margin-bottom: 10em;
                }
             }
          }
      }
      .post-signup-bg {
          background: url(${PostSignupBg});
          background-size: cover;
      }
`;    