import apiClient from "../../network/restAPI";
import {
  PERSONALITY_TYPES,
  USER_CHECK_PERSONALITY,
} from "../../network/restAPI/apiRoutes";
import { generateError, Success } from "../useCommonSerivce";

export default function usePersonality() {
  const getPersonalityTypes = async () => {
    return new Promise(async (resolve) => {
      try {
        const data = await apiClient.get(PERSONALITY_TYPES);
        resolve(Success(data));
      } catch (error) {
        resolve(generateError(error));
      }
    });
  };

  const checkUserPersonality = async (params) => {
    return new Promise(async (resolve) => {
      try {
        const data = await apiClient.post(USER_CHECK_PERSONALITY, params);
        resolve(Success(data));
      } catch (error) {
        resolve(generateError(error));
      }
    });
  };

  return {
    getPersonalityTypes,
    checkUserPersonality,
  };
}


