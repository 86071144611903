import styled from "styled-components";
import { LoginBg, SignUpBg } from "../../assets/images";

export const LoginWrapperStyled = styled.div`
    align-items: stretch;
    @media(max-width:767px) {
       flex-direction:column;
    }
    .left-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 100vh;
        @media(max-width:1023px) {
            height: 100vh;
        }
    }
    .Form-content {
        &__signUp {
            width: 60%;
            margin: 50px auto 30px;
            /* height: calc(100vh - 80px); */
            display: flex;
            flex-direction: column;
            justify-content: center;
            @media(max-width:1023px) {
                 width: 80%;
           }
           ul {
            margin: 0;
            li {
                margin-bottom:5px;
                color: ${({theme }) => theme.colors.light.lightDarktext};
                font-size: ${({theme }) => theme.fontSizes.font14};
                svg {
                    width: 18px;
                    margin-right:5px;
                }
            }
           }
        }
        h1 {
            line-height: 32px;
            color: ${({theme }) => theme.colors.light.titleClr};
            font-family: var(--secondary-font);
            font-size: ${({theme }) => theme.fontSizes.font24};
            font-weight: ${({theme }) => theme.fontWeight.fontWeight700};
            @media(max-width:767px) {
                font-size: ${({theme }) => theme.fontSizes.font23};
                line-height: 32px;
            }
        }
        .link-btn {
            text-decoration:none;
            font-size: ${({theme }) => theme.fontSizes.font14};
            font-weight: ${({theme }) => theme.fontWeight.fontWeight600};
            margin-left: 5px;   
        }
        .input-check-grp {
            display: flex;
            justify-content: space-between;
            &__item {
                display: flex;
                align-items:center;
                input {
                    margin-right: 5px;
                    cursor: pointer;
                }
            }
        }
        .link-text {
            color: #475467;
            margin: 30px 0 0;
            font-size: ${({theme }) => theme.fontSizes.font14};
            font-weight: ${({theme }) => theme.fontWeight.fontWeight600};
        }
        .Otp-form-grp {
            .link-text {
                color: #475467;
                margin: 20px 0 0;
                font-size: ${({ theme }) => theme.fontSizes.font14};
                font-weight: ${({ theme }) => theme.fontWeight.fontWeight600};
                .link-btn {
                    color: ${({ theme }) => theme.colors.light.PrimaryThemeClr};
                    display: inline-block;
                    margin-left: 7px;
                    cursor: pointer;
                }
            }
            .otp-input {
                div {
                justify-content: space-between;
                margin-top: 10px;
                }
            }
            .invalid {
                color: ${({theme }) => theme.colors.light.errorClr};
                font-size: ${(props) => props.theme.fontSizes.font12};
                font-weight: ${(props) => props.theme.fontWeight.fontWight400};
                line-height: 24px;
                height: 20px;
            }
        }
    }
    .Login-content {
        width: 60%;
        margin: 50px auto ;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media(max-width:1023px) {
            width: 90%;
        }
    }
    .login-otp {
        width: 50%;
        @media(max-width:1023px) {
            width: 90%;
        }
    }
    .Login-wrapper {
        /* min-height:calc(100vh - 40px); */
        min-height:100vh;
        background: url(${LoginBg});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center right;
        @media(max-width:767px) {
            display: none;
        }
    }
    .SignUp-wrapper {
        background: url(${SignUpBg});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center right;
        padding: 0;
        /* height: 100vh; */
        img {
            min-height: 100%;
            object-fit: cover;
            width: 100%;
            display: none;
            @media(max-width:767px) {
                display: none;
            }
        }
    }
`;