import dayjs from "dayjs";
export const validate = (values) => {
  const errors = {};

  if (!values.firstName) {
    errors.firstName = "Required";
  } else if (values.firstName.trim() === "") {
    errors.firstName = "Firstname cannot be blank";
  } else if (values.firstName.length < 3) {
    errors.firstName = "Firstname must be at least 3 characters";
  } else if (!/^[A-Za-z]+$/.test(values.firstName)) {
    errors.firstName = "Invalid firstname";
  }

  if (!values.lastName) {
    errors.lastName = "Required";
  } else if (values.lastName.trim() === "") {
    errors.lastName = "Lastname cannot be blank";
  } else if (values.lastName.length < 3) {
    errors.lastName = "Lastname must be at least 3 characters";
  } else if (!/^[A-Za-z]+$/.test(values.lastName)) {
    errors.lastName = "Invalid lastname";
  }

  if (!values.birthday) {
    errors.birthday = "Date of Birth is required.";
  } else {
    const minYear = 1901;
    const currentYear = dayjs().year();
    const enteredYear = values.birthday.getFullYear();

    if (enteredYear < minYear || enteredYear > currentYear) {
      errors.birthday = `Invalid Date of Birth.`;
    } else if (!isAgeValid(values.birthday, 13)) {
      errors.birthday = "Age must be at least 13 years.";
    }
  }

  return errors;
};

function isAgeValid(dateString, minAge) {
  const birthday = dayjs(dateString);
  if (!birthday.isValid()) {
    return false;
  }
  const today = dayjs();
  const ageInYears = today.diff(birthday, "year");
  return ageInYears >= minAge;
}
