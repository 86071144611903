import React from "react";
import AuthLayout from "../../layouts/auth";
import HomeLayout from "../../layouts/home";
import HomePage from "../../pages/web/homePage";
import NotFoundPage from "../../pages/web/notFoundPage";
import ForgetPassword from "../../views/auth/forgetpassword";
import Login from "../../views/auth/login";
import VerifyOtp from "../../views/auth/otp";
import Resetpassword from "../../views/auth/resetpassword";
import Resetpasswordsuccess from "../../views/auth/resetpassword/resetpasswordsuccess/index.";
import SignUp from "../../views/auth/signup";
import PostSignup from "../../views/auth/signup/postSignup";
import CreatePersonality from "../../views/web/personalityTest";
import Personality from "../../views/web/personalityTest/landingPage";
import Dashboard from "../../views/web/dashboard/index";
import EditProfile from "../../views/web/profile/index";
import Terms from "../../views/web/terms";
import ComingSoon from "../../views/web/comingsoon";
import PersonalityOverview from "../../views/web/personalityTest/personalityoverview";
import PersonalityQuizSuccess from "../../views/web/personalityTest/personalityQuizSuccess";
import CreateTravel from "../../views/web/travelTest";
import TravelStyle from "../../views/web/preTravelQuizSection/travelstyle";
import Invitefriend from "../../views/web/preTravelQuizSection/invitefriend";
import SelectTravellers from "../../views/web/preTravelQuizSection/selecttravellers";
import TravelBudget from "../../views/web/preTravelQuizSection/travelbudget/travelbudget";
import SelectDays from "../../views/web/preTravelQuizSection/selectdays";
import WhySignup from "../../views/web/preTravelQuizSection/whysignup";
import PaymentSuccess from "../../views/web/Payment/paymentSuccess";
import PaymentCanceled from "../../views/web/Payment/paymentCanceled";
import TravelQuizSuccess from "../../views/web/travelTest/travelQuizSuccess";
import ViewItinerary from "../../views/web/itinerary";
import ItineraryDetails from "../../views/web/itinerary/itineraryDetails";
import ItineraryAwaits from "../../views/web/travelTest/itineraryAwaits";
import GuestUserNearlyDone from "../../views/web/travelTest/guestUserNearlyDone";
import DemoLinks from "../../views/web/demolinks";
import Location from "../../views/web/preTravelQuizSection/location";
import GuestItineraryDetails from "../../views/web/guestItinerary";
import InvitedUser from "../../views/web/invite-user";
import ItineraryInprogress from "../../views/web/inProgessItinerary";
import VerifyEmail from "../../views/auth/verifyemail";
import SharePersonality from "../../views/web/personalityTest/personalityOverviewShare";
import ItineraryNotFound from "../../views/web/notFound/itineraryNotFound";

const WebRoutes = [
  {
    name: "home",
    element: <HomePage />,
    path: "",
    protected: false,
    navigation: true,
    layout: HomeLayout,
  },
  {
    name: "dashboard",
    element: <Dashboard />,
    path: "dashboard",
    protected: true,
    navigation: true,
  },
  {
    name: "view-itineraries",
    element: <ViewItinerary />,
    path: "view-itineraries",
    protected: true,
    navigation: true,
  },
  {
    name: "itinerary-details",
    element: <ItineraryDetails />,
    path: "itinerary-details",
    protected: false,
    navigation: true,
  },
  {
    name: "itinerary-details/guest",
    element: <GuestItineraryDetails />,
    path: "itinerary-details/guest",
    protected: false,
    navigation: true,
  },
  {
    name: "itinerary-in-progress",
    element: <ItineraryInprogress />,
    path: "itinerary-in-progress",
    protected: false,
    navigation: true,
  },
  {
    name: "edit-profile",
    element: <EditProfile />,
    path: "editprofile",
    protected: true,
    navigation: true,
    layout: AuthLayout,
  },
  {
    name: "personalityTest",
    element: <CreatePersonality />,
    path: "personalitytest",
    protected: false,
    navigation: true,
    layout: AuthLayout,
  },
  {
    name: "login",
    element: <Login />,
    path: "signin",
    protected: false,
    navigation: false,
    layout: AuthLayout,
  },
  {
    name: "signup",
    element: <SignUp />,
    path: "signup",
    protected: false,
    navigation: true,
    layout: AuthLayout,
  },
  {
    name: "postSignup",
    element: <PostSignup />,
    path: "post-signup",
    protected: false,
    navigation: true,
    layout: AuthLayout,
  },
  {
    name: "personality",
    element: <Personality />,
    path: "personality",
    protected: false,
    navigation: false,
  },
  {
    name: "terms",
    element: <Terms />,
    path: "terms",
    protected: false,
    navigation: false,
  },
  {
    name: "Logout",
    element: <div>Logout</div>,
    path: "logout",
    protected: false,
    navigation: true,
    layout: AuthLayout,
  },
  {
    name: "forgetPassword",
    element: <ForgetPassword />,
    path: "forgetpassword",
    protected: false,
    navigation: true,
    layout: AuthLayout,
  },
  {
    name: "otp",
    element: <VerifyOtp />,
    path: "otp",
    protected: false,
    navigation: true,
    layout: AuthLayout,
  },
  {
    name: "resetPassword",
    element: <Resetpassword />,
    path: "resetpassword",
    protected: false,
    navigation: true,
    layout: AuthLayout,
  },
  {
    name: "comingSoon",
    element: <ComingSoon />,
    path: "comingsoon",
    protected: false,
    navigation: true,
  },
  {
    name: "resetPasswordSuccess",
    element: <Resetpasswordsuccess />,
    path: "resetpassword-success",
    protected: false,
    navigation: true,
    layout: AuthLayout,
  },
  {
    name: "personalityOverview",
    element: <PersonalityOverview />,
    path: "personality-overview",
    protected: false,
    navigation: true,
    // layout: AuthLayout,
  },
  {
    name: "personalitySuccess",
    element: <PersonalityQuizSuccess />,
    path: "personality-success",
    protected: false,
    navigation: true,
    layout: AuthLayout,
  },
  {
    name: "travelQuiz",
    element: <CreateTravel />,
    path: "travel-quiz",
    protected: false,
    navigation: false,
    layout: AuthLayout,
  },
  {
    name: "paymentSuccess",
    element: <PaymentSuccess />,
    path: "payment-success",
    protected: false,
    navigation: true,
    layout: AuthLayout,
  },
  {
    name: "paymentCanceled",
    element: <PaymentCanceled />,
    path: "payment-canceled",
    protected: false,
    navigation: true,
    layout: AuthLayout,
  },
  {
    name: "travelStyle",
    element: <TravelStyle />,
    path: "travel-style",
    protected: false,
    navigation: true,
    layout: AuthLayout,
  },
  {
    name: "inviteFriend",
    element: <Invitefriend />,
    path: "travel-invite",
    protected: false,
    navigation: true,
    layout: AuthLayout,
  },
  {
    name: "selectTravellers",
    element: <SelectTravellers />,
    path: "travel-group",
    protected: false,
    navigation: true,
    layout: AuthLayout,
  },
  {
    name: "travelBudget",
    element: <TravelBudget />,
    path: "travel-budget",
    protected: false,
    navigation: true,
    layout: AuthLayout,
  },
  {
    name: "selectDays",
    element: <SelectDays />,
    path: "travel-days",
    protected: false,
    navigation: true,
    layout: AuthLayout,
  },
  {
    name: "travelSuccess",
    element: <TravelQuizSuccess />,
    path: "travel-success",
    protected: false,
    navigation: true,
    layout: AuthLayout,
  },
  {
    name: "whySignup",
    element: <WhySignup />,
    path: "why-signup",
    protected: false,
    navigation: true,
    layout: AuthLayout,
  },
  {
    name: "itineraryAwaits",
    element: <ItineraryAwaits />,
    path: "itinerary-awaits",
    protected: false,
    navigation: true,
    layout: AuthLayout,
  },
  {
    name: "nearlyDone",
    element: <GuestUserNearlyDone />,
    path: "nearly-done",
    protected: false,
    navigation: true,
    layout: AuthLayout,
  },
  {
    name: "demoLinks",
    element: <DemoLinks />,
    path: "demo-links",
    protected: false,
    navigation: true,
    layout: AuthLayout,
  },
  {
    name: "travelLocation",
    element: <Location />,
    path: "travel-location",
    protected: false,
    navigation: true,
    layout: AuthLayout,
  },
  {
    name: "invitedUser",
    element: <InvitedUser />,
    path: "invited-user",
    protected: false,
    navigation: true,
    layout: AuthLayout,
  },
  {
    name: "verifyEmail",
    element: <VerifyEmail />,
    path: "verify-email",
    protected: false,
    navigation: false,
    layout: AuthLayout,
  },
  {
    name: "sharePersonality",
    element: <SharePersonality />,
    path: "personality-overview/:personalityId",
    protected: false,
    navigation: false,
    layout: AuthLayout,
  },
  {
    name: "itineraryNotFound",
    element: <ItineraryNotFound />,
    path: "itinerary-not-found",
    protected: false,
    navigation: false,
    layout: AuthLayout,
  },
  {
    path: "*",
    element: <NotFoundPage />,
    protected:false,
    navigation:true,
    layout:AuthLayout
  },
];

export default WebRoutes;
