import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";


import { convertToBase64 } from "../../../utils/utilityFunctions";
import { ItinerarysliderInner } from "./style";
import { appendQueryParametersInImage } from "../../../utils/utilityFunctions";


const Itineraryslider = ({ item, myPdf }) => {
  const [imageUrls, setImageUrls] = useState([]);
  const [overviewImage, setoverviewImage] = useState();

  useEffect(() => {
    if (item?.itinerary?.itinerary) {
      const imageURLs = item?.itinerary?.itinerary?.map((element) => {
        const url = element?.image?.url;
        return appendQueryParametersInImage(url);
      });
      Promise.all(imageURLs?.map((url) => convertToBase64(url)))
        .then((base64Images) => {
          const limitedImages = base64Images.slice(0, 5);
          setImageUrls(limitedImages);
        })
        .catch((error) => {
          console.error("Error converting images to base64:", error);
        });
    }
    if (item?.itinerary?.overview_image?.url) {
      const overviewImage = appendQueryParametersInImage(
        item?.itinerary?.overview_image?.url
      );
      convertToBase64(overviewImage)
        .then((base64) => {
          setoverviewImage(base64);
        })
        .catch((error) => {
          console.error("Error converting overviewimage to base64:", error);
        });
    }
  }, [item]);

  return (
    <ItinerarysliderInner>
      <div className="tr-slider-wrapper">
        <Container>
          <Row>
            <Col>
              <div className="tr-slider-info-area">
                <span className="tr-slider-title">
                  {["a", "e", "i", "o", "u"].includes(
                    item?.personality[0].toLowerCase()
                  )
                    ? "An"
                    : "A"}{" "}
                  {item?.personality} in{" "}
                  {item?.itinerary?.title_destination || item?.itinerary?.destination || " "}
                </span>
              </div>
            </Col>
          </Row>
        </Container>
        {item?.isPaid && myPdf && (
          <>
            <div className="container my-5">
              <div className="slider-img-box slider-img-box--mob">
                <img
                  src={overviewImage ? overviewImage : imageUrls[0]}
                  alt="Loading.."
                  width={"100%"}
                  className="img-fluid"
                />
              </div>
            </div>
          </>
        )}
      </div>
    </ItinerarysliderInner>
  );
};

export default Itineraryslider;
