import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Lock } from "../../../../assets/icons";
import { CheckIcon } from "../../../../assets/images/checkIcon";
import BackButton from "../../../../components/common/backbutton";
import Button from "../../../../components/common/button";
import AuthScreenHeading from "../../../../components/common/heading";
import Input from "../../../../components/common/input";
import useSignup from "../../../../services/auth";
import { setForgetPasswordEmail } from "../../../../state/user/actions";
import { defaultContext } from "../../../../states/context";
import { MSG } from "../../../../utils/constants";
import { ROUTES } from "../../../../utils/constants/routes";
import {
  containsSpecialChars,
  isContainOneNumber,
  isUserExist,
} from "../../../../utils/utilityFunctions";
import { validate } from "./validate";
const ResetPasswordForm = () => {
  const dispatch = useDispatch();
  const { contextState } = useContext(defaultContext);
  const { forgotPasswordReset } = useSignup();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { forgetPasswordEmail } = useSelector((state) => state?.user);

  let isShowToast = false;

  const initialValues = {
    password: "",
    confirmPassword: "",
    email: forgetPasswordEmail,
  };

  const dataToSend = (values) => {
    return {
      newPassword: values?.confirmPassword,
      otp: contextState?.forgetPasswordOTP,
    };
  };

  const onSubmit = (values, { resetForm }) => {
    const { password, confirmPassword } = values;
    if (password !== "" && confirmPassword !== "") {
      setLoading(true);
      forgotPasswordReset(dataToSend(values)).then((response) => {
        if (!response?.isSuccess) {
          resetForm(initialValues);
          setLoading(false);
          return navigate(ROUTES?.forgetPassword);
        }
        dispatch(setForgetPasswordEmail(""));
        toast.success(MSG?.ON_UPDATE_PASSWORD_SUCCESS);
        setTimeout(() => {
          setLoading(false);
          navigate(ROUTES?.resetPasswordSuccess);
        }, 2000);
      });
    }
  };

  const { values, errors, handleBlur, handleChange, handleSubmit, touched } =
    useFormik({
      initialValues,
      onSubmit,
      validate,
      enableReinitialize: true,
    });

  useEffect(() => {
    isUserExist(navigate);
  }, [navigate]);
  useEffect(() => {
    const isUser = isUserExist(navigate);
    if (!isUser && forgetPasswordEmail === "") {
      if (!isShowToast) {
        toast.info(MSG.ON_EMAIL_NOT_FOUND);
        isShowToast = true;
      }
      return navigate(ROUTES.forgetPassword);
    }
  }, [navigate]);
  return (
    <div className="Login-content Form-content">
      <div className="Form-content__signUp w-100">
        <div>
          <AuthScreenHeading
            Logo={Lock}
            mainHeading={"Set new password"}
            subHeading={
              "Your new password must be different to previously used passwords."
            }
          />
        </div>
        <Form onSubmit={handleSubmit}>
          <div>
            <Input
              placeholder={"Email"}
              type="email"
              label="Email"
              id="Email"
              disabled={true}
              name="email"
              value={values.email}
            />
          </div>
          <div>
            <Input
              required
              maxLength={"20"}
              type={"password"}
              placeholder={"Password"}
              label="Password"
              id="password"
              disabled={loading}
              name="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.password && touched.password && errors.password}
            />
          </div>
          <div>
            <Input
              required
              maxLength={"20"}
              type={"password"}
              placeholder={"Confirm password"}
              label="Confirm password"
              id="Confirm password"
              disabled={loading}
              name="confirmPassword"
              value={values.confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                errors.confirmPassword &&
                touched.confirmPassword &&
                errors.confirmPassword
              }
            />
          </div>
          <div className="mt-1">
            <ul>
              <li>
                <CheckIcon
                  fill={
                    !values?.password || values?.password?.length < 8
                      ? "#D0D5DD"
                      : "#008000"
                  }
                />
                Must be at least 8 characters{" "}
              </li>
              <li>
                <CheckIcon
                  fill={
                    !values?.password || !containsSpecialChars(values?.password)
                      ? "#D0D5DD"
                      : "#008000"
                  }
                />
                Must contain one special character{" "}
              </li>
              <li>
                <CheckIcon
                  fill={
                    !values?.password || !isContainOneNumber(values?.password)
                      ? "#D0D5DD"
                      : "#008000"
                  }
                />
                Must contain at least one number{" "}
              </li>
            </ul>
          </div>
          <div>
            <Button
              type="submit"
              text={"Reset password"}
              loading={loading}
              disabled={
                loading ||
                values?.confirmPassword === "" ||
                values?.password === ""
              }
              borderbtn
            />
          </div>
          <div>
            <BackButton path={"/signin"} />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
