import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Logo } from "../../../assets/icons";
import LoginForm from "./loginform";
import { Link, useNavigate } from "react-router-dom";
import useSignup from "../../../services/auth";
import AllAuth from "../../../components/common/auth";
import { Container, Row } from "react-bootstrap";
import { ROUTES } from "../../../utils/constants/routes";
import {
  checkFbLoginEmail,
  decodeFacebookToken,
  getGuestRoutePublicId,
  getInvitedUserId,
  getPersonalityId,
} from "../../../utils/utilityFunctions";
import useItineraryOperation from "../../../utils/hooks/useItineraryOperation";
import useUserData from "../../../services/user";
import CommonModal from "../../../components/common/modals/commonModal";
import EmailForm from "./emailForm";
import { deleteAllPersonalityQuesData } from "../../../state/personality/actions";

const Login = () => {
  const { getUser } = useUserData();
  const { socialLogin, updateUserProfile } = useSignup();
  const navigate = useNavigate();
  const { userDetails } = useSelector((state) => state?.user);
  const [loading, setLoading] = useState(false);
  const routePublicId = getGuestRoutePublicId();
  const { itineraryCreatePayload } = useSelector((state) => state.travel);
  const { handleUpdatePersonality } = useItineraryOperation();
  const [openModal, setOpenModal] = useState(false);
  const [dataToSend, setDataToSend] = useState(null);
  const personalityId = getPersonalityId();

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleLoginStart = () => {
    setLoading(true);
  };

  const getAccessToken = (data, provider) => {
    let token = "";
    if (provider === "google") {
      token = data?.access_token;
    } else if (provider === "facebook") {
      token = data?.accessToken;
    } else if (provider === "apple") {
      token = data?.authorization?.id_token;
    }
    return token;
  };

  const handleLoginSuccess = (data, provider) => {
    if (getAccessToken(data, provider)) {
      setLoading(true);

      if (provider === "facebook") {
        // await get decodeFacebookToken
        decodeFacebookToken(getAccessToken(data, provider))
          .then((res) => {
            // console.log("decodeFacebookToken", res);
            if (!res?.data?.email) {
              // email = res?.data?.email;
              checkFbLoginEmail(res?.data?.id).then((res) => {
                // console.log("checkFbLoginEmail promise", res);
                if (!res?.isSuccess) {
                  setDataToSend(data);
                  setOpenModal(true);
                  return;
                }
                handleSocialLogin(data, provider);
              });
            } else {
              handleSocialLogin(data, provider);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        // google, apple
        handleSocialLogin(data, provider);
      }
    }
    setLoading(false);
  };

  const handleLoginError = () => {
    setLoading(false);
  };

  const handleSocialLogin = (data, provider, email) => {
    socialLogin(getAccessToken(data, provider), provider, email)
      .then((response) => {
        if (response?.isSuccess) {
          if (
            itineraryCreatePayload &&
            Object.keys(itineraryCreatePayload)?.length &&
            response?.data?.data?.response?.email ===
              itineraryCreatePayload?.email
          ) {
            //new flow
            const { id } = response?.data?.data?.response;
            handleUpdatePersonality(id);
            return;
          }
          if (getInvitedUserId()) {
            getUser(response?.data?.data?.response?.id)
              .then((res) => {
                if (res?.isSuccess) {
                  if (personalityId && !res?.data?.personalityType?._id) {
                    updateUserProfile(res?.data?._id, {
                      personalityType: personalityId,
                    });
                  }
                  if (personalityId || res?.data?.personalityType?._id) {
                    return navigate(ROUTES?.travelQuiz);
                  } else {
                    dispatch(deleteAllPersonalityQuesData());
                    return navigate(ROUTES?.personalityTest);
                  }
                }
              })
              .catch(() => {
                return navigate(ROUTES?.personality);
              });
          } else if (routePublicId) {
            return navigate(
              `${ROUTES?.groupItinerary}?publicId=${routePublicId}`
            );
          } else if (response?.data?.data?.response?.isFirstLogin) {
            getUser(response?.data?.data?.response?.id)
              .then((res) => {
                if (res?.data?.personalityType?._id) {
                  return navigate(ROUTES?.travelStyle);
                } else {
                  return navigate(ROUTES?.personality);
                }
              })
              .catch(() => {
                return navigate(ROUTES?.personality);
              });
          } else {
            setTimeout(() => {
              navigate(ROUTES?.dashboard);
            }, 700);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <Container fluid>
      <Row>
        <div className="col-md-6 left-content">
          <div className="px-4 mt-3">
            <Link to="/">
              <Logo />
            </Link>
          </div>
          <div className="Login-content Form-content">
            <h1>Welcome back </h1>
            <p> Welcome back! Please enter your details </p>
            <div className="Form-content__GoogleLogin">
              <AllAuth
                handleLoginStart={handleLoginStart}
                handleLoginSuccess={handleLoginSuccess}
                handleLoginError={handleLoginError}
              />
            </div>
            <div>
              <LoginForm lodingSocialLogin={loading} />
            </div>
          </div>
          <div className="px-4">
            <p> © Ethoventures 2023</p>
          </div>
        </div>
        <div className="col-md-6 Login-wrapper"></div>
      </Row>
      <CommonModal
        size="md"
        show={openModal}
        closeHandler={() => setOpenModal(false)}
        showHeader={true}
        title={"Please add your email id"}
      >
        <EmailForm
          setOpenModal={setOpenModal}
          dataToSend={dataToSend}
          handleSocialLogin={handleSocialLogin}
        />
      </CommonModal>
    </Container>
  );
};

export default Login;
