import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Marquee from "react-fast-marquee";

const TravelItinerary = () => {
  return (
    <div className="home-wrapper__TravelItinerary" id="about">
      <Container>
        <Row>
          <Col md="7">
            <h1 className="heading"> travel itineraries FOR $1 PER DAY </h1>
          </Col>

          <Col md="5">
            <p>
              Bid farewell to endless reviews and meaningless searches. Just
              take our fun quiz, choose your destination, and get an itinerary
              that matches your interests. Traveling with others? We create
              group plans that cater to everyone's preferences!
            </p>
          </Col>
        </Row>
      </Container>

      <div className="home-wrapper__TravelItinerary__about">
        <div className="marqueeItem marquee-left">
          <Marquee>
            Your journey, your way, Your journey, your way, Your journey, your
            way, Your journey, your way, Your journey, your way, Your journey,
            your way, Your journey, your way, Your journey, your way, Your
            journey, your way, your way, Your journey, your way, Your journey,
            your way, Your journey, your way, Your journey, Your journey, your
            way, Your journey, your way, Your journey, your way, Your journey,
            your way, Your journey, your way, Your journey, your way, Your
            journey, your way, Your journey, your way, Your journey, your way,
            your way, Your journey, your way, Your journey, your way, Your
            journey, your way, Your journey, Your journey, your way, Your
            journey, your way, Your journey, your way, Your journey, your way,
            Your journey, your way, Your journey, your way, Your journey, your
            way, Your journey, your way, Your journey, your way, your way, Your
            journey, your way, Your journey, your way, Your journey, your way,
            Your journey, Your journey, your way, Your journey, your way, Your
            journey, your way, Your journey, your way, Your journey, your way,
            Your journey, your way, Your journey, your way, Your journey, your
            way, Your journey, your way, your way, Your journey, your way, Your
            journey, your way, Your journey, your way, Your journey,
          </Marquee>
        </div>

        <div className="marqueeItem marquee-right">
          <Marquee direction="right">
            Adventure unleashed, Adventure unleashed, Adventure unleashed,
            Adventure unleashed, Adventure unleashed,Adventure unleashed,
            Adventure unleashed, Adventure unleashed, Adventure unleashed,
            Adventure unleashed,Adventure unleashed, Adventure unleashed,
            Adventure unleashed, Adventure unleashed, Adventure unleashed,
            Adventure unleashed, Adventure unleashed, Adventure unleashed,
            Adventure unleashed, Adventure unleashed,Adventure unleashed,
            Adventure unleashed, Adventure unleashed, Adventure unleashed,
            Adventure unleashed,Adventure unleashed, Adventure unleashed,
            Adventure unleashed, Adventure unleashed, Adventure unleashed,
            Adventure unleashed, Adventure unleashed, Adventure unleashed,
            Adventure unleashed, Adventure unleashed,Adventure unleashed,
            Adventure unleashed, Adventure unleashed, Adventure unleashed,
            Adventure unleashed,Adventure unleashed, Adventure unleashed,
            Adventure unleashed, Adventure unleashed, Adventure unleashed,
          </Marquee>
        </div>
      </div>
    </div>
  );
};

export default TravelItinerary;
