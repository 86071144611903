import React from "react";
import CheckboxButton from "../../common/checkboxbutton";
import { CheckboxStyle } from "./style";

const CheckboxButtonHOC = ({
  data,
  name = "checkbox",
  selectedItem,
  uid = "unique",
  getSelected,
}) => {
  const handleChange = (e, option) => {
    if (getSelected) getSelected(option);
  };

  const renderOptions = (option, index) => {
    return (
      <CheckboxStyle>
        <label
          key={`${uid}-checkboxbutton-${index}-${option} `}
          className={
            selectedItem.includes(option?._id)
              ? "quiz-single-item active"
              : "quiz-single-item"
          }
          htmlFor={option?._id}
        >
          <div className="radio-ui-box">
            <CheckboxButton
              name={name}
              value={option?.label}
              onChange={(e) => handleChange(e, option)}
              checked={selectedItem?.includes(option?._id)}
              id={option?._id}
              className="radio-ui-box-inner"
            />
            <label htmlFor={option?._id} className="radio-label-ui">
              {option?.label}
            </label>
          </div>
        </label>
      </CheckboxStyle>
    );
  };

  return (
    <div>
      {Array.isArray(data) && data?.length ? (
        data?.map(renderOptions)
      ) : (
        <p>No data</p>
      )}{" "}
    </div>
  );
};

export default CheckboxButtonHOC;
