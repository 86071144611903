export const testimonials = [
  {
    title:
      " “Ethoventures saved me hours of Googling! My itinerary matched my interests perfectly - thank you for simplifying my trip planning! ”",
    name: " Sarah J.",
    // post: " Travel Land",
  },
  {
    title:
      " “I can't believe I didn't discover Ethoventures sooner. This is my new go-to for travel planning - it's a game-changer!”",
    name: " David P.",
    // post: " Travel Land",
  },
  {
    title:
      " “Ethoventures made our group trip a breeze! It created the perfect plan, keeping everyone smiling. Happy travelers, indeed!”",
    name: " Emily H. ",
    // post: " Travel Land",
  },
];
 