import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TEXT } from "../../../utils/constants";
import { Container, Row, Col } from "react-bootstrap";
import { OverviewStyled } from "./style";
import { QuizInnerBox } from "./formStyle";
import { perOverviewBg } from "../../../assets/images";
import Image from "../../../components/common/image";
import usePersonality from "../../../services/personality";
import Loader from "../../../components/common/loader";
import { updateUserDetails } from "../../../state/user/actions";
import { ItineraryWrapper } from "../itinerary/style";

const SharePersonality = () => {
  const dispatch = useDispatch();
  const { getPersonalityTypes } = usePersonality();
  const { personalityId } = useParams();
  const [loading, setLoading] = useState(true);
  const { userDetails } = useSelector((state) => state.user);
  const [personalityDetails, setPersonalityDetails] = useState(null);

  useEffect(() => {
    setLoading(true);
    getPersonalityList();
  }, []);

  const getPersonalityList = () => {
    if (personalityId) {
      setLoading(true);
      getPersonalityTypes()
        .then((res) => {
          if (res?.isSuccess) {
            const filterPersonality =
              Array?.isArray(res?.data?.data) && res?.data?.data?.length > 0
                ? res?.data?.data?.filter((_) => _?._id === personalityId)[0]
                : null;
            if (filterPersonality) {
              const data = {
                personalityType: { ...filterPersonality },
              };
              setPersonalityDetails(filterPersonality);
              return dispatch(updateUserDetails(data));
            }
            setPersonalityDetails(null);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      setPersonalityDetails(null);
    }
  };

  if (loading) {
    return (
      <div className="web-spinner-loader">
        <Loader
          size="lg"
          dimension="row"
          message={TEXT?.ON_GET_PERSONALITY_DETAILS}
        />
      </div>
    );
  }
  if (!userDetails?.personalityType?.name || !personalityDetails) {
    return (
      <ItineraryWrapper>
        <Container>
          <h1 className="text-secondary text-center mt-5">No data found</h1>
        </Container>
      </ItineraryWrapper>
    );
  }
  return (
    <QuizInnerBox>
      <Container fluid>
        <OverviewStyled>
          <Row className="Overview-wrapper Overview-share-wrapper">
            <Col lg={6} md={12} sm={12} className="p-0">
              <div
                className="quiz-img-wrap"
                style={{ backgroundImage: `url(${perOverviewBg})` }}
              ></div>
            </Col>
            <Col lg={6} md={12} sm={12} className="p-0">
              
              <div className="Overview-card">
                <div className="Overview-card-item">
                  <div className="card-media">
                    <Image
                      src={
                        userDetails?.personalityType?.image ||
                        personalityDetails?.image ||
                        ""
                      }
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="card-description">
                    <h1>
                      {userDetails?.personalityType?.name ||
                        personalityDetails?.name ||
                        ""}
                    </h1>
                    <p>
                      {userDetails?.personalityType?.description ||
                        personalityDetails?.description ||
                        ""}
                    </p>
                  </div>
                </div>
              </div>
             
            </Col>
          </Row>
        </OverviewStyled>
      </Container>
    </QuizInnerBox>
  );
};

export default SharePersonality;
