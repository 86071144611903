import React, { useState } from "react";
import { Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  SlideIcon1,
  SlideIcon2,
  SlideIcon3,
  SlideIcon4,
  SlideIcon5,
  SlideIcon6,
  cardLayer,
} from "../../../assets/images";
import Marquee from "react-fast-marquee";
import { ArrowIcon } from "../../../utils/svg";
import Image from "../../../components/common/image";

function CategoriesSlider() {

  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <ArrowIcon />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <ArrowIcon />
      </div>
    );
  }

  const featureSlide = [
    {
      title: "Adventurer",
      content:
        "Adventurers seek the unknown and are not afraid to take risks. Fascinated with what the world has to offer, Adventurers waste no time lounging around on vacation.",
      image: SlideIcon1,
    },
    {
      title: "Explorer",
      content:
        "Explorers are the most well-balanced traveller, enjoying a bit of familiarity mixed with a taste of the unknown. The Explorer personality is best defined by their ability to perceive and appreciate life and all of its experiences without having to spend an exorbitant amount of money to do so.",
      image: SlideIcon2,
    },
    {
      title: "Globetrotter",
      content:
        "Globetrotters are travellers that take their time to enjoy the moment, absorb the story, and bask in the experience. This group of travellers are willing to taste many of the world's greatest experiences.",
      image: SlideIcon3,
    },
    {
      title: "Escapist",
      content:
        "Escapists relish every opportunity to break free from the 9 to 5 grind. There is no better feeling to an Escapist than the last day of work before they board a plane to a foreign destination.",
      image: SlideIcon4,
    },
    {
      title: "Wanderer",
      content:
        "Wanderers are easygoing and tend to focus on the positive sides of life. Life’s simple pleasures come easy to Wanderers as a nice sunset walk or a cool morning swim is the best part of the day.",
      image: SlideIcon5,
    },
    {
      title: "Extravagancers",
      content:
        "Extravagancers know how to enjoy the finer things in life and are always on the lookout for exclusive and unique experiences. Extravagancers live life to the fullest and tend to go on multiple trips per year.",
      image: SlideIcon6,
    },
  ];

  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  return (
    <div className="home-wrapper__sliderGrp">
      <Container fluid>
        <Row>
          <Slider
            {...settings}
            asNavFor={nav2}
            ref={(slider1) => setNav1(slider1)}
          >
            {featureSlide?.map((index, ind) => (
              <div key={`title-${ind}-marquee`} className="marquee-text-grp">
                <Marquee>
                  <span className="marquee-text">{index.title} - {index.title}</span>
                </Marquee>
                <Marquee direction="right">
                  <span className="marquee-text">{index.title} - {index.title}</span>
                </Marquee>
                <Marquee>
                  <span className="marquee-text">{index.title} - {index.title}</span>
                </Marquee>
              </div>
            ))}
          </Slider>
        </Row>
        <div className="centerDiv">
          <div className="slider-layer">
            <Image src={cardLayer} alt="" />
          </div>
          <Slider
            asNavFor={nav1}
            ref={(slider2) => setNav2(slider2)}
            slidesToShow={1}
            swipeToSlide={false}
            focusOnSelect={true}
            infinite={true}
            slidesToScroll={1}
            fade={true}
            arrows={true}
            variableWidth={true}
            nextArrow={<SampleNextArrow />}
            prevArrow={<SamplePrevArrow />}
          >
            {featureSlide?.map((index, ind) => (
              <div
                key={`title-${ind}-slide`}
                className="d-flex justify-content-center align-items-center slideHolder"
              >
                <div className="home-wrapper__sliderGrp__contentItem__slider-item">
                  <div className="slider-innerItem">
                    <div className="item-content">
                      <h3> {index.title} </h3>
                      <p> {index.content}</p>
                    </div>
                  </div>
                  <div className="slider-innerItem">
                    <div
                      className="item-media"
                      style={{ backgroundImage: `url(${index.image})` }}
                    ></div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </Container>
    </div>
  );
}

export default CategoriesSlider;
