import styled from "styled-components";

export const PageNotFound = styled.section`
  .page-not-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: var(--secondary-font);
    h1 {
      color: #101828;
      text-align: center;
      font-style: normal;
      font-weight: ${({ theme }) => theme.fontWeight.fontWeight700};
      font-size: ${({ theme }) => theme.fontSizes.font42};
      line-height: 60px;
      letter-spacing: -0.96px;
      margin: 20px 0 0;
    }
    p {
      color: var(--gray-600, #475467);
      font-weight: ${({ theme }) => theme.fontWeight.fontWeight400};
      font-size: ${({ theme }) => theme.fontSizes.font18};
      font-style: normal;
      line-height: 30px;
      margin-bottom: 0;
    }
  }
`;

export const ItinerariesWrapper = styled.section`
  font-family: var(--secondary-font);
  .page-title {
    color: ${({ theme }) => theme.colors.light.titleClr};
    font-weight: ${({ theme }) => theme.fontWeight.fontWeight700};
    font-size: ${({ theme }) => theme.fontSizes.font48};
    font-style: normal;
    line-height: 60px;
    letter-spacing: -0.96px;
    @media (max-width: 767px) {
      font-size: ${({ theme }) => theme.fontSizes.font32};
    }
  }
  h3 {
    font-size: ${({ theme }) => theme.fontSizes.font36};
    font-weight: ${({ theme }) => theme.fontWeight.fontWeight700};
    margin-top: 40px;
    @media (max-width: 767px) {
      font-size: ${({ theme }) => theme.fontSizes.font26};
      margin-top: 20px;
      border-bottom: 1px solid #e6e8ec;
      padding-bottom: 20px;
    }
  }
  p {
    color: var(--gray-600, #475467);
    font-weight: ${({ theme }) => theme.fontWeight.fontWeight400};
    font-size: ${({ theme }) => theme.fontSizes.font20};
    font-style: normal;
    line-height: 30px;
    margin-bottom: 0;
  }
  .img-wrapper {
    margin: 25px 0;
  }
  .Back-btn {
    color: ${({ theme }) => theme.colors.light.lightBlueText};
    display: flex;
    align-items: center;
    margin:50px 0 20px;
    svg {
      path {
        stroke: ${({ theme }) => theme.colors.light.lightBlueText};
      }
    }
    @media (max-width: 767px) {
      display: none;
    }
  }
`;
