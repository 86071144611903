import { React, useRef, useEffect, useState } from "react";
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../utils/constants/routes";
import { LogoIcon } from "../../../utils/svg";
import { HeaderStyled } from "./style";
import {
  handleScroll,
  isUserLoggedIn,
  handleRedirectionToQuizPage,
  showQuizCtaInHeader,
  getPersonalityId,
} from "../../../utils/utilityFunctions";
import {
  clearUserDetails,
  setSelectedTab,
  menuBarToggleState,
} from "../../../state/user/actions";
import {
  MenuDashboardIcon,
  MenuHomeIcon,
  MenuProfileIcon,
  MenuShreIcon,
  MenuTravelIcon,
  UserProfileIcon,
} from "../../../assets/images/checkIcon";
import { Logo } from "../../../assets/icons";
import {
  deleteAllItineraries,
  deleteAllTravelQuesData,
} from "../../../state/travel/actions";

function Header({ isDashboard }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { selectedTab, userDetails } = useSelector((state) => state?.user);
  const [isFixedHeader, setIsFixedHeader] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);

  const [dynamicKey, setDynamicKey] = useState("123");
  const navbarCollapseRef = useRef(null);

  const scrollListener = () => {
    if (document.documentElement.scrollTop >= 80) {
      setIsFixedHeader(true);
    } else {
      setIsFixedHeader(false);
    }
  };

  const closeNavbar = () => {
    const navbarCollapse = navbarCollapseRef.current;
    setDynamicKey(new Date().getTime());
    if (navbarCollapse) {
      return navbarCollapse.classList.remove("show");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollListener);
    document.body.addEventListener("click", handleBodyClick);

    navbarCollapseRef.current = document.querySelector(".navbar-collapse");
    return () => {
      window.removeEventListener("scroll", scrollListener);
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, []);

  const handleBodyClick = (e) => {
    const navbar = document.querySelector(".header-wrapper__navigation");
    if (!navbar.contains(e.target)) {
      closeNavbar();
    }
  };

  const handleSelectedTab = (tab, route) => {
    dispatch(setSelectedTab(tab));
    if (tab === "Take the quiz") {
      return handleRedirectionToQuizPage(navigate);
    } else {
      return navigate(route);
    }
  };

  useEffect(() => {
    dispatch(menuBarToggleState(isProfileMenuOpen || isMenuOpen));
  }, [isProfileMenuOpen, isMenuOpen]);

  const userName = userDetails?.firstName + " " + userDetails?.lastName;
  const nameparts = userName.split(" ");
  const initialsUserName =
    nameparts[0].charAt(0).toUpperCase() + nameparts[1].charAt(0).toUpperCase();

  const showTakeQuizCta = showQuizCtaInHeader(location);

  return (
    <HeaderStyled
      key={dynamicKey}
      onClick={() => {
        if (!isProfileMenuOpen && !isMenuOpen) {
          return;
        }
        if (isProfileMenuOpen) {
          setIsMenuOpen(false);
          setIsProfileMenuOpen(false);
        } else {
          setIsMenuOpen(!isMenuOpen);
        }
      }}
    >
      <div
        className={`header-wrapper ${
          window.location.pathname === "/"
            ? isFixedHeader
              ? "fixed-header"
              : ""
            : "fixed-header"
        } ${isDashboard ? "after-login-user dashboard" : ""}`}
      >
        <Navbar
          expand="lg"
          className="header-wrapper__navigation fixed-header__navigation"
          expanded={isMenuOpen}
        >
          <Container>
            <Navbar.Brand
              onClick={() => {
                navigate(ROUTES?.home);
              }}
              className="d-none d-md-block"
            >
              <LogoIcon />
            </Navbar.Brand>
            <>
              <Navbar.Toggle
                aria-controls="basic-navbar-nav"
                className="navbar-toggle"
                onClick={() => {
                  setIsMenuOpen(!isMenuOpen);
                }}
              >
                <span className="icon-bar top-bar"></span>
                <span className="icon-bar middle-bar"></span>
                <span className="icon-bar bottom-bar"></span>
              </Navbar.Toggle>
              {isUserLoggedIn() ? (
                <>
                  <div className="UserMenu-grp">
                    <Navbar.Brand
                      onClick={() => {
                        navigate(ROUTES?.home);
                      }}
                    >
                      <Logo />
                    </Navbar.Brand>
                    {/* Start profile Navigation desktop view */}

                    <div
                      className="user-profile-item"
                      onClick={(e) => {
                        setIsMenuOpen(false);
                        e.stopPropagation();
                      }}
                    >
                      <div
                        onClick={() => setIsProfileMenuOpen(!isProfileMenuOpen)}
                      >
                        {userDetails?.picture ? (
                          <img src={userDetails?.picture} alt="" />
                        ) : userDetails?.firstName && userDetails?.lastName ? (
                          <div
                            className="user-profile-text"
                            title={`${userDetails.firstName} ${userDetails.lastName}`}
                          >
                            {initialsUserName}
                          </div>
                        ) : (
                          <UserProfileIcon />
                        )}
                      </div>

                      {/* Profile dropdown for desktop view */}
                      <div className="d-none d-lg-block">
                        <NavDropdown>
                          <Nav.Link
                            onClick={() =>
                              handleSelectedTab("Home", ROUTES?.home)
                            }
                            className={`usermenu-item-link ${
                              selectedTab === "Home" &&
                              location?.pathname === ROUTES?.home
                                ? "active-link"
                                : ""
                            }`}
                          >
                            <span className="usermenu-item-icon">
                              <MenuHomeIcon />
                            </span>
                            Home
                          </Nav.Link>
                          <Nav.Link
                            onClick={() =>
                              handleSelectedTab("Dashboard", ROUTES?.dashboard)
                            }
                            className={`usermenu-item-link ${
                              selectedTab === "Dashboard" &&
                              location?.pathname === ROUTES?.dashboard
                                ? "active-link"
                                : ""
                            }`}
                          >
                            <span className="usermenu-item-icon">
                              <MenuDashboardIcon />
                            </span>
                            Dashboard
                          </Nav.Link>
                          {showTakeQuizCta && (
                            <Nav.Link
                              onClick={() =>
                                handleSelectedTab(
                                  "Take the quiz",
                                  userDetails?.personalityType?._id
                                    ? ROUTES?.travelStyle
                                    : ROUTES?.personality
                                )
                              }
                              className={`usermenu-item-link ${
                                selectedTab === "Take the quiz" &&
                                location?.pathname === ROUTES?.personality
                                  ? "active-link"
                                  : ""
                              }`}
                            >
                              <span className="usermenu-item-icon">
                                <MenuTravelIcon />
                              </span>
                              Take the quiz
                            </Nav.Link>
                          )}
                          {getPersonalityId() &&
                            ![
                              ROUTES?.personalitySuccess,
                              ROUTES?.personalityoverview,
                            ].includes(location?.pathname) && (
                              <Nav.Link
                                onClick={() =>
                                  handleSelectedTab(
                                    "personalityoverview",
                                    ROUTES?.personalityoverview
                                  )
                                }
                                className={`usermenu-item-link ${
                                  selectedTab === `personalityoverview` &&
                                  location?.pathname ===
                                    ROUTES?.personalityoverview
                                    ? `active-link`
                                    : ""
                                }`}
                              >
                                <span className="usermenu-item-icon share-icon-item">
                                  <MenuShreIcon />
                                </span>
                                Share Personality
                              </Nav.Link>
                            )}
                          {/* <Nav.Link
                            onClick={() =>
                              handleSelectedTab("Profile", ROUTES?.editProfile)
                            }
                            className={`usermenu-item-link ${
                              selectedTab === "Profile" &&
                              location?.pathname === ROUTES?.editProfile
                                ? "active-link"
                                : ""
                            }`}
                          >
                            <span className="usermenu-item-icon">
                              <MenuProfileIcon />
                            </span>
                            Profile 
                          </Nav.Link> */}
                          <span
                            title={userDetails?.email || ""}
                            className="usermenu-item-link  nav-link"
                          >
                            {userDetails?.email || ""}
                          </span>
                          <hr className="d-md-block d-none" />
                          <div
                            className="d-md-none"
                            style={{ height: 480 }}
                            onClick={(e) => {
                              e.stopPropagation();
                              closeNavbar();
                            }}
                          />

                          <Nav.Link
                            onClick={() => {
                              navigate(ROUTES?.login);
                              if (isUserLoggedIn()) {
                                localStorage.clear();
                                sessionStorage.clear()
                                dispatch(clearUserDetails());
                                dispatch(deleteAllTravelQuesData());
                                dispatch(deleteAllItineraries());
                              }
                            }}
                            className="logout-link d-none d-lg-block"
                          >
                            Logout
                          </Nav.Link>
                          <div className="d-md-none res-login-btn-grp">
                            <Nav.Link
                              onClick={() => {
                                navigate(ROUTES?.login);
                                if (isUserLoggedIn()) {
                                  localStorage.clear();
                                  sessionStorage.clear();
                                  dispatch(clearUserDetails());
                                  dispatch(deleteAllTravelQuesData());
                                  dispatch(deleteAllItineraries());
                                }
                              }}
                              className="logout-link"
                            >
                              Logout
                            </Nav.Link>
                          </div>
                        </NavDropdown>
                      </div>
                    </div>
                  </div>
                  <Navbar.Collapse
                    id="basic-navbar-nav"
                    ref={navbarCollapseRef}
                    className="navbar-collapse"
                  >
                    <Nav
                      className={`me-auto w-100 ${
                        !isUserLoggedIn() && "res-loggedIn-navBar"
                      }`}
                    >
                      <Nav.Link
                        className={`${
                          selectedTab === "Home" &&
                          location?.pathname === ROUTES?.home
                            ? "active-link"
                            : ""
                        }`}
                        onClick={() => {
                          closeNavbar();
                          handleSelectedTab("Home", ROUTES?.home);
                        }}
                      >
                        Home
                      </Nav.Link>
                      <Nav.Link
                        onClick={() => {
                          closeNavbar();
                          handleSelectedTab("Itinerary", ROUTES?.viewItineries);
                        }}
                        className={`${
                          selectedTab === "Itinerary" &&
                          location?.pathname === ROUTES?.viewItineries
                            ? "active-link"
                            : ""
                        }`}
                      >
                        My Itineraries
                      </Nav.Link>
                      {showTakeQuizCta && (
                        <Nav.Link
                          onClick={() => {
                            closeNavbar();
                            handleSelectedTab(
                              "Take the quiz",
                              userDetails?.personalityType?.name
                                ? ROUTES?.travelStyle
                                : ROUTES?.personality
                            );
                          }}
                          className={`${
                            selectedTab === "Take the quiz" &&
                            location?.pathname === ROUTES?.personality
                              ? "active-link"
                              : ""
                          }`}
                        >
                          Take the quiz
                        </Nav.Link>
                      )}

                      <Nav.Link
                        onClick={() => {
                          closeNavbar();
                          handleSelectedTab(
                            "Edit Profile",
                            ROUTES?.editProfile
                          );
                        }}
                        className={`${
                          selectedTab === "Edit Profile" &&
                          location?.pathname === ROUTES?.editProfile
                            ? "active-link"
                            : ""
                        }`}
                      >
                        Edit Profile
                      </Nav.Link>
                    </Nav>
                    {/* <div
                    className="d-md-none"
                    style={{ height: 480,}}
                    onClick={(e) => {
                      setIsMenuOpen(false)
                      e.stopPropagation();
                      closeNavbar();
                    }}
                  /> */}
                  </Navbar.Collapse>
                </>
              ) : (
                <>
                  <Navbar.Brand
                    onClick={() => {
                      navigate(ROUTES?.home);
                    }}
                    className="d-md-none d-sm-block res-logo"
                  >
                    <Logo />
                  </Navbar.Brand>
                  <Navbar.Collapse
                    id="basic-navbar-nav"
                    ref={navbarCollapseRef}
                    className="navbar-collapse"
                  >
                    {/* <Nav className="me-auto w-100"> */}
                    <Nav
                      className={`me-auto w-100 ${
                        !isUserLoggedIn() && "res-loggedIn-navBar"
                      }`}
                    >
                      {/* {(location?.pathname === "/") && ( */}
                      <>
                        <Nav.Link
                          onClick={() => {
                            navigate(ROUTES?.home);
                            closeNavbar();
                            setTimeout(() => {
                              handleScroll("about");
                            }, 100);
                          }}
                        >
                          About
                        </Nav.Link>
                        <Nav.Link
                          onClick={() => {
                            navigate(ROUTES?.home);
                            closeNavbar();
                            setTimeout(() => {
                              handleScroll("feature");
                            }, 100);
                          }}
                        >
                          Features
                        </Nav.Link>
                        <Nav.Link
                          onClick={() => {
                            navigate(ROUTES?.home);
                            closeNavbar();
                            setTimeout(() => {
                              handleScroll("reviews");
                            }, 100);
                          }}
                        >
                          Reviews
                        </Nav.Link>
                      </>
                      {/* )} */}
                      {getPersonalityId() &&
                        ![
                          ROUTES?.personalitySuccess,
                          ROUTES?.personalityoverview,
                        ].includes(location?.pathname) && (
                          <Nav.Link
                            onClick={() =>
                              handleSelectedTab(
                                "personalityoverview",
                                ROUTES?.personalityoverview
                              )
                            }
                            className={`usermenu-item-link ${
                              selectedTab === `personalityoverview` &&
                              location?.pathname === ROUTES?.personalityoverview
                                ? `active-link`
                                : ""
                            }`}
                          >
                            Share Personality
                          </Nav.Link>
                        )}
                      <div className="header-wrapper__navigation__ctaBtn">
                        {isUserLoggedIn() && (
                          <Nav.Link
                            onClick={() => {
                              closeNavbar();
                              navigate(ROUTES?.dashboard);
                            }}
                            className="logout-link"
                          >
                            Dashboard
                          </Nav.Link>
                        )}
                        {showTakeQuizCta && (
                          <Nav.Link
                            onClick={() => {
                              closeNavbar();
                              handleSelectedTab(
                                "Take the quiz",
                                userDetails?.personalityType?._id
                                  ? ROUTES?.travelStyle
                                  : ROUTES?.personality
                              );
                            }}
                            className="quiz-btn"
                          >
                            Take the quiz
                          </Nav.Link>
                        )}

                        <Nav.Link
                          onClick={() => {
                            closeNavbar();
                            navigate(ROUTES?.login);
                          }}
                          className="d-md-block d-none "
                        >
                          Log in
                        </Nav.Link>

                        <div className="d-lg-none res-login-btn-grp">
                          <Nav.Link
                            onClick={() => {
                              closeNavbar();
                              navigate(ROUTES?.signup);
                            }}
                          >
                            Sign up
                          </Nav.Link>
                          <Nav.Link
                            onClick={() => {
                              closeNavbar();
                              navigate(ROUTES?.login);
                            }}
                          >
                            Log in
                          </Nav.Link>
                        </div>
                      </div>
                    </Nav>
                  </Navbar.Collapse>
                </>
              )}

              {/* User profile menu for mobile view */}
              {isProfileMenuOpen && (
                <div className="navigation-bar d-lg-none">
                  <div className="profile-navbar">
                    <Nav.Link
                      onClick={() => handleSelectedTab("Home", ROUTES?.home)}
                      className={`usermenu-item-link ${
                        selectedTab === "Home" &&
                        location?.pathname === ROUTES?.home
                          ? "active-link"
                          : ""
                      }`}
                    >
                      <span className="usermenu-item-icon">
                        <MenuHomeIcon />
                      </span>
                      Home
                    </Nav.Link>
                    <Nav.Link
                      onClick={() =>
                        handleSelectedTab("Dashboard", ROUTES?.dashboard)
                      }
                      className={`usermenu-item-link ${
                        selectedTab === "Dashboard" &&
                        location?.pathname === ROUTES?.dashboard
                          ? "active-link"
                          : ""
                      }`}
                    >
                      <span className="usermenu-item-icon">
                        <MenuDashboardIcon />
                      </span>
                      Dashboard
                    </Nav.Link>
                    {showTakeQuizCta && (
                      <Nav.Link
                        onClick={() =>
                          handleSelectedTab(
                            "Take the quiz",
                            userDetails?.personalityType?._id
                              ? ROUTES?.travelStyle
                              : ROUTES?.personality
                          )
                        }
                        className={`usermenu-item-link ${
                          selectedTab === "Take the quiz" &&
                          location?.pathname === ROUTES?.personality
                            ? "active-link"
                            : ""
                        }`}
                      >
                        <span className="usermenu-item-icon">
                          <MenuTravelIcon />
                        </span>
                        Take the quiz
                      </Nav.Link>
                    )}
                    {getPersonalityId() &&
                      ![
                        ROUTES?.personalitySuccess,
                        ROUTES?.personalityoverview,
                      ].includes(location?.pathname) && (
                        <Nav.Link
                          onClick={() =>
                            handleSelectedTab(
                              "personalityoverview",
                              ROUTES?.personalityoverview
                            )
                          }
                          className={`usermenu-item-link ${
                            selectedTab === `personalityoverview` &&
                            location?.pathname === ROUTES?.personalityoverview
                              ? `active-link`
                              : ""
                          }`}
                        >
                          <span className="usermenu-item-icon share-icon-item">
                            <MenuShreIcon />
                          </span>
                          Share Personality
                        </Nav.Link>
                      )}
                    {/* <Nav.Link
                      onClick={() =>
                        handleSelectedTab("Profile", ROUTES?.editProfile)
                      }
                      className={`usermenu-item-link ${
                        selectedTab === "Profile" &&
                        location?.pathname === ROUTES?.editProfile
                          ? "active-link"
                          : ""
                      }`}
                    >
                      <span className="usermenu-item-icon">
                        <MenuProfileIcon />
                      </span>
                      Profile
                    </Nav.Link> */}
                    <span
                      title={userDetails?.email || ""}
                      className="usermenu-item-link  nav-link"
                    >
                      {userDetails?.email || ""}
                    </span>
                    <hr className="d-md-block d-none" />
                    {/* <div
                    className="d-md-none"
                    style={{ height: 480, backgroundColor:'red'}}
                    onClick={(e) => {
                      e.stopPropagation();
                      closeNavbar();
                    }}
                  /> */}

                    <Nav.Link
                      onClick={() => {
                        navigate(ROUTES?.login);
                        if (isUserLoggedIn()) {
                          localStorage.clear();
                          sessionStorage.clear();
                          dispatch(clearUserDetails());
                          dispatch(deleteAllTravelQuesData());
                          dispatch(deleteAllItineraries());
                        }
                      }}
                      className="logout-link d-none d-lg-block"
                    >
                      Logout
                    </Nav.Link>
                    <div className="d-lg-none res-login-btn-grp">
                      <Nav.Link
                        onClick={() => {
                          navigate(ROUTES?.login);
                          if (isUserLoggedIn()) {
                            localStorage.clear();
                            sessionStorage.clear();
                            dispatch(clearUserDetails());
                            dispatch(deleteAllTravelQuesData());
                            dispatch(deleteAllItineraries());
                          }
                        }}
                        className="logout-link"
                      >
                        Logout
                      </Nav.Link>
                    </div>
                  </div>
                </div>
              )}
            </>
          </Container>
        </Navbar>
      </div>
    </HeaderStyled>
  );
}

export default Header;
