export const travelStyleData = [
  {
    _id: 1,
    text: "Get your own personalized itinerary based on your unique personality.",
    heading: "Solo Adventure",
    // label: "solo",
    value: "solo",
  },
  {
    _id: 2,
    text: "Start the journey for your group and invite others to input their travel styles. We'll create a detailed plan that suits everyone.",
    heading: "Group Getaway",
    // label: "group",
    value: "group",
  },
];

export const travelStyleDataGuest = [
  {
    _id: 1,
    text: "Get your own personalized itinerary based on your unique personality.",
    heading: "Solo traveller",
    // label: "solo",
    value: "solo",
  },
];
