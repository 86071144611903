import React, { useEffect } from "react";
import ResetPasswordForm from "./resetpasswordform";
import { Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Logo } from "../../../assets/icons";
import { isUserExist } from "../../../utils/utilityFunctions";

const Resetpassword = () => {
  const navigate = useNavigate();
  useEffect(() => {
    isUserExist(navigate);
  }, []);
  return (
    <Container fluid>
      <Row>
        <div className="col-md-6 left-content">
          <div className="px-4 mt-3">
            <Link to="/">
              <Logo />
            </Link>
          </div>
          <ResetPasswordForm />
          <div className="px-4">
            <p> © Ethoventures 2023</p>
          </div>
        </div>
        <div className="col-md-6 SignUp-wrapper"></div>
      </Row>
    </Container>
  );
};

export default Resetpassword;
