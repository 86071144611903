import React from "react";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";
import { InputStyled } from "./style";

const Input = ({
  id,
  name,
  type,
  value,
  placeholder,
  onChange,
  onBlur,
  onKeydown,
  onKeyup,
  classes,
  inputClass,
  labelClasses,
  label,
  required,
  error,
  disabled,
  defaultValue,
  maxLength,
  onCopy,
  onPaste,
  Icon,
  onClick,
  ref,
  ...rest
}) => {
  return (
    <InputStyled
      disabled={disabled}
      className={`input-grp ${inputClass} ${error && "input-error"}`}
    >
      {label && (
        <Form.Label className={`${labelClasses}`}>
          {label} {required ? <span className="text-danger">*</span> : ""}
        </Form.Label>
      )}
      <div>
        <Form.Control
          onCopy={onCopy}
          onPaste={onPaste}
          id={id}
          name={name}
          ref={ref}
          type={type}
          value={value}
          placeholder={placeholder}
          onChange={disabled ? () => {} : onChange}
          disabled={disabled}
          onBlur={onBlur}
          onKeyDown={onKeydown}
          onKeyUp={onKeyup}
          defaultValue={defaultValue}
          maxLength={maxLength}
          {...rest}
        />
        {Icon && (
          <span onClick={onClick}>
            <Icon />
          </span>
        )}
      </div>
      <div className="invalid" type="invalid">
        {error}
      </div>
    </InputStyled>
  );
};

const defaultHandler = () => {};

Input.propTypes = {
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  classes: PropTypes.string,
  inputClass: PropTypes.string,
  labelClasses: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeydown: PropTypes.func,
};

Input.defaultProps = {
  id: null,
  name: "",
  type: "text",
  value: "",
  placeholder: "",
  classes: "",
  inputClass: "",
  labelClasses: "",
  label: "",
  disabled: false,
  required: false,
  error: null,
  onChange: defaultHandler,
  onBlur: defaultHandler,
  onKeydown: defaultHandler,
  onKeyup: defaultHandler,
};

export default Input;
