import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../utils/constants/routes";
import { DemoLinkStyle } from "./style";
import { Col, Container, Row } from "react-bootstrap";

const DemoLinks = () => {
  return (
    <DemoLinkStyle>
      <Container>
        <Row>

          <Col xl="4">
            <h1> Sprint 1 </h1>
            <div className="mt-5">
              <ul>
                <li>
                  <Link target={"_blank"} to={ROUTES?.home}>
                    -- Home page
                  </Link>
                </li>
                <li>
                  <Link target={"_blank"} to={ROUTES?.login}>
                    -- Sign in
                  </Link>
                </li>
                <li>
                  <Link target={"_blank"} to={ROUTES?.signup}>
                    -- Sign up
                  </Link>
                </li>
                <li>
                  <Link target={"_blank"} to={ROUTES?.forgetPassword}>
                    -- Forget password
                  </Link>
                </li>
                <li>
                  <Link target={"_blank"} to={ROUTES?.otp}>
                    -- Enter Otp
                  </Link>
                </li>
                <li>
                  <Link target={"_blank"} to={ROUTES?.resetPassword}>
                    -- Reset password
                  </Link>
                </li>
                <li>
                  <Link target={"_blank"} to={ROUTES?.forgetPassword}>
                    -- Forget password
                  </Link>
                </li>
                <li>
                  <Link target={"_blank"} to={ROUTES?.dashboard}>
                    -- Dashboard
                  </Link>
                </li>
              </ul>
            </div>
          </Col>

          <Col xl="4">
            <h1> Travel Style  </h1>

            <div className="mt-5">
              <ul>
                <li>
                  <Link target={"_blank"} to={ROUTES?.travelStyle}>
                    -- Travel Style
                  </Link>
                </li>
                <li>
                  <Link target={"_blank"} to={ROUTES?.travelInvite}>
                    -- Travel invite
                  </Link>
                </li>
                <li>
                  <Link target={"_blank"} to={ROUTES?.travelSelect}>
                    -- Travel Group
                  </Link>
                </li>
                <li>
                  <Link target={"_blank"} to={ROUTES?.travelBudget}>
                    -- Travel Budget
                  </Link>
                </li>
                <li>
                  <Link target={"_blank"} to={ROUTES?.travelDays}>
                    -- Travel Days
                  </Link>
                </li>
                <li>
                  <Link target={"_blank"} to={ROUTES?.travelSuccess}>
                    -- Travel Success
                  </Link>
                </li>
                <li>
                  <Link target={"_blank"} to={ROUTES?.whySignup}>
                    -- Why Signup
                  </Link>
                </li>
                <li>
                  <Link target={"_blank"} to={ROUTES?.travelLocation}>
                    -- Travel Location
                  </Link>
                </li>
              </ul>
            </div>
          </Col>


          <Col xl="4">
            <h1> Payment confirmation  </h1>
            <div className="mt-5">
              <ul>
                <li>
                  <Link target={"_blank"} to={ROUTES?.paymentSuccess}>
                    -- Payment Success
                  </Link>
                </li>
                <li>
                  <Link target={"_blank"} to={ROUTES?.paymentCanceled}>
                    -- Payment Canceled
                  </Link>
                </li>
              </ul>
            </div>
            <div className="mt-3">
              <h1> Travel Quiz  </h1>
              <div className="mt-3 ps-3">
                <ul>
                  <li>
                    <Link target={"_blank"} to={ROUTES?.travelQuiz}>
                      -- Travel Quiz
                    </Link>
                  </li>
                  <li>
                    <Link target={"_blank"} to={ROUTES?.personalityoverview}>
                      -- Personality Overview
                    </Link>
                  </li>
                  <li>
                    <Link target={"_blank"} to={ROUTES?.personalitySuccess}>
                      -- Personality Quiz Success
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="mt-3">
              <h1> Itinerary  </h1>
              <div className="mt-3 ps-3">
                <ul>
                  <li>
                    <Link target={"_blank"} to={ROUTES?.itineraryDetails}>
                      -- Itinerary Details
                    </Link>
                  </li>
                  <li>
                    <Link target={"_blank"} to={ROUTES?.guestUserNearlyDone}>
                      -- GuestUser NearlyDone
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </Col>

          <hr />

        </Row>
      </Container>
    </DemoLinkStyle>
  );
};

export default DemoLinks;
