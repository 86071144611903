export const validate = (values) => {
  const passwordRegex =
    /^(?=.*\d)(?=.*[A-Za-z])(?=.*[!@#$%^&*£()_+{}\[\]:;<>,.?~\\/\-=?\^]).{8,}$/;

  const errors = {};
  if (!values.email) {
    errors.email = "Email field cannot be empty";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!values.password) {
    errors.password = "Password field cannot be empty";
  } else if (
    // atleast 8 characters,atleast 1 number,atleast 1 letter and a special character
    // !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/i.test(
    //   values.password)
    !passwordRegex.test(values.password)
  ) {
    errors.password = "Invalid password";
  }
  return errors;
};
