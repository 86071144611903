/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { EditIconOutline, UserIconOutline } from "../../../assets/icons";
import CustomSelect from "../../../components/HOCs/customSelect";
import Input from "../../../components/common/input";
import Label from "../../../components/common/label";
import useProfile from "../../../services/profile/index";
import { updateUserDetails } from "../../../state/user/actions";
import { MSG } from "../../../utils/constants";
import { countryOptions } from "../../../utils/constants/countryData";
import { genderOptions } from "../../../utils/constants/genderOptions";
import { ROUTES } from "../../../utils/constants/routes";
import { getFileExtensionOfImage } from "../../../utils/utilityFunctions";
import { EditprofileSection } from "./style";
import { validate } from "./validate";
import Button from "../../../components/common/button";
import { EmailIcon } from "../../../assets/images/checkIcon";

const EditProfile = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { updateUserProfile, uploadSignedImage, updateImage } = useProfile();
  const navigate = useNavigate();
  const [urlPath, setUrlPath] = useState("");
  const [image, setImage] = useState("");
  const [initialData, setInitialData] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");

  const { userDetails } = useSelector((state) => state.user);

  useEffect(() => {
    setInitialData(userDetails);
  }, [userDetails]);

  useEffect(() => {
    if (urlPath && imageFile) {
      setLoading(true);
      updateImage(urlPath, imageFile)
        .then((res) => {
          if (res?.isSuccess) {
            const image = urlPath.split("?")[0];
            const cloudfrontId = process.env.REACT_APP_REDIRECT_URL;
            const pattern = /\/user\//;
            const parts = image.split(pattern);
            const prefixToRemove = parts[0];
            const modifiedImageUrl = image.replace(
              prefixToRemove,
              cloudfrontId
            );
            setImage(modifiedImageUrl);
          } else {
            setImageFile(null);
            setImageUrl("");
            toast.info(MSG?.ON_UNHANDLED_ERROR);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [urlPath, imageFile]);

  const handleImageSelect = (event) => {
    const selectedImage = event.target.files[0];
    if (selectedImage) {
      const imageSizeInKB = selectedImage.size / 1024;
      if (imageSizeInKB > 5120) {
        toast.info(MSG?.PROFILE_IMAGE_SIZE_ERROR);
        return;
      }
      setImageFile(selectedImage);
      const imageUrl = URL.createObjectURL(selectedImage);
      setImageUrl(imageUrl);
      const lastExtension = getFileExtensionOfImage(selectedImage.name);
      if (lastExtension) {
        setLoading(true);
        const dataToSend = {
          extension: lastExtension,
          type: "profile",
        };
        uploadSignedImage(dataToSend)
          .then((res) => {
            if (res?.isSuccess) {
              if (res?.data?.data) {
                setUrlPath(res.data.data.url);
              }
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };
  const handleDateChange = (date) => {
    handleChange({ target: { name: "birthday", value: date } });
  };
  const onSubmit = (values) => {
    const selectedCountryOption = countryOptions.find(
      (option) => option.value === values.country
    );
    const selectedCountryLabel = selectedCountryOption
      ? selectedCountryOption.label
      : "";
    const selectedCountryImage = selectedCountryOption
      ? selectedCountryOption.image
      : "";
    const countryToSend = values.country
      ? {
          code: values.country,
          name: selectedCountryLabel,
          image: selectedCountryImage,
        }
      : initialData?.country;
    const dataToSend = {
      ...values,
      ...(image ? { picture: image } : {}),
      country: countryToSend,
      gender: values.gender || initialData?.gender,
      personalityType: initialData?.personalityType._id,
    };
    handleUploadUserData(dataToSend);
  };

  const handleUploadUserData = (dataToSend) => {
    const userId = localStorage.getItem("user-id");
    const { personalityType, ...updateDataRedux } = dataToSend;
    setLoading(true);
    updateUserProfile(userId, dataToSend)
      .then((res) => {
        if (res?.isSuccess) {
          toast.success(MSG?.ON_UPDATE_PROFILE_SUCCESS);
          dispatch(updateUserDetails(updateDataRedux));
          navigate(ROUTES.dashboard);
        } else {
          toast.error(MSG?.ON_ERROR);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const initialBirthday = initialData?.birthday
    ? new Date(initialData?.birthday)
    : null;
  const { values, errors, handleBlur, handleChange, handleSubmit, touched } =
    useFormik({
      initialValues: {
        firstName: initialData?.firstName || "",
        lastName: initialData?.lastName || "",
        birthday: initialBirthday,
        country: "",
        gender: "",
      },
      enableReinitialize: true,
      onSubmit,
      validate,
    });

  const handleChangeCountry = (selectedOption) => {
    const selectedValue = selectedOption?.value || "";
    handleChange({ target: { name: "country", value: selectedValue } });
  };
  const handleChangeGender = (selectedOption) => {
    const selectedValue = selectedOption?.value || "";
    handleChange({ target: { name: "gender", value: selectedValue } });
  };

  return (
    <EditprofileSection imageurl={imageUrl} picture={initialData?.picture}>
      <section className="tr-page-head-banner"></section>
      <section className="tr-page-top-content-wrap">
        <Container>
          <Row>
            <Col>
              <div className="tr-edit-profile-content-box">
                <div className="tr-edit-profile-upper-content-box">
                  <span className="tr-edit-profile-mobile-title">
                    Personal Info
                  </span>
                  <div className="tr-user-img-wrap">
                    <div className="tr-user-icon-box">
                      {!imageUrl && !initialData?.picture && (
                        <UserIconOutline width="80" height="80" />
                      )}
                    </div>
                    <Input
                      placeholder={"Please upload picture"}
                      type="file"
                      className="hidden-input"
                      id="user-image-upload"
                      name="image"
                      value={values.image}
                      onChange={handleImageSelect}
                      onBlur={handleBlur}
                      error={errors.image && touched.image && errors.image}
                      accept="image/jpg, image/gif, image/jpeg, image/png, image/svg"
                    />
                    <label htmlFor="user-image-upload" className="tr-edit-icon">
                      <EditIconOutline />
                    </label>
                  </div>
                  <div className="tr-user-details-wrap">
                    <span className="tr-username">
                      {initialData?.firstName && initialData?.lastName ? (
                        <>
                          {initialData?.firstName + " " + initialData?.lastName}
                        </>
                      ) : null}
                    </span>
                  </div>
                </div>
                <div className="tr-edit-profile-bottom-content-box">
                  <div className="tr-edit-profile-form-box">
                    <Form onSubmit={handleSubmit}>
                      <Row className="tr-form-row">
                        <Col>
                          <div className="tr-form-group">
                            <Input
                              required
                              maxLength={"20"}
                              placeholder={"Please enter first name"}
                              label="First Name"
                              id="firstName"
                              name="firstName"
                              value={values?.firstName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                errors.firstName &&
                                touched.firstName &&
                                errors.firstName
                              }
                            />
                          </div>
                        </Col>
                        <Col>
                          <div className="tr-form-group">
                            <Input
                              required
                              maxLength={"20"}
                              placeholder={"Please enter last name"}
                              label="Last Name"
                              id="lastName"
                              name="lastName"
                              value={values?.lastName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                errors.lastName &&
                                touched.lastName &&
                                errors.lastName
                              }
                            />
                          </div>
                        </Col>
                      </Row>
                      <span className="tr-spacer"></span>
                      <Row className="tr-form-row">
                        <Col>
                          <div className="tr-form-group position-relative">
                            <div className="email-icon"><EmailIcon /></div>
                            <Input
                              placeholder={"Please enter email address"}
                              label="Email Address"
                              id="email"
                              name="email"
                              type="email"
                              value={initialData?.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                errors.email && touched.email && errors.email
                              }
                              disabled
                              style={{paddingLeft:'45px'}}
                            />
                          </div>
                        </Col>
                      </Row>
                      <span className="tr-spacer"></span>
                      <Row className="tr-form-row">
                        <Col>
                          <div className="tr-form-group">
                            <Label
                              labelClass="form-label"
                              required
                              label={" Date of Birth"}
                            />

                            <DatePicker
                              className={`form-control ${
                                errors.birthday && touched.birthday
                                  ? "invalid-input"
                                  : ""
                              }`}
                              // showIcon
                              // isClearable
                              closeOnScroll={(e) => e.target === document}
                              id="birthday"
                              name="birthday"
                              selected={values.birthday}
                              onChange={handleDateChange}
                              onBlur={handleBlur}
                              dateFormat="dd/MM/yyyy"
                              placeholderText="dd/mm/yyyy"
                            />
                            {errors.birthday && touched.birthday && (
                              <div className="invalid">{errors.birthday}</div>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <span className="tr-spacer"></span>
                      <Row className="tr-form-row">
                        <Col>
                          <div className="tr-form-group">
                            <CustomSelect
                              label="Gender"
                              options={genderOptions}
                              name="gender"
                              value={values.gender || initialData?.gender}
                              placeholder={"Please Select Your Gender"}
                              onChange={handleChangeGender}
                            />
                          </div>
                        </Col>
                      </Row>
                      <span className="tr-spacer"></span>
                      <Row className="tr-form-row">
                        <Col>
                          <div className="tr-form-group">
                            <CustomSelect
                              label="Country"
                              options={countryOptions}
                              name="country"
                              value={values.country || initialData?.country}
                              onChange={handleChangeCountry}
                              selectedCountry={initialData?.country}
                              placeholder={"Please Select Your Country"}
                            />
                          </div>
                        </Col>
                      </Row>
                      <span className="tr-spacer"></span>
                      <Row className="tr-form-row">
                        <Col>
                          <ul className="tr-inline-buttons-wrap">
                            <li className="tr-inline-button-li">
                              <Button
                                type="reset"
                                onClick={() => navigate(-1)}
                                text="Cancel"
                                className="btn btn-cancel"
                              />
                            </li>
                            <li className="tr-inline-button-li">
                              <Button
                                type="submit"
                                text="Save"
                                className="btn btn-save"
                                disabled={loading}
                                loading={loading}
                              />
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </EditprofileSection>
  );
};

export default EditProfile;
