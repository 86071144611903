export const ROUTES = {
  home: "/",
  login: "/signin",
  signup: "/signup",
  profile: "/profile",
  logout: "/logout",
  dashboard: "/dashboard",
  personalityTest: "/personalitytest",
  personality: "/personality",
  comingsoon: "/comingsoon",
  forgetPassword: "/forgetpassword",
  resetPassword: "/resetpassword",
  resetPasswordSuccess: "/resetpassword-success",
  otp: "/otp",
  personalityoverview: "/personality-overview",
  personalitySuccess: "/personality-success",
  travelQuiz: "/travel-quiz",
  travelStyle: "/travel-style",
  travelInvite: "/travel-invite",
  travelSelect: "/travel-group",
  travelBudget: "/travel-budget",
  travelDays: "/travel-days",
  whySignup: "/why-signup",
  paymentSuccess: "/payment-success",
  paymentCanceled: "/payment-canceled",
  viewItineries: "/view-itineraries",
  itineraryDetails: "/itinerary-details",
  travelSuccess: "/travel-success",
  itineraryAwaits: "/itinerary-awaits",
  guestUserNearlyDone: "/nearly-done",
  demoLinks: "/demo-links",
  travelLocation: "/travel-location",
  guestItinerary: "/itinerary-details/guest",
  editProfile: "/editprofile",
  invitedUser: "/invited-user",
  groupItinerary: "/itinerary-in-progress",
  itineraryNotFound: "/itinerary-not-found",
  postSignup: "/post-signup",
  terms: "/terms",   
};
