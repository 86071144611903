import styled from "styled-components";
import {
  AboutBg,
  BannerSection,
  BannerBgRes,
  CTABg,
  TestimonialImg,
} from "../../../assets/images";

export const HomeWrapper = styled.div`
  .CTA-btn {
    background-color:${({ theme }) => theme.colors.light.textwhiteClr};
    width: auto;
    color:${({ theme }) => theme.colors.light.CtaText};
    font-size: ${({ theme }) => theme.fontSizes.font16};
    font-weight: ${({ theme }) => theme.fontWeight.fontWeight700};
    border-radius: 30px;
    padding: 8px 30px;
    text-transform: uppercase;
    font-family: var(--primary-font);
    &:hover {
      opacity: 0.8;
    }
    @media (max-width: 767px) {
      width: 100%;
    }
  }
  .heading {
    font-size: ${({ theme }) => theme.fontSizes.font60};
    font-weight: ${({ theme }) => theme.fontWeight.fontWeight700};
    letter-spacing: 1.2px;
    text-transform: uppercase;
    @media (max-width: 767px) {
      font-size: ${({ theme }) => theme.fontSizes.font24};
      padding-right: 10px;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      font-size: ${({ theme }) => theme.fontSizes.font32};
    }
  }
  .slick-slider.slick-initialized {
    width: 100vw;
  }

  .centerDiv{
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    user-select: none;
    left: 50%;
    z-index: 1;
   .slideHolder{
    width: 100vw !important;
   }
  }
  
  overflow: hidden;
  .home-wrapper {
    &__TravelItinerary {
      padding-top: 90px;
      p {
        font-family: var(--primary-font);
        color: ${({ theme }) => theme.colors.light.blackClr};
        font-size: ${({ theme }) => theme.fontSizes.font18};
        font-weight: ${({ theme }) => theme.fontWeight.fontWeight400};
        line-height: 150%;
      }
      &__about {
        background: url(${AboutBg});
        background-repeat: no-repeat;
        height: 700px;
        background-position: bottom right;
        padding-top: 140px;
        position: relative;
        @media (max-width: 767px) {
          height: 300px;
          background-position: 0 0;
          background-size: cover;
        }
        .marqueeItem {
          padding: 30px 0 25px;
          font-size: ${({ theme }) => theme.fontSizes.font26};
          line-height: 65px;
          font-family: var(--primary-font);
          text-transform: uppercase;
          min-width: 2500px;
          font-weight: 400;
          height: 125px;
          @media (max-width: 767px) {
            height: auto;
            line-height: 1;
            font-size: ${({ theme }) => theme.fontSizes.font18};
          }
        }
        .marquee-left {
          background-color: ${({ theme }) =>
          theme.colors.light.PrimaryThemeClr};
          transform: rotate(4deg);
          margin-left: -10px;
          color: ${({ theme }) => theme.colors.light.blackClr};
        }
        .marquee-right {
          background-color: ${({ theme }) => theme.colors.light.blackClr};
          color: ${({ theme }) => theme.colors.light.textwhiteClr};
          z-index: 9;
          position: absolute;
          transform: rotate(-5deg);
          bottom: 53%;
          left: -67px;
          @media (max-width: 767px) {
            transform: rotate(-9deg);
            bottom: 80%;
          }
        }
      }
    }
    &__sliderGrp {
      /* height: 100vh; */
      padding: 20px 0;
      overflow: hidden;
      position: relative;
      @media (max-width: 767px) {
        height: auto;
      }
      @media (min-width: 768px) and (max-width: 1023px) {
        height: 70vh;
      }
      .marquee-text-grp {
        .marquee-text {
          font-size: 280px;
          color: ${({ theme }) => theme.colors.light.Textlight};
          -webkit-text-stroke: rgba(0, 0, 0, 0.2);
          text-transform: uppercase;
          display: inline-block;
          line-height: 0.9em;
          min-width: auto;
          font-weight: 600;
          overflow: hidden;
          @media (max-width: 767px) {
            font-size: 180px;
          }
        }
      }
      .slider-layer {
        position: absolute;
        left: calc(100% - 75%);
        top: -12px;
        width: 100%;
        height: 100%;
        img {
          height: 550px;
          width: 52%;
        }
        @media (max-width:767px) {
          left: 0;
          img {
            height: 385px;
            width: 100%;
          }
        }
        @media (min-width:768px) and (max-width:1023px) {
            left: calc(100% - 98%);
            img {
              width: 98%;
            }
          }
      }
      &__contentItem {
          &__slider-item {
              margin: 0 auto;
              display: flex;
              align-items: center;
              background: #fff;
              height: 100%;
              justify-content: center;
              @media(min-width:1200px) {
                width: 50% ;
              }
              @media (max-width:1023px) {
                width:90%;
              }
            .slider-innerItem {
              flex: 0 0 50%;
            }
            .item-content, .item-media {
              min-height:100%;
            }
            .item-media {
              background: #fff;
              background-repeat: no-repeat;
              height: 500px;
              width: 100%;
              background-size: cover;
              background-position: center center;
              @media (max-width:767px) {
                height: 350px;
              }
            }
        .item-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 25px;
            h3 {
                color: #000;
                font-size: 30px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-bottom: 30px;
                @media (max-width:767px) {
                  font-size:15px;
                  margin-bottom:10px;
                }
              }
              p {
                color: #666;
                font-family: var(--primary-font);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                @media (max-width:767px) {
                  font-size:12px;
                }
              }
        }
      }
      }
        .slick-arrow {
          width: 55px;
          height: 55px;
          background-color: ${({ theme }) => theme.colors.light.PrimaryThemeClr};
          border-radius:50%;
          opacity: 1;
          position: absolute;
          top: 50%;
          border: 1px solid #2871FF;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1;
          @media (max-width:767px) {
            height: 40px;
            width: 40px;
            top: auto;
            bottom: -30%;
          }
          @media (min-width:768px) and (max-width:1023px) {
            top: auto;
            bottom: -25%;
          }
      }
      .slick-next {
        right: 75px;
        svg {
          transform: rotate(180deg);
        }
        @media (max-width:767px) {
          right: 40px;
        }
        &::before{
          content:none;
        }
       }
       .slick-prev {
           left: 70px;
           @media (max-width:767px) {
            left:45px;
           }
           &::before{
            content:none;
          }
       }
       .slick-prev:before,.slick-next:before {
          color: ${({ theme }) => theme.colors.light.textwhiteClr};;
       }
    }
    &__Banner {
      background: url(${BannerSection});
      background-repeat: no-repeat;
      background-size: cover;
      background-attachment: fixed;
      background-position: 0 -150px;
      height: 100vh;
      color: ${({ theme }) => theme.colors.light.textwhiteClr};
      padding: 120px 0 100px;
      @media (min-width:1024px) and (max-width:1200px) {
        padding: 100px 0;
      }
      @media (max-width: 767px) {
        background: url(${BannerBgRes});
        background-size: cover;
        padding: 100px 0;
        .CTA-btn {
          width: 80%;
          margin:0 auto;
        }
        .home-banner-content-mobile{
          z-index: 99;
          position: relative;
        }
      }
      .starIcon {
         text-align: right;
         margin-right: -45px;
      }
      h1 {
        margin: 0;
        font-family: var(--primary-font);
        font-size: ${({ theme }) => theme.fontSizes.font54};
        font-style: normal;
        font-weight: ${({theme }) => theme.fontWeight.fontWeight700};
        line-height: 62px;
        @media (max-width: 1023px) {
          font-size: ${({ theme }) => theme.fontSizes.font32};
          line-height: 37.097px;
          margin-top: 35px;
          letter-spacing: -1.28px;
        }
        @media (min-width:1024px) and (max-width:1200px) {
          font-size: ${({ theme }) => theme.fontSizes.font48};
          line-height: 48px;
        }
      }
      &__arrowIcon {
        position: absolute;
        right: calc(100% - 85%);
        bottom: 15px;
        @media (max-width: 1023px) {
          display: none;
        }
      }
      .image-box-grp {
        position: absolute;
        width: 100%;
        height: 100vh;
        left: 0;
        top: 75px;
        padding: 0px 70px;
      }
      .image-box {
        display: flex;
            /* position: absolute;
            margin: auto;
            width: auto; */
            img {
                max-width: 100%;
                transition: all 0.3s;
                display: block;
                width: auto;
                height: auto;
                transform: scale(1);
            }
            &:hover {
                img {
                    transform: scale(1.1);
                }
            }
        } 
        .bannerSlide1 {
          justify-content: end;
          padding-top: 20px;
          @media (max-width:1023px) {
            padding-left: 70px;
            justify-content: start;
          }
        }
        .bannerSlide2 {
          justify-content: end;
           > div {
            position: absolute;
            top: 5rem;
            margin-right: 6rem;
            @media (max-width:767px) {
              margin-right: 2rem;
              top: auto;
            } 
            @media (min-width:768px) and (max-width:1023px) {
              position: unset;
              margin-right: 0;
            }
           }
        }
        .bannerSlide3 {
          padding-top: 50px;
        }
        .bannerSlide4 {
          justify-content: center;
          padding-top: 80px;
          @media (max-width:1023px) {
            padding-left: 20px;
            justify-content: start;
          }
          @media (min-width: 1200px) and (max-width:1280px) {
            padding-top: 30px;
              > div {
                width: 150px;
              }
          }
        }
        .bannerSlide5 {
          justify-content: end;
          @media (min-width:768px) and (max-width:1023px) {
              position: absolute;
              right: 55px;
          }
          @media (min-width: 1200px) and (max-width:1280px) {
              > div {
                width: 200px;
                margin-top: -35px;
              }
          }
        }
    }
    &__CtaSection {
      background: url(${CTABg});
      background-repeat: no-repeat;
      height: 700px;
      background-size: cover;
      padding: 80px 0;
      text-align: center;
      color: ${({ theme }) => theme.colors.light.textwhiteClr};
      background-position: center center;
      position: relative;
      @media (max-width: 767px) {
        padding: 30px 0;
        height: 100vh;
      }
      h1 {
        font-family: var(--primary-font);
        font-size: 185px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 1.2px;
        text-transform: uppercase;
        @media (max-width: 1023px) {
          text-align: left;
          font-size: 115px;
        }
        @media (min-width: 1024px) and (max-width: 1290px) {
           font-size: 165px;
        }
      }
      &__item {
        text-align: left;
        width: 35%;
        position: absolute;
        bottom: 100px;
        p {
          line-height: 30px;
          font-family: var(--primary-font-light);
          font-size: ${({ theme }) => theme.fontSizes.font18};
          font-weight: ${({ theme }) => theme.fontWeight.fontWeight300};
        }
        @media (max-width: 1023px) {
          width: 92%;
          bottom: 0;
          margin-bottom: 30px;
        }
      }
    }
    &__feature {
      background-color: ${({ theme }) => theme.colors.light.blackClr};
      padding: 80px 0;
      color: ${({ theme }) => theme.colors.light.textwhiteClr};
      font-family: var(--primary-font);
      @media (max-width: 767px) {
        padding: 30px 0;
      }
      .heading {
        padding-bottom: 50px;
      }
      span {
        margin: 15px 0;
        display: inline-block;
        letter-spacing: 1.5px;
        color: rgba(255, 255, 255, 0.8);
      }
      .img-wrapper {
        width: 100%;
        height: 100%;
        overflow: hidden;
        margin-bottom: 30px;
        display: inline-block;
        box-sizing: border-box;
        .inner-img {
          transition: 0.3s;
          width: 100%;
          object-fit: cover;
          &:hover {
            transform: scale(1.1);
          }
        }
        .res-inner-img {
          @media (max-width: 767px) {
            height: 100vh;
          }
        }
      }
      &__item {
        h3 {
          font-style: normal;
          font-size: ${({ theme }) => theme.fontSizes.font48};
          font-weight: ${({ theme }) => theme.fontWeight.fontWeight700};
          line-height: 48px;
          @media (max-width: 767px) {
            font-size: ${({ theme }) => theme.fontSizes.font24};
            text-transform: uppercase;
          }
        }
        h4 {
          font-size: ${({ theme }) => theme.fontSizes.font32};
          font-weight: ${({ theme }) => theme.fontWeight.fontWeight700};
        }
        p {
          font-family: var(--primary-font-light);
          font-size: ${({ theme }) => theme.fontSizes.font18};
          font-weight: ${({ theme }) => theme.fontWeight.fontWeight300};
        }
        &__Itinerary {
          p {
            color: rgba(255, 255, 255, 0.75);
          }
        }
      }
      &__innerItem {
        position: absolute;
        top: 60px;
        left: 55px;
        @media (max-width: 767px) {
          left: 25px;
        }
        .CTA-btn {
          @media (max-width: 767px) {
            bottom: -35em;
            position: absolute;
            left: -12px;
          }
          @media (min-width:360px) and (max-width:739px) {
            bottom: -25em;
          }
        }
      }
    }
   

    &__Testimonial {
      padding: 100px 0;
      font-family: var(--primary-font);
      background: url(${TestimonialImg});
      background-repeat: no-repeat;
      background-position: bottom left;
      @media (max-width: 767px) {
        background-position: 199% 100%;
        height: 465px;
      }
      .carousel-inner {
        min-height: 320px;
      }
      .carousel-item {
        h5 {
          color: rgba(29, 45, 100, 0.5);
          text-align: center;
          font-family: var(--primary-font);
          font-size: ${({ theme }) => theme.fontSizes.font52};
          font-weight: ${({ theme }) => theme.fontWeight.fontWeight400};
          line-height: 100%;
          letter-spacing: -1.28px;
          @media (max-width: 767px) {
            font-size: ${({ theme }) => theme.fontSizes.font24};
            line-height: 120%;
          }
          @media (min-width: 768px) and (max-width: 1023px) {
            font-size: ${({ theme }) => theme.fontSizes.font30};
            line-height: 38px;
          }
        }
        p,
        span {
          color: #1d2d64;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
        }
      }
      .carousel-indicators {
        bottom: -80px;
        counter-reset: sec;
        @media (max-width: 767px) {
          bottom: 70px;
          }
        button {
          border: 1px solid rgba(29, 45, 100, 1);
          height: 30px;
          border-radius: 50%;
          position: relative;
          @media (max-width: 767px) {
            margin: 0 10px;
          }
          &.active {
            background-color: rgba(29, 45, 100, 1);
            &::after {
              color: #fff;
            }
          }
          &::after {
            counter-increment: sec;
            content: "" counter(sec) "";
            position: absolute;
            left: 32.1rem;
            top: 4px;
            color: rgba(29, 45, 100, 1);
            font-size: 16px;
            font-weight: 600;
          }
        }
      }
    }
  }
`;
