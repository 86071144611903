import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import BackButton from "../../../components/common/backbutton";
import { PaymentCompleted } from "../../../assets/images";
import Button from "../../../components/common/button";
import { PaymentStyled } from "./style";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../utils/constants/routes";
import { getUserId } from "../../../utils/utilityFunctions";

function PaymentSuccess() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paidId = searchParams.get("id");
  const sessionId = searchParams.get("session_id");
  const publicId = searchParams.get("publicId");
  const userId = getUserId();

  const navigateToItineraryList = () => {
    if (userId) {
      navigate(
        `${ROUTES?.itineraryDetails}?session_id=${sessionId}&id=${paidId}&publicId=${publicId}`
      );
    }
    if (!userId) {
      navigate(
        `${ROUTES?.guestItinerary}?session_id=${sessionId}&id=${paidId}&publicId=${publicId}`
      );
    }
  };

  return (
    <PaymentStyled>
      <Container fluid>
        <Row className="Overview-wrapper">
          <Col lg={6} md={12} sm={12} className="Overview-content">
            <div className="Payment-status">
              <p>
                <span className="Payment-status-icon"></span> Payment Successful
              </p>
            </div>
            <h1> Payment confirmed </h1>
            <p> You payment was successful. You can now view your itinerary.</p>

            <div className="btn-grp">
              <BackButton path={"/"} btnClass="Back-btn" text="Take me home" />
              <Button
                text={"View Itinerary"}
                btnClass="primary-btn"
                onClick={navigateToItineraryList}
              />
            </div>
          </Col>

          <Col lg={6} md={12} sm={12} className="p-lg-0">
            <div
              className="quiz-img-wrap"
              style={{ backgroundImage: `url(${PaymentCompleted})` }}
            ></div>
          </Col>
        </Row>
      </Container>
    </PaymentStyled>
  );
}

export default PaymentSuccess;
