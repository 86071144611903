import React from "react";
import { Route, Routes } from "react-router-dom";
import AccessControler from "./accessControler";
import NotFoundPage from "../pages/web/notFoundPage";
import WebRoutes from "./web";
import Web from "../views/web";
import { ROUTES } from "../utils/constants/routes";

export default function AppRoutes() {
  const renderWebRoutes = (route, index) => {
    if (!route?.protected) {
      return (
        <Route
          key={`web-route-${index}`}
          path={route?.path}
          element={<Web Layout={route?.layout}>{route?.element}</Web>}
        />
      );
    }
    return (
      <Route
        key={`web-route-protected-${index}`}
        path={route?.path}
        element={
          <AccessControler redirectPath={ROUTES.login}>
            <Web Layout={route?.layout}>{route?.element}</Web>
          </AccessControler>
        }
      />
    );
  };

  return (
    <Routes>
      <Route exact path="/*">
        {WebRoutes?.map(renderWebRoutes)}
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
