import React, { useEffect, useState, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import Button from "../../../components/common/button";
import {
  getUserId,
  capitalizeFirstLetter,
  appendQueryParametersInImage,
  getUnpaidItineraryImages,
  formatTripadvisorData,
} from "../../../utils/utilityFunctions";
import { SingleitineraryInner } from "./style";
import { LABELS } from "../../../utils/constants";
import { TEXT, MSG } from "../../../utils/constants";
import Image from "../../../components/common/image";
import {
  AccommodationIcon,
  ActivityIcon,
  RestaurantsIcon,
} from "../../../assets/images";
import { useMemo } from "react";

const Singleitinerary = ({
  ClassName,
  item,
  status,
  inviteLink,
  paymentLink,
  userId,
  type,
  response,
  index,
}) => {
  const [scroll, setScroll] = useState(false);

  const height = document.body.scrollHeight;

  const divRef = useRef(null);

  // useEffect(() => {
  //   window.addEventListener("scroll", () => {
  //     if (divRef.current) {
  //       const height = divRef.current.clientHeight;
  //       setScroll(window.scrollY > height + 1100);
  //     }
  //   });
  // }, []);

  // useEffect(() => {
  //   window.addEventListener("scroll", () => {
  //     let dynamicHeight = height
  //     if(dynamicHeight > 4000) {
  //       dynamicHeight = dynamicHeight - 3500
  //     }
  //     console.log('dynamicHeight---', dynamicHeight)
  //     setScroll(window.scrollY > dynamicHeight);
  //   });
  // }, [height]);

  const journeyHandler = () => {
    if (paymentLink) {
      window.open(paymentLink, "_self");
    }
  };

  const handleLinkClick = (status, url) => {
    if (status) {
      window.open(url, "_blank");
    }
  };

  let tripAdvisor = formatTripadvisorData(item?.trip_advisor_suggestions);
  if (!status) {
    tripAdvisor = [
      { category: "accommodation" },
      { category: "restaurants" },
      { category: "activities" },
    ];
  }

  let imageURL = item?.image?.url?.includes("images.unsplash.com")
    ? appendQueryParametersInImage(item?.image?.url, 80)
    : item?.image?.url;

  const updateIMage = useMemo(() => {
    if (!status) {
      // unpaid
      if (index !== 0) {
        // not 1st day
        return (imageURL = getUnpaidItineraryImages());
      }
    }
  }, [!status]);

  return (
    <SingleitineraryInner>
      <Row>
        <Col>
          <div className={`tr-single-itinerary-box ${ClassName}`}>
            <div className="tr-iti-img-wrap">
              <Image
                src={updateIMage || imageURL}
                className="itinerary-hero-img"
                item={item}
              />

              <div className="tr-iti-day">
                <span title={item?.title}>{item?.title}</span>
              </div>
            </div>
            <div className="tr-iti-content-wrap mb-0" ref={divRef}>
              <span className="tr-iti-title">{item?.title}</span>
              <span className="tr-iti-desc">{item?.description}</span>
              <Row>
                <Col
                  xl="9"
                  className={
                    tripAdvisor.length ? "trip-advisor-paid-grp" : "m-0"
                  }
                >
                  {tripAdvisor?.map((data, index) => {
                    let linkText = "";
                    let icons = "";
                    let color = "";
                    if (data?.category === "accommodation") {
                      linkText = TEXT.BOOK_STAY;
                      icons = AccommodationIcon;
                      color = "#DD2590";
                    } else if (data?.category === "restaurants") {
                      linkText = TEXT.BOOK_TABLE;
                      icons = RestaurantsIcon;
                      color = "#5720B7";
                    } else if (data?.category === "activities") {
                      linkText = TEXT.BOOK_ACTIVITY;
                      icons = ActivityIcon;
                      color = "#DC6803";
                    }
                    return (
                      <div
                        key={`trip_advisor_suggestions-${index}`}
                        className={`trip-advisor-item ${
                          status ? "trip_advisor_paid" : "trip_advisor_unpaid"
                        }`}
                      >
                        <div className="d-flex">
                          <div>
                            <img src={icons} alt="" />
                          </div>

                          <h3>{capitalizeFirstLetter(data?.category)}</h3>
                        </div>
                        <p
                          style={{ color }}
                          onClick={() => handleLinkClick(status, data?.web_url)}
                        >
                          {linkText}
                        </p>
                      </div>
                    );
                  })}
                </Col>
              </Row>
              {!status && (
                <div className="mt-4">
                  <h5 className="mb-3">{MSG.ACCESS_RECOMMENDATIONS_NOTE}</h5>
                </div>
              )}
              <div>
                {((!status && paymentLink) || getUserId() === userId) &&
                  index === 0 && (
                    <div
                      className={
                        scroll
                          ? "tr-iti-sticky-wrap tr-iti-sticky-scrolled"
                          : "tr-iti-sticky-wrap tr-iti-sticky-scrolled"
                      }
                    >
                      <div className="tr-iti-stickyItem-wrap">
                        <Row className="w-100">
                          <Col md="8">
                            <h3>
                              {TEXT?.ON_UNLOCK_JOURNEY_AMOUNT_TEXT}
                              {response?.amount}
                            </h3>
                            <div>
                              <p>{TEXT?.ON_UNLOCK_JOURNEY_STATIC_TEXT}</p>
                            </div>
                          </Col>
                          <Col md="4" className="text-center">
                            <Button
                              text={LABELS?.UNLOCK_JOURNEY}
                              className="CTA-btn"
                              onClick={journeyHandler}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  )}
              </div>

              {userId !== getUserId() && !status && type === "Group travel" && (
                <h4>Payment is not completed for this itinerary</h4>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </SingleitineraryInner>
  );
};

export default Singleitinerary;
