import React from "react";
import { ItinerariesWrapper, PageNotFound } from "./style";
import { Col, Container, Row } from "react-bootstrap";
import { ItinerariesPageNot, PageNot } from "../../../assets/images";
import { ROUTES } from "../../../utils/constants/routes";
import { ChevLeft } from "../../../assets/icons";
import BackButton from "../../../components/common/backbutton";

const ItineraryNotFound = () => {
  return (
    <ItinerariesWrapper>
      <Container>
        <div className="web-layout-body page-not-wrapper">
          <BackButton
            path={ROUTES?.dashboard}
            text="Back to dashboard"
            btnClass="Back-btn"
          />
          <Row>
            <Col xl="12">
              <h1 className="page-title">Itineraries</h1>

              <h3> My itineraries </h3>

              <div className="img-wrapper">
                <img
                  src={ItinerariesPageNot}
                  alt="Not Found"
                  className="img-fluid"
                />
              </div>

              <div className="text-center content-box mb-5">
                <h1 className="page-title"> Itinerary not found </h1>
                <p> The page you are looking for doesn't exist.</p>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </ItinerariesWrapper>
  );
};

export default ItineraryNotFound;
