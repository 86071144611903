import {
  SAVE_TRAVEL_QUESTIONS,
  SAVE_USER_TRAVEL_ANSWERS,
  SAVE_OPENAI_QUESTIONS,
  SAVE_USER_OPENAI_ANSWERS,
  SAVE_TRAVEL_QUIZ_CURRENT_STEPS,
  SAVE_PRE_TRAVEL_QUIZ_DATA,
  SAVE_PAIDITINERARY_DETAILS,
  SAVE_UNPAIDITINERARY_DETAILS,
  DELETE_OPENAI_QUES_AND_ANS,
  DELETE_ALL_TRAVEL_QUES_DATA,
  GROUP_ITINERARY_STATUS,
  DELETE_ALL_ITINERARIES,
  UPDATE_ITINERARY_CREATE_PAYLOAD
} from "./constants";

export const saveTravelQuestions = (payload) => {
  return {
    type: SAVE_TRAVEL_QUESTIONS,
    payload,
  };
};

export const saveUserTravelAnswers = (payload) => {
  return {
    type: SAVE_USER_TRAVEL_ANSWERS,
    payload,
  };
};

export const saveOpenAIQuestions = (payload) => {
  return {
    type: SAVE_OPENAI_QUESTIONS,
    payload,
  };
};

export const saveUserOpenAIAnswers = (payload) => {
  return {
    type: SAVE_USER_OPENAI_ANSWERS,
    payload,
  };
};

export const saveCurrentSteps = (payload) => {
  return {
    type: SAVE_TRAVEL_QUIZ_CURRENT_STEPS,
    payload,
  };
};

export const savePreTravelQuizData = (payload) => {
  return {
    type: SAVE_PRE_TRAVEL_QUIZ_DATA,
    payload,
  };
};
export const savePaidItineraryDetails = (payload) => {
  return {
    type: SAVE_PAIDITINERARY_DETAILS,
    payload,
  };
};

export const saveUnpaidItineraryDetails = (payload) => {
  return {
    type: SAVE_UNPAIDITINERARY_DETAILS,
    payload,
  };
};

export const deleteOpenAIQuesAndAns = () => {
  return {
    type: DELETE_OPENAI_QUES_AND_ANS,
  };
};

export const deleteAllTravelQuesData = () => {
  return {
    type: DELETE_ALL_TRAVEL_QUES_DATA,
  };
};

export const generateGroupItineraryStatus = () => {
  return {
    type: GROUP_ITINERARY_STATUS,
  };
};

export const deleteAllItineraries = () => {
  return {
    type: DELETE_ALL_ITINERARIES,
  };
};

export const updateItineraryCreatePayload = (payload) => {
  return {
    type: UPDATE_ITINERARY_CREATE_PAYLOAD,
    payload,
  };
};
