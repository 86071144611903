const PreloadImages = () => {
  return (
    <>
      {Array(18)
        .fill()
        .map((_, index) => {
          const desktopURL = `${process.env.REACT_APP_CDN_URL}desktop/d${index + 1}.png`;
          const mobileURL = `${process.env.REACT_APP_CDN_URL}mobile/m${index + 1}.png`;
          return (
            <div key={`prload-image-${index}`}>
              <img src={desktopURL} alt="preload" style={{ display: "none" }} />
              <img src={mobileURL} alt="preload" style={{ display: "none" }} />
            </div>
          );
        })}
    </>
  );
};

export default PreloadImages;
