import styled from "styled-components";

export const BackButtonStyled = styled.div`
    color: #475467;
    font-family: var(--secondary-font);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; 
    border: none;
    background: transparent;
    margin-top:20px;
    text-align:center;
    cursor: pointer;
    /* display: flex; */
    align-items: center;
    &:hover {
      color: ${({theme }) => theme.colors.light.PrimaryThemeClr};
    }
    svg {
      margin-right:8px;
    }
`;
