import React, { useEffect, useMemo, useState, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import Button from "../../../components/common/button";
import {
  appendQueryParametersInImage,
  getUnpaidItineraryImages,
} from "../../../utils/utilityFunctions";
import { SingleitineraryInner } from "./style";
import Image from "../../../components/common/image";
import { LABELS, TEXT, MSG } from "../../../utils/constants";
import {
  capitalizeFirstLetter,
  formatTripadvisorData,
} from "../../../utils/utilityFunctions";
import {
  AccommodationIcon,
  ActivityIcon,
  RestaurantsIcon,
} from "../../../assets/images";

const Singleitinerary = ({
  ClassName,
  item,
  stripeLink,
  status,
  userId,
  response,
  index,
}) => {
  const [scroll, setScroll] = useState(false);

  const divRef = useRef(null);

  // useEffect(() => {
  //   window.addEventListener("scroll", () => {
  //     if (divRef.current) {
  //       const height = divRef.current.clientHeight;
  //       setScroll(window.scrollY > height + 1100);
  //     }
  //   });
  // }, []);

  // useEffect(() => {
  //   window.addEventListener("scroll", () => {
  //     setScroll(window.scrollY > 2000);
  //   });
  // }, []);

  const journeyHandler = () => {
    window.open(stripeLink, "_self");
  };

  const handleLinkClick = (status, url) => {
    if (status) {
      window.open(url, "_blank");
    }
  };

  let tripAdvisor = formatTripadvisorData(item?.trip_advisor_suggestions);

  if (!status) {
    tripAdvisor = [
      { category: "accommodation" },
      { category: "restaurants" },
      { category: "activities" },
    ];
  }

  let imageURL = item?.image?.url?.includes("images.unsplash.com")
    ? appendQueryParametersInImage(item?.image?.url, 80)
    : item?.image?.url;

  const updateIMage = useMemo(() => {
    if (!status) {
      // unpaid
      if (index !== 0) {
        // not 1st day
        return (imageURL = getUnpaidItineraryImages());
      }
    }
  }, [!status]);

  return (
    <SingleitineraryInner>
      <Row>
        <Col>
          <div className={`tr-single-itinerary-box ${ClassName}`}>
            <div className="tr-iti-img-wrap">
              <Image
                src={updateIMage || imageURL}
                alt="Loading.."
                className="itinerary-hero-img"
                item={item}
              />
              <div className="tr-iti-day">
                <span title={item?.title}>{item?.title}</span>
              </div>
            </div>
            <div className="tr-iti-content-wrap" ref={divRef}>
              <span className="tr-iti-title">{item?.title}</span>
              <span className="tr-iti-desc">{item?.description}</span>
              <Col
                xl="9"
                className={tripAdvisor.length ? "trip-advisor-paid-grp" : "m-0"}
              >
                {tripAdvisor?.map((data, index) => {
                  let linkText = "";
                  let icons = "";
                  let color = "";
                  if (data?.category === "accommodation") {
                    linkText = TEXT.BOOK_STAY;
                    icons = AccommodationIcon;
                    color = "#DD2590";
                  } else if (data?.category === "restaurants") {
                    linkText = TEXT.BOOK_TABLE;
                    icons = RestaurantsIcon;
                    color = "#5720B7";
                  } else if (data?.category === "activities") {
                    linkText = TEXT.BOOK_ACTIVITY;
                    icons = ActivityIcon;
                    color = "#DC6803";
                  }
                  return (
                    <div
                      key={`trip_advisor_suggestions-${index}`}
                      className={`trip-advisor-item ${
                        status ? "trip_advisor_paid" : "trip_advisor_unpaid"
                      }`}
                    >
                      <div className="d-flex">
                        <div>
                          <img src={icons} alt="" />
                        </div>

                        <h3>{capitalizeFirstLetter(data?.category)}</h3>
                      </div>
                      <p
                        style={{ color }}
                        onClick={() => handleLinkClick(status, data?.web_url)}
                      >
                        {linkText}
                      </p>
                    </div>
                  );
                })}
              </Col>
              {!status && (
                <div className="mt-4">
                  <h5 className="mb-3">{MSG.ACCESS_RECOMMENDATIONS_NOTE}</h5>
                </div>
              )}
              <div
                className={
                  scroll
                    ? "tr-iti-sticky-wrap tr-iti-sticky-scrolled"
                    : "tr-iti-sticky-wrap tr-iti-sticky-scrolled"
                }
              >
                {!status && stripeLink && index === 0 && (
                  <div className="tr-iti-stickyItem-wrap">
                    <Row className="w-100">
                      <Col md="8">
                        <h3>
                          {TEXT?.ON_UNLOCK_JOURNEY_AMOUNT_TEXT}
                          {response?.amount}
                        </h3>
                        <div>
                          <p>{TEXT?.ON_UNLOCK_JOURNEY_STATIC_TEXT}</p>
                        </div>
                      </Col>
                      <Col md="4" className="text-center">
                        <Button
                          text={LABELS?.UNLOCK_JOURNEY}
                          className="CTA-btn"
                          onClick={journeyHandler}
                        />
                      </Col>
                    </Row>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </SingleitineraryInner>
  );
};

export default Singleitinerary;
