import React from "react";
import { useLocation } from "react-router-dom";
import Header from "../../components/common/header";
import Footer from "../../components/common/footer";

export default function WebLayout({ children }) {
  const location = useLocation();
  let isCommonHeaderFooter = false;
  let isShowHeaderFooter = true;
  if (["/personalitytest", "/editprofile"].includes(location.pathname)) {
    isShowHeaderFooter = false;
  }
  if (
    [
      "/dashboard",
      "/comingsoon",
      "/personality-overview",
      "/personality",
      "/personalitySuccess",
      "/view-itineraries",
      "/itinerary-details",
      "/itinerary-details/guest",
      "/itinerary-in-progress",
      "/terms",
    ].includes(location.pathname)
  ) {
    isCommonHeaderFooter = true;
  }

  return (
    <div>
      <div className="d-flex flex-column res-wrapper-flow">
        {isShowHeaderFooter && <Header isDashboard={isCommonHeaderFooter} />}
        <div className="web-layout-body">{children}</div>

        {isShowHeaderFooter && <Footer isDashboard={isCommonHeaderFooter} />}
      </div>
    </div>
  );
}
