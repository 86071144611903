import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  FeatureImg1,
  FeatureImg2,
  FeatureSection,
} from "../../../assets/images";
import { StartIcon } from "../../../utils/svg";
import Button from "../../../components/common/button";
import { useNavigate } from "react-router-dom";
import Image from "../../../components/common/image";
import { handleRedirectionToQuizPage } from "../../../utils/utilityFunctions";
const Feature = () => {
  const navigate = useNavigate();
  return (
    <div className="home-wrapper__feature" id="feature">
      <Container>
        <Row>
          <Col md="6" className="offset-md-3 text-center d-none d-md-block">
            <div>
              {" "}
              <StartIcon />{" "}
            </div>
            <span> FEATURES </span>
            <h1 className="heading"> Unlock your travel desires. </h1>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <div className="home-wrapper__feature__item position-relative">
              <div className="img-wrapper">
                <Image
                  className="inner-img res-inner-img"
                  alt=""
                  src={FeatureSection}
                />
              </div>
              <Col md="4" className="home-wrapper__feature__innerItem">
                <h3> Personalized Recommendations </h3>
                <p className="mb-0">
                  {" "}
                  <strong> Our mission is simple: </strong>{" "}
                </p>
                <p>
                  {" "}
                  To make every trip you take a tailored experience that
                  reflects who you are.{" "}
                </p>
                <Button
                  onClick={() => handleRedirectionToQuizPage(navigate)}
                  text={"TAkE THE QUIZ"}
                  className="CTA-btn mt-0"
                />
              </Col>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md="6">
            <div className="home-wrapper__feature__item">
              <div className="img-wrapper">
                <Image
                  className="inner-img"
                  alt=""
                  src={FeatureImg1}
                  width="400px"
                  height="400px"
                />
              </div>
              <div className="home-wrapper__feature__item__Itinerary">
                <h4> Smart Itinerary Planner </h4>
                <p>
                  {" "}
                  Our smart itinerary planner simplifies travel scheduling. Tell
                  us where you want to go, when, and what you like, and we'll
                  create a complete and well-optimized itinerary just for you.
                </p>
              </div>
            </div>
          </Col>

          <Col md="6">
            <div className="home-wrapper__feature__item">
              <div className="img-wrapper">
                <Image
                  className="inner-img"
                  alt=""
                  src={FeatureImg2}
                  width="400px"
                  height="400px"
                />
              </div>
              <div className="home-wrapper__feature__item__Itinerary">
                <h4> Group Itineraries </h4>
                <p>
                  {" "}
                  Planning a trip with a group has never been easier. Our Group
                  Itinerary feature allows you to seamlessly coordinate and
                  organize your travel plans with your fellow adventurers.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Feature;
