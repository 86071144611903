import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../../components/common/button";
import Input from "../../../../components/common/input";
import useSignup from "../../../../services/auth";
import { ROUTES } from "../../../../utils/constants/routes";
import { validate } from "./validate";
import { deleteAllPersonalityQuesData } from "../../../../state/personality/actions";
import {
  isUserExist,
  setExpiryToken,
  getGuestRoutePublicId,
  getInvitedUserId,
  getPersonalityId,
} from "../../../../utils/utilityFunctions";
import useItineraryOperation from "../../../../utils/hooks/useItineraryOperation";
import useUserData from "../../../../services/user";

const LoginForm = ({ lodingSocialLogin }) => {
  const { getUser } = useUserData();
  const dispatch = useDispatch();

  const { loginUser, updateUserProfile } = useSignup();
  const [loading, setLoading] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const navigate = useNavigate();
  const personalityId = getPersonalityId();

  const { itineraryCreatePayload } = useSelector((state) => state.travel);
  const { handleUpdatePersonality } = useItineraryOperation();

  const routePublicId = getGuestRoutePublicId();
  const initialValues = {
    email: "",
    password: "",
  };

  const dataToSend = (values) => {
    return {
      email: values?.email,
      password: values?.password,
    };
  };

  const onSubmit = (values, { resetForm }) => {
    setLoading(true);
    loginUser(dataToSend(values))
      .then((response) => {
        if (response?.isSuccess) {
          if (isCheckboxChecked) {
            setExpiryToken();
          }
          if (
            itineraryCreatePayload &&
            Object.keys(itineraryCreatePayload)?.length &&
            values?.email === itineraryCreatePayload?.email
          ) {
            //new flow
            const { id } = response?.data?.data?.response;
            handleUpdatePersonality(id);
            return;
          }

          if (getInvitedUserId()) {
            getUser(response?.data?.data?.response?.id)
              .then((res) => {
                if (res?.isSuccess) {
                  if (personalityId && !res?.data?.personalityType?._id) {
                    updateUserProfile(res?.data?._id, {
                      personalityType: personalityId,
                    });
                  }
                  if (personalityId || res?.data?.personalityType?._id) {
                    return navigate(ROUTES?.travelQuiz);
                  } else {
                    dispatch(deleteAllPersonalityQuesData());
                    return navigate(ROUTES?.personalityTest);
                  }
                }
              })
              .catch(() => {
                return navigate(ROUTES?.personality);
              });
          } else if (routePublicId) {
            return navigate(
              `${ROUTES?.guestItinerary}?publicId=${routePublicId}`
            );
          } else if (response?.data?.data?.response?.isFirstLogin) {
            getUser(response?.data?.data?.response?.id)
              .then((res) => {
                if (res?.data?.personalityType?._id) {
                  return navigate(ROUTES?.travelStyle);
                } else {
                  return navigate(ROUTES?.personality);
                }
              })
              .catch(() => {
                return navigate(ROUTES?.personality);
              });
          } else {
            setTimeout(() => {
              navigate(ROUTES?.dashboard);
            }, 1000);
          }
        } else {
          resetForm(initialValues);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const { values, errors, handleBlur, handleChange, handleSubmit, touched } =
    useFormik({
      initialValues,
      onSubmit,
      validate,
    });

  useEffect(() => {
    isUserExist(navigate);
  }, [navigate]);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <div>
          <Input
            required
            maxLength={"256"}
            placeholder={"Please enter email"}
            label="Email"
            id="email"
            disabled={loading || lodingSocialLogin}
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.email && touched.email && errors.email}
          />
        </div>
        <div>
          <Input
            required
            maxLength={"20"}
            disabled={loading || lodingSocialLogin}
            label="Password"
            type="password"
            placeholder="Please enter password"
            value={values.password}
            id="password"
            name="password"
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.password && touched.password && errors.password}
          />
        </div>
        <div className="input-check-grp">
          <div className="input-check-grp__item">
            <input
              type="checkbox"
              id=""
              name=""
              value=""
              checked={isCheckboxChecked}
              onChange={() => setIsCheckboxChecked(!isCheckboxChecked)}
            />
            <label for=""> Remember for 30 days </label>
          </div>
          <Link to="/forgetpassword" className="link-btn">
            Forgot password
          </Link>
        </div>
        <div>
          <Button
            type="submit"
            text={loading || lodingSocialLogin ? "Please wait" : "Sign in"}
            loading={loading || lodingSocialLogin}
            disabled={
              loading ||
              lodingSocialLogin ||
              values?.email === "" ||
              values?.password === ""
            }
            borderbtn
          />
          <p className="text-center link-text">
            Don’t have an account?
            <Link to="/signup" className="link-btn">
              Sign Up
            </Link>
          </p>
        </div>
      </Form>
    </>
  );
};

export default LoginForm;
