import React from "react";
import {
  LoginSocialFacebook,
  LoginSocialGoogle,
  LoginSocialApple,
} from "reactjs-social-login";
import {
  FacebookLoginButton,
  GoogleLoginButton,
  AppleLoginButton,
} from "react-social-login-buttons";
import { SocialButtonStyled } from "./style";

const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URL;
const APPLE_REDIRECT_URI = process.env.REACT_APP_APPLE_REDIRECT_URL;

const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID;
const appleClientId = process.env.REACT_APP_APPLE_CLIENT_ID;
const AllAuth = (props) => {
  const { handleLoginStart, handleLoginSuccess, handleLoginError } = props;

  return (
    <SocialButtonStyled>
      <LoginSocialGoogle
        client_id={googleClientId || ""}
        redirect_uri={REDIRECT_URI}
        isOnlyGetToken
        scope="openid profile email"
        onResolve={({ provider, data, ...rest }) => {
          handleLoginSuccess(data, provider || "google");
        }}
        onReject={(err) => {
          handleLoginError(err);
        }}
        className="social-login-btn"
      >
        <GoogleLoginButton>
          <span>Continue with Google</span>
        </GoogleLoginButton>
      </LoginSocialGoogle>

      <LoginSocialFacebook
        onLoginStart={handleLoginStart}
        appId={facebookAppId}
        isOnlyGetToken={true}
        auth_type="reauthenticate"
        scope="public_profile,email"
        xfbml={true}
        fieldsProfile={
          "id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender"
        }
        redirect_uri={REDIRECT_URI}
        onResolve={({ provider, data }) => {
          handleLoginSuccess(data, provider || "facebook");
        }}
        onReject={(err) => {
          handleLoginError(err);
        }}
        className="social-login-btn"
      >
        <FacebookLoginButton className="facebook-icon">
          <span>Continue with Facebook</span>
        </FacebookLoginButton>
      </LoginSocialFacebook>

      <LoginSocialApple
        client_id={appleClientId}
        scope={"name email"}
        redirect_uri={APPLE_REDIRECT_URI}
        onLoginStart={() => {}}
        isOnlyGetToken={true}
        onResolve={({ provider, data }) => {
          handleLoginSuccess(data, provider || "apple");
        }}
        onReject={(err) => {
          handleLoginError(err);
        }}
        className="social-login-btn"
      >
        <AppleLoginButton>
          <span>Continue with Apple</span>
        </AppleLoginButton>
      </LoginSocialApple>
      <div className="social-login-btn"></div>
    </SocialButtonStyled>
  );
};

export default AllAuth;
