import React from "react";
import PropTypes from "prop-types";
import { RadioButtonStyled } from "./style";

const RadioButton = ({
  className,
  value,
  id,
  name,
  onChange,
  checked,
  text,
  radioButtonClass,
  heading,
  disabled,
  ...props
}) => {
  return (
    <div className={className}>
      <RadioButtonStyled
        className={`radio-ui-box-inner ${radioButtonClass}`}
        value={value}
        id={id}
        type={"radio"}
        name={name}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
        {...props}
      />
      <label htmlFor={id} className="radio-label-ui">
        <div className="radio-label-content">
          <h3>{heading && heading}</h3>
          {text}
        </div>
      </label>
    </div>
  );
};

RadioButton.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  checked: PropTypes.bool,
  text: PropTypes.string,
};

RadioButton.defaultProps = {
  className: "",
  value: "",
  checked: false,
  onChange: () => {},
  name: "radio",
  text: "",
};

export default RadioButton;
