import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Container, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { KeyIcon, Logo } from "../../../assets/icons";
import BackButton from "../../../components/common/backbutton";
import Button from "../../../components/common/button";
import AuthScreenHeading from "../../../components/common/heading";
import Input from "../../../components/common/input";
import useSignup from "../../../services/auth";
import { defaultContext } from "../../../states/context";
import { MSG } from "../../../utils/constants";
import { ROUTES } from "../../../utils/constants/routes";
import { validate } from "./validation";
import { isUserExist } from "../../../utils/utilityFunctions";
import { useDispatch } from "react-redux";
import { setForgetPasswordEmail } from "../../../state/user/actions";

const ForgetPassword = () => {
  const dispatch = useDispatch();
  const { setContextState } = useContext(defaultContext);
  const navigate = useNavigate();
  const { forgetPassword } = useSignup();
  const [loading, setLoading] = useState(false);
  const initialValues = {
    email: "",
  };

  const dataToSend = (values) => {
    return {
      email: values?.email,
    };
  };
  const onSubmit = (values, { resetForm }) => {
    setContextState((s) => ({
      ...s,
      forgetPasswordEmail: values?.email || "",
    }));
    dispatch(setForgetPasswordEmail(values?.email));

    setLoading(true);
    forgetPassword(dataToSend(values)).then((response) => {
      if (response?.isSuccess) {
        toast.info(MSG?.ON_OTP_SUCCESS);
        setContextState((s) => ({
          ...s,
          forgetPasswordOTP: response?.data?.data?.message,
        }));
        setTimeout(() => {
          setLoading(false);
          navigate(ROUTES?.otp);
        }, 3000);
      } else {
        setLoading(false);
        resetForm(initialValues);
      }
    });
  };

  const { values, errors, handleBlur, handleChange, handleSubmit, touched } =
    useFormik({
      initialValues,
      onSubmit,
      validate,
    });

  useEffect(() => {
    isUserExist(navigate);
  }, [navigate]);

  return (
    <Container fluid>
      <Row>
        <div className="col-md-6 left-content">
          <div className="px-4 mt-3">
            <Link to="/">
              <Logo />
            </Link>
          </div>
          <div className="Login-content Form-content">
            <AuthScreenHeading
              Logo={KeyIcon}
              mainHeading={"Forget password?"}
              subHeading={`No worries,we'll send you reset instructions`}
            />

            <div className="email-form">
              <Form onSubmit={handleSubmit}>
                <Input
                  placeholder={"Enter your email"}
                  type={"email"}
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.email}
                  error={errors.email && touched.email && errors.email}
                />
                <Button
                  type={"submit"}
                  text={"Reset password"}
                  loading={loading}
                  disabled={loading || values?.email === ""}
                />
                <BackButton
                  path={"/signin"}
                  text={"Back"}
                  btnClass="Back-btn"
                />
              </Form>
            </div>
          </div>
          <div className="px-4">
            <p> © Ethoventures 2023</p>
          </div>
        </div>
        <div className="col-md-6 SignUp-wrapper"></div>
      </Row>
    </Container>
  );
};

export default ForgetPassword;
