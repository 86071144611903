import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Button from "../../../components/common/button";
import SocialShare from "../../../components/HOCs/socialshare";
import { TEXT } from "../../../utils/constants";
import { ROUTES } from "../../../utils/constants/routes";
import { Container, Row, Col } from "react-bootstrap";
import { OverviewStyled } from "./style";
import { QuizInnerBox } from "./formStyle";
import { perOverviewBg } from "../../../assets/images";
import { getInvitedUserId, getUserId } from "../../../utils/utilityFunctions";
import Image from "../../../components/common/image";

const PersonalityOverview = () => {
  const { userDetails } = useSelector((state) => state.user);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const userId = getUserId();
  const isInvitedUser = getInvitedUserId();
  let isShowToast = false;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  useEffect(() => {
    getPersonality();
  }, [userDetails]);

  const getPersonality = () => {
    if (!userDetails?.personalityType?.name) {
      if (!isShowToast) {
        toast.info(TEXT?.ON_CREATE_PERSONALITY);
        isShowToast = true;
      }
      return navigate(ROUTES?.personality);
    }
  };
  const handleStartTravelQuiz = () => {
    if (userId) {
      if (isInvitedUser) {
        return navigate(ROUTES?.travelQuiz);
      }
      return navigate(ROUTES?.travelStyle);
    }
    navigate(ROUTES?.whySignup);
  };
  return (
    <QuizInnerBox>
      <Container fluid>
        <OverviewStyled>
          <Row className="Overview-wrapper">
            <Col lg={6} md={12} sm={12} className="p-0">
              <div
                className="quiz-img-wrap"
                style={{ backgroundImage: `url(${perOverviewBg})` }}
              ></div>
            </Col>
            <Col lg={6} md={12} sm={12} className="p-0 tr-overview-card-grp">
              <div className="Overview-card-grp">
              <div className="Overview-card">
                <div className="Overview-top-header">
                  <h4> Congrats, your travel personality has been unveiled! </h4>
                  <p className="card-text mt-0"> Your travel personality will be saved to your Ethoventures profile. </p>
                </div>
                <div className="Overview-card-item">
                  <div className="card-media">
                    <Image
                      src={userDetails?.personalityType?.image || ""}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="card-description">
                    <h1>{userDetails?.personalityType?.name || ""}</h1>
                    <p>{userDetails?.personalityType?.description || ""}</p>
                  </div>
                </div>
                <div className="card-footer-item">
                  <p className="card-text">
                    Click below to make your own travel itinerary!
                  </p>
                  <div className="Overview-btn-grp">
                    <Button
                      onClick={handleStartTravelQuiz}
                      text={"Get started"}
                    />
                    <Button
                      text={"Share"}
                      onClick={() => setShow(!show)}
                      className="share-btn"
                    />
                  </div>
                </div>
                <div className="share-icon-wrapper">
                  {show && (
                    <div className="share-icon">
                      <SocialShare setSow={setShow} />
                    </div>
                  )}
                </div>
              </div>
              </div>
            </Col>
          </Row>
        </OverviewStyled>
      </Container>
    </QuizInnerBox>
  );
};

export default PersonalityOverview;
