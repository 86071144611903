import React, { useState } from "react";
import Button from "../../../components/common/button";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { UserIconPlace } from "../../../assets/images";
import { placeholderIcon } from "../../../assets/images";
import { ROUTES } from "../../../utils/constants/routes";
import { formatDateToMonthYear } from "../../../utils/utilityFunctions";
import { reduceImageQuality } from "../../../utils/utilityFunctions";
import { extractInitialsFromMate } from "../../../utils/utilityFunctions";

const UnpaidItinerary = ({ unpaidItinerary }) => {
  const [visibleItineraries, setVisibleItineraries] = useState(8);
  const { userDetails } = useSelector((state) => state.user);
  const loadMoreItineraries = () => {
    setVisibleItineraries((prevCount) => prevCount + 4);
  };
  const userName =
    userDetails?.firstName && userDetails?.lastName
      ? userDetails?.firstName + " " + userDetails?.lastName
      : null;
  const nameparts = userName ? userName.split(" ") : null;
  const initialsUserName = nameparts
    ? nameparts[0]?.charAt(0)?.toUpperCase() +
      nameparts[1]?.charAt(0)?.toUpperCase()
    : null;
  return (
    <>
      {unpaidItinerary?.slice(0, visibleItineraries)?.map((item) =>
        item?.itinerary ? (
          <Link
            to={`${ROUTES?.itineraryDetails}?id=${item?._id}`}
            key={`${item?._id}_tr-itinerary-users-unpaid`}
          >
            <div className="tr-itinerary-card">
              <div
                className={`${
                  item?.itinerary?.itinerary[0]?.image?.url
                    ? "tr-itinerary-card-img-box"
                    : "card-placeholder tr-itinerary-card-img-box"
                }`}
                style={{
                  backgroundImage: item?.itinerary?.itinerary[0]?.image?.url
                    ? `url(${reduceImageQuality(
                        item?.itinerary?.itinerary[0]?.image?.url
                      )})`
                    : `url(${placeholderIcon})`,
                }}
              >
                <span className="tr-trip-info">
                  {item?.metaData[0]?.number_of_days} Day Trip
                </span>
              </div>
              <div className="tr-itinerary-card-body">
                <div className="tr-itinerary-card-content">
                  <span
                    className="tr-itinerary-card-title"
                    title={item?.itinerary?.title_destination || item?.itinerary?.destination}
                  >
                    {item?.itinerary?.title_destination || item?.itinerary?.destination || " "}
                  </span>
                  <span className="tr-itinerary-card-subtitle">
                    {formatDateToMonthYear(item?.createdAt)}
                  </span>
                </div>
                <div className="tr-users-thumb-wrap thumbs-sm">
                  {item?.type === "Group travel" &&
                    item?.tripMatesDetails?.slice(0, 3)?.map((mate, index) => (
                      <div
                        className="tr-users-thumb-img"
                        key={`${index}_tr-itinerary-users-img`}
                        title={mate?.email}
                      >
                        {mate?.picture ? (
                          <img
                            src={mate?.picture || UserIconPlace}
                            alt="User Avatar"
                            className="user-avatar"
                          />
                        ) : (
                          <div className="user-profile-text">
                            {extractInitialsFromMate(mate) || (
                              <img
                                src={UserIconPlace}
                                alt="User Icon"
                                className="default-user-icon"
                              />
                            )}
                          </div>
                        )}
                      </div>
                    ))}
                  {item?.type === "Solo travel" && (
                    <div
                      className="tr-users-thumb-img"
                      title={userDetails?.email}
                    >
                      {userDetails?.picture ? (
                        <img
                          src={userDetails?.picture}
                          alt="UserAvatar"
                          className="user-avatar"
                        />
                      ) : (
                        <div
                          className="user-profile-text"
                          title={`${userDetails?.email || ""} `}
                        >
                          {initialsUserName || (
                            <img
                              src={UserIconPlace}
                              alt="UserAvatar"
                              className="user-avatar"
                            />
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  {item?.tripMatesDetails?.length > 3 && (
                    <div className="tr-users-thumb-img">
                      <span className="tr-users-thumb-count">
                        +{item.tripMatesDetails.length - 3}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Link>
        ) : !item?.itinerary && item?.type === "Group travel" ? (
          <Link
            to={`${ROUTES?.groupItinerary}?id=${item?._id}`}
            key={`${item?._id}_tr-itinerary-users-unpaid-null`}
          >
            <div className="tr-itinerary-card">
              <div
                className={`${
                  item?.itinerary?.itinerary[0]?.image?.url
                    ? "tr-itinerary-card-img-box"
                    : "tr-itinerary-card-img-box card-placeholder"
                }`}
                style={{
                  backgroundImage: item?.itinerary?.itinerary[0]?.image?.url
                    ? `url(${reduceImageQuality(
                        item?.itinerary?.itinerary[0]?.image?.url
                      )})`
                    : `url(${placeholderIcon})`,
                }}
              >
                <span className="tr-trip-info">
                  {item?.metaData[0]?.number_of_days} Day Trip
                </span>
              </div>
              <div className="tr-itinerary-card-body">
                <div className="tr-itinerary-card-content">
                  <span className="tr-itinerary-card-title"></span>
                  <span className="tr-itinerary-card-subtitle">
                    {formatDateToMonthYear(item?.createdAt)}
                  </span>
                </div>
                <div className="tr-users-thumb-wrap thumbs-sm">
                  {item?.type === "Group travel" &&
                    item?.tripMatesDetails?.slice(0, 3)?.map((mate, index) => (
                      <div
                        className="tr-users-thumb-img"
                        key={`${index}_tr-itinerary-group-img`}
                        title={mate?.email}
                      >
                        {mate?.picture ? (
                          <img
                            src={mate?.picture || UserIconPlace}
                            alt="User Avatar"
                            className="user-avatar"
                          />
                        ) : (
                          <div className="user-profile-text">
                            {extractInitialsFromMate(mate) || (
                              <img
                                src={UserIconPlace}
                                alt="User Icon"
                                className="default-user-icon"
                              />
                            )}
                          </div>
                        )}
                      </div>
                    ))}
                  {item?.tripMatesDetails?.length > 3 && (
                    <div className="tr-users-thumb-img">
                      <span className="tr-users-thumb-count">
                        +{item.tripMatesDetails.length - 3}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Link>
        ) : (
          <div className="tr-itinerary-card-grp">
            <div
              className="tr-itinerary-card"
              key={`${item?._id}_tr-itinerary-users-unpaid-plain`}
            >
              <div
                className={`${
                  item?.itinerary?.itinerary[0]?.image?.url
                    ? "tr-itinerary-card-img-box"
                    : "card-placeholder tr-itinerary-card-img-box"
                }`}
                style={{
                  backgroundImage: item?.itinerary?.itinerary[0]?.image?.url
                    ? `url(${reduceImageQuality(
                        item?.itinerary?.itinerary[0]?.image?.url
                      )})`
                    : `url(${placeholderIcon})`,
                }}
              >
                <span className="tr-trip-info">
                  {item?.metaData[0]?.number_of_days} Day Trip
                </span>
              </div>
              <div className="tr-itinerary-card-body">
                <div className="tr-itinerary-card-content">
                  <span className="tr-itinerary-card-title">
                    {item?.itinerary?.title_destination || item?.itinerary?.destination || " "}
                  </span>
                  <span className="tr-itinerary-card-subtitle">
                    {formatDateToMonthYear(item?.createdAt)}
                  </span>
                </div>
                <div className="tr-users-thumb-wrap thumbs-sm">
                  {item?.type === "Group travel" &&
                    item?.tripMatesDetails?.slice(0, 3)?.map((item, index) => (
                      <div
                        className="tr-users-thumb-img"
                        key={`tripmates-detail-${index}-key`}
                        title={item?.email}
                      >
                        {item?.picture ? (
                          <img
                            src={item?.picture || UserIconPlace}
                            alt="User Avatar"
                            className="user-avatar"
                          />
                        ) : (
                          <div className="user-profile-text">
                            {extractInitialsFromMate(item) || (
                              <img
                                src={UserIconPlace}
                                alt="User Icon"
                                className="default-user-icon"
                              />
                            )}
                          </div>
                        )}
                      </div>
                    ))}
                  {item?.type === "Solo travel" && (
                    <div
                      className="tr-users-thumb-img"
                      title={userDetails?.email}
                    >
                      {userDetails?.picture ? (
                        <img
                          src={userDetails?.picture}
                          alt="UserAvatar"
                          className="user-avatar"
                        />
                      ) : (
                        <div
                          className="user-profile-text"
                          title={`${userDetails?.email || ""} `}
                        >
                          {initialsUserName || (
                            <img
                              src={UserIconPlace}
                              alt="UserAvatar"
                              className="user-avatar"
                            />
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  {item?.tripMatesDetails?.length > 3 && (
                    <div className="tr-users-thumb-img">
                      <span className="tr-users-thumb-count">
                        +{item.tripMatesDetails.length - 3}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )
      )}
      {visibleItineraries < unpaidItinerary?.length && (
        <div className="text-center w-100">
          <Button
            onClick={loadMoreItineraries}
            className="btn btn-primary w-auto px-3 mb-5"
            text={"Load More..."}
          ></Button>
        </div>
      )}
    </>
  );
};

export default UnpaidItinerary;
