/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  ItineraryInprogressInner,
  ItineraryTextItem,
  ItineraryWrapper,
} from "./style";
import Itineraryslider from "./slider";
import ItineraryOverview from "./overview";
import Singleitinerary from "./singleitinerary";
import BackButton from "../../../components/common/backbutton";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { downloadPdf } from "../../../utils/utilityFunctions";
import useItinerary from "../../../services/itinerary";
import Loader from "../../../components/common/loader";
import { TEXT, MSG } from "../../../utils/constants";
import { getUserId } from "../../../utils/utilityFunctions";
import { UserIconPlace } from "../../../assets/images";
import { ROUTES } from "../../../utils/constants/routes";
import ItineraryLayoutPdf from "./itineraryDetailsPdf";
import {
  extractInitialsFromMate,
  isCheckAllItineraryGenerated,
} from "../../../utils/utilityFunctions";
import { PDFDownloadLink } from "@react-pdf/renderer";
import MyDocument from "./myPdfDocument";
import { DownloadIcon } from "../../../assets/images/checkIcon";
import { StarIcon } from "../../../assets/icons";

const ItineraryLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { getItineraryDetailsById } = useItinerary();
  const { getGuestItineraryDetails } = useItinerary();
  const [loading, setLoading] = useState(true);
  const [loadingPdf, setLoadingPdf] = useState(true);
  const [itineraryResponse, setItineraryResponse] = useState();
  const [isDownloadingPdf, setDownloadingPdf] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const publicId = searchParams.get("publicId");
  // get sessionId once after successfully payment
  const sessionId = searchParams.get("session_id");
  const urlId = searchParams.get("id");

  const isAllItineraryGenerated =
    isCheckAllItineraryGenerated(itineraryResponse);

  const fetchItineraryDetails = async (isShowLoading) => {
    if (isShowLoading) {
      setLoading(true);
      window.scrollTo(0, 0);
    }

    if (urlId) {
      let res;
      if ((publicId && getUserId()) || (getUserId() && urlId)) {
        res = await getItineraryDetailsById(
          urlId,
          getUserId() ? sessionId : undefined
        );
      } else if (publicId && !getUserId()) {
        res = await getGuestItineraryDetails(publicId);
      }
      if (res?.isSuccess) {
        setItineraryResponse(res?.data?.data?.responseData);
      } else {
        if (isShowLoading) {
          navigate(ROUTES?.itineraryNotFound);
        } else {
          navigate(ROUTES?.dashboard);
        }
      }
    }

    // getting ItineraryResponse in diff format
    if (publicId && !getUserId()) {
      const res = await getGuestItineraryDetails(publicId, sessionId);
      if (res?.isSuccess) {
        setItineraryResponse(res?.data?.data);
      } else {
        if (isShowLoading) {
          navigate(ROUTES?.itineraryNotFound);
        } else {
          navigate(ROUTES?.dashboard);
        }
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchItineraryDetails(true);
  }, [location.state, urlId, sessionId, publicId]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchItineraryDetails(false);
    }, 10000);

    if (isAllItineraryGenerated && itineraryResponse?.isPaid) {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  });

  const handleDownloadPdf = () => {
    setDownloadingPdf(true);
    setTimeout(() => {
      downloadPdf();
      setDownloadingPdf(false);
    }, 1000);
  };

  useEffect(() => {
    setTimeout(() => {
      setLoadingPdf(false);
    }, 5000);
  }, []);

  if (loading) {
    return (
      <div className="web-spinner-loader">
        <Loader size="lg" dimension="row" message={TEXT?.ON_LOAD_ITINERARY} />
      </div>
    );
  }
  if (!location.search) {
    return (
      <ItineraryWrapper>
        <Container>
          <h1 className="text-secondary text-center mt-5">No data found</h1>
        </Container>
      </ItineraryWrapper>
    );
  }
  return (
    <ItineraryWrapper>
      <Container>
        <Row>
          <Col>
            <div className="d-flex align-items-center justify-content-between">
              <BackButton path={"/dashboard"} text="Back" btnClass="Back-btn" />
              <div className="download-btn-grp">
                {itineraryResponse?.isPaid && isAllItineraryGenerated && (
                  <div className="">
                    {loadingPdf ? (
                      <>
                        <div className="download-btn-item disabled">
                          <div className="Download-btn d-flex align-items-center">
                            Generating PDF
                          </div>
                          <DownloadIcon className="ml-2" />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="download-btn-item with-transition">
                          <PDFDownloadLink
                            className="Download-btn"
                            document={<MyDocument item={itineraryResponse} />}
                            fileName="Ethovenures customised trip itinerary.pdf"
                            // style={{visibility: hidden}}
                          >
                            {({ blob, url, loading, error }) => {
                              // return loading ? "Generating PDF" : "Download PDF";
                              return "Download PDF";
                            }}
                          </PDFDownloadLink>
                          <DownloadIcon />
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div>
        <Container>
          <Row>
            <Col>
              <div>
                <Itineraryslider item={itineraryResponse} />
              </div>
              <Container>
                <ItineraryInprogressInner>
                  <Row className="tr-itinerary-inprogress-wrap ">
                    {itineraryResponse?.tripMatesDetails?.map((item, index) => (
                      <Col
                        lg={4}
                        md={6}
                        xs={12}
                        key={`${index}-tripmate-details-non-pdf`}
                        className="mb-5"
                      >
                        <div className="tr-itinerary-inprogress-card">
                          <span
                            className={`tr-label ${
                              item.status === "Finished"
                                ? "tr-label-finished"
                                : "tr-label-sent"
                            }`}
                          >
                            {item?.status === "Finished"
                              ? "Finished"
                              : "In Progress"}
                          </span>
                          <div className="tr-user-info-area">
                            <div className="tr-user-img" title={item?.email}>
                              {item?.picture ? (
                                <img
                                  src={item?.picture || UserIconPlace}
                                  alt=""
                                />
                              ) : (
                                <div className="user-profile-text">
                                  {extractInitialsFromMate(item) || (
                                    <img
                                      src={UserIconPlace}
                                      alt="User Icon"
                                      className="default-user-icon"
                                    />
                                  )}
                                </div>
                              )}
                            </div>
                            <div className="tr-user-info">
                              <span
                                style={{ height: "30px" }}
                                className="tr-username"
                                title={
                                  item?.firstName
                                    ? `${item?.firstName} ${item.lastName}`
                                    : item?.email
                                }
                              >
                                {item?.firstName
                                  ? `${item?.firstName} ${item.lastName}`
                                  : item?.email}
                              </span>
                              <span
                                className="tr-user-location"
                                title={
                                  itineraryResponse?.itinerary
                                    ?.title_destination ||
                                  itineraryResponse?.itinerary?.destination
                                }
                              >
                                {itineraryResponse?.itinerary
                                  ?.title_destination ||
                                  itineraryResponse?.itinerary?.destination ||
                                  " "}
                              </span>
                            </div>
                          </div>
                          <div className="tr-user-desc">
                            {item?.personality_description}
                          </div>
                        </div>
                      </Col>
                    ))}

                    <ItineraryOverview item={itineraryResponse} />
                    {itineraryResponse?.isPaid &&
                      itineraryResponse?.itinerary?.itinerary?.map(
                        (item, index) => (
                          <Singleitinerary
                            ClassName="tr-itinerary-active"
                            item={item}
                            status={itineraryResponse?.isPaid}
                            key={`${index}-itinerary-paid`}
                            response={itineraryResponse}
                          />
                        )
                      )}
                    {!isAllItineraryGenerated && itineraryResponse?.isPaid && (
                      <div className="tr-spinner-loader">
                        <Loader
                          size="lg"
                          dimension="row"
                          message={MSG?.CREATING_ITINERARY_MSG}
                          // variant={"secondary"}
                        />
                      </div>
                    )}
                    {!itineraryResponse?.isPaid &&
                      itineraryResponse?.itinerary?.itinerary?.map(
                        (item, index) => (
                          <Singleitinerary
                            ClassName={
                              index === 0
                                ? "tr-itinerary-active"
                                : "tr-itinerary-blur"
                            }
                            item={item}
                            status={itineraryResponse?.isPaid}
                            inviteLink={itineraryResponse?.inviteLink}
                            paymentLink={itineraryResponse?.paymentLink}
                            key={`${index}-singleitinerary-unpaid`}
                            userId={itineraryResponse?.userId}
                            type={itineraryResponse?.type}
                            response={itineraryResponse}
                            index={index}
                            isAllItineraryGenerated={isAllItineraryGenerated}
                          />
                        )
                      )}
                    <ItineraryTextItem>
                      <Col>
                        <div className="tr-card-grp">
                          {itineraryResponse?.itinerary?.hidden_gems && (
                            <div className="tr-card-grp-item">
                              <div className="tr-card-item">
                                Hidden Gem <StarIcon />
                              </div>
                              <div className="tr-user-desc">
                                {itineraryResponse?.itinerary?.hidden_gems}
                              </div>
                            </div>
                          )}
                          {itineraryResponse?.itinerary?.helpful_tips && (
                            <div className="tr-card-grp-item">
                              <div className="tr-card-item">Helpful Tips</div>
                              <div>
                                <ul>
                                  {itineraryResponse?.itinerary?.helpful_tips?.map(
                                    (tip, index) => (
                                      <li
                                        key={`${index}-itinerary-unpaid-tip-details`}
                                        className="tr-user-desc"
                                      >
                                        {tip}
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            </div>
                          )}
                        </div>
                      </Col>
                    </ItineraryTextItem>
                  </Row>
                </ItineraryInprogressInner>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
      <div style={{ display: isDownloadingPdf ? "block" : "none" }}>
        {itineraryResponse?.isPaid && (
          <ItineraryLayoutPdf
            itineraryResponse={itineraryResponse}
            isDownloadingPdf={isDownloadingPdf}
          />
        )}
      </div>
    </ItineraryWrapper>
  );
};

export default ItineraryLayout;
