import styled from "styled-components";

export const InputStyled = styled.div`
      position: relative;
      /* margin: 20px 0; */
      .invalid {
        height: 20px;
      }
      .form-label {
          color: ${({theme }) => theme.colors.light.labeltext};
          font-family: var(--secondary-font);
          font-size: ${({theme }) => theme.fontSizes.font14};
          font-weight: ${({theme }) => theme.fontWeight.fontWeight400};
          font-style: normal;
          line-height: 20px; 
      }
      .form-control {
        opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
        border-radius: 8px;
        padding: 10px 14px;
        border:1px solid ${({theme }) => theme.colors.border.bdrPrimaryClr};
        font-size: ${({theme }) => theme.fontSizes.font14};
        background: #fff;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        /* text-transform: capitalize; */
        &::placeholder {
          font-size: ${({theme }) => theme.fontSizes.font14};
          color: ${({theme }) => theme.colors.light.inputLabelClr};
          text-transform: capitalize;
        }
        &:active, &:focus {
          outline: none;
          border: 1px solid ${({theme }) => theme.colors.border.bdrDarkClr};
        }
        &:hover {
          border: 1px solid ${({theme }) => theme.colors.border.bdrDarkClr};
        }
      }

    &.input-error {
      /* margin-bottom:15px; */
      .invalid {
        color: ${({theme }) => theme.colors.light.errorClr};
        font-size: ${(props) => props.theme.fontSizes.font12};
        font-weight: ${(props) => props.theme.fontWeight.fontWight400};
        line-height: 24px;
        height: 20px;
      }
      .form-control {
        border:1px solid ${({theme }) => theme.colors.border.errorClr};
      }
    }
`;
