/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ExplainationBox } from "./style";
import { ExplainermobBanner } from "../../../assets/images";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../utils/constants/routes";
import useQuiz from "../../../services/quiz";
import {
  savePersonalityQuestions,
  deleteAllPersonalityQuesData,
} from "../../../state/personality/actions";
import { MSG, USER_ID } from "../../../utils/constants";
import Button from "../../../components/common/button";
import PreloadImages from "../../../components/common/preloadImages";
import useSignup from "../../../services/auth";
import {
  getPersonalityId,
  removePersonalityId,
} from "../../../utils/utilityFunctions";
import useUserData from "../../../services/user";
import Image from "../../../components/common/image";

const Personality = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { updateUserProfile } = useSignup();
  const { getUser } = useUserData();
  const { userDetails, isFirstLogin } = useSelector((state) => state.user);
  const { getQuizQuestions } = useQuiz();
  const [isShowLoading, setIsShowLoading] = useState(false);
  const isPersonalityId = getPersonalityId();
  const userId = localStorage.getItem(USER_ID);
  const [hasUpdatedProfile, setHasUpdatedProfile] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleRedirectToPersonalityTest = () => {
    const shouldNavigate = userId
      ? userDetails?.personalityType?.name
      : isPersonalityId;

    if (shouldNavigate) {
      navigate(ROUTES?.personalityoverview);
      return;
    }
    setIsShowLoading(true);
    dispatch(deleteAllPersonalityQuesData());
    getQuizQuestions().then((response) => {
      setIsShowLoading(false);
      if (response?.isSuccess) {
        dispatch(savePersonalityQuestions(response?.data?.data?.questions));
        navigate(ROUTES?.personalityTest);
      } else {
        toast.error(MSG.ON_ERROR);
      }
    });
  };

  useEffect(() => {
    const shouldUpdateProfile =
      isPersonalityId && userId && isFirstLogin && !hasUpdatedProfile;
    // case for personality created in guest mode and then signup
    if (shouldUpdateProfile) {
      const payload = {
        personalityType: isPersonalityId,
      };
      updateUserProfile(userId, payload).then((response) => {
        if (!response?.isSuccess) {
          return;
        }
        getUser(userId);
        removePersonalityId();
        setHasUpdatedProfile(true);
      });
    }
  }, [isPersonalityId, userId, isFirstLogin, hasUpdatedProfile]);
  return (
    <>
      <PreloadImages />
      <ExplainationBox>
        <section className="explainer-section-one">
          <Container className="explainer-container">
            <Row>
              <Col md={6} xs={12}>
                <div className="exp-img-mobile-area">
                  <Image
                    src={ExplainermobBanner}
                    alt="BannerImage"
                    className="explainer-mob-banner"
                  />
                </div>
                <div className="exp-content-area">
                  <div className="exp-title-lg">
                    Ready to start your adventure?
                  </div>
                  <div className="exp-desc-lg">
                    Don’t get lost in the noise of travel guides and websites
                    when you can effortlessly curate your own adventure here.
                  </div>
                  <Button
                    type="submit"
                    text="Get Started"
                    loading={isShowLoading}
                    disabled={isShowLoading}
                    className="exp-btn mobile-hidden"
                    onClick={() => handleRedirectToPersonalityTest()}
                  />
                </div>
              </Col>
              <Col md={6} xs={12}>
                <div className="exp-content-area-inline">
                  <div className="exp-number-area">1</div>
                  <div className="exp-content-area-inline-inner">
                    <div className="exp-title-sm">
                      Uniquely Tailored Experiences
                    </div>
                    <div className="exp-desc-sm">
                      Embark on a thrilling journey of self-discovery. Unveil
                      your unique travel personality through our exciting
                      adventure quiz!
                    </div>
                  </div>
                </div>
                <div className="exp-content-area-inline">
                  <div className="exp-number-area">2</div>
                  <div className="exp-content-area-inline-inner">
                    <div className="exp-title-sm">
                      Why take the personality quiz?
                    </div>
                    <div className="exp-desc-sm">
                      Taking the quiz ensures that every experience caters to
                      your tastes and passions, making your vacation
                      unforgettable!
                    </div>
                  </div>
                </div>
                <button
                  onClick={() => handleRedirectToPersonalityTest()}
                  className="exp-btn desktop-hidden"
                >
                  Get Started
                </button>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="explainer-section-two">
          <Container className="explainer-container">
            <Row>
              <Col className="position-relative">
                <div className="Banner-Img"></div>
                {/* <Image
                  src={ExplainerBanner}
                  alt=""
                  className="explainer-banner mobile-hidden"
                /> */}
              </Col>
            </Row>
          </Container>
        </section>
      </ExplainationBox>
    </>
  );
};

export default Personality;
