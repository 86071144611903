/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import { ItineraryInprogressInner } from "./style";
import { Form } from "react-bootstrap";
import { CopyIcon } from "../../../assets/icons";
import { Col, Container, Row } from "react-bootstrap";
import Itineraryslider from "./progressImage";
import { ItineraryWrapper } from "./style";
import BackButton from "../../../components/common/backbutton";
import { useLocation, useNavigate } from "react-router-dom";
import useItinerary from "../../../services/itinerary";
import { useState } from "react";
import { ROUTES } from "../../../utils/constants/routes";
import { toast } from "react-toastify";
import copy from "copy-to-clipboard";
import Button from "../../../components/common/button";
import Loader from "../../../components/common/loader";
import { TEXT, MSG } from "../../../utils/constants";
import { UserIconPlace } from "../../../assets/images";
import Modal from "../../../components/common/modals/commonModal";
import { useDispatch } from "react-redux";
import { generateGroupItineraryStatus } from "../../../state/travel/actions";
import { getUserId } from "../../../utils/utilityFunctions";
import { extractInitialsFromMate } from "../../../utils/utilityFunctions";

const Itineraryinprogress = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { getItineraryDetailsById, getGuestItineraryDetails } = useItinerary();
  const [itineraryDetails, setItineraryDetails] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const { createGroupItineraryById } = useItinerary();
  const [showModal, setShowModal] = useState(false);

  const searchParams = new URLSearchParams(location.search);
  const itineraryId = searchParams.get("id");

  const publicId = searchParams.get("publicId");

  const handleFetchItineraryDetails = (isShowLoading) => {
    let getItineraryDetails = getItineraryDetailsById;
    if (publicId) {
      getItineraryDetails = getGuestItineraryDetails;
    }
    if (isShowLoading) {
      setLoading(true);
    }
    getItineraryDetails(itineraryId || publicId)
      .then((res) => {
        if (res?.isSuccess) {
          if (res?.data?.data?.responseData?.itinerary) {
            return navigate(ROUTES?.dashboard);
          }
          if (itineraryId) {
            setItineraryDetails(res?.data?.data?.responseData);
          } else {
            setItineraryDetails(res?.data?.data);
          }
        }
      })
      .finally(() => {
        if (isShowLoading) {
          setLoading(false);
        }
      });
  };
  const finishedCount =
    itineraryDetails?.tripMatesDetails?.filter(
      (item) => item?.status === "Finished"
    )?.length - 1;

  useEffect(() => {
    if (!(itineraryId || publicId)) {
      toast.info(MSG.INVALID_URL);
      return navigate(ROUTES?.dashboard);
    }

    handleFetchItineraryDetails(true);

    const interval = setInterval(() => {
      handleFetchItineraryDetails(false);
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [itineraryId, publicId]);

  const copyToClipboard = () => {
    let copyText = inputRef?.current?.value;
    let isCopy = copy(copyText);
    if (isCopy) {
      toast.info("Copied to Clipboard");
    }
  };

  const allStatusFinished = itineraryDetails?.tripMatesDetails?.every(
    (tripMate) => tripMate?.status === "Finished"
  );

  const isCheckAllTripmateCompletedTest = () => {
    const status =
      allStatusFinished &&
      itineraryDetails?.tripMatesDetails?.length ===
        itineraryDetails?.metaData[0]?.number_of_users;
    return status;
  };

  const generateGroupApi = () => {
    if (!isCheckAllTripmateCompletedTest()) {
      setShowModal(true);
    } else {
      createGroupItineraryById(itineraryId).then((res) => {
        setLoading(true);
        if (res?.isSuccess) {
          dispatch(generateGroupItineraryStatus(true));
          toast.success("Please wait , Your Itinerary is being generated.");
          navigate(ROUTES?.dashboard);
          setLoading(false);
        }
      });
    }
  };
  if (loading) {
    return (
      <div className="web-spinner-loader">
        <Loader
          size="lg"
          dimension="row"
          message={TEXT?.ON_LOAD_ITINERARY}
          variant={"secondary"}
        />
      </div>
    );
  }
  const handleClick = (response) => {
    if (response) {
      createGroupItineraryById(itineraryId).then((res) => {
        setLoading(true);
        if (res?.isSuccess) {
          dispatch(generateGroupItineraryStatus(true));
          toast.success(
            "Please wait , Your Itinerary is being generated. If you did not receive, kindly check your junk mail once."
          );
          navigate(ROUTES?.dashboard);
          setLoading(false);
        }
      });
    }
    setLoading(false);
    setShowModal(false);
  };
  return (
    <ItineraryWrapper>
      <Container>
        <Row>
          <Col>
            <BackButton path={"/dashboard"} text="Back" btnClass="Back-btn" />
            <Itineraryslider />

            <ItineraryInprogressInner>
              <div className="tr-itinerary-inprogress-wrap">
                <span className="tr-itinerary-inprogress-title">
                  Itinerary in progress
                </span>
                <Form>
                  <div className="tr-copy-input-wrap mb-3">
                    <label className="tr-label"> Group Link </label>
                    <div className="tr-copy-input-inner">
                      <input
                        placeholder={"url here"}
                        label="Group Link"
                        value={itineraryDetails?.inviteLink}
                        ref={inputRef}
                        type={"text"}
                        disabled
                      />
                      <div className="tr-copy-btn1" onClick={copyToClipboard}>
                        <span>
                          <CopyIcon title="Click to copy!" />
                        </span>
                      </div>
                    </div>
                  </div>
                </Form>
                {!itineraryDetails?.itinerary &&
                  getUserId() === itineraryDetails?.userId && (
                    <Button
                      text={
                        itineraryDetails?.status === "In progress"
                          ? "Please wait your Itinerary is in progress"
                          : "Generate group itinerary"
                      }
                      onClick={generateGroupApi}
                      disabled={itineraryDetails?.status === "In progress"}
                    />
                  )}
                <Row>
                  {!itineraryDetails?.tripMatesDetails && (
                    <div>No Tripmates</div>
                  )}
                  {itineraryDetails?.tripMatesDetails?.map((item, index) => (
                    <Col
                      lg={4}
                      md={6}
                      xs={12}
                      key={`itinerary-details-${index}-card`}
                    >
                      <div className="tr-itinerary-inprogress-card">
                        <span
                          className={`tr-label ${
                            item.status === "Finished"
                              ? "tr-label-finished"
                              : "tr-label-sent"
                          }`}
                        >
                          {item?.status === "Finished"
                            ? "Finished"
                            : "In Progress"}
                        </span>
                        <div className="tr-user-info-area">
                          <div className="tr-user-img">
                            {item?.picture ? (
                              <img
                                src={item?.picture || UserIconPlace}
                                alt="loading..."
                              />
                            ) : (
                              <div className="user-profile-text">
                                {extractInitialsFromMate(item) || (
                                  <img
                                    src={UserIconPlace}
                                    alt="User Icon"
                                    className="default-user-icon"
                                  />
                                )}
                              </div>
                            )}
                          </div>
                          <div className="tr-user-info">
                            <span
                              className="tr-username"
                              title={
                                item?.firstName
                                  ? `${item?.firstName} ${item.lastName}`
                                  : item?.email
                              }
                            >
                              {item?.firstName
                                ? `${item?.firstName} ${item.lastName}`
                                : item?.email}
                              {/* {item?.firstName} {item.lastName} */}
                            </span>
                            <span
                              className="tr-user-location"
                              title={
                                itineraryDetails?.itinerary
                                  ?.title_destination ||
                                itineraryDetails?.itinerary?.destination
                              }
                            >
                              {itineraryDetails?.itinerary?.title_destination ||
                                itineraryDetails?.itinerary?.destination}
                            </span>
                          </div>
                        </div>
                        <div className="tr-user-desc">
                          {item?.personality_description}
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
              {showModal && (
                <Modal
                  size="md"
                  show={showModal}
                  closeHandler={() => setShowModal(false)}
                >
                  <div className="Group-itinerary-modal">
                      <h3 className="text-start mb-3">
                        Are you ready to generate your personalised travel plan?
                      </h3>
                      <p className="text-start">
                        <span style={{ marginRight: "5px" }}>
                          {finishedCount} out of{" "}
                          {itineraryDetails?.metaData[0]?.number_of_users - 1}
                        </span>
                        tripmates in the group has responded to the questions, so
                        the itinerary will be based on the responses received. Once
                        generated, you cannot regenerate this plan.
                      </p>
                  </div>
                  <div className="modal-footer-item">
                      <Button
                          text={"No"}
                          onClick={() => handleClick(false)}
                          btnClass={"light-btn"}
                        />
                       <Button text={"Yes"} onClick={() => handleClick(true)} />
                  </div>
                </Modal>
              )}
            </ItineraryInprogressInner>
          </Col>
        </Row>
      </Container>
    </ItineraryWrapper>
  );
};

export default Itineraryinprogress;
