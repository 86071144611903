import React, { useState } from "react";
import PreTravelQuizHOC from ".";
import { LABELS, TEXT } from "../../../utils/constants";
import { TravelStyleImg } from "../../../assets/images";
import Button from "../../../components/common/button";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../utils/constants/routes";
import { savePreTravelQuizData } from "../../../state/travel/actions";
import Image from "../../../components/common/image";

const SelectTravellers = () => {
  const { preTravelQuizData } = useSelector((state) => state?.travel);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [travellers, setTravellers] = useState(
    preTravelQuizData?.travellerCount || 2
  );
  const handleIncrease = () => {
    setTravellers(travellers + 1);
  };
  const handleDecrease = () => {
    travellers > 1 ? setTravellers(travellers - 1) : setTravellers(2);
  };

  const handleContinue = () => {
    dispatch(savePreTravelQuizData({ travellerCount: travellers }));
    navigate(ROUTES?.travelDays);
  };
  return (
    <div className="quiz-group-content">
      <PreTravelQuizHOC
        description={TEXT.ON_SELECT_TRAVELLERS}
        label={LABELS.SELECT_TRAVELLERS}
        image={TravelStyleImg}
        path={ROUTES?.travelStyle}
      >
        <div className="quiz-mobile-content-wrap">
          <Image src={TravelStyleImg} alt="" className="quiz-mob-img" />
          <div className="description quiz-mobile-description">
            {TEXT.ON_SELECT_TRAVELLERS}
          </div>
        </div>
        <div className="mt-4 quiz-single-item p-4">
          <div className="travel-grp">
            <div>
              <h4 className="mb-0">Travellers</h4>
              <p className="mb-0"> Ages 13 or above </p>
            </div>
            <div className="quiz-numbers">
              <button disabled={travellers === 2} onClick={handleDecrease}>
                <div className="quiz-numbers-item">
                  {" "}
                  <span> - </span>{" "}
                </div>
              </button>
              <span className="quiz-number-text">{travellers}</span>
              <button onClick={handleIncrease}>
                <div className="quiz-numbers-item">
                  {" "}
                  <span> + </span>{" "}
                </div>
              </button>
            </div>
          </div>
        </div>
        <Button text={"Continue"} onClick={handleContinue} />
      </PreTravelQuizHOC>
    </div>
  );
};

export default SelectTravellers;
