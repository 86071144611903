import React, { useState } from "react";
import { LABELS, TEXT } from "../../../utils/constants";
import { TravelLocation } from "../../../assets/images";
import Button from "../../../components/common/button";
import PreTravelQuizHOC from ".";
import Input from "../../../components/common/input";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../utils/constants/routes";
import { savePreTravelQuizData } from "../../../state/travel/actions";
import Image from "../../../components/common/image";

const Location = () => {
  const { preTravelQuizData } = useSelector((state) => state?.travel);
  const [location, setLocation] = useState(
    preTravelQuizData?.destination || ""
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChangeInput = (value) => {
    setLocation(value);
  };

  const handleContinue = () => {
    dispatch(savePreTravelQuizData({ destination: location }));
    navigate(ROUTES?.travelQuiz);
  };
  const handleSkip = () => {
    dispatch(savePreTravelQuizData({ destination: "" }));
    navigate(ROUTES?.travelQuiz);
  };
  return (
    <PreTravelQuizHOC
      description={TEXT.TELL_US_YOUR_VACATION}
      label={TEXT.ON_ENTER_LOCATION}
      image={TravelLocation}
      path={ROUTES?.travelBudget}
    >
    {/* <p className="d-none d-md-block">{TEXT.TELL_US_YOUR_VACATION}</p> */}
      <div className="quiz-mobile-content-wrap">
        <Image src={TravelLocation} alt="" className="quiz-mob-img" />
        <div className="description quiz-mobile-description">
           <h6 className="text-center" style={{color:'#1E4B78'}}>{TEXT.TELL_US_YOUR_VACATION}</h6>
        </div>
      </div>
      <div className="mt-4">
        <Input
          id="location"
          name={"location"}
          value={location}
          // as="textarea"
          // rows={5}
          placeholder={"Enter location..."}
          onChange={(e) => handleChangeInput(e?.target?.value)}
        />
      </div>
      <Button
        disabled={location?.length < 2}
        onClick={handleContinue}
        text={"Continue"}
      />
      <Button onClick={handleSkip} text={"Skip"} btnClass="light-blue-btn" />
    </PreTravelQuizHOC>
  );
};

export default Location;

