export const itineraryStatus = {
  paid: "paid",
  unpaid: "unpaid",
  paidItinerary: {},
  unpaidItinerary: {},
};

export const travelType = {
  solo:"Solo travel",
  group:"Group travel"
};