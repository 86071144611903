import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../../components/common/button";
import { ROUTES } from "../../../../utils/constants/routes";
import { Container, Row } from "react-bootstrap";
import { Logo } from "../../../../assets/icons";
import { WhySignUpStyled } from "../style";
import { getInvitedUserId } from "../../../../utils/utilityFunctions";
import {
  AccountUserIcon1,
  AccountUserIcon2,
  AccountUserIcon3,
} from "../../../../assets/images";

const WhySignup = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const isInvitedUser = getInvitedUserId();
  return (
    <WhySignUpStyled>
      <Container fluid>
        <Row>
          <div className="col-md-6 left-content">
            <div className="px-4 mt-3">
              <Link to="/">
                <Logo />
              </Link>
            </div>
            <div className="Login-content Form-content">
              <div className="why-signup-grp">
                <div className="signup-item text-center">
                  <h4 className="mb-3"> Why should I sign up? </h4>
                  <div className="signup-inner-item">
                    <div>
                      <img
                        src={AccountUserIcon1}
                        alt="Not Found"
                        className="img-fluid"
                      />
                    </div>
                    <h5>Unlock Your Unique Profile</h5>
                    <p className="description">
                      By signing up, your travel personality is saved, ensuring
                      you don't have to retake the test. Your profile becomes a
                      key to tailored travel experiences.
                    </p>
                  </div>

                  <div className="signup-inner-item">
                    <div>
                      <img
                        src={AccountUserIcon2}
                        alt="Not Found"
                        className="img-fluid"
                      />
                    </div>
                    <h5>Effortless Future Planning</h5>
                    <p className="description">
                      Save time and hassle by creating an account. Next trip?
                      Just choose your destination, and we'll craft a perfect
                      itinerary, aligned with your personality.
                    </p>
                  </div>

                  <div className="signup-inner-item">
                    <div>
                      <img
                        src={AccountUserIcon3}
                        alt="Not Found"
                        className="img-fluid"
                      />
                    </div>
                    <h5>Seamless Dashboard Access</h5>
                    <p className="description">
                      Your free account gives you access to a personalized
                      dashboard, making it easy to plan and book your dream
                      vacations.
                    </p>
                  </div>
                </div>
                <p className="text-center link-text">
                  Already have an account?{" "}
                  <a className="link-btn" href="/signin">
                    Sign in
                  </a>
                </p>
                <Button
                  onClick={() => navigate(ROUTES?.signup)}
                  text={"Sign Up"}
                />
                <Button
                  onClick={
                    isInvitedUser
                      ? () => navigate(ROUTES?.travelQuiz)
                      : () => navigate(ROUTES?.travelStyle)
                  }
                  text={"Continue as guest"}
                  btnClass="guest-btn"
                />
              </div>
            </div>
            <div className="px-4">
              <p> © Ethoventures 2023</p>
            </div>
          </div>
          <div className="col-md-6 signup-bg-img"></div>
        </Row>
      </Container>
    </WhySignUpStyled>
  );
};

export default WhySignup;
