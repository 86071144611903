import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { ROUTES } from "../../../utils/constants/routes";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { emailValidation } from "../../../utils/utilityFunctions";
import { LABELS, TEXT } from "../../../utils/constants";
import Button from "../../../components/common/button";
import usePersonality from "../../../services/personality";
import { ExplainermobBanner } from "../../../assets/images";
import { TravelSection } from "./style";
import Image from "../../../components/common/image";
import { useDispatch } from "react-redux";
import { updateItineraryCreatePayload } from "../../../state/travel/actions";
import Loader from "../../../components/common/loader";
import Modal from "../../../components/common/modals";
import { MSG } from "../../../utils/constants";
import useItineraryOperation from "../../../utils/hooks/useItineraryOperation";

const GuestUserNearlyDone = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const { checkUserPersonality } = usePersonality();
  const { handleGenerateItineraries } = useItineraryOperation();

  const data = useLocation();

  const initialValues = { email: "" };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  const handleSubmit = (values, { resetForm }) => {
    if (data?.state?.metaData) {
      const payloadData = { ...data.state };
      payloadData.email = values.email;
      dispatch(updateItineraryCreatePayload(payloadData));
      resetForm();

      setIsLoading(true);
      const checkPersonalityPayload = {
        email: values.email,
        personalityType: data.state?.personalityId,
      };
      checkUserPersonality(checkPersonalityPayload).then((response) => {
        setIsLoading(false);
        if (response?.isSuccess) {
          if (response?.data?.data?.isMatched) {
            handleGenerateItineraries(payloadData);
          } else {
            setIsShowModal(true);
          }
        } else {
          navigate(ROUTES?.home);
        }
      });
    }
  };

  const handlePopupResponse = (val, action) => {
    setIsShowModal(false);
    if (action === "close") {
      return;
    }
    if (val) {
      return navigate(ROUTES?.login);
    }
    handleGenerateItineraries();
  };

  if (isLoading) {
    return (
      <div className="web-spinner-loader">
        <Loader size="lg" dimension="row" variant={"secondary"} />
      </div>
    );
  }

  return (
    <TravelSection>
      <Container>
        <Row>
          <Col>
            <div className="tr-nearly-wrap">
              <div className="tr-nearly-img-box">
                <Image
                  src={ExplainermobBanner}
                  alt=""
                  className="explainer-mob-banner"
                />
              </div>
              <div className="tr-nearly-content-box">
                <span className="tr-title">{TEXT.NEARLY_DONE}</span>
                <span className="tr-desc">{TEXT.NEED_EMAIL_DESCRIPTION}</span>
              </div>
              <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                <Form>
                  <div>
                    <Field
                      placeholder={TEXT.ENTER_YOUR_EMAIL}
                      className="form-control"
                      validate={emailValidation}
                      id="email"
                      name="email"
                    />
                    <Button
                      type="submit"
                      text={LABELS.UNLOCK_JOURNEY}
                      className="submit-btn btn-outline"
                      disabled={isLoading}
                      loading={isLoading}
                    />
                  </div>
                  <ErrorMessage
                    name="email"
                    component="p"
                    className="error-msg"
                    style={{ color: "red" }}
                  />
                </Form>
              </Formik>
            </div>
          </Col>
        </Row>
      </Container>
      <Modal
        showModal={isShowModal}
        handlePopupResponse={(val, action) => handlePopupResponse(val, action)}
        contentText={MSG.PERSONALITY_EXIST_WITH_THIS_MAIL_ID}
        modalSize="md"
        isTravelTestModal={true}
        primaryButtonText={LABELS.LOGIN_AND_UPDATE}
        secondaryButtonText={LABELS.CONTINUE}
      />
    </TravelSection>
  );
};

export default GuestUserNearlyDone;
