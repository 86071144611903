import { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/styles/override_bootstrap/override_bootstrap.scss";
import "./assets/styles/global.scss";
import "./App.css";
import AppRoutes from "./routes";
import { isTokenExpired, logout } from "./utils/utilityFunctions";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isExpired = isTokenExpired();

  const { isMenuBarToggleState } = useSelector((state) => state?.user);
  
  useEffect(() => {
    if (isExpired) {
      logout(navigate, dispatch);
    }
  }, [dispatch, isExpired, navigate]);
  return (
    <div className={`App ${isMenuBarToggleState ? 'responsive-wrapper' : ''}`}>
      <AppRoutes />
    </div>
  );
}

export default App;
