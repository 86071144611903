export const validate = (values) => {
  const passwordRegex =
  /^(?=.*\d)(?=.*[A-Za-z])(?=.*[!@#$%^&*£()_+{}\[\]:;<>,.?~\\/\-=?\^]).{8,}$/;

  const errors = {};
  if (!values.email) {
    errors.email = "Email field cannot be empty";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  } else if (values.email?.length < 4) {
    errors.email = "Too short";
  }
  if (!values.password) {
    errors.password = "Password field cannot be empty";
  } else if (
    // !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/i.test(
    //   values.password
    // )
    !passwordRegex.test(values.password)
  ) {
    errors.password = "Invalid password";
  }
  if (!values.firstName) {
    errors.firstName = "Firstname field cannot be empty";
  } else if (/^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]+$/.test(values.firstName)) {
    errors.firstName = "Invalid firstname";
  } else if (!/^[A-Za-z]+$/.test(values.firstName)) {
    errors.firstName = "Invalid firstname";
  } else if (values.firstName?.length < 3) {
    errors.firstName = "Too short";
  }
  if (!values.lastName) {
    errors.lastName = "Lastname field cannot be empty";
  } else if (/^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]+$/.test(values.lastName)) {
    errors.lastName = "Invalid lastname";
  } else if (!/^[A-Za-z]+$/.test(values.lastName)) {
    errors.lastName = "Invalid lastname";
  } else if (values.lastName?.length < 3) {
    errors.lastName = "Too short";
  }
  return errors;
};
