import React from "react";
import PropTypes from "prop-types";

const AuthScreenHeading = ({ Logo, mainHeading, subHeading }) => {
  return (
    <div className="text-center">
      <div className="logo">{Logo ? <Logo /> : null}</div>
      <div className="main-heading">
        <h2>{mainHeading}</h2>
      </div>
      <div>
        <p>{subHeading}</p>
      </div>
    </div>
  );
};

AuthScreenHeading.propTypes = {
  mainHeading: PropTypes.string,
  subHeading: PropTypes.string,
};

AuthScreenHeading.defaultProps = {
  mainHeading: "main heading",
  subHeading: "sub heading",
};

export default AuthScreenHeading;
