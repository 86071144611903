export const validate = (values) => {
  const passwordRegex =
    /^(?=.*\d)(?=.*[A-Za-z])(?=.*[!@#$%^&*£()_+{}\[\]:;<>,.?~\\/\-=?\^]).{8,}$/;
  const errors = {};
  if (!values.password) {
    errors.password = "Please enter new password";
  } else if (
    // !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/i.test(
    //   values.password
    // )
    !passwordRegex.test(values.password)
  ) {
    errors.password = "Invalid password";
  }
  if (!values.confirmPassword) {
    errors.confirmPassword = "Please re-enter password";
  } else if (
    // !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/i.test(
    //   values.confirmPassword
    // )
    !passwordRegex.test(values.confirmPassword)
  ) {
    errors.confirmPassword = "Invalid confirm password";
  }

  if (values.password !== values.confirmPassword) {
    errors.confirmPassword = "Password do not match.";
  }

  return errors;
};
