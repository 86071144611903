import axios from "axios";
import { toast } from "react-toastify";
import { MSG, USER_TOKEN } from "../../utils/constants";
import { BASE_URL } from "./apiRoutes";

const _axios = axios.create({
  baseURL: BASE_URL,
});

const middleware = _axios.interceptors;

function requestMiddleware(config) {
  // Do something before request is sent
  const token = localStorage.getItem(USER_TOKEN);
  if (token) config.headers["Authorization"] = `Bearer ${token}`;
  config.headers["Cross-Origin-Opener-Policy"] = "same-origin-allow-popups";
  return config;
}

function responseMiddleware(response) {
  // Do something with response data
  return response;
}

middleware.request.use(requestMiddleware, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

middleware.response.use(responseMiddleware, function (error) {
  // Do something with response error
  const statusCode = error?.response?.status;
  const message =
    error?.response?.data?.error?.validations[0]?.message ||
    error?.response?.data?.message ||
    error?.message ||
    MSG.ON_UNHANDLED_ERROR;
  switch (statusCode) {
    case 500:
      toast.info(error?.message);
      break;
    // case 422:
    //   toast.error(message);
    // case 401:
    //   toast.error(MSG.ON_TOKEN_EXPIRED);
    //   setTimeout(() => {
    //     localStorage.clear();
    //     sessionStorage.clear();
    //     window.location.search = "";
    //     window.location.pathname = ROUTES.login;
    //   }, 4000);
    //   break;
    case 401:
      toast.info(message);
      break;
    case 400:
      toast.info(message);
      break;
    case 404:
      toast.info(message);
      break;
    case 409:
      toast.info(message);
      break;
    default:
      toast.info(message);
      break;
  }
  return Promise.reject(error?.message);
});

export default _axios;
