import React from "react";
import WebLayout from "../../layouts/web";

export default function Web({ Layout, children }) {
  return (
    <>
      {Layout ? <Layout>{children}</Layout> : <WebLayout>{children}</WebLayout>}
    </>
  );
}
