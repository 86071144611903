export const BASE_URL = process.env.REACT_APP_BASE_URL;
// ============================================================================
// Auth APIs
// ============================================================================
export const USER_SIGNUP = "/auth/register";
export const VERIFY_EMAIL = "/auth/verify-email";
export const USER_LOGIN = "/auth/login";
export const RESEND_VERIFICATION_EMAIL = "/auth/resend-verification-email ";
export const GOOGLE_LOGIN = "/auth/google";
export const FORGET_PASSWORD = "/auth/forgot-password";
export const VERIFY_OTP = "/auth/verify-otp";
export const FACEBOOK_LOGIN = "/auth/facebook";
export const APPLE_LOGIN = "/auth/apple";
export const RESET_PASSWORD = "/auth/reset-password";
export const FORGOT_PASSWORD_RESET = "/auth/forgot-password-reset";
export const SUBSCRIBERS = "/subscribers";
export const QUESTIONS_PERSONALITY_QUIZ = (isTravel = false) =>
  `/questions/quiz?category=${isTravel ? "travel" : "personality"}`;
export const QUESTION_PERSONALITY_SCORES = "/question-personality-scores";
export const USER_DETAIL = (id) => `/users/${id}`;
export const PERSONALITY_TYPES = `/travel-personalities`;
export const USER_PROFILE = "/users";
export const FILE_UPLOAD = "/file-upload/signed-url";
export const GENERATE_OPEN_AI_QUESTIONS = "/questions/generate";
export const GENERATE_ITINERAIES = "/itineraries";
export const GET_ITINERARY_DETAILS = "/itineraries/user";
export const USER_CHECK_PERSONALITY = "/users/_check-personality";
export const ITINERARY_DETAILS_GUEST = (publicId) =>
  `/itineraries/public/${publicId}`;
export const GET_GROUP_ITINERARY_INVITE_LINK = "/itineraries/group";
export const GENERATE_ITINERARY_FOR_GROUP = (id) => `/itineraries/group/${id}`;
export const ADD_TRIPMATE = (itineraryId, ItineraryCreatorUserId) =>
  `/itineraries/group/add-tripmate/${itineraryId}/${ItineraryCreatorUserId}`;
export const GENERATE_ITINERARY_FOR_INVITED_USER = (id, userId) =>
  `/itineraries/group/invited-user/${id}/${userId}`;
export const ITINERARY_DETAILS_BY_ITINERARY_ID = (itineraryId) =>
  `/itineraries/${itineraryId}`;
export const CREATE_GROUP_ITINERARY_BY_ID = (itineraryId) =>
  `/itineraries/_generate-group/${itineraryId}`;
export const GROUP_INVITE_LINK_CHECK = (invitedItineraryId, invitedUserId) =>
  `/itineraries/group/invite-link?id=${invitedItineraryId}&userId=${invitedUserId}`;
export const CHECK_FB_EMAIL = (id) => `/auth/facebook/${id}`;
