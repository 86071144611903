import React, { useRef, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import RadioButtonHOC from "../../../components/HOCs/radiobutton";
import CheckboxButtonHOC from "../../../components/HOCs/checkboxButton";
import { Quizmobimg } from "../../../assets/images";
import { QuizInnerBox } from "../personalityTest/formStyle";
import Loader from "../../../components/common/loader";
import { ChevLeft } from "../../../assets/icons";
import { QUES_TYPE, TEXT } from "../../../utils/constants";
import Input from "../../../components/common/input";
import Image from "../../../components/common/image";

const TravelForm = (props) => {
  const {
    image,
    mobileViewImage,
    questionData,
    selected,
    getData,
    getCheckboxSelectedData,
    totalQuestions,
    currentSteps,
    isShowLoading,
    handleChangeInput,
    userInput,
    checkboxAns,
    isOpenaAIQuestionStarted,
    handleBackButton,
    isMobile
  } = props;

  const containerRef = useRef(null);
  useEffect(() => {
    setTimeout(() => {
      if (containerRef.current?.scrollTop) {
        containerRef.current.scrollTop = 0;
      }
    }, isMobile ? 980 : 100);
  }, [currentSteps]);

  const isLastQuestion = currentSteps === totalQuestions - 1;
  return (
    <>
      <QuizInnerBox className="quiz-main-wrapper" image={image}>
        <Container fluid>
          <Row>
            <Col lg={6} md={12} sm={12} className="p-0">
              <div className="quiz-img-wrap">
                <div className="quiz-img-bg"></div>
                <div className="quiz-img-title">{TEXT.TRAVEL_QUIZ}</div>
              </div>
            </Col>
            {isShowLoading ? (
              <Col lg={6} md={12} sm={12} className="p-0 position-relative">
                <div className="tr-loader-wrap-item tr-loader-wrap">
                  <Loader
                    size="lg"
                    dimension="row"
                    className="spinner-loader"
                    style={{ marginTop: 50, background: "orange" }}
                  />
                  <h2 className="tr-loader-title">
                    Please wait while we are fetching the questions...
                  </h2>
                </div>
              </Col>
            ) : (
              <Col lg={6} md={12} sm={12} className="p-0">
                <div className="quiz-content-wrap">
                  <div className="progress-wrapper">
                    {Array(totalQuestions)
                      .fill()
                      .map((_, index) => (
                        <span
                          key={`steps-travel-quiz-${index}`}
                          className={`progress-item ${
                            index <= currentSteps ? "active" : ""
                          }`}
                        ></span>
                      ))}
                  </div>
                  <div className="quiz-mobile-content-wrap">
                    <div className="quiz-mobile-head-area">
                      <div className="quiz-mobile-back-btn">
                        <ChevLeft onClick={() => handleBackButton()} />
                      </div>
                      <div className="quiz-img-title-mobile">Travel quiz</div>
                    </div>
                    <Image
                      src={mobileViewImage || Quizmobimg}
                      alt=""
                      className="quiz-mob-img"
                    />
                  </div>
                  <div className="quiz-question">
                    {questionData?.label || questionData}
                  </div>
                  <div className="quiz-item-wrap" ref={containerRef}>
                    {questionData?.optionsType === QUES_TYPE.RADIO && (
                      <RadioButtonHOC
                        uid="travelOptions"
                        name="travel"
                        data={questionData?.options}
                        getSelected={getData}
                        selectedItem={selected}
                      />
                    )}
                    {questionData?.optionsType === QUES_TYPE.CHECKBOX && (
                      <CheckboxButtonHOC
                        uid="travelOptions"
                        name="travel"
                        data={questionData?.options}
                        getSelected={getCheckboxSelectedData}
                        selectedItem={checkboxAns}
                      />
                    )}
                    {(questionData?.optionsType === QUES_TYPE.INPUT ||
                      isOpenaAIQuestionStarted) && (
                      <>
                        <Input
                          id="userInput"
                          name="userInput"
                          as="textarea"
                          rows={5}
                          value={userInput}
                          onChange={(e) => handleChangeInput(e?.target?.value)}
                          placeholder={TEXT.ENTER_YOUR_ANS}
                          maxLength={!isLastQuestion ? 250 : 999999}
                        />
                        <strong>Note: </strong>
                        {isLastQuestion
                          ? TEXT.LAST_QUES_NOTE
                          : TEXT.AI_ANS_NOTE}
                      </>
                    )}
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </QuizInnerBox>
    </>
  );
};

export default TravelForm;
