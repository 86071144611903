import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../utils/constants/routes";
import { ExplainermobBanner } from "../../../assets/images";
import { TravelSection } from "./style";
import { isUserLoggedIn } from "../../../utils/utilityFunctions";
import Image from "../../../components/common/image";

const TravelQuizSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      if (isUserLoggedIn()) {
        if (location?.state) {
          return navigate(`${ROUTES?.groupItinerary}?id=${location?.state}`);
        } else {
          return navigate(ROUTES?.dashboard);
        }
      }
      navigate(ROUTES?.home);
    }, 5000);
  }, [navigate]);

  return (
    <TravelSection>
      <Container>
        <Row>
          <Col>
            <div className="tr-nearly-wrap">
              <div className="tr-nearly-img-box">
                <Image
                  src={ExplainermobBanner}
                  alt=""
                  className="explainer-mob-banner"
                />
              </div>
              <div className="tr-nearly-content-box">
                <span className="tr-title">Your itinerary is on the way !</span>
                <span className="tr-desc">
                  Your personalised itinerary is being generated, you will get
                  an email to update you once this is complete!
                  <p>
                    If you don't see the email in your inbox, please remember to
                    check your junk folder.
                  </p>
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </TravelSection>
  );
};

export default TravelQuizSuccess;
