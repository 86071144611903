import React, { useEffect, useState } from "react";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import Banner from "./banner";
import TravelItinerary from "./travelItinerary";
import { HomeWrapper } from "./style";
import CtaSection from "./cta";
import Feature from "./feature";
import Testimonial from "./testimonial";
import CategoriesSlider from "./categoriesSlider";
import { LogoIcon } from "../../../utils/svg";

export default function HomeComponent() {
  const [show, setShow] = useState(true);

  useEffect(() => {
    setShow(true);
    let t = setTimeout(() => {
      setShow(false);
    }, 3000);
    return () => {
      clearTimeout(t);
    };
  }, []);

  return (
    <HomeWrapper>
      {show && (
        <div className="loader-wrapper">
          <div className="loader">
            <LogoIcon />
          </div>
        </div>
      )}
      <Banner />
      <TravelItinerary />
      <Feature />
      <CategoriesSlider />
      <Testimonial />
      <CtaSection />
    </HomeWrapper>
  );
}
