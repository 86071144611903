import React from "react";
import { Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/common/button";
import { handleRedirectionToQuizPage } from "../../../utils/utilityFunctions";

function CtaSection() {
  const navigate = useNavigate();
  return (
    <div className="home-wrapper__CtaSection">
      <Container style={{ maxWidth: "100%" }}>
        <Row>
          <h1> Take the quiz </h1>
          <Container>
            <div className="home-wrapper__CtaSection__item ms-lg-3">
              <p>
                Ready to embark on your personalized travel adventure? Take the
                test now and discover the perfect itinerary crafted just for
                you!
              </p>

              <Button
                onClick={() => handleRedirectionToQuizPage(navigate)}
                text={"TAkE THE QUIZ"}
                className="CTA-btn"
              />
            </div>
          </Container>
        </Row>
      </Container>
    </div>
  );
}

export default CtaSection;
