import React, { useMemo } from "react";
import { Navigate } from "react-router-dom";
import { USER_TOKEN } from "../utils/constants";

export default function AccessControler({ children, redirectPath }) {
  const userToken = localStorage.getItem(USER_TOKEN);
  const isLoggedIn = useMemo(() => {
    if (!userToken || userToken === "undefined") {
      return false;
    }
    return true;
  }, [userToken]);
  return <>{isLoggedIn ? children : <Navigate to={redirectPath} />}</>;
}
