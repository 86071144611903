/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { UserIconOutline } from "../../../assets/icons";
import { SidebarmobImg, placeholderIcon } from "../../../assets/images";
import Loader from "../../../components/common/loader";
import useItinerary from "../../../services/itinerary";
import { MSG, TEXT, USER_ID } from "../../../utils/constants";
import { ROUTES } from "../../../utils/constants/routes";
import { DashboardSection } from "./style";
import {
  saveUnpaidItineraryDetails,
  savePaidItineraryDetails,
} from "../../../state/travel/actions";
import {
  formatDateToMonthYear,
  reduceImageQuality,
  handleRedirectionToQuizPage,
  extractInitialsFromMate,
  capitalizeFirstLetter,
  getInProgressImages,
} from "../../../utils/utilityFunctions";
import { NoData } from "../../../assets/icons";
import Button from "../../../components/common/button";
import { UserIconPlace } from "../../../assets/images";
import Image from "../../../components/common/image";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { userDetails } = useSelector((state) => state.user);
  const { paidItinerary, unpaidItinerary } = useSelector(
    (state) => state.travel
  );

  const [loading, setLoading] = useState(false);
  const [dynamicKey, setDynamicKey] = useState("123");
  const navigate = useNavigate();
  const { itineraryDetails } = useItinerary();
  const userId = localStorage.getItem(USER_ID);
  const userName =
    userDetails?.firstName && userDetails?.lastName
      ? userDetails?.firstName + " " + userDetails?.lastName
      : null;
  const nameparts = userName ? userName.split(" ") : null;
  const initialsUserName = nameparts
    ? nameparts[0]?.charAt(0)?.toUpperCase() +
      nameparts[1]?.charAt(0)?.toUpperCase()
    : null;
  useEffect(() => {
    setLoading(true);
    getUserDetails(userId);
    itineraryDetails(userId, "paid").then((res) => {
      if (res?.isSuccess) {
        dispatch(savePaidItineraryDetails(res?.data?.data?.itineraries));
      } else {
        setLoading(false);
      }
    });
    itineraryDetails(userId, "unpaid").then((res) => {
      if (res?.isSuccess) {
        dispatch(saveUnpaidItineraryDetails(res?.data?.data?.itineraries));
      } else {
        setLoading(false);
      }
    });
    setLoading(false);

    const interval = setInterval(() => {
      handleFetchItineraryDetails("paid");
      handleFetchItineraryDetails("unpaid");
    }, 15000);

    return () => {
      clearInterval(interval);
    };
  }, [userId]);

  const handleFetchItineraryDetails = (paidType) => {
    itineraryDetails(userId, paidType).then((res) => {
      if (res?.isSuccess) {
        if (paidType === "paid") {
          dispatch(savePaidItineraryDetails(res?.data?.data?.itineraries));
          setTimeout(() => {
            setDynamicKey(new Date().getTime());
          }, 100);
        } else {
          dispatch(saveUnpaidItineraryDetails(res?.data?.data?.itineraries));
        }
      }
    });
  };
  let isShowToast = false;
  const getUserDetails = (id) => {
    if (id && !userDetails?.personalityType?.name) {
      if (!isShowToast) {
        toast.info(TEXT?.ON_CREATE_PERSONALITY);
        isShowToast = true;
      }
      return navigate(ROUTES?.personality);
    }
  };
  const navigationHandler = () => {
    navigate(ROUTES?.viewItineries);
  };

  if (loading) {
    return (
      <div className="web-spinner-loader">
        <Loader
          size="lg"
          dimension="row"
          message={MSG?.ON_LOAD_PROFILE}
          variant={"secondary"}
        />
      </div>
    );
  }

  return (
    <DashboardSection
      imageurl={userDetails?.picture ? userDetails?.picture : ""}
    >
      <section className="tr-page-head-banner"></section>
      <section className="tr-page-top-content-wrap">
        <Container>
          <Row>
            <Col>
              <div className="tr-profile-content-box">
                <div className="tr-user-icon">
                  {!userDetails?.picture && (
                    <div className="tr-user-icon-inner">
                      <UserIconOutline />
                    </div>
                  )}
                </div>
                <span className="tr-username">
                  {userDetails?.firstName && userDetails?.lastName
                    ? capitalizeFirstLetter(userDetails?.firstName) +
                      " " +
                      capitalizeFirstLetter(userDetails?.lastName)
                    : ""}
                </span>
                <span>
                  <h3 className="tr-username-subheading">
                    {userDetails?.personalityType?.name || ""}
                  </h3>
                </span>
                <span className="tr-userdesc">
                  <p>{userDetails?.personalityType?.description || ""}</p>
                  <p>
                    Embrace your adventurous spirit, for the world is yours to
                    explore.
                  </p>
                </span>
                <Link to="/editprofile" className="btn btn-light">
                  Edit Profile
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="tr-page-bottom-content-wrap">
        <aside className="tr-sidebar-wrap">
          <div className="tr-sidebar-items-wrap">
            <div className="tr-sidebar-single-item">
              <span className="item-title">Travel quiz</span>
              <span className="item-info">Discover your dream trip</span>
              <div className="tr-item-mobile-img">
                <Image
                  src={SidebarmobImg}
                  alt="Loading.."
                  className="sidebar-hero-img"
                />
              </div>
              <div className="item-desc">
                <span className="item-info mb-2" style={{ color: "#667085" }}>
                  Unlock Your Dream Getaway
                </span>
                <p>
                  Now that you have your unique travel style, it's time to
                  unlock your dream getaway with Ethoventures' Travel Quiz.
                  Let's craft your personalized itinerary that's as unique as
                  you are!
                </p>
                <span className="item-info mb-2" style={{ color: "#667085" }}>
                  The Journey Begins Here
                </span>
                <p>
                  But first, a few fun questions to understand your travel
                  style, your destination wishes, and budget. Ready to embark on
                  your next adventure?
                </p>
                <span className="item-info mb-2" style={{ color: "#667085" }}>
                  Start Your Adventure Now
                </span>
              </div>
              <div
                onClick={() => handleRedirectionToQuizPage(navigate)}
                className="btn btn-dark"
              >
                Begin the Quiz
              </div>
            </div>
          </div>
        </aside>
        <div className="tr-right-content-wrap">
          <div className="tr-cards-item-wrap">
            {unpaidItinerary.length === 0 && paidItinerary.length === 0 && (
              <div className="tr-no-itinerary">
                <NoData />
                No Itinerary Found
              </div>
            )}
            {paidItinerary?.slice(0, 2)?.map((item) => (
              <div
                className="tr-card-single-item"
                key={`${item?._id}-itinerary-card-paid`}
              >
                <div className="tr-item-img-box">
                  <Image
                    key={dynamicKey}
                    src={
                      item?.itinerary
                        ? item?.itinerary?.itinerary[0]?.image?.url
                        : getInProgressImages()
                    }
                    alt="Loading.."
                    className="itinerary-hero-img"
                    isHideName={true}
                  />
                </div>
                <div className="tr-card-title-wrap">
                  <span className="tr-card-title">
                    {item?.itinerary === null
                      ? "itinerary"
                      : item?.itinerary?.title_destination ||
                        item?.itinerary?.destination + " itinerary"}
                  </span>
                  <div className="tr-users-thumb-wrap">
                    {item?.type === "Group travel" &&
                      item?.tripMatesDetails
                        ?.slice(0, 3)
                        ?.map((mate, index) => (
                          <div
                            className="tr-users-thumb-img"
                            key={`user-avatar-paid-${index}`}
                            title={mate?.email}
                          >
                            {mate?.picture ? (
                              <img
                                src={mate?.picture || UserIconPlace}
                                alt="Loading"
                                className="user-avatar"
                              />
                            ) : (
                              <div className="user-profile-text">
                                {extractInitialsFromMate(mate) || (
                                  <img
                                    src={UserIconPlace}
                                    alt="User Icon"
                                    className="default-user-icon"
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        ))}
                    {item?.type === "Solo travel" && (
                      <div
                        className="tr-users-thumb-img"
                        title={userDetails?.email}
                      >
                        {userDetails?.picture ? (
                          <img
                            src={userDetails?.picture || UserIconPlace}
                            alt="Loading"
                            className="user-avatar"
                          />
                        ) : (
                          <div
                            className="user-profile-text"
                            title={`${userDetails?.email || ""} `}
                          >
                            {initialsUserName || (
                              <img
                                src={UserIconPlace}
                                alt="Loading"
                                className="user-avatar"
                              />
                            )}
                          </div>
                        )}
                      </div>
                    )}
                    {item?.tripMatesDetails?.length > 3 && (
                      <div className="tr-users-thumb-img">
                        <span className="tr-users-thumb-count">
                          +{item.tripMatesDetails.length - 3}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <span className="tr-card-desc">
                  {item?.itinerary?.overview}
                </span>
                <span className="tr-card-budget">
                  Estimated budget: {item?.metaData[0]?.total_budget}
                </span>
                {item?.itinerary ? (
                  <div className="tr-content-left">
                    <Link
                      to={`${ROUTES?.itineraryDetails}?id=${item?._id}`}
                      className="btn btn-dark btn-sm"
                    >
                      View Itinerary
                    </Link>
                  </div>
                ) : (
                  <div className="tr-spinner-loader">
                    <Loader
                      size="lg"
                      dimension="row"
                      message={MSG?.CREATING_ITINERARY_MSG}
                      // variant={"secondary"}
                    />
                  </div>
                )}
              </div>
            ))}
            {paidItinerary?.length > 2 && (
              <div className="tr-view-more-btn-area">
                <Button
                  text={"View More"}
                  className="view-more"
                  onClick={navigationHandler}
                />
              </div>
            )}
            <div className="tr-card-single-item tr-itineraries-card">
              <div className="tr-card-title-wrap">
                {unpaidItinerary?.length > 0 && (
                  <span className="tr-card-title tr-itineraries-card-title">
                    My itineraries
                  </span>
                )}
              </div>
              <div className="tr-itinerary-wrap">
                {unpaidItinerary?.slice(0, 6)?.map((item) =>
                  item?.itinerary ? (
                    <Link
                      to={`${ROUTES?.itineraryDetails}?id=${item?._id}`}
                      key={`user-avatar-unpaid-${item?._id}`}
                    >
                      <div className="tr-itinerary-card">
                        <div
                          className={`${
                            item?.itinerary?.itinerary[0]?.image?.url
                              ? "tr-itinerary-card-img-box"
                              : "card-placeholder tr-itinerary-card-img-box"
                          }`}
                          style={{
                            backgroundImage: item?.itinerary?.itinerary[0]
                              ?.image?.url
                              ? `url(${reduceImageQuality(
                                  item?.itinerary?.itinerary[0]?.image?.url
                                )})`
                              : `url(${placeholderIcon})`,
                          }}
                        >
                          <span className="tr-trip-info">
                            {item?.metaData[0]?.number_of_days} Day Trip
                          </span>
                        </div>
                        <div className="tr-itinerary-card-body">
                          <div className="tr-itinerary-card-content">
                            <span
                              className="tr-itinerary-card-title"
                              title={
                                item?.itinerary?.title_destination ||
                                item?.itinerary?.destination
                              }
                            >
                              {item?.itinerary?.title_destination ||
                                item?.itinerary?.destination}
                            </span>
                            <span className="tr-itinerary-card-subtitle">
                              {formatDateToMonthYear(item?.createdAt)}
                            </span>
                          </div>
                          <div className="tr-users-thumb-wrap thumbs-sm">
                            {item?.type === "Group travel" &&
                              item?.tripMatesDetails
                                ?.slice(0, 3)
                                ?.map((mate, index) => (
                                  <div
                                    className="tr-users-thumb-img"
                                    key={`${index}_users-thumb-img-my`}
                                    title={mate?.email}
                                  >
                                    {mate?.picture ? (
                                      <img
                                        src={mate?.picture || UserIconPlace}
                                        alt="Loading"
                                        className="user-avatar"
                                      />
                                    ) : (
                                      <div className="user-profile-text">
                                        {extractInitialsFromMate(mate) || (
                                          <img
                                            src={UserIconPlace}
                                            alt="Loading"
                                            className="default-user-icon"
                                          />
                                        )}
                                      </div>
                                    )}
                                  </div>
                                ))}
                            {item?.type === "Solo travel" && (
                              <div
                                className="tr-users-thumb-img"
                                title={userDetails?.email}
                              >
                                {userDetails?.picture ? (
                                  <img
                                    src={userDetails?.picture || UserIconPlace}
                                    alt="Loading"
                                    className="user-avatar"
                                  />
                                ) : (
                                  <div
                                    className="user-profile-text"
                                    title={`${userDetails?.email || ""} `}
                                  >
                                    {initialsUserName || (
                                      <img
                                        src={UserIconPlace}
                                        alt="Loading"
                                        className="user-avatar"
                                      />
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                            {item?.tripMatesDetails?.length > 3 && (
                              <div className="tr-users-thumb-img">
                                <span className="tr-users-thumb-count">
                                  +{item.tripMatesDetails.length - 3}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </Link>
                  ) : !item?.itinerary && item?.type === "Group travel" ? (
                    <Link
                      to={`${ROUTES?.groupItinerary}?id=${item?._id}`}
                      key={`user-avatar-unpaid-null-itinerary-${item?._id}`}
                    >
                      <div className="tr-itinerary-card">
                        <div
                          className={`${
                            item?.itinerary?.itinerary[0]?.image?.url
                              ? "tr-itinerary-card-img-box"
                              : "tr-itinerary-card-img-box card-placeholder"
                          }`}
                          style={{
                            backgroundImage: item?.itinerary?.itinerary[0]
                              ?.image?.url
                              ? `url(${item?.itinerary?.itinerary[0]?.image?.url})`
                              : `url(${placeholderIcon})`,
                          }}
                        >
                          <span className="tr-trip-info">
                            {item?.metaData[0]?.number_of_days} Day Trip
                          </span>
                        </div>
                        <div className="tr-itinerary-card-body">
                          <div className="tr-itinerary-card-content">
                            <span className="tr-itinerary-card-title"></span>
                            <span className="tr-itinerary-card-subtitle">
                              {formatDateToMonthYear(item?.createdAt)}
                            </span>
                          </div>
                          <div className="tr-users-thumb-wrap thumbs-sm">
                            {item?.type === "Group travel" &&
                              item?.tripMatesDetails
                                ?.slice(0, 3)
                                ?.map((mate, index) => (
                                  <div
                                    title={mate?.email}
                                    className="tr-users-thumb-img"
                                    key={`${index}_users-thumb-group-null`}
                                  >
                                    {mate?.picture ? (
                                      <img
                                        src={mate?.picture || UserIconPlace}
                                        alt="Loading"
                                        className="user-avatar"
                                      />
                                    ) : (
                                      <div className="user-profile-text">
                                        {extractInitialsFromMate(mate) || (
                                          <img
                                            src={UserIconPlace}
                                            alt="Loading"
                                            className="default-user-icon"
                                          />
                                        )}
                                      </div>
                                    )}
                                  </div>
                                ))}
                            {item?.tripMatesDetails?.length > 3 && (
                              <div className="tr-users-thumb-img">
                                <span className="tr-users-thumb-count">
                                  +{item.tripMatesDetails.length - 3}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </Link>
                  ) : (
                    <div
                      className="tr-itinerary-card"
                      key={`existing-avatar-unpaid-${item?._id}`}
                    >
                      <div
                        className={`${
                          item?.itinerary?.itinerary[0]?.image?.url
                            ? "tr-itinerary-card-img-box"
                            : "card-placeholder tr-itinerary-card-img-box"
                        }`}
                        style={{
                          backgroundImage: item?.itinerary?.itinerary[0]?.image
                            ?.url
                            ? `url(${item?.itinerary?.itinerary[0]?.image?.url})`
                            : `url(${placeholderIcon})`,
                        }}
                      >
                        <span className="tr-trip-info">
                          {item?.metaData[0]?.number_of_days} Day Trip
                        </span>
                      </div>
                      <div className="tr-itinerary-card-body">
                        <div className="tr-itinerary-card-content">
                          <span className="tr-itinerary-card-title">
                            {item?.itinerary?.title_destination ||
                              item?.itinerary?.destination}
                          </span>
                          <span className="tr-itinerary-card-subtitle">
                            {formatDateToMonthYear(item?.createdAt)}
                          </span>
                        </div>
                        <div className="tr-users-thumb-wrap thumbs-sm">
                          {item?.type === "Group travel" &&
                            item?.tripMatesDetails
                              ?.slice(0, 3)
                              ?.map((item, index) => (
                                <div
                                  className="tr-users-thumb-img"
                                  key={`${index}_users_plain`}
                                  title={item?.email}
                                >
                                  {item?.picture ? (
                                    <img
                                      src={item?.picture || UserIconPlace}
                                      alt="Loading"
                                      className="user-avatar"
                                    />
                                  ) : (
                                    <div className="user-profile-text">
                                      {extractInitialsFromMate(item) || (
                                        <img
                                          src={UserIconPlace}
                                          alt="Loading"
                                          className="default-user-icon"
                                        />
                                      )}
                                    </div>
                                  )}
                                </div>
                              ))}
                          {item?.type === "Solo travel" && (
                            <div
                              className="tr-users-thumb-img"
                              title={userDetails?.email}
                            >
                              {userDetails?.picture ? (
                                <img
                                  src={userDetails?.picture}
                                  alt="UserAvatar"
                                  className="user-avatar"
                                />
                              ) : (
                                <div
                                  className="user-profile-text"
                                  title={`${userDetails?.email || ""} `}
                                >
                                  {initialsUserName || (
                                    <img
                                      src={UserIconPlace}
                                      alt="Loading"
                                      className="user-avatar"
                                    />
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                          {item?.tripMatesDetails?.length > 3 && (
                            <div className="tr-users-thumb-img">
                              <span className="tr-users-thumb-count">
                                +{item.tripMatesDetails.length - 3}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
              {unpaidItinerary?.length > 6 && (
                <div className="tr-view-more-btn-area">
                  <Button
                    text={"View More"}
                    className="CTA-btn"
                    onClick={navigationHandler}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </DashboardSection>
  );
};

export default Dashboard;
