import { Success, generateError } from "../useCommonSerivce";
import apiClient from "../../network/restAPI";
import {
  GET_ITINERARY_DETAILS,
  ITINERARY_DETAILS_BY_ITINERARY_ID,
  ITINERARY_DETAILS_GUEST,
  GET_GROUP_ITINERARY_INVITE_LINK,
  ADD_TRIPMATE,
  CREATE_GROUP_ITINERARY_BY_ID,
} from "../../network/restAPI/apiRoutes";

export default function useItinerary() {
  const itineraryDetails = async (id, paymentStatus) => {
    return new Promise(async (resolve) => {
      try {
        const data = await apiClient.get(
          `${GET_ITINERARY_DETAILS}/${id}?paymentStatus=${paymentStatus}`
        );
        resolve(Success(data));
      } catch (error) {
        resolve(generateError(error));
      }
    });
  };
  const getGuestItineraryDetails = async (publicId, sessionId) => {
    return new Promise(async (resolve) => {
      try {
        if (sessionId) {
          const data = await apiClient.get(
            ITINERARY_DETAILS_GUEST(`${publicId}?sessionId=${sessionId}`)
          );
          resolve(Success(data));
        } else {
          const data = await apiClient.get(ITINERARY_DETAILS_GUEST(publicId));
          resolve(Success(data));
        }
      } catch (error) {
        resolve(generateError(error));
      }
    });
  };

  const getInviteLink = async () => {
    return new Promise(async (resolve) => {
      try {
        const data = await apiClient.post(GET_GROUP_ITINERARY_INVITE_LINK);
        resolve(Success(data));
      } catch (error) {
        resolve(generateError(error));
      }
    });
  };

  const addTripmates = (itineraryId, ItineraryCreatorUserId, params) => {
    return new Promise(async (resolve) => {
      try {
        const data = await apiClient.post(
          ADD_TRIPMATE(itineraryId, ItineraryCreatorUserId),
          params
        );
        resolve(Success(data));
      } catch (error) {
        resolve(generateError(error));
      }
    });
  };

  const getItineraryDetailsById = async (itineraryId, sessionId) => {
    return new Promise(async (resolve) => {
      try {
        if (sessionId) {
          const data = await apiClient.get(
            ITINERARY_DETAILS_BY_ITINERARY_ID(
              `${itineraryId}?sessionId=${sessionId}`
            )
          );
          resolve(Success(data));
        } else {
          const data = await apiClient.get(
            ITINERARY_DETAILS_BY_ITINERARY_ID(itineraryId)
          );
          resolve(Success(data));
        }
      } catch (error) {
        resolve(generateError(error));
      }
    });
  };

  const createGroupItineraryById = async (itineraryId) => {
    return new Promise(async (resolve) => {
      try {
        const data = await apiClient.post(
          CREATE_GROUP_ITINERARY_BY_ID(itineraryId)
        );
        resolve(Success(data));
      } catch (error) {
        resolve(generateError(error));
      }
    });
  };
  return {
    itineraryDetails,
    getGuestItineraryDetails,
    getInviteLink,
    addTripmates,
    getItineraryDetailsById,
    createGroupItineraryById,
  };
}
