export const allRoutes = [
  "/",
  "/signin",
  "/signup",
  "/post-signup",
  "/profile",
  "/logout",
  "/dashboard",
  "/personalitytest",
  "/personality",
  "/comingsoon",
  "/forgetpassword",
  "/resetpassword",
  "/resetpassword-success",
  "/otp",
  "/personality-overview",
  "/personality-success",
  "/travel-quiz",
  "/travel-style",
  "/travel-invite",
  "/travel-group",
  "/travel-budget",
  "/travel-days",
  "/why-signup",
  "/payment-success",
  "/payment-canceled",
  "/view-itineraries",
  "/itinerary-details",
  "/travel-success",
  "/itinerary-awaits",
  "/nearly-done",
  "/demo-links",
  "/travel-location",
  "/itinerary-details/guest",
  "/editprofile",
  "/invited-user",
  "/itinerary-in-progress",
];
