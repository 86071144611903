export const USER_TOKEN = "refresh-token";
export const USER_ID = "user-id";
export const INVITED_ITINERARY_ID = "invited-itinerary-id";
export const INVITED_USER_ID = "invited-user-id";
export const EMAIL_FOR_SEND_VERFICATION_MAIL = "email-id";
export const IS_INVITED_USER_TRAVEL_QUIZ_COMPLETED =
  "isInvitedUserTravelQuizCompleted";
export const TOKEN_EXPIRY_DATE = "token-expiry-date";
export const PERSONALITY_ID = "personality-id";
export const GUEST_ROUTE_PUBLIC_ID = "guest-route-public-id";
export const SIGN_MESSGAE = "SIGN IN SUCCESS ";
export const FACEBOOK_URL = process.env.REACT_APP_FACEBOOK_URL;
export const INSTAGRAM_URL = process.env.REACT_APP_INSTAGRAM_URL;
export const TIKTOK_URL = process.env.REACT_APP_TIKTOK_URL;
export const TWITTER_URL = process.env.REACT_APP_TWITTER_URL;
export const YOUTUBE_LINK = process.env.REACT_APP_YOUTUBE_URL;
export const OPEN_AI_LAST_QUES =
  "Is there anything else you want us to know but we haven’t asked yet?";

export const MSG = {
  ON_REJECT_SIGN: "You need to sign the message to be able to log in.",
  ON_CONNECTION_REJECTION_DEFAULT: "Connection request rejected.",
  ON_USER_REJECT_CONNECTION: "User rejected the connection request.",
  ON_LOGIN_SUCCESS: "You are successfully logged in.",
  ON_LOGOUT_SUCCESS: "You have logged out successfully.",
  ON_TOKEN_EXPIRED: "Your session has been expired. Please relogin.",
  ON_UNHANDLED_ERROR: "Oops! something went wrong, Please try again.",
  ON_OTP_SUCCESS: "OTP sent successfully on your Email",
  ON_OTP_VERIFY_SUCCESS: "OTP verified successfully.",
  ON_ERROR: "Something went wrong",
  ON_UPDATE_PASSWORD_SUCCESS: "Password changed successfully",
  ON_SUBSCRIBERS_SUCCESS: "Thanks for subscribing with us",
  ON_EMAIL_NOT_FOUND: "Please enter email",
  ON_SIGNUP_SUCCESS: "User registered successfully",
  PERSONALITY_CREATED: "Personality created successfully",
  ON_LOGIN_DASHBOARD: "Please login to access dashboard",
  ON_UPDATE_PROFILE_SUCCESS: "Profile Updated Successfully.",
  ON_IMAGE_UPLOAD_SUCCESS: "Image uploaded Successfully.",
  DELETE_PROGRESS_TITLE: "Are you sure you want to go back?",
  DELETE_PROGRESS:
    "We have already started creating your itinerary. If you go back now you will lose your current answers.",
  ON_ADD_TRIPMATE:
    "Congratulations,you have successfully registered for the invited trip",
  ON_VERIFICATION_FAILED: "Email verification failed",
  ON_VERIFICATION_SUCCESS: "Email verified successfully",
  ON_VERIFICATION_EMAIL_SENT:
    "To get started, please verify your email address.",
  ON_LOAD_PROFILE: "Please wait while your profile loads.",
  INVALID_URL: "Invalid URL",
  PROFILE_IMAGE_SIZE_ERROR: "Profile picture should be less than 5MB",
  ON_USER_ACCESS_TRIPMATE_URL:
    "This link can only be used by the invited users.",
  ON_COMPLETE_INVITE_FLOW:
    "You are invited on a trip,please complete tripmate flow first",
  ON_CREATE_PERSONALITY: "Please give personality test first",
  PERSONALITY_EXIST_WITH_THIS_MAIL_ID:
    "Seems like this email is already registered. Please login if you want to update your existing personality.",
  CREATING_ITINERARY_MSG:
    "We're creating your personalized itinerary. Feel free to wait or carry on; we'll email it to you either way",
  ACCESS_RECOMMENDATIONS_NOTE:
    "Access recommendations for your trip once you purchase the full itinerary.",
  SUBMIT_QUIZ: "Ready to submit your answers?",
  VERIFICATION_EMAIL_SENT: "Verification email sent successfully",
  MINIMUM_BUDGET_WARNING:
    "The minimum budget should be greater than or equal to $100.",
  MAXIMUM_BUDGET_WARNING:
    "The maximum budget should be greater than the minimum budget.",
};
export const LABELS = {
  SUBMIT: "Submit",
  NEXT: "Next",
  BACK: "Back",
  CANCEL: "Cancel",
  SKIP: "Skip",
  INVITE_FRIEND: "Invite Others",
  SELECT_TRAVELLERS: "Group Getaway",
  SELECT_BUDGET: "What is your rough budget for this trip?",
  PRICE_RANGE: "Price Range",
  SELECT_DAYS: "Choose the amount of days you want a plan for?",
  UNLOCK_JOURNEY: "Unlock the Full Journey",
  ENTER_LOCATION: "Travel quiz",
  CONTINUE: "Continue",
  LOGIN_AND_UPDATE: "Login and update",
  RESEND_EMAIL: "Resend Verification Email",
};

export const TEXT = {
  ON_CREATE_PERSONALITY: "Please create personality first",
  ON_GET_PERSONALITY_DETAILS: "Loading personality...",
  ON_INVITE_FRIEND:
    "Share this link with others to plan your trip together. We'll create an itinerary that suits everyone. You can share the link now or later; we'll remind you at the end.",
  ON_SELECT_TRAVELLERS:
    "Embark on a group adventure and unlock the power of shared experiences. Discover new perspectives, forge lifelong friendships, and create stories that will be told for years to come.",
  ON_SELECT_DAYS:
    "Choose the number of days that you want included in your itinerary. Remember, it is $1 USD for each day selected",
  ENTER_YOUR_EMAIL: "Enter your email",
  TRAVEL_QUIZ: "Travel Quiz",
  ENTER_YOUR_ANS: "Enter your answer",
  ON_ENTER_LOCATION: "Where are you planning your trip ?",
  TELL_US_YOUR_VACATION:
    "Tell us your vacation destination(s). You can choose more than one place!",
  AI_ANS_NOTE:
    "More details will help us better understand your needs for curating the best possible experience.",
  LAST_QUES_NOTE:
    "This is the most important question of them all. Let us know all the little details that would make your trip perfect. We'll plan the rest!",
  ON_LOAD_ITINERARY: "Please wait till your itinerary loads.",
  ON_SELECT_BUDGET:
    "This should be your budget for the trip once you've arrived at your destination - transportation to destination should not be considered here.",
  NEARLY_DONE: "Nearly done...",
  NEED_EMAIL_DESCRIPTION:
    "We’ll need your email so that you can be sent your personalised plan for your trip!",
  ON_UNLOCK_JOURNEY_AMOUNT_TEXT: "Unlock the full journey only for $",
  ON_UNLOCK_JOURNEY_STATIC_TEXT:
    "Ethoventures charges only $1 per day for your itinerary",
  BOOK_STAY: "Book your stay",
  BOOK_TABLE: "Book a table",
  SELECT_DAYS_NOTE:
    "For a trip at this length, you might come across some repeated recommendations.",
  BOOK_ACTIVITY: "Book your activity",
  EMAIL_NOT_RECEIVED: "Still can’t find the email? No Problem",
  WE_HAVE_SENT_EMAIL: (emailID) =>
    `We’ve sent you an email to ${emailID}, please verify your email to continue and create your first personalized travel itinerary!`,
};

export const QUES_TYPE = {
  RADIO: "Radio",
  INPUT: "Input",
  OPTIONAL: "Optional",
  CHECKBOX: "Checkbox",
};
