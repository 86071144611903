import styled from "styled-components";
import { DashboardBanner, FrameBg } from "../../../assets/images";

export const ItinerarySection = styled.section`
  .btn {
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    max-width: 298px;
    width: 100%;
    padding: 8px 14px;
    font-family: var(--secondary-font);
    font-size: ${({ theme }) => theme.fontSizes.font14};
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-transform: capitalize;
    &:hover {
      opacity: 0.8;
    }
  }
  .tr-users-thumb-wrap {
    display: flex;
    align-items: center;
    padding-left: 17px;
    .tr-users-thumb-img {
      width: 39px;
      height: 39px;
      overflow: hidden;
      border-radius: 96px;
      margin-left: -17px;
      img {
        /* max-width: 100%; */
        height: 39px;
        width: 39px;
        object-fit: cover;
        border: 1px solid #0d6efdbd;
        border-radius: 50%;
      }
    }
    .user-profile-text {
      background-color: ${({ theme }) => theme.colors.light.lightBlueText};
      width: 39px;
      height: 39px;
      border-radius: 50%;
      font-size: ${({ theme }) => theme.fontSizes.font16};
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${({ theme }) => theme.colors.light.textwhiteClr};
      @media (max-width: 767px) {
        font-size: 13px;
        width: 32px;
        height: 32px;
      }
    }
    .tr-users-thumb-count {
      font-family: var(--secondary-font);
      line-height: 1.5;
      font-size: 15px;
      font-weight: 600;
      background: #eee;
      width: 39px;
      height: 39px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 96px;
      border: 1.2px solid ${({ theme }) => theme.colors.light.textwhiteClr};
      color: ${({ theme }) => theme.colors.light.PrimaryThemeClr};
      @media (max-width: 767px) {
        width: 32px;
        height: 32px;
        font-size: ${({ theme }) => theme.fontSizes.font12};
      }
    }
  }
  .tr-page-head-banner {
    background-image: url(${DashboardBanner});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100% 50%;
    width: 100%;
    height: 330px;
    @media (min-width: 768px) and (max-width: 1024px) {
      height: 240px;
    }
    @media (max-width: 767px) {
      display: none;
    }
  }
  .tr-page-top-content-wrap {
    background: ${({ theme }) => theme.colors.light.bgClr1};
    @media (max-width: 767px) {
      background: ${({ theme }) => theme.colors.light.textwhiteClr};
      margin-top: 50px;
    }
  }
  .tr-profile-content-box {
    margin-top: -62.5px;
    padding-bottom: 24px;
    max-width: 100%;
    @media (min-width: 768px) and (max-width: 1299px) {
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
    @media (max-width: 767px) {
      margin: 46px auto 0;
      padding: 16px;
      max-width: 350px;
      text-align: center;
      border-radius: 8px;
      border: 1px solid ${({ theme }) => theme.colors.border.bdrLightClr};
      background: ${({ theme }) => theme.colors.light.textwhiteClr};
      box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    }
    .tr-user-icon {
      border-radius: 150px;
      border: 3px solid ${({ theme }) => theme.colors.light.textwhiteClr};
      background: ${({ theme }) => theme.colors.light.iconBg};
      background-image: ${({ imageUrl }) =>
        imageUrl ? `url(${imageUrl})` : "none"};
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      box-shadow: 0 3px 4.5px -1.5px rgba(16, 24, 40, 0.03),
        0 9px 12px -3px rgba(16, 24, 40, 0.08);
      width: 123px;
      height: 123px;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 767px) {
        margin: 0 auto;
      }
      .tr-user-icon-inner {
        border-radius: 150px;
        border: 0.75px solid rgba(16, 24, 40, 0.08);
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .tr-username {
      display: block;
      color: ${({ theme }) => theme.colors.light.titleClr};
      font-family: var(--secondary-font);
      font-size: ${({ theme }) => theme.fontSizes.font48};
      font-style: normal;
      font-weight: 700;
      line-height: 60px;
      letter-spacing: -0.96px;
      margin: 50px 0 24px;
      text-transform: capitalize;
      @media (max-width: 767px) {
        font-size: ${({ theme }) => theme.fontSizes.font32};
        line-height: normal;
        margin: 16px 0 5px;
      }
    }
    .tr-usertype {
      color: ${({ theme }) => theme.colors.light.titleClr};
      font-family: var(--secondary-font);
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
      display: block;
      @media (max-width: 767px) {
        font-size: ${({ theme }) => theme.fontSizes.font16};
        font-weight: 600;
        line-height: normal;
      }
    }

    .tr-userdesc {
      color: ${({ theme }) => theme.colors.light.contentText};
      font-family: var(--secondary-font);
      font-size: ${({ theme }) => theme.fontSizes.font16};
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      margin: 32px 0;
      display: block;
      @media (max-width: 767px) {
        margin: 24px 0;
        text-align: left;
        letter-spacing: -0.2px;
        font-size: ${({ theme }) => theme.fontSizes.font14};
        line-height: 24px;
      }
    }

    .btn-light {
      border: 1px solid ${({ theme }) => theme.colors.border.bdrPrimaryClr};
      background: ${({ theme }) => theme.colors.light.textwhiteClr};
      color: ${({ theme }) => theme.colors.light.titleClr};
    }
  }
  .tr-page-bottom-content-wrap {
    display: flex;
    @media (min-width: 768px) and (max-width: 1024px) {
      flex-direction: column;
    }
    @media (max-width: 767px) {
      flex-direction: column;
    }
    aside.tr-sidebar-wrap {
      max-width: 362px;
      width: 100%;
      height: max-content;
      position: -webkit-sticky;
      position: sticky;
      top: 67px;
      @media (min-width: 768px) and (max-width: 1024px) {
        max-width: 100%;
        height: auto;
        position: unset;
      }
      @media (max-width: 767px) {
        max-width: 350px;
        margin: 32px auto;
        padding: 0;
        height: auto;
        position: unset;
      }
    }
    .tr-right-content-wrap {
      width: calc(100% - 362px);
      /* padding-bottom: 85px; */
      @media (min-width: 768px) and (max-width: 1024px) {
        width: 100%;
      }
      @media (max-width: 767px) {
        width: 100%;
        padding-bottom: 0;
      }
    }
  }
  .view-itinerary-wrap {
    /* height:100vh; */
    .tr-card-single-item {
      padding: 50px 0 0 !important;
    }
  }
  .tr-sidebar-items-wrap {
    margin-top: 3px;
    .tr-sidebar-single-item {
      border-radius: 8px;
      background: ${({ theme }) => theme.colors.light.bgClr1};
      box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
      display: flex;
      width: 100%;
      height: auto;
      // padding: 41px 32px 100px 32px;
      padding: 27px 32px 100px 32px;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      @media (min-width: 768px) and (max-width: 1024px) {
        padding: 45px;
      }
      @media (max-width: 767px) {
        border-radius: 8px;
        border: 1px solid ${({ theme }) => theme.colors.border.bdrLightClr};
        background: ${({ theme }) => theme.colors.light.textwhiteClr};
        box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
        padding: 16px;
        align-items: center;
      }
      .item-title {
        color: ${({ theme }) => theme.colors.light.titleClr};
        font-family: var(--secondary-font);
        font-size: ${({ theme }) => theme.fontSizes.font32};
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        @media (max-width: 767px) {
          font-size: ${({ theme }) => theme.fontSizes.font20};
        }
      }
      .item-info {
        color: ${({ theme }) => theme.colors.light.titleClr};
        font-family: var(--secondary-font);
        font-size: ${({ theme }) => theme.fontSizes.font16};
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        display: block;
        margin: 5px 0 24px;
        text-transform: capitalize;
        @media (max-width: 767px) {
          font-weight: 500;
          margin-bottom: 0;
        }
      }
      .item-desc {
        color: var(--gray-500, #667085);
        font-family: var(--secondary-font);
        font-size: ${({ theme }) => theme.fontSizes.font16};
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        @media (max-width: 767px) {
          font-size: ${({ theme }) => theme.fontSizes.font14};
          color: ${({ theme }) => theme.colors.light.contentText};
          line-height: 19px;
          margin: 15px 0;
        }
      }
      .btn {
        position: absolute;
        bottom: 34px;
        left: 0;
        right: 0;
        margin: 0 auto;
        @media (min-width: 768px) and (max-width: 1024px) {
          position: unset;
          margin: 15px 0 0;
        }
        @media (max-width: 767px) {
          position: unset;
        }
      }
      .btn-dark {
        border: 1px solid ${({ theme }) => theme.colors.light.PrimaryThemeClr};
        background: ${({ theme }) => theme.colors.light.PrimaryThemeClr};
        color: ${({ theme }) => theme.colors.light.textwhiteClr};
      }
      .tr-item-mobile-img {
        display: none;
        @media (max-width: 767px) {
          display: block;
          margin: 19px 0;
        }
      }
    }
  }
  .tr-cards-item-wrap {
    @media (max-width: 767px) {
      padding: 0 12px;
      .tr-card-single-item.tr-itineraries-card {
        padding: 0;
        border: none;
        .tr-card-title.tr-itineraries-card-title {
          color: #000;
          font-size: 24px;
          font-weight: 700;
          line-height: 40px;
          letter-spacing: -0.24px;
          text-transform: capitalize;
        }
      }
    }
    .tr-card-single-item {
      display: flex;
      width: 100%;
      padding: 40px 80px 40px 64px;
      flex-direction: column;
      align-items: center;
      background: ${({ theme }) => theme.colors.light.textwhiteClr};
      @media (min-width: 768px) and (max-width: 1024px) {
        padding: 45px;
      }
      @media (max-width: 767px) {
        padding: 24px;
        max-width: 350px;
        width: 100%;
        margin: 0 auto 24px;
        border-radius: 8px;
        /* border: 1px solid #e6e8ec; */
        background: ${({ theme }) => theme.colors.light.textwhiteClr};
        overflow: hidden;
      }
      .tr-item-img-box {
        width: 100%;
        margin-bottom: 30px;
        img {
          width: 100%;
        }
        @media (min-width: 768px) and (max-width: 1024px) {
          margin-bottom: 40px;
        }
        @media (max-width: 767px) {
          margin: -24px 0 24px;
          width: calc(100% + 48px);
          height: 240px;
          img {
            height: 100%;
          }
        }
      }
      .tr-card-title-wrap {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 20px;
        @media (max-width: 767px) {
          gap: 16px;
          flex-direction: row-reverse;
        }
        .tr-card-title {
          color: ${({ theme }) => theme.colors.light.titleClr};
          font-family: var(--secondary-font);
          font-size: 36px;
          font-style: normal;
          font-weight: 700;
          line-height: 44px;
          letter-spacing: -0.72px;
          text-transform: capitalize;
          @media (max-width: 767px) {
            color: var(--neutrals-1, #141416);
            font-size: ${({ theme }) => theme.fontSizes.font16};
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0;
          }
        }
      }
      .tr-card-desc {
        color: #777e90;
        font-family: var(--secondary-font);
        font-size: ${({ theme }) => theme.fontSizes.font16};
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        margin: 28px 0 16px;
        text-align: left;
        @media (max-width: 767px) {
          line-height: 24px;
          margin: 24px 0;
          font-size: ${({ theme }) => theme.fontSizes.font14};
        }
      }
      .tr-card-budget {
        width: 100%;
        color: var(--neutrals-2, #23262f);
        font-family: var(--secondary-font);
        font-size: ${({ theme }) => theme.fontSizes.font18};
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
        margin-bottom: 32px;
        @media (max-width: 767px) {
          margin-bottom: 24px;
          font-size: ${({ theme }) => theme.fontSizes.font14};
          line-height: 1;
        }
      }
      .tr-content-left {
        width: 100%;
      }
      .btn-sm {
        border: 1px solid ${({ theme }) => theme.colors.light.PrimaryThemeClr};
        background: ${({ theme }) => theme.colors.light.PrimaryThemeClr};
        @media (max-width: 767px) {
          border-color: ${({ theme }) => theme.colors.border.bdrPrimaryClr};
          background: ${({ theme }) => theme.colors.light.textwhiteClr};
          color: ${({ theme }) => theme.colors.light.titleClr};
        }
      }
      .btn-disabled {
        opacity: 0.5;
        cursor: no-drop;
      }
    }
  }
  .tr-itinerary-wrap {
    /* width: 100%;
    display: block;
    margin-top: 40px; */
    width: 100%;
    display: flex;
    margin-top: 40px;
    flex-wrap: wrap;
    a,
    .tr-itinerary-card-grp {
      width: 23%;
      margin-bottom: 25px;
      margin-right: 24px;
      @media (min-width: 1200px) and (max-width: 1365px) {
        width: 30%;
      }
      @media (max-width: 767px) {
        width: 100%;
      }
      @media (min-width: 768px) and (max-width: 1024px) {
        width: 46%;
      }
    }
    @media (max-width: 767px) {
      margin-top: 24px;
      flex-direction: column;
    }
    .tr-itinerary-card {
      border-radius: 10px;
      border: 1px solid #e9e9e9;
      background: #fcfcfd;
      overflow: hidden;
      display: inline-block;
      /* margin-right: 15px; */
      margin-bottom: 24px;
      vertical-align: top;
      /* max-width: 23%; */
      width: 100%;
      @media (max-width: 767px) {
        margin: 0 0 24px 0;
        max-width: 100%;
        width: 100%;
      }
      /* @media (min-width: 1200px) and (max-width: 1365px) {
        max-width: 30%;
      } */
      .tr-itinerary-card-img-box {
        position: relative;
        height: 240px;
        background-size: cover;
        display: block;

        background-size: cover;
        img {
          height: 100%;
          width: 100%;
        }
        @media (min-width: 768px) and (max-width: 1024px) {
          img {
            width: 100%;
          }
        }
        @media (max-width: 767px) {
          img {
            width: 100%;
          }
        }
        .tr-trip-info {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          z-index: 1;
          color: ${({ theme }) => theme.colors.light.textwhiteClr};
          font-family: var(--secondary-font);
          font-size: ${({ theme }) => theme.fontSizes.font20};
          font-style: normal;
          font-weight: 600;
          line-height: 12px; /* 60% */
          text-transform: capitalize;
        }
      }
      .card-placeholder {
        background-position: center center;
        background-repeat: no-repeat;
        width: 100%;
      }
      .tr-itinerary-card-body {
        padding: 16px;
        display: flex;
        justify-content: space-between;
        .tr-itinerary-card-title {
          display: block;
          color: #333;
          font-family: var(--secondary-font);
          font-size: ${({ theme }) => theme.fontSizes.font20};
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          text-transform: capitalize;
          margin-bottom: 10px;
          max-width: 135px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          @media (max-width: 767px) {
            max-width: 195px;
          }
        }

        .tr-itinerary-card-subtitle {
          display: block;
          color: #333;
          font-family: var(--secondary-font);
          font-size: ${({ theme }) => theme.fontSizes.font14};
          font-style: normal;
          font-weight: 500;
          line-height: 14px;
          text-transform: capitalize;
        }
      }
    }
  }
  .Back-btn {
    @media (max-width: 767px) {
      display: block !important;
      text-align: left;
    }
  }
  .tr-card-title {
    h1 {
      color: ${({ theme }) => theme.colors.light.titleClr};
      font-family: var(--secondary-font);
      font-size: ${({ theme }) => theme.fontSizes.font48};
      font-style: normal;
      font-weight: 700;
      line-height: 44px;
      letter-spacing: -0.72px;
      text-transform: capitalize;
      margin-bottom: 50px;
      @media (max-width: 767px) {
        margin-bottom: 30px;
      }
    }
  }
`;

export const ItineraryWrapper = styled.div`
  padding: 50px 0;
  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 80px 30px;
  }
  .Back-btn {
    color: ${({ theme }) => theme.colors.light.lightBlueText};
    display: flex;
    margin-top: 0;
    svg {
      path {
        stroke: ${({ theme }) => theme.colors.light.lightBlueText};
      }
    }
    @media (max-width: 767px) {
      display: none;
    }
  }
  .download-btn-grp {
    @media (max-width: 767px) {
      margin-left: auto;
      margin-bottom: 20px;
    }
    .download-btn-item {
      border-radius: 8px;
      border: 1px solid;
      border-color: ${({ theme }) => theme.colors.light.PrimaryThemeClr};
      background-color: ${({ theme }) => theme.colors.light.PrimaryThemeClr};
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      padding: 12px 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 767px) {
        padding: 10px 26px;
      }
      &.disabled {
        background-color: grey;
        text-decoration: none;
        pointer-event: none;
      }
      &.with-transition {
        transition: background-color 0.3s ease; /* Transition for background-color */
      }
      .Download-btn {
        color: ${({ theme }) => theme.colors.light.textwhiteClr};
        font-size: ${({ theme }) => theme.fontSizes.font18};
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        text-decoration: none;
        margin-right: 15px;
        @media (max-width: 767px) {
          font-size: ${({ theme }) => theme.fontSizes.font14};
        }
        &.disabled {
          background-color: grey;
          text-decoration: none;
          pointer-event: none;
        }
      }
      .download-spinner {
        font-size: ${({ theme }) => theme.fontSizes.font18};
        font-style: normal;
        font-weight: 600;
      }
    }
  }
  .tr-spinner-loader {
    /* background-color: rgb(174 196 227);*/
    padding: 50px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    .text-secondary {
      font-size: 14px !important;
      flex: 1;
      color: ${({ theme }) => theme.colors.light.PrimaryThemeClr} !important;
    }
    .spinner-border {
      border-color: #0061ea;
    }
  }
`;

export const ItinerarysliderInner = styled.div`
  .iti-slider {
    min-height: 650px;
    display: flex;
    justify-content: center;
    align-items: center;
    .text-secondary {
      color: ${({ theme }) => theme.colors.light.PrimaryThemeClr} !important;
    }
    .spinner-border {
      border-color: ${({ theme }) =>
        theme.colors.light.PrimaryThemeClr} !important;
    }
  }
  .tr-slider-wrapper {
    margin-top: 50px;
    padding: 0;
    @media (max-width: 767px) {
      margin-top: 0;
      .tr-slider-info-area {
        display: flex;
        gap: 12px;
        flex-wrap: wrap;
      }
    }
    .tr-slider-back-mob {
      display: none;
      @media (max-width: 767px) {
        display: block;
      }
    }
    .tr-slider-title {
      color: ${({ theme }) => theme.colors.light.titleClr};
      font-family: var(--secondary-font);
      font-size: ${({ theme }) => theme.fontSizes.font48};
      font-style: normal;
      font-weight: 700;
      line-height: 60px;
      letter-spacing: -0.96px;
      @media (max-width: 767px) {
        font-size: ${({ theme }) => theme.fontSizes.font32};
        font-style: normal;
        font-weight: 600;
        line-height: 44px;
        letter-spacing: -0.64px;
      }
    }
    .tr-itinerary-slider-box {
      margin: 50px 0px 120px;
      @media (min-width: 1367px) and (max-width: 1439px) {
        margin-left: calc(100% - 96%);
      }

      @media (max-width: 767px) {
        margin: 32px 0 80px;
      }
      .slick-prev,
      .slick-next {
        top: auto;
        bottom: -113px;
        width: 48px;
        height: 48px;
        @media (max-width: 767px) {
          display: none !important;
        }
      }
      .slick-prev {
        left: auto;
        right: 100px;
      }
      .slick-next {
        right: 35px;
      }
      .slick-list {
        height: 450px;
        @media (min-width: 768px) and (max-width: 1024px) {
          height: 420px;
        }
        @media (max-width: 767px) {
          height: 405px;
        }
        .slick-slide {
          .slider-img-box {
            margin-right: 25px;
            @media (max-width: 767px) {
              display: none;
            }
            img {
              width: 100%;
              height: 450px;
              object-fit: cover;
            }
          }
          .slider-img-box.slider-img-box--mob {
            display: none;
            @media (max-width: 767px) {
              display: block;
            }
            img {
              width: 100%;
            }
          }
        }
      }
      .slick-dots {
        text-align: left;
        bottom: -75px;
        width: auto;
        @media (max-width: 767px) {
          display: none !important;
        }
        li {
          width: 8px;
          height: 8px;
          margin: 0 4px;
          vertical-align: middle;
          &:first-child {
            margin-left: 0;
          }
          button {
            width: 8px;
            height: 8px;
            &:before {
              width: 8px;
              height: 8px;
              line-height: 1;
              opacity: 1;
              color: #b2ccff;
            }
          }
        }
        li.slick-active {
          button {
            &:before {
              opacity: 1;
              color: ${({ theme }) => theme.colors.light.PrimaryThemeClr};
            }
          }
        }
      }
    }
  }
  .img-wrapper-box {
    width: 100%;
    height: 450px;
    object-fit: cover;
  }
`;

export const ItineraryOverviewInner = styled.div`
  @media (max-width: 767px) {
    padding: 0 20px;
  }
  .tr-overview-title {
    color: ${({ theme }) => theme.colors.light.titleClr};
    font-size: ${({ theme }) => theme.fontSizes.font48};
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    display: block;
    text-align: left;
    margin-bottom: 32px;
    @media (max-width: 767px) {
      font-size: ${({ theme }) => theme.fontSizes.font32};
      line-height: 40px;
      letter-spacing: -0.32px;
      margin-bottom: 24px;
    }
  }
  .tr-overview-desc {
    color: ${({ theme }) => theme.colors.light.DescClr};
    font-family: var(--secondary-font);
    font-size: ${({ theme }) => theme.fontSizes.font16};
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    max-width: 650px;
    display: block;
    text-align: left;
    @media (max-width: 767px) {
      border-top: 1px solid ${({ theme }) => theme.colors.border.bdrLightClr2};
      padding-top: 40px;
    }
  }
  .tr-btn-more {
    display: none;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 90px;
    border: 2px solid ${({ theme }) => theme.colors.border.bdrLightClr2};
    background-color: transparent;
    color: #23262f;
    text-align: center;
    font-size: ${({ theme }) => theme.fontSizes.font14};
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    width: auto;
    margin: 48px 0 0;
    @media (max-width: 767px) {
      display: flex;
      margin: 0px;
    }
  }
  .tr-overview-cards-wrap {
    padding: 50px 0;
    /* margin: 80px 0 0; */
    @media (max-width: 767px) {
      padding: 0;
      /* margin: 64px 0 0; */
      .col-lg-3 {
        margin-bottom: 20px;
      }
    }
    .show-more {
      color: ${({ theme }) => theme.colors.light.PrimaryThemeClr};
      font-weight: 500;
      cursor: pointer;
    }
    .tr-overview-single-card {
      display: flex;
      padding: 24px;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      border-radius: 8px;
      background: ${({ theme }) => theme.colors.light.bgClr1};
      transition: 0.3s;
      min-height: 100%;
      max-height: 380px;
      @media (min-width: 768px) and (max-width: 1024px) {
        margin-bottom: 24px;
      }
      .tr-count {
        display: flex;
        width: 48px;
        height: 48px;
        padding: 12px 0;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        background: ${({ theme }) => theme.colors.light.PrimaryThemeClr};
        color: ${({ theme }) => theme.colors.light.textwhiteClr};
        font-size: ${({ theme }) => theme.fontSizes.font28};
        font-weight: 700;
        font-family: var(--primary-font);
      }
      .tr-card-title {
        color: ${({ theme }) => theme.colors.light.titleClr};
        font-family: var(--secondary-font);
        font-size: ${({ theme }) => theme.fontSizes.font24};
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        display: block;
        margin: 30px 0 8px;
        text-transform: capitalize;
        @media (max-width: 767px) {
          margin-top: 48px;
        }
      }
      .tr-card-desc {
        color: var(--gray-600, #475467);
        font-family: var(--secondary-font);
        font-size: ${({ theme }) => theme.fontSizes.font16};
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        height: auto;
        overflow-y: auto;
        max-height: 150px;
        padding-right: 5px;
        text-align: left;
      }
      &:hover {
        transition: 0.3s;
        background: ${({ theme }) => theme.colors.light.PrimaryThemeClr};
        .tr-count {
          color: ${({ theme }) => theme.colors.light.PrimaryThemeClr};
          background: ${({ theme }) => theme.colors.light.textwhiteClr};
        }
        .tr-card-title {
          color: ${({ theme }) => theme.colors.light.textwhiteClr};
        }
        .tr-card-desc {
          color: ${({ theme }) => theme.colors.light.textwhiteClr};
        }
        .show-more {
          color: ${({ theme }) => theme.colors.light.textwhiteClr};
        }
      }
      @media (max-width: 767px) {
        margin-bottom: 24px;
      }
    }
  }
`;

export const SingleitineraryInner = styled.div`
  .tr-single-itinerary-box {
    margin: 50px 0 0;
    // page-break-before: always;
    @media (max-width: 767px) {
      margin: 20px 0;
      border-radius: 10px;
      border: 1px solid ${({ theme }) => theme.colors.border.bdrLightClr};
      background: var(--neutrals-8, #fcfcfd);
    }
    .tr-iti-img-wrap {
      position: relative;
      border-radius: 10px 10px 0 0;
      overflow: hidden;
      .itinerary-hero-img {
        max-width: 100%;
        width: 100%;
        transition: 0.3s;
        height: 450px;
        object-fit: cover;
      }
      .tr-iti-day {
        border-radius: 6.529px;
        background: var(--primary-25, #f5f8ff);
        color: var(--base-black, #000);
        font-family: var(--secondary-font);
        font-size: ${({ theme }) => theme.fontSizes.font16};
        font-style: normal;
        font-weight: 700;
        line-height: 19.587px;
        text-transform: uppercase;
        width: auto;
        display: inline-flex;
        height: 52px;
        justify-content: center;
        align-items: center;
        padding: 0 13px;
        position: absolute;
        left: 15px;
        top: 15px;
        @media (max-width: 767px) {
          border-radius: 4px;
          font-size: ${({ theme }) => theme.fontSizes.font12};
          height: 32px;
          padding: 8px;
        }
        span {
          max-width: 200px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          @media (max-width: 767px) {
            max-width: 135px;
          }
        }
      }
      &:hover {
        .itinerary-hero-img {
          transform: scale(1.05);
          transition: 0.3s;
        }
      }
    }
    .tr-iti-content-wrap {
      padding: 30px 0;
      text-align: left;
      @media (max-width: 767px) {
        padding: 24px;
      }
      .tr-iti-title {
        color: ${({ theme }) => theme.colors.light.titleClr};
        font-family: var(--secondary-font);
        font-size: ${({ theme }) => theme.fontSizes.font48};
        font-style: normal;
        font-weight: 700;
        line-height: 60px;
        letter-spacing: -0.96px;
        display: block;
        @media (max-width: 767px) {
          font-size: ${({ theme }) => theme.fontSizes.font16};
          font-weight: 500;
          line-height: 1;
          letter-spacing: 0;
          display: block;
        }
      }
      .tr-iti-desc {
        display: block;
        margin: 30px 0 0px;
        color: ${({ theme }) => theme.colors.light.DescClr};
        font-family: var(--secondary-font);
        font-size: ${({ theme }) => theme.fontSizes.font18};
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        max-width: 70%;
        width: 100%;
        @media (min-width: 768px) and (max-width: 1024px) {
          max-width: 100%;
          width: 100%;
        }
        @media (max-width: 767px) {
          margin: 16px 0 12px;
          font-size: ${({ theme }) => theme.fontSizes.font14};
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          max-width: 100%;
          width: 100%;
        }
      }
      .CTA-btn {
        max-width: 256px;
        width: 100%;
        border-color: ${({ theme }) => theme.colors.light.PrimaryThemeClr};
        padding: 5px 12px;
        height: 36px;
        line-height: 1;
        font-size: ${({ theme }) => theme.fontSizes.font14};
        font-style: normal;
        font-weight: 600;
        border-radius: 8px;
        font-family: var(--primary-font);
        @media (max-width: 767px) {
          max-width: 100%;
          width: 100%;
        }
      }
    }
  }
  .trip-advisor-paid-grp {
    display: flex;
    justify-content: start;
    margin: 50px 0 0;
    @media (max-width: 767px) {
      flex-direction: column;
    }
    .trip-advisor-item {
      border-radius: ${({ theme }) => theme.borderRadius.bradius8};
      border: 1px solid ${({ theme }) => theme.colors.border.bdrLightClr};
      background: ${({ theme }) => theme.colors.light.bgClr1};
      padding: 24px;
      margin-right: 24px;
      max-width: 32%;
      width: 100%;
      &:last-child {
        margin-right: 0;
      }
      @media (max-width: 767px) {
        max-width: 100%;
        margin: 0 0 10px;
      }
      h3 {
        color: ${({ theme }) => theme.colors.light.titleClr};
        font-size: ${({ theme }) => theme.fontSizes.font18};
        font-style: normal;
        font-weight: ${({ theme }) => theme.fontWeight.fontWeight700};
        line-height: 32px;
        margin-left: 10px;
        margin-bottom: 10px;
      }
      p {
        font-size: ${({ theme }) => theme.fontSizes.font14};
        font-style: normal;
        font-weight: ${({ theme }) => theme.fontWeight.fontWeight600};
        line-height: 28px;
        text-decoration-line: underline;
        margin-bottom: 0;
        cursor: pointer;
      }
      img {
        width: 35px;
      }
    }
    .trip_advisor_unpaid {
      p {
        color: #98a2b3 !important;
        cursor: no-drop;
      }
      img {
        filter: grayscale(1);
      }
    }
  }
  .tr-iti-sticky-wrap {
    min-height: 190px;
    transition: all 0.1s linear;
    .tr-iti-stickyItem-wrap {
      background-image: url(${FrameBg});
      background-size: cover;
      background-repeat: no-repeat;
      padding: 20px 100px;
      position: absolute;
      width: 100%;
      left: 0;
      max-width: 100%;
      min-height: 186px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: left;
      transition: all 0.1s linear;
      @media (max-width: 767px) {
        padding: 50px 20px;
        text-align: center;
        background-image: none;
        background-color: #d1e0ff;
        border-radius: 8px 8px 0px 0px;
      }

      h3 {
        color: ${({ theme }) => theme.colors.light.titleClr};
        font-family: var(--secondary-font);
        font-size: ${({ theme }) => theme.fontSizes.font28};
        font-style: normal;
        font-weight: ${({ theme }) => theme.fontWeight.fontWeight700};
        line-height: 30px;
        letter-spacing: -0.56px;
      }
      p {
        color: #000;
        font-family: var(--secondary-font);
        font-size: ${({ theme }) => theme.fontSizes.font20};
        font-style: normal;
        font-weight: ${({ theme }) => theme.fontWeight.fontWeight400};
        line-height: 28px;
      }
      button {
        width: auto;
      }
    }
  }
  .tr-iti-sticky-scrolled {
    min-height: 0;
    .tr-iti-stickyItem-wrap {
      position: fixed;
      bottom: 0;
      z-index: 9;
    }
  }
  .tr-itinerary-blur {
    filter: blur(9px);
    cursor: not-allowed;
    pointer-events: none;
  }
`;

export const ContinueItineraryInner = styled.div`
  .tr-continue-iti-wrap {
    text-align: center;
    @media (max-width: 767px) {
      padding: 28px 30px 48px;
    }
    .tr-continue-title {
      color: ${({ theme }) => theme.colors.light.titleClr};
      text-align: center;
      font-family: var(--secondary-font);
      font-size: ${({ theme }) => theme.fontSizes.font48};
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      margin-bottom: 16px;
      display: block;
      @media (max-width: 767px) {
        font-size: ${({ theme }) => theme.fontSizes.font24};
        line-height: 32px;
      }
    }
    .tr-continue-desc {
      color: ${({ theme }) => theme.colors.light.titleClr};
      font-family: var(--secondary-font);
      font-size: ${({ theme }) => theme.fontSizes.font14};
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      display: block;
      margin-bottom: 24px;
    }
    .tr-continue-form {
      max-width: 320px;
      margin: 0 auto;
      @media (max-width: 767px) {
        max-width: 100%;
      }
      .CTA-btn {
        width: 100%;
        color: #667085;
        border-color: #98a2b3;
        background: ${({ theme }) => theme.colors.light.textwhiteClr};
        padding: 5px 12px;
        height: 36px;
        line-height: 1;
        font-size: ${({ theme }) => theme.fontSizes.font14};
        font-style: normal;
        font-weight: 600;
        border-radius: 8px;
        font-family: var(--primary-font);
        margin: 0;
        box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
      }
    }
  }
`;

export const ItineraryInprogressInner = styled.div`
  @media (max-width: 767px) {
    /* padding: 24px 20px; */
  }
  .tr-itinerary-inprogress-wrap {
    text-align: center;
    .tr-itinerary-inprogress-title {
      color: ${({ theme }) => theme.colors.light.titleClr};
      text-align: center;
      font-family: var(--secondary-font);
      font-size: ${({ theme }) => theme.fontSizes.font48};
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      @media (max-width: 767px) {
        font-size: ${({ theme }) => theme.fontSizes.font32};
        letter-spacing: -0.32px;
        padding-bottom: 24px;
        border-bottom: 1px solid
          ${({ theme }) => theme.colors.border.bdrLightClr2};
        width: 100%;
        display: block;
        text-align: left;
      }
    }
    .tr-copy-input-wrap {
      margin: 45px auto;
      text-align: left;
      max-width: 420px;
      position: relative;
      .tr-copy-btn {
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-40%);
        cursor: pointer;
      }
    }
    .tr-itinerary-inprogress-card {
      border-radius: 8px;
      border: 1px solid var(--gray-200, #eaecf0);
      background: ${({ theme }) => theme.colors.light.bgClr1};
      padding: 32px;
      position: relative;
      margin-bottom: 24px;
      min-height: 100%;
      @media (min-width: 768px) and (max-width: 1024px) {
        padding: 16px;
      }
      .tr-label {
        display: inline-flex;
        padding: 2px 8px;
        justify-content: center;
        align-items: center;
        border-radius: 16px;
        text-align: center;
        font-family: var(--secondary-font);
        font-size: ${({ theme }) => theme.fontSizes.font12};
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        position: absolute;
        right: 32px;
        top: auto;
        @media (min-width: 768px) and (max-width: 1024px) {
          right: 16px;
        }
        @media (max-width: 767px) {
          right: 16px;
        }
      }
      .tr-label-sent {
        background: var(--primary-50, #f9f5ff);
        color: var(--primary-700, #6941c6);
      }
      .tr-label-finished {
        background: var(--success-200, #abefc6);
        color: var(--success-600, #079455);
      }
      .tr-user-info-area {
        display: flex;
        gap: 17px;
        .tr-user-img {
          width: 64px;
          height: 64px;
          border-radius: 4px;
          img {
            width: 64px;
            height: 64px;
            object-fit: cover;
            border-radius: 6px;
            border: 2px solid #0061ea85;
          }
        }
        .tr-user-info {
          text-align: left;
          .tr-username {
            display: block;
            color: var(--gray-900, #101828);
            font-family: var(--secondary-font);
            font-size: ${({ theme }) => theme.fontSizes.font18};
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
            text-transform: capitalize;
            max-width: 135px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
          .tr-user-location {
            color: var(--gray-900, #101828);
            font-family: var(--secondary-font);
            font-size: ${({ theme }) => theme.fontSizes.font12};
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
            max-width: 135px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            display: inline-block;
          }
        }
      }
      .user-profile-text {
        background-color: ${({ theme }) => theme.colors.light.lightBlueText};
        height: 64px;
        width: 64px;
        border-radius: 6px;
        font-size: ${({ theme }) => theme.fontSizes.font20};
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${({ theme }) => theme.colors.light.textwhiteClr};
        @media (max-width: 767px) {
          font-size: 13px;
        }
      }
      .tr-user-desc {
        color: ${({ theme }) => theme.colors.light.DescClr};
        font-family: var(--secondary-font);
        font-size: ${({ theme }) => theme.fontSizes.font16};
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-top: 16px;
        text-align: justify;
      }
      @media (max-width: 767px) {
        padding: 16px;
        margin-bottom: 48px;
      }
    }
  }
`;

export const ItineraryTextItem = styled.div`
  text-align: left;
  margin-top: 50px;
  .tr-card-grp-item {
    margin-bottom: 50px;
    .tr-card-item {
      width: 100%;
      color: #344054;
      font-family: var(--secondary-font);
      font-size: ${({ theme }) => theme.fontSizes.font48};
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      margin-bottom: 40px;
      @media (max-width: 767px) {
        margin-bottom: 24px;
        font-size: ${({ theme }) => theme.fontSizes.font14};
        line-height: 1;
      }
    }
    .tr-user-desc {
      display: block;
      margin: 30px 0 0px;
      color: ${({ theme }) => theme.colors.light.DescClr};
      font-family: var(--secondary-font);
      font-size: ${({ theme }) => theme.fontSizes.font18};
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      width: 100%;
      @media (min-width: 768px) and (max-width: 1024px) {
        max-width: 100%;
        width: 100%;
      }
      @media (max-width: 767px) {
        margin: 16px 0 12px;
        font-size: ${({ theme }) => theme.fontSizes.font14};
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        max-width: 100%;
        width: 100%;
      }
    }
    ul {
      padding-left: 25px;
      li {
        position: relative;
        margin: 0 0 15px !important;
        &::after {
          content: "";
          background-color: ${({ theme }) => theme.colors.light.MenuItemClr1};
          height: 12px;
          width: 12px;
          position: absolute;
          left: -23px;
          border-radius: 50%;
          top: 7px;
        }
      }
    }
  }
`;
export const PdfWrapper = styled.div`
  .tr-slider-wrapper {
    margin-top: 0 !important;
  }
  .overView-pdfcard {
    .col-lg-3 {
      width: 46%;
      margin: 10px 0;
    }
    .tr-card-title {
      margin: 10px 0 8px !important;
    }
  }
  .slider-img-box {
    img {
      height: 450px !important;
      object-fit: cover;
    }
  }
  .tr-single-itinerary-box {
    border-radius: 0;
    border: none;
    .tr-iti-img-wrap {
      border-radius: 0px;
    }
    .tr-iti-content-wrap {
      padding: 24px 0;
    }
  }
  .blank-space {
    min-height: 50px;
    margin: 25px 0;
  }
  .tr-card-item {
    font-size: 32px !important;
  }
  .tr-username {
    max-width: 100% !important;
  }
`;
