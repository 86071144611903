import styled from "styled-components";
import { CTABg } from "../../../assets/images";

export const DemoLinkStyle = styled.div`
    background: url(${CTABg});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center right;
    padding: 0;
    height: 100vh;
    padding-top:80px;
    /* display: flex;
    align-items: center;
    justify-content: center;
    text-align:center; */
    color:#fff;
    a {
        font-size: 20px;
        text-decoration: none;
        line-height: 30px;
        margin-bottom: 15px;
        display: inline-block;
        color: #fff;
    }
    h1 {
        font-size:28px;
    }
`;