import React from "react";
import OtpInput from "react-otp-input";
import PropTypes from "prop-types";

const   OtpInputHOC = ({ code, handleChange, error }) => {
  const getCode = (code) => {
    if (handleChange && typeof handleChange === "function") handleChange(code);
  };

  const preventCopyPaste = (e) => {
    e.preventDefault()
    alert("Copying and pasting is not allowed!")
  }
  return (
    <div className="otp-input">
      <OtpInput
        isInputNum={true}
        value={code}
        onChange={getCode}
        numInputs={4}
        separator={<span style={{ width: "8px" }}></span>}
        shouldAutoFocus={true}
        inputStyle={{
          border: "1px solid #155EEF",
          borderRadius: "8px",
          width: "65px",
          height: "65px",
          fontSize: "18px",
          color: "#000",
          fontWeight: "400",
          // caretColor: "blue",
        }}
        focusStyle={{
          border: "2px solid #155EEF",
          outline: "none",
        }}
      />
      <div className="invalid">{error}</div>
    </div>
  );
};

OtpInputHOC.propTypes = {
  handleChange: PropTypes.func,
};

OtpInputHOC.defaultProps = {
  handleChange: () => {},
};

export default OtpInputHOC;
