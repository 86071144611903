/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import TravelForm from "./travelForm";
import {
  saveCurrentSteps,
  saveTravelQuestions,
  saveUserTravelAnswers,
  saveOpenAIQuestions,
  saveUserOpenAIAnswers,
  deleteOpenAIQuesAndAns,
} from "../../../state/travel/actions";
import { LABELS, QUES_TYPE } from "../../../utils/constants";
import { ROUTES } from "../../../utils/constants/routes";
import useQuiz from "../../../services/quiz";
import { MSG, OPEN_AI_LAST_QUES } from "../../../utils/constants";
import {
  getRandomImage,
  getInvitedUserId,
  getItineraryId,
} from "../../../utils/utilityFunctions";
import { QuizInnerBox } from "../personalityTest/formStyle";
import Button from "../../../components/common/button";
import {
  getPersonalityId,
  removeInvitedUserDetails,
} from "../../../utils/utilityFunctions";
import Modal from "../../../components/common/modals";
import { deleteAllTravelQuesData } from "../../../state/travel/actions";

const CreatePersonality = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    getQuizQuestions,
    generateOpenAIQuestions,
    generateItineraries,
    generateItinerariesForGroupTravellers,
    generateItinerariesForInvitedUser,
  } = useQuiz();

  const invitedUserId = getInvitedUserId();
  const invitedItineraryId = getItineraryId();

  const { userDetails } = useSelector((state) => state.user);
  const {
    travelQuestions,
    openaAIQuestions,
    currentSteps,
    userTravelAnswers,
    userOpenAIAnswers,
    preTravelQuizData,
  } = useSelector((state) => state.travel);

  let isOpenaAIQuestionStarted = false;
  if (travelQuestions?.length < currentSteps + 1) {
    isOpenaAIQuestionStarted = true;
  }

  const allQuestions = [...travelQuestions, ...openaAIQuestions];

  const [currentAns, setCurrentAns] = useState({});
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [quesSubmitLoading, setQuesSubmitLoading] = useState(false);
  const [isLoadinAIQues, setIsLoadinAIQues] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [checkboxAns, setCheckboxAns] = useState([]);
  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowSubmitTestModal, setIsShowSubmitTestModal] = useState(false);
  const [savePopupResponse, setSavePopupResponse] = useState(null);
  const [itineraryPayloadData, setItineraryPayloadData] = useState({});
  const [image, setImage] = useState("");
  const [mobileViewImage, setMobileViewImagetImage] = useState("");
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
  }, []);

  useEffect(() => {
    setImage(getRandomImage(null, true));
    setMobileViewImagetImage(getRandomImage(true, true));
    handleStoreAttemptedQues(currentSteps);
    handleFetchQuestions();
  }, []);

  useEffect(() => {
    if (userDetails?.personalityType?.name || getPersonalityId()) {
    } else {
      navigate(ROUTES?.personality);
    }
  }, []);

  useEffect(() => {
    if (savePopupResponse) {
      dispatch(deleteOpenAIQuesAndAns());
      isOpenaAIQuestionStarted = false;
      setSavePopupResponse(null);
      proceedBackActions();
    }
  }, [savePopupResponse]);

  const handleFetchQuestions = () => {
    if (travelQuestions.length) return;
    setIsShowLoading(true);
    getQuizQuestions(true).then((response) => {
      setIsShowLoading(false);
      if (response?.isSuccess) {
        dispatch(saveTravelQuestions(response?.data?.data?.questions));
      } else {
        toast.error(MSG.ON_ERROR);
      }
    });
  };

  const handleStoreAttemptedQues = (stepNum) => {
    window.scrollTo(0, 0);
    if (isOpenaAIQuestionStarted) {
      const data = userOpenAIAnswers.find(
        (obj) => obj?.question === allQuestions[stepNum]
      );
      setUserInput(data?.ans);
    } else if (userTravelAnswers?.length) {
      const data = userTravelAnswers.find(
        (obj) => obj?.questionId === travelQuestions[stepNum]?._id
      );
      if (data) {
        const { optionsType } = travelQuestions[stepNum];
        if (optionsType === QUES_TYPE.RADIO) {
          setCurrentAns({ _id: data.optionId });
        } else if (optionsType === QUES_TYPE.INPUT) {
          setUserInput(data?.ans);
        } else if (optionsType === QUES_TYPE.CHECKBOX) {
          setCheckboxAns(data?.optionId);
        }
      }
    }
    setImage(getRandomImage());
    setMobileViewImagetImage(getRandomImage(true));
  };

  const handleResetUserAns = () => {
    setCurrentAns({});
    setCheckboxAns([]);
    setUserInput("");
  };

  const handlePopupResponse = (val) => {
    setIsShowModal(false);
    setSavePopupResponse(val);
  };

  const proceedBackActions = async () => {
    handleResetUserAns();
    if (currentSteps > 0) {
      handleStoreAttemptedQues(currentSteps - 1);
      dispatch(saveCurrentSteps(currentSteps - 1));
    } else {
      if (getInvitedUserId()) {
        return navigate(ROUTES?.home);
      }
      navigate(ROUTES?.travelLocation);
    }
  };
  const handleBackButton = async () => {
    if (travelQuestions.length === currentSteps) {
      setIsShowModal(true);
    } else {
      proceedBackActions();
    }
  };

  const getPreQuizData = (isTravelTestPayload) => {
    const data = {
      number_of_days: Number(preTravelQuizData?.travelDays),
      total_budget: preTravelQuizData?.travelCost || "",
    };
    if (isTravelTestPayload) {
      data.itineraryType =
        preTravelQuizData?.travelStyle?.value === "group"
          ? "Group travel"
          : "Solo travel";

      if (preTravelQuizData?.travelStyle?.value === "group") {
        data.number_of_users = preTravelQuizData?.travellerCount;
      }
    }
    if (preTravelQuizData?.destination) {
      data.destination = preTravelQuizData?.destination;
    }
    return data;
  };

  const handleSubmitTravelTest = (lastQue) => {
    const allUserTravelAnswersData = [...userTravelAnswers];
    if (lastQue) {
      const itemIndex = allUserTravelAnswersData.findIndex(
        (item) => item.questionId === lastQue.questionId
      );
      itemIndex !== -1
        ? (allUserTravelAnswersData[itemIndex] = lastQue)
        : allUserTravelAnswersData.push(lastQue);
    }
    let payload = {
      questionDetails: allUserTravelAnswersData,
    };
    if (invitedUserId) {
      payload.itineraryId = invitedItineraryId;
    } else if (!invitedUserId) {
      //not invited user
      payload = {
        ...payload,
        ...getPreQuizData(true),
      };
    }
    handleCallGenerateOpenAIQuesAPI(payload);
  };

  const handleCallGenerateOpenAIQuesAPI = (payloadData) => {
    setIsLoadinAIQues(true);
    generateOpenAIQuestions(payloadData)
      .then((response) => {
        if (response?.isSuccess && response?.data?.data?.questions?.length) {
          const { questions } = response?.data?.data;
          questions.push(OPEN_AI_LAST_QUES);
          dispatch(saveOpenAIQuestions(questions));
          handleResetUserAns();
          handleStoreAttemptedQues(currentSteps + 1);
          dispatch(saveCurrentSteps(currentSteps + 1));
        } else {
          toast.error(MSG.ON_ERROR);
        }
      })
      .finally(() => {
        setIsLoadinAIQues(false);
      });
  };

  const createPayloadForItineraries = (lastQue) => {
    const allOpenAIAnswersData = [...userOpenAIAnswers];

    if (lastQue?.question) {
      const itemIndex = allOpenAIAnswersData.findIndex(
        (item) => item.question === lastQue.question
      );
      itemIndex !== -1
        ? (allOpenAIAnswersData[itemIndex] = lastQue)
        : allOpenAIAnswersData.push(lastQue);
    }

    const randomQuestionDetails = {};
    for (let index = 0; index < allOpenAIAnswersData.length; index++) {
      randomQuestionDetails[allOpenAIAnswersData[index].question] = [
        allOpenAIAnswersData[index].ans,
      ];
    }

    let payloadData = {
      metaData: {
        questionDetails: userTravelAnswers,
        randomQuestionDetails,
      },
    };

    if (!invitedUserId) {
      // not invited user
      payloadData = {
        metaData: {
          ...payloadData.metaData,
          ...getPreQuizData(false),
        },
      };
    }

    // show popup
    setItineraryPayloadData(payloadData);
    setIsShowSubmitTestModal(true);

    // if (userDetails?.email) {
    //  handleGenerateItineraries(payloadData);
    // } else {
    //   payloadData.personalityId = getPersonalityId();
    //   navigate(ROUTES?.guestUserNearlyDone, { state: payloadData });
    // }
  };

  const handleGenerateItineraries = (data) => {
    let payload = { ...data };
    let itineraryId = "";
    let invitedUserUserId = "";
    let createItineraries = generateItineraries;
    if (preTravelQuizData?.travelStyle?.value === "group") {
      createItineraries = generateItinerariesForGroupTravellers;
      payload.metaData.number_of_users = preTravelQuizData?.travellerCount;
      itineraryId = preTravelQuizData?.groupItineraryId;
    }
    if (invitedUserId) {
      createItineraries = generateItinerariesForInvitedUser;
      itineraryId = invitedItineraryId;
      invitedUserUserId = invitedUserId;
      delete payload.metaData.number_of_users;
    }
    setQuesSubmitLoading(true);
    createItineraries(payload, itineraryId, invitedUserUserId)
      .then((response) => {
        if (response?.isSuccess) {
          if (
            preTravelQuizData?.travelStyle?.value === "group" &&
            payload?.metaData?.number_of_users
          ) {
            return navigate(`${ROUTES?.groupItinerary}?id=${itineraryId}`);
          } else {
            return navigate(ROUTES?.travelSuccess);
          }
        } else {
          dispatch(deleteAllTravelQuesData());
          if (invitedUserId) {
            removeInvitedUserDetails();
          }
          navigate(ROUTES?.home);
        }
      })
      .finally(() => {
        setQuesSubmitLoading(false);
      });
  };

  const determineSubmitData = (currentAnsData) => {
    const currentQuestion = travelQuestions[currentSteps];
    const questionId = currentQuestion._id;
    if (currentQuestion.optionsType === QUES_TYPE.RADIO) {
      let data = { ...currentAnsData };
      if (!currentAnsData?._id) {
        data = { ...currentAns };
      }
      return { questionId, optionId: data?._id };
    } else if (currentQuestion.optionsType === QUES_TYPE.INPUT) {
      return { questionId, ans: userInput };
    } else if (currentQuestion.optionsType === QUES_TYPE.CHECKBOX) {
      return { questionId, optionId: checkboxAns };
    }
    return {};
  };

  const handleNextButton = (currentAnsData) => {
    if (isOpenaAIQuestionStarted) {
      // store open ai question in redux
      let data = {};
      if (userInput) {
        data = {
          question: allQuestions[currentSteps],
          ans: userInput,
        };
        dispatch(saveUserOpenAIAnswers(data));
      }
      if (currentSteps + 1 === allQuestions.length) {
        createPayloadForItineraries(data);
      } else {
        setUserInput("");
        handleStoreAttemptedQues(currentSteps + 1);
        dispatch(saveCurrentSteps(currentSteps + 1));
      }
    } else {
      // check type of ques & store in redux
      const submitData = determineSubmitData(currentAnsData);
      dispatch(saveUserTravelAnswers(submitData));
      if (currentSteps + 1 === travelQuestions.length) {
        handleSubmitTravelTest(submitData);
      } else {
        handleResetUserAns();
        handleStoreAttemptedQues(currentSteps + 1);
        dispatch(saveCurrentSteps(currentSteps + 1));
      }
    }
  };

  const handleSkipQue = () => {
    if (isOpenaAIQuestionStarted) {
      handleNextButton();
      return;
    }
    if (currentSteps + 1 === travelQuestions.length) {
      handleSubmitTravelTest();
    }
    dispatch(saveCurrentSteps(currentSteps + 1));
  };

  const getData = (data) => {
    setCurrentAns(data);
    const { optionsType } = travelQuestions[currentSteps];
    if (optionsType === QUES_TYPE.RADIO && isMobile) {
      setTimeout(() => {
        handleNextButton(data);
      }, 1000);
    }
  };

  const handleChangeInput = (value) => {
    setUserInput(value);
  };

  const getCheckboxSelectedData = (data) => {
    const options = [...checkboxAns];
    const indexToRemove = options.indexOf(data?._id);
    if (indexToRemove !== -1) {
      options.splice(indexToRemove, 1); // remove options
    } else {
      options.push(data?._id);
    }
    setCheckboxAns(options);
  };

  const handleTestSubmitConfirmation = (val) => {
    setIsShowSubmitTestModal(false);
    if (val) {
      if (userDetails?.email) {
        handleGenerateItineraries(itineraryPayloadData);
      } else {
        itineraryPayloadData.personalityId = getPersonalityId();
        navigate(ROUTES?.guestUserNearlyDone, { state: itineraryPayloadData });
      }
    }
  };

  const isNextButtonEnabled =
    currentAns?._id || userInput?.trim()?.length > 2 || checkboxAns?.length;

  const isSkippableQues =
    allQuestions[currentSteps]?.isSkippable ||
    travelQuestions?.length + 5 === currentSteps;

  return (
    <div>
      <QuizInnerBox>
        <TravelForm
          questionData={allQuestions[currentSteps]}
          getData={getData}
          getCheckboxSelectedData={getCheckboxSelectedData}
          selected={currentAns}
          totalQuestions={travelQuestions?.length + 6}
          currentSteps={currentSteps}
          image={image}
          mobileViewImage={mobileViewImage}
          isShowLoading={isShowLoading || isLoadinAIQues}
          handleChangeInput={handleChangeInput}
          userInput={userInput}
          checkboxAns={checkboxAns}
          isOpenaAIQuestionStarted={isOpenaAIQuestionStarted}
          handleBackButton={handleBackButton}
          isMobile={isMobile}
        />
        {!(isLoadinAIQues || isShowLoading) && (
          <div className="step-form-footer">
            <div className={`step-form-footer-inner`}>
              {isSkippableQues && (
                <Button
                  onClick={handleSkipQue}
                  className="step-btn step-btn-back"
                  text={LABELS.SKIP}
                />
              )}
              <Button
                onClick={handleBackButton}
                className="step-btn step-btn-back"
                text={LABELS.BACK}
              />
              <Button
                onClick={handleNextButton}
                className="step-btn step-btn-next"
                disabled={!isNextButtonEnabled}
                loading={quesSubmitLoading}
                text={
                  travelQuestions?.length + 5 === currentSteps
                    ? LABELS.SUBMIT
                    : LABELS.NEXT
                }
              />
            </div>
          </div>
        )}
      </QuizInnerBox>
      <Modal
        showModal={isShowModal}
        handlePopupResponse={(val) => handlePopupResponse(val)}
        contentTitle={MSG.DELETE_PROGRESS_TITLE}
        contentText={MSG.DELETE_PROGRESS}
        className="modal-body-text"
        modalSize="md"
        secondaryButtonText="Cancel"
        primaryButtonText="Go Back"
        isTravelTestModal={true}
      />
      <Modal
        showModal={isShowSubmitTestModal}
        handlePopupResponse={(val, action) =>
          handleTestSubmitConfirmation(val, action)
        }
        contentText={MSG.SUBMIT_QUIZ}
        modalSize="md"
        isTravelTestModal={true}
        primaryButtonText={LABELS.SUBMIT}
        secondaryButtonText={LABELS.CANCEL}
      />
    </div>
  );
};

export default CreatePersonality;
