import {
  UPDATE_USER_DETAILS,
  CLEAR_USER_DETAILS,
  SET_USER_LOGIN,
  SET_FORGET_PASSWORD_EMAIL,
  SET_VERIFICATION_LINK,
  SET_SELECTED_TAB,
  MENU_BAR_TOGGLE_STATE
} from "./constants";

const initialState = {
  userDetails: {},
  isFirstLogin: true,
  forgetPasswordEmail: "",
  verificationLink: "",
  selectedTab: "",
  isMenuBarToggleState: false
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USER_DETAILS:
      const updateData = { ...state.userDetails, ...action.payload };
      return { ...state, userDetails: updateData };

    case CLEAR_USER_DETAILS:
      return { ...state, userDetails: {} };

    case SET_USER_LOGIN:
      return { ...state, isFirstLogin: action.payload };

    case SET_FORGET_PASSWORD_EMAIL:
      return { ...state, forgetPasswordEmail: action.payload };

    case SET_VERIFICATION_LINK:
      return { ...state, verificationLink: action.payload };

    case SET_SELECTED_TAB:
      return { ...state, selectedTab: action.payload };

    case MENU_BAR_TOGGLE_STATE:
      return { ...state, isMenuBarToggleState: action.payload };

    default:
      return state;
  }
};

export default userReducer;
