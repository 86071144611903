//==================================================================
// Default state schema
// You can change default schema according to your requirement

//==================================================================
export const defaultContextSchema = {
  users: [
    { name: "test user one", email: "test_user_one@test.com" },
    { name: "test user two", email: "test_user_two@test.com" },
  ],
  products: [
    { name: "test product one", price: 30, currency: "Rs" },
    { name: "test product tow", price: 140, currency: "Rs" },
  ],
  forgetPasswordOTP: null,
  forgetPasswordEmail: "",
  userPersonality: null,
};
