export const Image1 = require("./Image-1.png");
export const Image2 = require("./Image-2.png");
export const Image3 = require("./Image-3.png");
export const Image4 = require("./Image-4.png");
export const Image5 = require("./Image-5.png");
export const Image6 = require("./Image-6.png");
export const Image7 = require("./Image-7.png");
export const Image8 = require("./Image-8.png");
export const Image9 = require("./Image-9.png");
export const Image10 = require("./Image-10.png");
export const Image11 = require("./Image-11.png");
export const Image12 = require("./Image-12.png");
export const Image13 = require("./Image-13.png");
export const Image14 = require("./Image-14.png");
export const Image15 = require("./Image-15.png");
export const Image16 = require("./Image-16.png");
export const Image17 = require("./Image-17.png");
export const Image18 = require("./Image-18.png");
