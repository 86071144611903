import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "../../../components/common/button";
import Parallax from "react-rellax";
import {
  ArrowIcon,
  BannerSlide1,
  BannerSlide2,
  BannerSlide3,
  BannerSlide4,
  BannerSlide5,
  BannerSlideDesk1,
  BannerSlideDesk2,
  BannerSlideDesk3,
  BannerSlideDesk4,
  BannerSlideDesk5,
} from "../../../assets/images";
import { useNavigate } from "react-router-dom";
import { StarIcon } from "../../../assets/images/checkIcon";
import Image from "../../../components/common/image";
import { handleRedirectionToQuizPage } from "../../../utils/utilityFunctions";
const Banner = () => {
  const navigate = useNavigate();

  return (
    <div className="home-wrapper__Banner">
      <Container>
        <Row>
          <Col
            md="6"
            className="offset-md-3 text-center position-relative"
            style={{ zIndex: 1 }}
          >
            <Parallax speed={-4} className="d-none d-md-block">
              <div className="starIcon">
                <StarIcon />
              </div>
              <h1 className="text-center mt-sm-0">
                Personalized journeys that reflect your unique personality.
              </h1>
              <div className="home-wrapper__Banner__arrowIcon">
                <img src={ArrowIcon} alt="" />
              </div>
              <Button
                text={"TAkE THE QUIZ"}
                className="CTA-btn mt-5 px-5"
                onClick={() => {
                  handleRedirectionToQuizPage(navigate);
                }}
              />
            </Parallax>
            <Parallax
              speed={2}
              className="d-md-none d-block home-banner-content-mobile"
            >
              <h1 className="text-center mt-sm-0">
                Personalized journeys that reflect your unique personality.
              </h1>
              <div className="home-wrapper__Banner__arrowIcon">
                <img src={ArrowIcon} alt="" />
              </div>
            </Parallax>
          </Col>
          <Col className="d-none d-md-block image-box-grp">
            <div className="row">
              <div className="image-box bannerSlide1 col-lg-3">
                <Parallax speed={-2}>
                  <Image src={BannerSlideDesk1} alt="" />
                </Parallax>
              </div>
              <div className="image-box bannerSlide2 col-lg-9">
                <Parallax speed={-3}>
                  <Image src={BannerSlideDesk2} alt="Banner Slide" />
                </Parallax>
              </div>
            </div>
            <div className="row">
              <div className="image-box bannerSlide3 col-lg-3">
                <Parallax speed={-3}>
                  <Image src={BannerSlideDesk3} alt="Banner Slide" />
                </Parallax>
              </div>
            </div>

            <div className="row">
              <div className="image-box bannerSlide4 col-lg-5">
                <Parallax speed={2}>
                  <Image src={BannerSlideDesk4} alt="Banner Slide" />
                </Parallax>
              </div>
              <div className="image-box bannerSlide5 col-lg-7">
                <Parallax speed={2}>
                  <Image src={BannerSlideDesk5} alt="Banner Slide" />
                </Parallax>
              </div>
            </div>
          </Col>

          <Col md="12" className="d-md-none d-block">
            <div className="image-box bannerSlide1">
              <Parallax speed={-2}>
                <Image src={BannerSlide1} alt="" />
              </Parallax>
            </div>
            <div className="image-box bannerSlide2">
              <Parallax speed={-4}>
                <Image src={BannerSlide2} alt="Banner Slide" />
              </Parallax>
            </div>
            <div className="image-box bannerSlide3">
              <Parallax speed={-3}>
                <Image src={BannerSlide3} alt="Banner Slide" />
              </Parallax>
            </div>
            <div className="image-box bannerSlide4">
              <Parallax speed={4}>
                <Image src={BannerSlide4} alt="Banner Slide" />
              </Parallax>
            </div>
            <div className="image-box bannerSlide5">
              <Parallax speed={2}>
                <Image src={BannerSlide5} alt="Banner Slide" />
              </Parallax>
            </div>
          </Col>
          <Button
            text={"TAkE THE QUIZ"}
            className="CTA-btn d-md-none"
            onClick={() => handleRedirectionToQuizPage(navigate)}
          />
        </Row>
      </Container>
    </div>
  );
};

export default Banner;
