import React from "react";
import Select from "react-select";

const CustomSelect = ({
  label,
  options,
  name,
  value,
  onChange,
  selectedCountry,
  placeholder,
}) => {
  const selectedOption = options.find((option) => option.value === value);
  const defaultCountryOption = {
    value: selectedCountry?.code,
    label: selectedCountry?.name,
    image: selectedCountry?.image,
  };

  const isValueEmpty = value === undefined || value === null || value === "";

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused ? "1px solid #80bdff" : "1px solid #ced4da",
      boxShadow: state.isFocused
        ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
        : "none",
      "&:hover": {
        border: "1px solid #80bdff",
      },
    }),

    placeholder: (provided) => ({
      ...provided,
      color: "#aaa",
    }),
  };

  return (
    <div className="tr-form-group">
      <label className="form-label">{label}</label>

      <Select
        styles={customStyles}
        options={options}
        name={name}
        value={isValueEmpty ? null : selectedOption || defaultCountryOption}
        onChange={onChange}
        getOptionLabel={(option) => (
          <div>
            {option.image && (
              <img
                src={option.image}
                alt={option.label}
                width="20"
                height="20"
                style={{ marginRight: "8px" }}
              />
            )}
            {option.label}
          </div>
        )}
        getOptionValue={(option) => option.value}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        placeholder={placeholder}
      />
      <label className="form-label tr-optional">Optional</label>
    </div>
  );
};

export default CustomSelect;
