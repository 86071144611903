export const validate = (values) => {
  const errors = {};
  var reg = new RegExp(/^\d+$/);
  if (!values?.otp) {
    errors.otp = "Required";
  } else if (!reg.test(values.otp)) {
    errors.otp = "Enter numbers only";
  } else if (values?.otp?.length < 4) {
    errors.otp = "Please enter correct OTP";
  }
  return errors;
};
