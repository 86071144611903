import styled from "styled-components";

export const FooterStyled = styled.div`
.footer-wrapper {
        background-color: ${({ theme }) => theme.colors.light.blackClr};
        color: ${({ theme }) => theme.colors.light.textwhiteClr};
        padding: 50px 0 20px;
        font-family: var(--primary-font);
        h1 {
            font-family: var(--primary-font);
            font-size: 70px;
            font-style: normal;
            font-weight: 700;
            letter-spacing: 1.2px;
            text-transform: uppercase;
            @media (max-width:1023px) {
                font-size: ${({ theme }) => theme.fontSizes.font32};
            }
        }
        p {
            font-family: var(--primary-font);
            color: #F6F6F6;
            font-size: ${({ theme }) => theme.fontSizes.font16};
            font-weight: ${({ theme }) => theme.fontWeight.fontWeight400};
            margin-top: 20px;
        }
        span {
            color: #cacaca;
            font-family: var(--primary-font);
            font-size: ${({ theme }) => theme.fontSizes.font12};
            font-weight: ${({ theme }) => theme.fontWeight.fontWeight400};
        }
        ul {
            li {
                margin-right: 10px;
                .facebook:hover {
                    svg {
                        path {
                            fill: #4267B2;
                        }
                    }  
                }
                .Instagram {
                   &:hover {
                    svg {
                        path {
                            fill:#AE01FF;
                            /* fill: linear-gradient(180deg, #FFA800 0%, #AE01FF 100%); */
                        }
                    }  
                   } 
                }
                .Tiktok {
                   &:hover {
                    svg {
                        path {
                            fill: #00f2ea;
                        }
                    }  
                   } 
                }
                .twitter {
                   &:hover {
                    svg {
                        path {
                            fill: #00acee;
                        }
                    }  
                   } 
                }
            }
        }
        .res-text-center {
            @media (max-width:767px) {
                text-align:center;
                .navbar-brand {
                    padding-bottom: 15px;
                    display: block;
                }
            }
        }
        &__newsletter {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 30px 0 10px;
            @media (max-width:767px) { 
                flex-direction: column;
            }
            .form-control {
                border-radius: 20px;
                max-width: 350px;
                margin-right: 20px;
                @media (max-width:767px) { 
                    margin-right:0; 
                    max-width:100%;
                 }
            }
            .submit-btn {
                background-color: transparent;
                text-transform: uppercase;
                width: auto;
                padding: 10px 45px;
                border-radius: 20px;
                margin: 0;
                @media (max-width:767px) {  
                    margin-top: 15px;
                    width: 100%;
                }
            }
            .error-msg {
                color: ${({ theme }) => theme.colors.light.errorClr};
                text-align:left;
                margin: 10px 12px 0;
                font-size: ${({ theme }) => theme.fontSizes.font14};
            }
        }
        .nav {
            align-items: center;
            width: 100%;
            justify-content: center;
            .nav-link {
               color: ${({ theme }) => theme.colors.light.textwhiteClr};
               font-size: ${({ theme }) => theme.fontSizes.font14};
               font-weight: ${({ theme }) => theme.fontWeight.fontWeight400};
               padding: 0 15px;
               transition: all 0.2s ease-in 0s;
               &:hover {
                  color: ${({ theme }) => theme.colors.light.lightBlueClr};
               }
            }
            .active-link {
                color: ${({ theme }) => theme.colors.light.lightBlueClr};
            }
        }
        .copyright-text {
            color: #A3A3A3;
            font-size: ${({ theme }) => theme.fontSizes.font14};
     }
     .twitter-icon {
            svg {
                width: 16px;
            }
        }
  }
  .after-login-footer {
    padding: 15px 0;
    box-shadow: 0px 0px 5px #95a2b294;
    @media (max-width:767px) {
        text-align:center;
    }
    .social-icon {
        display: flex;
        justify-content:end;
        margin-bottom: 0;
        @media (max-width:767px) {
            justify-content:center;
            margin-top: 20px;
        }
        a {padding:5px;}
        svg {
        path {
            fill: rgba(152, 162, 179, 1);
            }
        }
        .twitter-icon {
            svg {
                width: 15px;
            }
        }
    }
  }
`;