import styled from "styled-components";

export const PaymentStyled = styled.section`
    .Overview-wrapper {
        min-height:100vh;
        .quiz-img-wrap {
            position: relative;
            height: 100%;
            background-repeat: no-repeat;
            width: 100%;
            background-size: cover;
            background-position: bottom center;
            @media (max-width:1023px) {
                background-position: center center;
            }
        }
        .Overview-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 65px;
            font-family: var(--secondary-font);
            @media (max-width:767px) {
                padding: 0 20px;
            }
            h1 {
                color:${({ theme }) => theme.colors.light.headingClr};
                font-size: ${({ theme }) => theme.fontSizes.font60};
                font-style: normal;
                font-weight: ${({ theme }) => theme.fontWeight.fontWeight600};
                line-height: 72px; 
                letter-spacing: -1.2px;
                margin: 20px 0 ;
                @media (max-width:767px) {
                    font-size: ${({ theme }) => theme.fontSizes.font36};
                    line-height: 50px;
                }
            }
            p {
                color:${({ theme }) => theme.colors.light.contentText};
                font-size: ${({ theme }) => theme.fontSizes.font20};
                font-style: normal;
                font-weight: ${({ theme }) => theme.fontWeight.fontWeight400};
                line-height: 30px; 
            }
            .Payment-status p {
                border: 1px solid ${({ theme }) => theme.colors.border.bdrPrimaryClr};
                font-size: ${({ theme }) => theme.fontSizes.font16};
                font-style: normal;
                font-weight: ${({ theme }) => theme.fontWeight.fontWeight500};
                padding: 4px 10px;
                border-radius: 8px;
                display: inline-block;
                margin-bottom: 0;
                .Payment-status-icon {
                    height: 10px;
                    width: 10px;
                    background-color: ${({theme}) => theme.colors.light.greenClr};
                    border: 1px solid transparent;
                    display: inline-block;
                    border-radius: 10px;
                    margin-right: 5px;
                }
                .cancel-icon {
                    background-color: ${({theme}) => theme.colors.light.redBgClr};
                }
            }
            .btn-grp {
                display: flex;
                @media (max-width:767px) {
                    flex-direction: column-reverse;
                }
                .Back-btn {
                    border: 1px solid ${({ theme }) => theme.colors.border.bdrPrimaryClr};
                    font-size: ${({ theme }) => theme.fontSizes.font16};
                    margin-right: 12px;
                    border-radius: 8px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 10px 25px;
                    @media (max-width:767px) {
                       margin-right:0;
                    }
                }
                .primary-btn {
                    width: auto;
                    padding: 10px 25px;
                }
            }
        }
    }
`;