import { Success, generateError } from "../useCommonSerivce";
import { SUBSCRIBERS } from "../../network/restAPI/apiRoutes";
import apiClient from "../../network/restAPI";

export default function useSubscribers() {
  const subscribersUser = async (params) => {
    return new Promise(async (resolve) => {
      try {
        const data = await apiClient.post(SUBSCRIBERS, params);
        resolve(Success(data));
      } catch (error) {
        resolve(generateError(error));
      }
    });
  };

  return {
    subscribersUser,
  };
}
