import styled from "styled-components";

export const HeaderStyled = styled.div`
  .header-wrapper {
    background: rgba(0, 0, 0, 0.34);
    /* background: #4A4B4B; */
    padding: 15px 0;
    max-width: 100%;
    width: 100%;
    display: block;
    transition: all 0.35s ease;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    .navbar-brand {
      cursor: pointer;
    }
    @media (max-width: 1023px) {
      background: rgba(0,0,0,0.64);
      padding: 5px 0;
      -webkit-backdrop-filter: blur(32px);
      backdrop-filter: blur(32px);
      .navbar-brand svg path:not(.icon) {
        fill: ${({ theme }) => theme.colors.light.textwhiteClr};
      }
    }
    &__navigation {
      position: relative;
      padding: 0;
      @media (max-width: 767px) {
        .container {
          flex-direction: row-reverse;
          padding: 0;
          .navbar-brand {
            padding-left: 16px;
          }
        }
      }
      .navbar-nav {
        align-items: center;
        .nav-link {
          color: ${({ theme }) => theme.colors.light.textwhiteClr};
          font-size: ${({ theme }) => theme.fontSizes.font14};
          font-weight: ${({ theme }) => theme.fontWeight.fontWeight600};
          padding: 0 15px;
          transition: all 0.2s ease-in 0s;
          &:hover {
            color: ${({ theme }) => theme.colors.light.lightBlueClr};
          }
          @media (max-width: 1023px) {
            margin-bottom: 10px;
          }
        }
        .active-link {
          color: ${({ theme }) => theme.colors.light.lightBlueClr} !important;
        }
      }
      &__ctaBtn {
        display: flex;
        margin-left: auto;
        align-items: center;
        .quiz-btn {
          background-color: ${({ theme }) => theme.colors.light.blackClr};
          padding: 7px 18px !important;
          border-radius: 20px;
          @media (max-width: 1023px) {
            background-color: transparent;
          }
        }
        @media (max-width: 991px) {
          display: block;
          margin-left: 0;
          padding: 12px 5px 24px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.24);
          width: 100%;
          .quiz-btn {
            padding: 0 15px !important;
          }
        }
      }
      @media (max-width: 1023px) {
        .navbar-nav {
          font-family: var(--primary-font);
          align-items: baseline;
          height: calc(100vh - 5vh);
          margin-top: 10px;
          border-top: 1px solid rgba(255, 255, 255, 0.24);
          padding: 24px 0;
          .nav-link {
            color: #eaecf0;
            font-size: ${({ theme }) => theme.fontSizes.font16};
            font-style: normal;
            font-weight: ${({ theme }) => theme.fontWeight.fontWeight400};
            line-height: 24px;
            padding: 12px 22px;
            width: 100%;
            margin: 0;
          }
        }
        .collapsed {
          border: none;
          margin-right: 16px;
          height: 30px;
          width: 30px;
          .icon-bar {
            background: ${({ theme }) => theme.colors.light.textwhiteClr};
            height: 2px;
            width: 20px;
            position: absolute;
            right: 20px;
            border-radius: 2px;
            border: 1px solid #fff;
            transition: all 0.3s ease-in-out;
          }
          .top-bar {
            top: 13px;
          }
          .bottom-bar {
            margin-top: 8px;
          }
        }
        .navbar-toggler:not(.collapsed) {
          border: none;
          &:before {
            content: '+';
            position: absolute;
            height: 20px;
            width: 20px;
            right: 5px;
            top: -3px;
            color: #EAECF0;
            font-size: 50px;
            transform: rotate(45deg);
          }
        }
        .res-login-btn-grp {
          position: fixed;
          bottom: 10px;
          width: 100%;
          left: 0;
          .nav-link {
            border: 1px solid #fff;
            padding: 12px 18px;
            margin: 0 auto 25px;
            width: 80%;
            text-align: center;
            border-radius: 32px;
            font-size: ${({ theme }) => theme.fontSizes.font16};
            font-weight: ${({ theme }) => theme.fontWeight.fontWeight700};
            color: #eaecf0;
            font-family: var(--primary-font);
            &:first-child {
              background: ${({ theme }) => theme.colors.light.textwhiteClr};
              color: #000;
            }
          }
        }
      }
    }
    @media (max-width: 1023px) { 
      .navigation-bar {
          width: 100%;
          height: calc(100vh - 50px);
          /* -webkit-backdrop-filter: blur(32px);
          backdrop-filter: blur(32px); */
          .profile-navbar {     
            .usermenu-item-link {
                @media (max-width: 1023px) {
                  color: #eaecf0;
                  font-size: ${({ theme }) => theme.fontSizes.font16};
                  font-style: normal;
                  font-weight: ${({ theme }) => theme.fontWeight.fontWeight400};
                  line-height: 24px;
                  padding: 12px 22px;
                  width: 100%;
                  margin: 0;
                  &:nth-child(5) {
                    content: "";
                    border-bottom: 1px solid rgba(255, 255, 255, 0.24);
                  }
                }
                &:hover {
                  @media (max-width: 1023px) { 
                    color: ${({ theme }) => theme.colors.light.lightBlueClr};
                  }
                }
                .usermenu-item-icon {
                  margin-right: 10px;
                  svg {
                    width: 18px;
                    margin-top: -4px;
                    @media (max-width: 1023px) {
                      path {
                        fill: #eaecf0;
                      }
                    }
                  }
                }
                .share-icon-item {
                  svg {
                    width: 15px;
                  }
                }
                span {
                  font-size: ${({ theme }) => theme.fontSizes.font12};
                }
              }
              .active-link {
                color: ${({ theme }) => theme.colors.light.MenuItemClr};
                @media (max-width: 1023px) {
                  color: ${({ theme }) => theme.colors.light.lightBlueClr};
                }
              }
          }
        }
    }
  }
  .res-loggedIn-navBar {
    .header-wrapper__navigation__ctaBtn {
      @media (max-width: 1023px) {
        border-bottom: none !important;
      }
    }
  }
  @media (min-width: 1023px) {
    .fixed-header {
      background: #f2f4f7 !important;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      padding: 5px 0;
      .navbar-nav {
        .nav-link:not(.quiz-btn) {
          color: ${({ theme }) => theme.colors.light.SecondaryClr};
          transition: all 0.2s ease-in 0s;
          &:hover {
            color: ${({ theme }) => theme.colors.light.lightBlueClr};
          }
          &:active,
          &:focus,
          &:focus-visible {
            outline: 0;
            box-shadow: none;
          }
          @media (max-width: 1023px) {
            color: ${({ theme }) => theme.colors.light.textwhiteClr};
          }
        }
      }
      .navbar-brand {
        cursor: pointer;
        svg {
          path:not(.icon) {
            fill: ${({ theme }) => theme.colors.light.SecondaryClr};
          }
        }
      }
    }
  }
  .dropdown-toggle.nav-link {
    position: absolute;
    height: 40px;
    top: 0;
    width: 40px;
    left: 0;
    border-radius: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    margin-top: -40px;
  }
  .dropdown-menu.show {
    left: -13em !important;
    top: 10px;
    padding: 10px 15px;
    border-radius: 20px;
    border: 1px solid var(--gray-200, #eaecf0);
    background: var(--gray-100, #f2f4f7);
    box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12);
    min-width: 250px;
    line-height: 36px;
  }
  .logout-link {
    padding: 12px;
    margin: 0;
    border-radius: 8px;
    border: 1px solid #b2ccff;
    background: ${({ theme }) => theme.colors.light.lightBlueClr1};
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: ${({ theme }) => theme.colors.light.lightBlueText};
    font-size: ${({ theme }) => theme.fontSizes.font14};
    font-style: normal;
    font-weight: ${({ theme }) => theme.fontWeight.fontWeight600};
    line-height: 20px;
    text-align: center;
  }
  .UserMenu-grp {
    order: 3;
    display: flex;
    @media (min-width: 768px) and (max-width: 1023px) {
      width: auto !important;
      margin-right: 6%;
    }
    @media (max-width: 1023px) {
      order: unset;
      display: flex;
      justify-content: space-between;
      width: calc(100% - 13%);
      .navbar-brand svg path:not(.icon) {
        fill: auto;
      }
    }
    @media (min-width: 768px) {
      .navbar-brand svg {
        display: none;
      }
    }
    .dropdown-menu {
      .usermenu-item-link {
        font-size: ${({ theme }) => theme.fontSizes.font14};
        color: ${({ theme }) => theme.colors.light.MenuItemClr1};
        @media (max-width: 1023px) {
          color: #eaecf0;
          font-size: ${({ theme }) => theme.fontSizes.font16};
          font-style: normal;
          font-weight: ${({ theme }) => theme.fontWeight.fontWeight400};
          line-height: 24px;
          padding: 12px 22px;
          width: 100%;
          margin: 0;
          &:nth-child(5) {
            content: "";
            border-bottom: 1px solid rgba(255, 255, 255, 0.24);
          }
        }
        &:hover {
          color: ${({ theme }) => theme.colors.light.MenuItemClr};
          @media (max-width: 1023px) { 
            color: ${({ theme }) => theme.colors.light.lightBlueClr};
          }
        }
        .usermenu-item-icon {
          margin-right: 10px;
          svg {
            width: 18px;
            margin-top: -4px;
            @media (max-width: 1023px) {
              path {
                fill: #eaecf0;
              }
            }
          }
        }
        .share-icon-item {
          svg {
            width: 15px;
          }
        }
        span {
          font-size: ${({ theme }) => theme.fontSizes.font12};
        }
      }
      .active-link {
        color: ${({ theme }) => theme.colors.light.MenuItemClr};
        @media (max-width: 767px) {
          color: ${({ theme }) => theme.colors.light.lightBlueClr};
        }
      }
    }
    .user-profile-item {
      background: #f2f4f7;
      border-radius: 50%;
      text-align: center;
      @media (max-width: 767px) {
        background: transparent;
        margin-top: 4px;
      }
      img {
        height: 45px;
        width: 45px;
        margin: 0 auto;
        border-radius: 50%;
        @media (max-width: 767px) {
          height: 35px;
          width: 35px;
        }
      }
    }
    .user-profile-text {
      background-color: ${({ theme }) => theme.colors.light.lightBlueText};
      height: 35px;
      width: 35px;
      border-radius: 50%;
      font-size: ${({ theme }) => theme.fontSizes.font14};
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${({ theme }) => theme.colors.light.textwhiteClr};
      line-height: 35px;
      @media (max-width: 767px) {
        font-size: 13px;
        height: 30px;
        width: 30px;
        border-radius: 8px;
      }
    }

  }
`;
