import React from "react";
import PropTypes from "prop-types";
import { ButtonStyled } from "./style";
import Loader from "../loader";
import { ArrowIcon } from "../../../assets/icons";

function Button({
  text,
  size,
  variant,
  onClick,
  classes,
  loading,
  loadingMessage,
  children,
  borderbtn,
  icon,
  disabled,
  btnClass,
  ...props
}) {
  return (
    <ButtonStyled
      className={`progressbar ${btnClass}`}
      onClick={loading || disabled ? () => {} : onClick}
      borderbtn={borderbtn ? "true" : "false"}
      disabled={disabled}
      {...props}
      icon={icon}
    >
      {loading ? (
        <Loader
          size="sm"
          dimension="row"
          variant={variant}
          className="spinner-loader"
        />
      ) : (
        <>
          {children}
          {icon ? <ArrowIcon /> : null}
          {text}
        </>
      )}
    </ButtonStyled>
  );
}

Button.propTypes = {
  text: PropTypes.string,
  size: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  loadingMessage: PropTypes.string,
};

Button.defaultProps = {
  text: "Button",
  variant: "primary",
  size: "",
  onClick: () => {},
  classes: "",
  loading: false,
  loadingMessage: "",
};

export default Button;
