import React, { useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../../components/common/button";
import { ROUTES } from "../../../utils/constants/routes";
import { Container, Row } from "react-bootstrap";
import { Logo, PostSignupIcon } from "../../../assets/icons";
import { LABELS, TEXT, MSG } from "../../../utils/constants";
import { WhySignUpStyled } from "../../web/preTravelQuizSection/style";
import useSignup from "../../../services/auth";
import { getEmailForSendVerificationMail } from "../../../utils/utilityFunctions";

const PostSignup = () => {
  const { resendVerificationMail } = useSignup();

  const navigate = useNavigate();
  const data = useLocation();
  const email = getEmailForSendVerificationMail() || data.state;

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!email) {
      return navigate(ROUTES?.home);
    }
  }, [email]);

  const handleClickOnResendMail = () => {
    resendVerificationMail({ email }).then((response) => {
      if (response?.isSuccess) {
        const message = response?.data?.data?.message || MSG.VERIFICATION_EMAIL_SENT
        toast.success(message);
      }
    });
  };
  return (
    <WhySignUpStyled>
      <Container fluid>
        <Row>
          <div className="col-md-6 left-content">
            <div className="px-4 mt-3">
              <Link to="/">
                <Logo />
              </Link>
            </div>
            <div className="Login-content post-signup-grp">
              <div className="why-signup-grp">
                <div className="signup-item text-center">
                  <div className="post-signup-inner">
                    <div>
                      <PostSignupIcon />
                    </div>
                    <p className="content">{TEXT.WE_HAVE_SENT_EMAIL(email)}</p>
                    <p className="description mb-0">
                      {TEXT.EMAIL_NOT_RECEIVED}
                    </p>
                  </div>
                </div>
                <Button
                  onClick={handleClickOnResendMail}
                  text={LABELS.RESEND_EMAIL}
                />
              </div>
            </div>
            <div className="px-4">
              <p> © Ethoventures 2023</p>
            </div>
          </div>
          <div className="col-md-6 signup-bg-img  post-signup-bg"></div>
        </Row>
      </Container>
    </WhySignUpStyled>
  );
};

export default PostSignup;
