/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { TravelQuizInnerBox } from "./style";
import BackButton from "../../../components/common/backbutton";
import { ROUTES } from "../../../utils/constants/routes";
import { ChevLeft } from "../../../assets/icons";
import { Route, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getInvitedUserId } from "../../../utils/utilityFunctions";
import { MSG } from "../../../utils/constants";

const PreTravelQuizHOC = ({ image, label, description, children, path }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userDetails } = useSelector((state) => state.user);
  let isShowBackButton = false;
  let isPersonalityGiven = true;

  if (!userDetails?.personalityType?.name) {
    isPersonalityGiven = false;
  }
  if (
    [
      ROUTES.travelInvite,
      ROUTES.travelDays,
      ROUTES.travelBudget,
      ROUTES?.travelLocation,
      ROUTES?.travelSelect,
      ROUTES?.travelStyle,
    ].includes(location.pathname)
  ) {
    isShowBackButton = true;
  }
  const handleClick = (path) => {
    navigate(path);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    let isShowToast = false;
    let isShowInvitedUser = false;
    if (!isPersonalityGiven) {
      if (!isShowToast) {
        toast.info(MSG?.ON_CREATE_PERSONALITY);
        isShowToast = true;
      }
      return navigate(ROUTES?.personality);
    } else {
      if (getInvitedUserId()) {
        if (!isShowInvitedUser) {
          toast.info(MSG?.ON_COMPLETE_INVITE_FLOW);
          isShowInvitedUser = true;
        }
        return navigate(ROUTES?.home);
      }
    }
  }, [isPersonalityGiven, getInvitedUserId, navigate]);

  return (
    <>
      <TravelQuizInnerBox className="quiz-main-wrapper" image={image}>
        <Container fluid>
          <Row>
            <Col lg={6} md={12} sm={12} className="p-0">
              <div className="quiz-img-wrap">
                <div className="quiz-img-bg"></div>
              </div>
            </Col>
            <Col lg={6} md={12} sm={12} className="p-0">
              {isShowBackButton && <BackButton path={path} />}
              <div className="quiz-mobile-back-btn">
                {isShowBackButton && (
                  <ChevLeft onClick={() => handleClick(path)} />
                )}
              </div>

              <div className="quiz-content-wrap">
                <div
                  className={
                    path?.includes("travel-group")
                      ? "quiz-question res-invite"
                      : "quiz-question"
                  }
                >
                  {label}
                </div>
                <div className="description mb-3">{description}</div>
                <div className="quiz-item-wrap">{children}</div>
              </div>
            </Col>
          </Row>
        </Container>
      </TravelQuizInnerBox>
    </>
  );
};

export default PreTravelQuizHOC;
