import { useFormik } from "formik";
import React from "react";
import { Form } from "react-bootstrap";
import Button from "../../../components/common/button";
import Input from "../../../components/common/input";
import { validate } from "./validation";

const EmailForm = ({ setOpenModal, dataToSend, handleSocialLogin }) => {
  const initialValues = { email: "" };

  const onSubmit = (values, { resetForm, setSubmitting }) => {
    handleSocialLogin(dataToSend, "facebook", values?.email);
    setTimeout(() => {
      setSubmitting(false);
      resetForm(initialValues);
      setOpenModal(false);
    }, 2000);
  };
  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    isSubmitting,
  } = useFormik({
    initialValues,
    onSubmit,
    validate,
  });

  const handleClick = (val) => {
    setOpenModal(val);
  };
  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Input
          placeholder={"Enter your email"}
          type={"email"}
          name="email"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values?.email || ""}
          error={errors.email && touched.email && errors.email}
        />
        <div className="modal-footer-item">
          <Button
            text={"Close"}
            onClick={() => handleClick(false)}
            btnClass={"light-btn"}
            disabled={isSubmitting}
          />
          <Button loading={isSubmitting} type="submit" text={"submit"} />
        </div>
      </Form>
    </div>
  );
};

export default EmailForm;
