import styled from "styled-components";
import { DashboardBanner } from "../../../assets/images";

export const DashboardSection = styled.section`
  .btn {
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    max-width: 298px;
    width: 100%;
    padding: 8px 14px;
    font-family: var(--secondary-font);
    font-size: ${({ theme }) => theme.fontSizes.font14};
    font-style: normal;
    font-weight: ${({ theme }) => theme.fontWeight.fontWeight600};
    line-height: 20px;
    text-transform: capitalize;
    &:hover {
      opacity: 0.8;
    }
  }
  .tr-users-thumb-wrap {
    display: flex;
    align-items: center;
    padding-left: 17px;
    .tr-users-thumb-img {
      width: 39px;
      height: 39px;
      margin-left: -10px;
      img {
        /* width: 100%; */
        width: 39px;
        height: 39px;
        object-fit: cover;
        border: 1px solid #0d6efdbd;
        border-radius: 50%;
      }
      @media (max-width: 767px) {
        width: 32px;
        height: 32px;
      }
    }
    .user-profile-text {
      background-color: ${({ theme }) => theme.colors.light.lightBlueText};
      width: 39px;
      height: 39px;
      border-radius: 50%;
      font-size: ${({ theme }) => theme.fontSizes.font16};
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${({ theme }) => theme.colors.light.textwhiteClr};
      @media (max-width: 767px) {
        font-size: 13px;
      }
    }
    .tr-users-thumb-count {
      font-family: var(--secondary-font);
      line-height: 1.5;
      font-size: 15px;
      font-weight: ${({ theme }) => theme.fontWeight.fontWeight600};
      background: #eee;
      width: 39px;
      height: 39px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 96px;
      border: 1.2px solid ${({ theme }) => theme.colors.light.textwhiteClr};
      color: ${({ theme }) => theme.colors.light.PrimaryThemeClr};
      @media (max-width: 767px) {
        width: 32px;
        height: 32px;
        font-size: ${({ theme }) => theme.fontSizes.font12};
      }
    }
  }
  .tr-page-head-banner {
    background-image: url(${DashboardBanner});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100% 50%;
    width: 100%;
    height: 330px;
    @media (min-width: 768px) and (max-width: 1024px) {
      height: 240px;
    }
    @media (max-width: 767px) {
      display: none;
    }
  }
  .tr-page-top-content-wrap {
    background: ${({ theme }) => theme.colors.light.bgClr1};
    @media (max-width: 767px) {
      background: ${({ theme }) => theme.colors.light.textwhiteClr};
      margin-top: 50px;
    }
  }
  .tr-profile-content-box {
    margin-top: -62.5px;
    padding-bottom: 24px;
    max-width: 100%;
    @media (min-width: 768px) and (max-width: 1299px) {
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
    @media (max-width: 767px) {
      margin: 10px auto 0;
      padding: 16px;
      max-width: 350px;
      text-align: center;
      border-radius: 8px;
      border: 1px solid ${({ theme }) => theme.colors.border.bdrLightClr};
      background: ${({ theme }) => theme.colors.light.textwhiteClr};
      box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    }
    .tr-user-icon {
      border-radius: 150px;
      border: 3px solid ${({ theme }) => theme.colors.light.textwhiteClr};
      background: ${({ theme }) => theme.colors.light.iconBg};
      background-image: ${({ imageurl }) =>
        imageurl ? `url(${imageurl})` : "none"};
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      box-shadow: 0 3px 4.5px -1.5px rgba(16, 24, 40, 0.03),
        0 9px 12px -3px rgba(16, 24, 40, 0.08);
      width: 123px;
      height: 123px;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 767px) {
        margin: 0 auto;
      }
      .tr-user-icon-inner {
        border-radius: 150px;
        border: 0.75px solid rgba(16, 24, 40, 0.08);
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .tr-username {
      display: block;
      color: ${({ theme }) => theme.colors.light.titleClr};
      font-family: var(--secondary-font);
      font-size: ${({ theme }) => theme.fontSizes.font48};
      font-style: normal;
      font-weight: ${({ theme }) => theme.fontWeight.fontWeight700};
      line-height: 60px;
      letter-spacing: -0.96px;
      margin: 50px 0 24px;
      text-transform: capitalize;
      @media (max-width: 767px) {
        font-size: ${({ theme }) => theme.fontSizes.font32};
        line-height: normal;
        margin: 16px 0 5px;
      }
    }
    .tr-username-subheading {
      color: ${({ theme }) => theme.colors.light.headingText};
      font-size: ${({ theme }) => theme.fontSizes.font24};
      font-weight: ${({ theme }) => theme.fontWeight.fontWeight600};
    }

    .tr-usertype {
      color: ${({ theme }) => theme.colors.light.titleClr};
      font-family: var(--secondary-font);
      font-size: 24px;
      font-style: normal;
      font-weight: ${({ theme }) => theme.fontWeight.fontWeight600};
      line-height: 32px;
      display: block;
      @media (max-width: 767px) {
        font-size: ${({ theme }) => theme.fontSizes.font16};
        line-height: normal;
      }
    }

    .tr-userdesc {
      color: ${({ theme }) => theme.colors.light.contentText};
      font-family: var(--secondary-font);
      font-size: ${({ theme }) => theme.fontSizes.font16};
      font-style: normal;
      font-weight: ${({ theme }) => theme.fontWeight.fontWeight400};
      line-height: 28px;
      margin: 32px 0;
      display: block;
      @media (max-width: 767px) {
        margin: 24px 0;
        text-align: left;
        letter-spacing: -0.2px;
        font-size: ${({ theme }) => theme.fontSizes.font14};
        line-height: 24px;
      }
    }

    .btn-light {
      border: 1px solid ${({ theme }) => theme.colors.border.bdrPrimaryClr};
      background: ${({ theme }) => theme.colors.light.textwhiteClr};
      color: ${({ theme }) => theme.colors.light.titleClr};
    }
  }
  .tr-page-bottom-content-wrap {
    display: flex;
    @media (min-width: 768px) and (max-width: 1024px) {
      flex-direction: column;
    }
    @media (max-width: 767px) {
      flex-direction: column;
    }
    aside.tr-sidebar-wrap {
      max-width: 362px;
      width: 100%;
      height: max-content;
      position: -webkit-sticky;
      position: sticky;
      top: 67px;
      @media (min-width: 768px) and (max-width: 1024px) {
        max-width: 100%;
        height: auto;
        position: unset;
      }
      @media (max-width: 767px) {
        max-width: 350px;
        margin: 32px auto;
        padding: 0;
        height: auto;
        position: unset;
      }
    }
    .tr-right-content-wrap {
      width: calc(100% - 362px);
      padding-bottom: 85px;
      @media (min-width: 768px) and (max-width: 1024px) {
        width: 100%;
      }
      @media (max-width: 767px) {
        width: 100%;
        padding-bottom: 0;
      }
    }
    .tr-spinner-loader {
      /* background-color: rgb(174 196 227);*/
      padding: 50px 0 0; 
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
        .text-secondary {
          font-size:14px !important;
          flex:1;
          color: ${({ theme }) => theme.colors.light.PrimaryThemeClr} !important;
        }
        .spinner-border {
            border-color: #0061ea;
        }
    }
  }
  .tr-sidebar-items-wrap {
    margin-top: 3px;
    .tr-sidebar-single-item {
      border-radius: 8px;
      background: ${({ theme }) => theme.colors.light.bgClr1};
      box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
      display: flex;
      width: 100%;
      height: auto;
      // padding: 41px 32px 100px 32px;
      padding: 27px 32px 100px 32px;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      @media (min-width: 768px) and (max-width: 1024px) {
        padding: 45px;
      }
      @media (max-width: 767px) {
        border-radius: 8px;
        border: 1px solid ${({ theme }) => theme.colors.border.bdrLightClr};
        background: ${({ theme }) => theme.colors.light.textwhiteClr};
        box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
        padding: 16px;
        align-items: center;
      }
      .item-title {
        color: ${({ theme }) => theme.colors.light.titleClr};
        font-family: var(--secondary-font);
        font-size: ${({ theme }) => theme.fontSizes.font32};
        font-style: normal;
        font-weight: ${({ theme }) => theme.fontWeight.fontWeight700};
        line-height: normal;
        @media (max-width: 767px) {
          font-size: ${({ theme }) => theme.fontSizes.font20};
        }
      }
      .item-info {
        color: ${({ theme }) => theme.colors.light.titleClr};
        font-family: var(--secondary-font);
        font-size: ${({ theme }) => theme.fontSizes.font16};
        font-style: normal;
        font-weight: ${({ theme }) => theme.fontWeight.fontWeight700};
        line-height: normal;
        display: block;
        margin: 5px 0 24px;
        text-transform: capitalize;
        @media (max-width: 767px) {
          font-weight: ${({ theme }) => theme.fontWeight.fontWeight500};
          margin-bottom: 0;
        }
      }
      .item-desc {
        color: var(--gray-500, #667085);
        font-family: var(--secondary-font);
        font-size: ${({ theme }) => theme.fontSizes.font16};
        font-style: normal;
        font-weight: ${({ theme }) => theme.fontWeight.fontWeight400};
        line-height: 28px;
        @media (max-width: 767px) {
          font-size: ${({ theme }) => theme.fontSizes.font14};
          color: ${({ theme }) => theme.colors.light.contentText};
          line-height: 19px;
          margin: 15px 0;
        }
      }
      .btn {
        position: absolute;
        bottom: 34px;
        left: 0;
        right: 0;
        margin: 0 auto;
        @media (min-width: 768px) and (max-width: 1024px) {
          position: unset;
          margin: 15px 0 0;
        }
        @media (max-width: 767px) {
          position: unset;
        }
      }
      .btn-dark {
        border: 1px solid ${({ theme }) => theme.colors.light.PrimaryThemeClr};
        background: ${({ theme }) => theme.colors.light.PrimaryThemeClr};
        color: ${({ theme }) => theme.colors.light.textwhiteClr};
      }
      .tr-item-mobile-img {
        display: none;
        @media (max-width: 767px) {
          display: block;
          margin: 19px 0;
        }
      }
    }
  }
  .tr-no-itinerary {
    padding: 25px;
    display: block;
    width: 100%;
    text-align: center;
    border-radius: 8px;
    font-size: 18px;
    font-weight: ${({ theme }) => theme.fontWeight.fontWeight600};
    margin-top: 70px;
    svg {
      display: block;
      margin: 15px auto 25px;
    }
  }
  .tr-view-more-btn-area {
    border-radius: 8px;
    text-align: center;
    margin: 10px 0;
    border-bottom: 1px solid #EFF4FF;
    padding-bottom: 25px;
    .view-more {
      background-color: transparent;
      color: ${({ theme }) => theme.colors.light.PrimaryThemeClr};
      border: none;
      border-bottom: 1px solid #0061ea;
      width: auto;
      border-radius: 0;
      padding: 0;
      font-size: 14px;
      margin: 0;
      box-shadow: none;
      &:hover {
        background-color: transparent;
      }
    }
  }
  .tr-cards-item-wrap {
    @media (max-width: 767px) {
      padding: 0 12px;
      .tr-card-single-item.tr-itineraries-card {
        padding: 0;
        border: none;
        .tr-card-title.tr-itineraries-card-title {
          color: #000;
          font-size: 24px;
          font-weight: ${({ theme }) => theme.fontWeight.fontWeight700};
          line-height: 40px;
          letter-spacing: -0.24px;
          text-transform: capitalize;
        }
      }
    }
    .tr-card-single-item {
      display: flex;
      width: 100%;
      padding: 40px 50px;
      flex-direction: column;
      align-items: center;
      background: ${({ theme }) => theme.colors.light.textwhiteClr};
      @media (min-width: 768px) and (max-width: 1024px) {
        padding: 45px;
      }
      @media (max-width: 767px) {
        padding: 24px;
        max-width: 350px;
        width: 100%;
        margin: 0 auto 24px;
        border-radius: 8px;
        border: 1px solid #e6e8ec;
        background: ${({ theme }) => theme.colors.light.textwhiteClr};
        overflow: hidden;
      }
      .tr-item-img-box {
        width: 100%;
        margin-bottom: 50px;
        img {
          width: 100%;
          max-height: 435px;
          object-fit: cover;
        }
        @media (min-width: 768px) and (max-width: 1024px) {
          margin-bottom: 40px;
        }
        @media (max-width: 767px) {
          margin: -24px 0 24px;
          width: calc(100% + 48px);
          height: 240px;
          img {
            height: 100%;
          }
        }
      }
      .tr-card-title-wrap {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 20px;
        @media (max-width: 767px) {
          gap: 16px;
          flex-direction: row-reverse;
        }
        .tr-card-title {
          color: ${({ theme }) => theme.colors.light.titleClr};
          font-family: var(--secondary-font);
          font-size: 36px;
          font-style: normal;
          font-weight: ${({ theme }) => theme.fontWeight.fontWeight700};
          line-height: 44px;
          letter-spacing: -0.72px;
          text-transform: capitalize;
          @media (max-width: 767px) {
            color: var(--neutrals-1, #141416);
            font-size: ${({ theme }) => theme.fontSizes.font16};
            font-weight: ${({ theme }) => theme.fontWeight.fontWeight500};
            line-height: 24px;
            letter-spacing: 0;
          }
        }
      }
      .tr-card-desc {
        color: #777e90;
        font-family: var(--secondary-font);
        font-size: ${({ theme }) => theme.fontSizes.font16};
        font-style: normal;
        font-weight: ${({ theme }) => theme.fontWeight.fontWeight400};
        line-height: 28px;
        margin: 28px 0 16px;
        height: auto;
        overflow-y: auto;
        max-height: 250px;
        @media (max-width: 767px) {
          line-height: 24px;
          margin: 24px 0;
          font-size: ${({ theme }) => theme.fontSizes.font14};
        }
      }
      .tr-card-budget {
        width: 100%;
        color: var(--neutrals-2, #23262f);
        font-family: var(--secondary-font);
        font-size: ${({ theme }) => theme.fontSizes.font18};
        font-style: normal;
        font-weight: ${({ theme }) => theme.fontWeight.fontWeight700};
        line-height: 28px;
        margin-bottom: 32px;
        @media (max-width: 767px) {
          margin-bottom: 24px;
          font-size: ${({ theme }) => theme.fontSizes.font14};
          line-height: 1;
        }
      }
      .tr-content-left {
        width: 100%;
      }
      .btn-sm {
        border: 1px solid ${({ theme }) => theme.colors.border.bdrLightBlue};
        color: ${({ theme }) => theme.colors.light.PrimaryThemeClr};
        background:${({ theme }) => theme.colors.light.lightBlueClr1};
        /* @media (max-width: 767px) {
          border-color: ${({ theme }) => theme.colors.border.bdrPrimaryClr};
          background: ${({ theme }) => theme.colors.light.textwhiteClr};
          color: ${({ theme }) => theme.colors.light.titleClr};
        } */
      }
      .btn-disabled {
        opacity: 0.5;
        cursor: no-drop;
      }
    }
  }
  .tr-itinerary-wrap {
    width: 100%;
    display: block;
    margin-top: 40px;
    @media (max-width: 767px) {
      margin-top: 24px;
    }
    .tr-itinerary-card {
      border-radius: 10px;
      border: 1px solid #e9e9e9;
      background: #fcfcfd;
      overflow: hidden;
      display: inline-block;
      margin-right: 12px;
      margin-bottom: 24px;
      vertical-align: top;
      max-width: 32%;
      width: 100%;
      @media (min-width: 768px) and (max-width: 1024px) {
        max-width: calc(50% - 24px);
      }
      @media (max-width: 767px) {
        margin: 0 0 24px 0;
        max-width: 100%;
        width: 100%;
      }
      @media (min-width: 1200px) and (max-width: 1366px) {
        max-width: 43%;
      }
      .tr-itinerary-card-img-box {
        position: relative;
        height: 240px;
        background-size: cover;
        display: block;

        background-size: cover;
        img {
          height: 100%;
          width: 100%;
        }
        @media (min-width: 768px) and (max-width: 1024px) {
          img {
            width: 100%;
          }
        }
        @media (max-width: 767px) {
          img {
            width: 100%;
          }
        }
        .tr-trip-info {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          color: ${({ theme }) => theme.colors.light.textwhiteClr};
          font-family: var(--secondary-font);
          font-size: ${({ theme }) => theme.fontSizes.font20};
          font-style: normal;
          font-weight: ${({ theme }) => theme.fontWeight.fontWeight600};
          line-height: 12px; /* 60% */
          text-transform: capitalize;
        }
      }
      .card-placeholder {
        background-position: center center;
        background-repeat: no-repeat;
        width: 100%;
      }
      .tr-itinerary-card-body {
        padding: 16px;
        display: flex;
        justify-content: space-between;
        .tr-itinerary-card-title {
          display: block;
          color: #333;
          font-family: var(--secondary-font);
          font-size: ${({ theme }) => theme.fontSizes.font20};
          font-style: normal;
          font-weight: ${({ theme }) => theme.fontWeight.fontWeight600};
          line-height: 24px;
          text-transform: capitalize;
          margin-bottom: 10px;
          max-width: 135px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          @media (max-width: 767px) {
            max-width: 195px;
          }
        }

        .tr-itinerary-card-subtitle {
          display: block;
          color: #333;
          font-family: var(--secondary-font);
          font-size: ${({ theme }) => theme.fontSizes.font14};
          font-style: normal;
          font-weight: 500;
          line-height: 14px; /* 85.714% */
          text-transform: capitalize;
        }
      }
    }
  }
`;
