import React from "react";
import PropTypes from "prop-types";
import { CheckboxButtonStyled } from "./style";

const CheckboxButton = ({
  className,
  value,
  id,
  name,
  onChange,
  checked,
  text,
  radioButtonClass,
  ...props
}) => {
  return (
    <div className={className}>
      <CheckboxButtonStyled
        className={`radio-button ${radioButtonClass}`}
        value={value}
        id={id}
        type="checkbox"
        name={name}
        onChange={onChange}
        checked={checked}
        {...props}
      />
      <label htmlFor={id}>{text}</label>
    </div>
  );
};

CheckboxButton.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  id: PropTypes.string,
  checked: PropTypes.bool,
  text: PropTypes.string,
};

CheckboxButton.defaultProps = {
  className: "",
  value: "",
  checked: false,
  onChange: () => {},
  name: "checkbox",
  text: "",
};

export default CheckboxButton;
