// ============================================================================
// react-toastify
// react toaster configuration
// ============================================================================
export const toast_configuration = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: true,
  newestOnTop: false,
  closeOnClick: false,
  rtl: false,
  pauseOnFocusLoss: false,
  draggable: true,
  pauseOnHover: true,
  theme: "colored",
  limit: 1,
};
