import styled from "styled-components";

export const LoaderStyled = styled.div`
        padding: 0 !important;
        .spinner-border {
        border-color: #fff;
        }
        .text-secondary {
            color: #fff !important;
        }
`;
