import React from "react";
import { Container } from "react-bootstrap";

const ItineraryAwaits = () => {
  return (
    <Container fluid>
      <h1>ItineraryAwaits screen</h1>
    </Container>
  );
};

export default ItineraryAwaits;
