/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Logo } from "../../../assets/icons";
import Button from "../../../components/common/button";
import { InviteUserStyled } from "./style";
import { InvitUserBG } from "../../../assets/images";
import { ROUTES } from "../../../utils/constants/routes";
import Image from "../../../components/common/image";
import {
  setInvitedUserDetails,
  useSetInvitedUserParams,
  removeInvitedUserDetails,
  getUserId,
} from "../../../utils/utilityFunctions";
import useQuiz from "../../../services/quiz";
import { toast } from "react-toastify";
import { MSG } from "../../../utils/constants";
import { deleteAllPersonalityQuesData } from "../../../state/personality/actions";
import PreloadImages from "../../../components/common/preloadImages";

const InvitedUser = () => {
  const navigate = useNavigate();
  const { checkInviteLinkExpire } = useQuiz();
  const { invitedItineraryId, invitedUserId } = useSetInvitedUserParams();
  const userId = getUserId();

  const dispatch = useDispatch();

  const setInviteUserData = () => {
    if (invitedItineraryId && invitedUserId) {
      checkInviteLinkExpire(invitedItineraryId, invitedUserId).then((res) => {
        if (res?.isSuccess) {
          if (!userId) {
            dispatch(deleteAllPersonalityQuesData());
          }
          return setInvitedUserDetails(invitedItineraryId, invitedUserId);
        }
        removeInvitedUserDetails(); // clear invited user data
        navigate(ROUTES?.home);
      });
      return;
    }
    navigate(ROUTES?.home);
  };

  useEffect(() => {
    let useEffectCalled = false;
    if (userId === invitedUserId) {
      if (!useEffectCalled) {
        toast.info(MSG?.ON_USER_ACCESS_TRIPMATE_URL);
        useEffectCalled = true;
      }
      return navigate(ROUTES?.home);
    }
    if (!useEffectCalled) {
      setInviteUserData();
      useEffectCalled = true;
    }
  }, []);

  return (
    <>
      <PreloadImages />
      <InviteUserStyled>
        <Container fluid>
          <Row>
            <div className="col-md-6">
              <div className="px-4 mt-3">
                <Link to="/">
                  <Logo />
                </Link>
              </div>
              <div className="inviteuser-mob">
                <Image src={InvitUserBG} alt="" className="img-fluied" />
              </div>
              <div className="Login-content Form-content">
                <div>
                  <div className="invite-user-grp">
                    <h4 className="text-center">
                      You have been invited on an adventure!
                    </h4>
                    <div className="inner-item">
                      <div className="invite-count">01</div>
                      <h5>Welcome to Ethoventures</h5>
                      <p className="description">
                         We craft personalized travel experiences tailored to your unique identity. Our journey begins with a brief Personality Quiz designed to understand your travel desires.
                      </p>
                    </div>

                    <div className="inner-item">
                      <div className="invite-count">02</div>
                      <h5> What is a personality quiz?</h5>
                      <p className="description">
                         It's a quick assessment that helps us dive into your preferences and create a travel persona that aligns perfectly with your personality.
                      </p>
                    </div>

                    <div className="inner-item">
                      <div className="invite-count">03</div>
                      <h5>What is a travel quiz?</h5>
                      <p className="description">
                          Share your vacation preferences, and we'll blend them seamlessly with your personality to craft your meticulously detailed travel plan.
                      </p>
                    </div>
                  </div>
                  <Button
                    text={"Get started"}
                    onClick={() => navigate(ROUTES?.personalityTest)}
                  />
                  {!userId && (
                    <Button
                      text={"Login"}
                      btnClass="secondrey-btn"
                      onClick={() => navigate(ROUTES?.login)}
                    />
                  )}
                </div>
              </div>
              <div className="px-4">
                <p> © Ethoventures 2023</p>
              </div>
            </div>
            <div className="col-md-6 invite-user"></div>
          </Row>
        </Container>
      </InviteUserStyled>
    </>
  );
};

export default InvitedUser;
