import styled from "styled-components";

export const TermsStyle = styled.div`
  padding-top: 100px;
  .top-header {
    border-bottom: 1px solid ${({ theme }) => theme.colors.border.bdrPrimaryClr};
    margin-bottom: 20px;
    h1 {
      font-size: ${({ theme }) => theme.fontSizes.font52};
      color: ${({ theme }) => theme.colors.light.PrimaryThemeClr};
      text-align: center;
      padding-bottom: 10px;
      font-weight: 600;
      @media (max-width: 767px) {
        font-size: ${({ theme }) => theme.fontSizes.font30};
      }
    }
  }
  .body-content {
    h2 {
      font-size: ${({ theme }) => theme.fontSizes.font28};
      color: ${({ theme }) => theme.colors.light.PrimaryThemeClr};
      text-align: left;
      font-weight: 600;
      @media (max-width: 767px) {
        font-size: ${({ theme }) => theme.fontSizes.font20};
      }
    }
    h4 {
      font-size: 20px;
      text-align: left;
      margin-top: 30px;
      font-weight: 500;
    }
    p {
      font-size: ${({ theme }) => theme.fontSizes.font16};
      line-height: 30px;
    }
    ul {
      padding-left: 50px;
      li {
        font-size: ${({ theme }) => theme.fontSizes.font16};
        line-height: 30px;
        margin-bottom: 20px;
        .count {
          margin-left: -30px;
          position: absolute;
        }
      }
      .inner-list {
        margin-left: 40px;
      }
    }
  }
  a {
    font-size: ${({ theme }) => theme.fontSizes.font16};
    /* text-decoration: none;
        line-height: 30px;
        margin-bottom: 15px; */
    display: inline-block;
    color: ${({ theme }) => theme.colors.light.PrimaryThemeClr};
  }
  .table-bordered {
    td {
      padding: 15px 10px;
      &:first-child {
        font-weight: 600;
      }
    }
  }
`;
