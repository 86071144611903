/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PersonalityForm from "./personalityForm";
import {
  saveCurrentSteps,
  saveUserAnswers,
  savePersonalityQuestions,
} from "../../../state/personality/actions";
import { LABELS } from "../../../utils/constants";
import { ROUTES } from "../../../utils/constants/routes";
import useQuiz from "../../../services/quiz";
import userProfile from "../../../services/auth";
import { MSG } from "../../../utils/constants";
import {
  getInvitedUserId,
  getIsInvitedUserTravelQuizCompleted,
  getItineraryId,
  getRandomImage,
  getUserId,
  storePersonalityId,
  getPersonalityId,
} from "../../../utils/utilityFunctions";
import { QuizInnerBox } from "./formStyle";
import { updateUserDetails } from "../../../state/user/actions";
import { deleteAllTravelQuesData } from "../../../state/travel/actions";
// import { deleteAllPersonalityQuesData } from "../../../state/travel/actions";
import Button from "../../../components/common/button";
import useItinerary from "../../../services/itinerary";
import Modal from "../../../components/common/modals";

const CreatePersonality = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userId = getUserId();
  const { personalityScores, getQuizQuestions } = useQuiz();
  const { updateUserProfile } = userProfile();
  const { userDetails } = useSelector((state) => state.user);
  const { allQuestions, currentSteps, userAnswers } = useSelector(
    (state) => state.personality
  );
  const [currentAns, setCurrentAns] = useState({});
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [quesSubmitLoading, setQuesSubmitLoading] = useState(false);
  const [image, setImage] = useState("");
  const [mobileViewImage, setMobileViewImagetImage] = useState("");
  const [isShowSubmitTestModal, setIsShowSubmitTestModal] = useState(false);
  const [isShowBackModal, setIsShowBackModal] = useState(false);
  const [testPayloadData, setTestPayloadData] = useState({});
  const [isMobile, setIsMobile] = useState(false);
  const [dynamicKey, setDynamicKey] = useState("123");

  const { addTripmates } = useItinerary();
  const invitedItineraryId = getItineraryId();
  const isInvitedUser = getInvitedUserId();
  const isInvitedUserCompletedTravelQuiz =
    getIsInvitedUserTravelQuizCompleted();

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [dynamicKey]);

  useEffect(() => {
    setImage(getRandomImage(null, true));
    setMobileViewImagetImage(getRandomImage(true, true));
    handleStoreAttemptedQues(currentSteps);
    handleFetchQuestions();
  }, []);

  useEffect(() => {
    if (userId) {
      if (userDetails?.personalityType?.name) {
        navigate(ROUTES?.personalityoverview);
        return;
      }
    } else {
      if (getPersonalityId()) {
        navigate(ROUTES?.personalityoverview);
        return;
      }
    }
  }, [navigate]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (containerRef.current?.scrollTop) {
  //       containerRef.current.scrollTop = 0;
  //     }
  //   }, 100)
  // }, [dynamicKey]);

  const handleFetchQuestions = () => {
    if (allQuestions.length) return;
    setIsShowLoading(true);
    getQuizQuestions().then((response) => {
      setIsShowLoading(false);
      if (response?.isSuccess) {
        dispatch(savePersonalityQuestions(response?.data?.data?.questions));
      } else {
        toast.error(MSG.ON_ERROR);
      }
    });
  };

  const handleStoreAttemptedQues = (stepNum) => {
    window.scrollTo(0, 0);
    if (userAnswers?.length) {
      const data = userAnswers.find(
        (obj) => obj?.questionId === allQuestions[stepNum]?._id
      );
      if (data?.optionId) {
        setCurrentAns({ _id: data.optionId });
      }
    }
    setImage(getRandomImage());
    setMobileViewImagetImage(getRandomImage(true));
  };

  const handleBackButton = () => {
    if (currentSteps === 0) {
      setIsShowBackModal(true);
      return;
    }
    setCurrentAns({});
    if (currentSteps > 0) {
      handleStoreAttemptedQues(currentSteps - 1);
      dispatch(saveCurrentSteps(currentSteps - 1));
    } else {
      setIsShowBackModal(true);
      // navigate(ROUTES?.personality);
    }
  };

  const handleSubmitPersonalityTest = (lastQue) => {
    // setQuesSubmitLoading(true);
    let allAns = [...userAnswers];
    const itemIndex = allAns?.findIndex(
      (item) => item.questionId === lastQue.questionId
    );
    if (itemIndex !== -1) {
      const updatedAns = [
        ...allAns.slice(0, itemIndex),
        lastQue,
        ...allAns.slice(itemIndex + 1),
      ];
      allAns = [...updatedAns];
    } else {
      allAns.push(lastQue);
    }
    const payloadData = {
      questionDetails: allAns,
    };

    setTestPayloadData(payloadData);
    setIsShowSubmitTestModal(true);

    // personalityScores(payloadData).then((response) => {
    //   if (response?.isSuccess) {
    //     toast.success(MSG.PERSONALITY_CREATED);
    //     const data = {
    //       personalityType: { ...response?.data?.data },
    //     };
    //     if (!userId) {
    //       // if (isInvitedUser) {
    //       //   setIsInvitedUserTravelQuizCompleted(false);
    //       // }
    //       storePersonalityId(response?.data?.data?._id);
    //     }
    //     // add trimate api call
    //     if (isInvitedUser && userId) {
    //       const dataToSend = {
    //         tripMateId: userId,
    //       };
    //       addTripmates(invitedItineraryId, isInvitedUser, dataToSend);
    //     }
    //     dispatch(updateUserDetails(data));
    //     const payload = {
    //       personalityType: response?.data?.data?._id,
    //     };
    //     handleUpdateUserDetails(payload);
    //   } else {
    //     setQuesSubmitLoading(false);
    //     navigate(ROUTES?.personality);
    //   }
    // });
  };

  const handleUpdateUserDetails = (payload) => {
    if (userId !== null) {
      updateUserProfile(userId, payload).then((response) => {
        setQuesSubmitLoading(false);
        if (response?.isSuccess) {
          // dispatch(deleteAllPersonalityQuesData());
          navigate(ROUTES?.personalitySuccess);
        }
      });
    } else {
      setQuesSubmitLoading(false);
      // dispatch(deleteAllPersonalityQuesData());
      navigate(ROUTES?.personalitySuccess);
    }
  };

  const handleNextButton = (currentAnsData) => {
    let data = { ...currentAnsData };
    if (!currentAnsData?._id) {
      data = { ...currentAns };
    }
    if (data?._id) {
      if (currentSteps < allQuestions.length) {
        const submitData = {
          questionId: allQuestions[currentSteps]?._id,
          optionId: data?._id,
        };
        dispatch(saveUserAnswers(submitData));
        if (currentSteps + 1 === allQuestions.length) {
          handleSubmitPersonalityTest(submitData);
        } else {
          setCurrentAns({});
          handleStoreAttemptedQues(currentSteps + 1);
          dispatch(saveCurrentSteps(currentSteps + 1));
        }
      }
    }
  };

  const getData = (data) => {
    setCurrentAns(data);
    if (isMobile && allQuestions?.length !== currentSteps + 1) {
      setTimeout(() => {
        setDynamicKey(new Date().getTime());
        handleNextButton(data);
      }, 1000);
    }
  };

  const handleTestSubmitConfirmation = (val) => {
    dispatch(deleteAllTravelQuesData());
    setIsShowSubmitTestModal(false);
    if (val) {
      setQuesSubmitLoading(true);
      personalityScores(testPayloadData).then((response) => {
        if (response?.isSuccess) {
          toast.success(MSG.PERSONALITY_CREATED);
          const data = {
            personalityType: { ...response?.data?.data },
          };
          if (!userId) {
            storePersonalityId(response?.data?.data?._id);
          }
          // add trimate api call
          if (isInvitedUser && userId) {
            const dataToSend = {
              tripMateId: userId,
            };
            addTripmates(invitedItineraryId, isInvitedUser, dataToSend);
          }
          dispatch(updateUserDetails(data));
          const payload = {
            personalityType: response?.data?.data?._id,
          };
          handleUpdateUserDetails(payload);
        } else {
          setQuesSubmitLoading(false);
          navigate(ROUTES?.personality);
        }
      });
    }
  };

  const handlePopupBackConfirmation = (val) => {
    setIsShowBackModal(false);
    if (val) {
      return navigate(ROUTES?.personality);
    }
  };
  return (
    <QuizInnerBox>
      <PersonalityForm
        questionData={allQuestions[currentSteps]}
        getData={getData}
        selected={currentAns}
        totalQuestions={allQuestions?.length}
        currentSteps={currentSteps}
        image={image}
        mobileViewImage={mobileViewImage}
        isShowLoading={isShowLoading}
        handleBackButton={handleBackButton}
        isMobile={isMobile}
      />
      <div className="step-form-footer">
        <div className={`step-form-footer-inner`}>
          <Button
            onClick={handleBackButton}
            className="step-btn step-btn-back"
            text={currentSteps === 0 ? LABELS.CANCEL : LABELS.BACK}
          />
          <Button
            onClick={handleNextButton}
            className="step-btn step-btn-next"
            disabled={!currentAns?._id || quesSubmitLoading}
            loading={quesSubmitLoading}
            text={
              allQuestions?.length === currentSteps + 1
                ? LABELS.SUBMIT
                : LABELS.NEXT
            }
          />
        </div>
      </div>
      <Modal
        showModal={isShowSubmitTestModal}
        handlePopupResponse={(val) => handleTestSubmitConfirmation(val)}
        contentText={MSG.SUBMIT_QUIZ}
        modalSize="md"
        isTravelTestModal={true}
        primaryButtonText={LABELS.SUBMIT}
        secondaryButtonText={LABELS.CANCEL}
      />
      <Modal
        showModal={isShowBackModal}
        handlePopupResponse={(val) => handlePopupBackConfirmation(val)}
        contentText="You answer won't be saved. Do you wish to continue?"
        modalSize="md"
        isTravelTestModal={true}
        primaryButtonText={LABELS.CONTINUE}
        secondaryButtonText={LABELS.CANCEL}
      />
    </QuizInnerBox>
  );
};

export default CreatePersonality;
