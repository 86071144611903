import React, { useEffect, useState } from "react";
import RadioButton from "../../common/radiobutton";

const RadioButtonHOC = ({
  data,
  name = "radio",
  selectedItem,
  uid = "unique",
  getSelected,
  isGusetMode,
}) => {
  const [selected, setSelected] = useState(
    selectedItem ? { ...selectedItem } : null
  );

  useEffect(() => {
    if (!selectedItem) return;
    setSelected(selectedItem ? { ...selectedItem } : null);
  }, [selectedItem]);

  const handleChange = (e, option) => {
    setSelected(option);
    if (getSelected) getSelected(option);
  };

  const renderOptions = (option, index) => {
    return (
      <label
        key={`${uid}-radiobutton-${index}-${option} `}
        className={`${
          selected?._id === option?._id
            ? "quiz-single-item active"
            : "quiz-single-item"
        } ${
          isGusetMode && option?.value === "group" && "quiz-single-item-disable"
        }`}
        htmlFor={option?._id}
      >
        <div className="radio-ui-box">
          <RadioButton
            heading={option?.heading || ""}
            text={option?.text || ""}
            name={name}
            value={option?.label}
            onChange={(e) => handleChange(e, option)}
            checked={selected?._id === option?._id}
            id={option?._id || ""}
            disabled={option?.value === "group" && isGusetMode}
            className="radio-ui-box-inner"
          />
          <label htmlFor={option?._id} className="radio-label-ui">
            {option?.label}
          </label>
        </div>
      </label>
    );
  };

  return (
    <div>
      {Array.isArray(data) && data?.length ? (
        data?.map(renderOptions)
      ) : (
        <p>No data</p>
      )}{" "}
    </div>
  );
};

export default RadioButtonHOC;
