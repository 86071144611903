import { createStore, applyMiddleware, combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import personalityReducer from "./personality/reducers";
import userReducer from "./user/reducers";
import travelReducer from "./travel/reducers";

const rootReducer = combineReducers({
  personality: personalityReducer,
  user: userReducer,
  travel: travelReducer,
});

const persistConfig = {
  key: "root1.107",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

const persistor = persistStore(store);

export { store, persistor };
