import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../components/common/backbutton";
import Button from "../../../components/common/button";
import Loader from "../../../components/common/loader";
import useItinerary from "../../../services/itinerary";
import {
  savePaidItineraryDetails,
  saveUnpaidItineraryDetails,
} from "../../../state/travel/actions";
import { TEXT } from "../../../utils/constants";
import { ROUTES } from "../../../utils/constants/routes";
import {
  getUserId,
  handleRedirectionToQuizPage,
} from "../../../utils/utilityFunctions";
import PaidItinerary from "./paidItinerary";
import { ItinerarySection, ItineraryWrapper } from "./style";
import UnpaidItinerary from "./unpaidItinerary";
const ViewItinerary = () => {
  const navigate = useNavigate();
  const { paidItinerary, unpaidItinerary } = useSelector(
    (state) => state.travel
  );
  const userId = getUserId();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { itineraryDetails } = useItinerary();
  const fetchData = async () => {
    try {
      const paidRes = await itineraryDetails(userId, "paid");
      const unpaidRes = await itineraryDetails(userId, "unpaid");

      if (paidRes?.isSuccess) {
        dispatch(savePaidItineraryDetails(paidRes?.data?.data?.itineraries));
      } else {
        navigate(ROUTES?.dashboard);
      }

      if (unpaidRes?.isSuccess) {
        dispatch(
          saveUnpaidItineraryDetails(unpaidRes?.data?.data?.itineraries)
        );
      } else {
        navigate(ROUTES?.dashboard);
      }
    } catch (error) {
      navigate(ROUTES?.dashboard);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    fetchData();
    setLoading(false);
    const intervalId = setInterval(fetchData, 10000);
    return () => clearInterval(intervalId);
  }, [userId]);
  if (loading) {
    return (
      <div className="web-spinner-loader">
        <Loader
          size="lg"
          dimension="row"
          message={TEXT?.ON_LOAD_ITINERARY}
          variant={"secondary"}
        />
      </div>
    );
  }
  if (paidItinerary?.length === 0 && unpaidItinerary?.length === 0) {
    return (
      <ItineraryWrapper>
        <Container>
          <Row>
            <Col md="6" className="offset-md-3 text-center">
              <h1 className="text-secondary text-center fs-5 p-3">
                Looks like you haven't created any travel plans yet. Try our
                quiz to get a personalized plan catered to your unique
                personality.
              </h1>
              <Button
                onClick={() => handleRedirectionToQuizPage(navigate)}
                text={"Take the quiz"}
                style={{ width: "auto" }}
              />
            </Col>
          </Row>
        </Container>
      </ItineraryWrapper>
    );
  }
  return (
    <ItinerarySection>
      <section className="tr-cards-item-wrap">
        <Container>
          <Row>
            <Col>
              <ItineraryWrapper>
                <BackButton
                  path={ROUTES?.dashboard}
                  text="Back"
                  btnClass="Back-btn"
                />
              </ItineraryWrapper>
              <div className="tr-card-title">
                <h1> Itineraries </h1>
              </div>
              <div className="tr-card-single-item tr-itineraries-card p-0">
                <div className="tr-card-title-wrap">
                  {unpaidItinerary?.length > 0 && (
                    <span className="tr-card-title tr-itineraries-card-title">
                      In Progress
                    </span>
                  )}
                </div>
                <div className="tr-itinerary-wrap">
                  <UnpaidItinerary unpaidItinerary={unpaidItinerary} />
                </div>
              </div>

              <div className="tr-card-single-item tr-itineraries-card p-0">
                <div className="tr-card-title-wrap">
                  {paidItinerary?.length > 0 && (
                    <span className="tr-card-title tr-itineraries-card-title">
                      My Itineraries
                    </span>
                  )}
                </div>
                <div className="tr-itinerary-wrap">
                  <PaidItinerary paidItinerary={paidItinerary} />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </ItinerarySection>
  );
};

export default ViewItinerary;
