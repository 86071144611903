import { Success, generateError } from "../useCommonSerivce";
import {
  FACEBOOK_LOGIN,
  FORGET_PASSWORD,
  FORGOT_PASSWORD_RESET,
  GOOGLE_LOGIN,
  APPLE_LOGIN,
  USER_LOGIN,
  USER_SIGNUP,
  VERIFY_EMAIL,
  VERIFY_OTP,
  USER_PROFILE,
  RESEND_VERIFICATION_EMAIL,
} from "../../network/restAPI/apiRoutes";
import apiClient from "../../network/restAPI";
import { toast } from "react-toastify";
import {
  MSG,
  USER_ID,
  USER_TOKEN,
  EMAIL_FOR_SEND_VERFICATION_MAIL,
} from "../../utils/constants";
import useUserData from "../user";
import { useDispatch } from "react-redux";
import { setUserLogin } from "../../state/user/actions";
import {
  getInvitedUserId,
  getIsInvitedUserTravelQuizCompleted,
  getItineraryId,
  getPersonalityId,
  removeInvitedUserDetails,
} from "../../utils/utilityFunctions";
import useItinerary from "../itinerary";
import {
  deleteAllTravelQuesData,
  savePaidItineraryDetails,
  saveUnpaidItineraryDetails,
} from "../../state/travel/actions";

export default function useSignup() {
  const dispatch = useDispatch();
  const isPersonalityId = getPersonalityId();
  const { addTripmates, itineraryDetails } = useItinerary();
  const { getUser } = useUserData();

  const isInvitedUser = getInvitedUserId();
  const invitedItineraryId = getItineraryId();
  const isInvitedUserCompletedTravelQuiz =
    getIsInvitedUserTravelQuizCompleted();

  const signupUser = async (params) => {
    return new Promise(async (resolve) => {
      try {
        const data = await apiClient.post(USER_SIGNUP, params);
        resolve(Success(data));
      } catch (error) {
        resolve(generateError(error));
      }
    });
  };

  const verifyUser = async (params) => {
    return new Promise(async (resolve) => {
      try {
        const data = await apiClient.get(VERIFY_EMAIL, params);
        resolve(Success(data));
      } catch (error) {
        resolve(generateError(error));
      }
    });
  };

  const handleLoginSuccess = (data, resolve) => {
    localStorage.setItem(USER_TOKEN, data?.data?.response?.token);
    localStorage.setItem(USER_ID, data?.data?.response?.id);
    dispatch(setUserLogin(data?.data?.response?.isFirstLogin));
    dispatch(deleteAllTravelQuesData());
    localStorage.removeItem(EMAIL_FOR_SEND_VERFICATION_MAIL);
    itineraryDetails(data?.data?.response?.id, "paid").then((res) => {
      if (res?.isSuccess) {
        dispatch(savePaidItineraryDetails(res?.data?.data?.itineraries));
      } else {
        dispatch(savePaidItineraryDetails([]));
      }
    });
    itineraryDetails(data?.data?.response?.id, "unpaid").then((res) => {
      if (res?.isSuccess) {
        dispatch(saveUnpaidItineraryDetails(res?.data?.data?.itineraries));
      } else {
        dispatch(saveUnpaidItineraryDetails([]));
      }
    });
    getUser(data?.data?.response?.id).then((res) => {
      //=======================================================================
      // call addTripmate api
      if (
        isInvitedUser &&
        !isInvitedUserCompletedTravelQuiz &&
        (isPersonalityId || res?.data?.personalityType?._id)
      ) {
        const dataToSend = {
          tripMateId: data?.data?.response?.id,
        };
        addTripmates(invitedItineraryId, isInvitedUser, dataToSend).then(
          (response) => {
            if (response?.isSuccess) {
              return toast.success(MSG?.ON_ADD_TRIPMATE);
            }
            removeInvitedUserDetails();
          }
        );
      }
      //=======================================================================
    });
    resolve(Success(data));
  };

  const loginUser = async (params) => {
    return new Promise(async (resolve) => {
      try {
        const data = await apiClient.post(USER_LOGIN, params);
        if (data?.data?.response) {
          handleLoginSuccess(data, resolve);
        }
      } catch (error) {
        resolve(generateError(error));
      }
    });
  };

  const socialLogin = async (token, provider, email) => {
    let URL = GOOGLE_LOGIN;
    if (provider === "facebook") {
      URL = FACEBOOK_LOGIN;
    } else if (provider === "apple") {
      URL = APPLE_LOGIN;
    }
    return new Promise(async (resolve) => {
      const payload = { accesstoken: token };
      if (provider === "facebook" && email) {
        payload.email = email;
      }
      if (isPersonalityId) {
        payload.personalityId = isPersonalityId;
      }
      try {
        const data = await apiClient.post(URL, payload);
        if (data?.data?.response === "Verification email sent successfully") {
          resolve(generateError(data));
          return toast.info(data?.data?.response);
        } else {
          handleLoginSuccess(data, resolve);
        }
      } catch (error) {
        resolve(generateError(error));
      }
    });
  };

  const forgetPassword = async (params) => {
    return new Promise(async (resolve) => {
      try {
        const data = await apiClient.post(FORGET_PASSWORD, params);
        resolve(Success(data));
      } catch (error) {
        resolve(generateError(error));
      }
    });
  };

  const verifyOtpEmail = async (params) => {
    return new Promise(async (resolve) => {
      try {
        const data = await apiClient.patch(VERIFY_OTP, params);
        resolve(Success(data));
      } catch (error) {
        resolve(generateError(error));
      }
    });
  };

  const forgotPasswordReset = async (params) => {
    return new Promise(async (resolve) => {
      try {
        const data = await apiClient.patch(FORGOT_PASSWORD_RESET, params);
        resolve(Success(data));
      } catch (error) {
        resolve(generateError(error));
      }
    });
  };

  const updateUserProfile = async (id, params) => {
    return new Promise(async (resolve) => {
      try {
        const data = await apiClient.put(`${USER_PROFILE}/${id}`, params);
        resolve(Success(data));
      } catch (error) {
        resolve(generateError(error));
      }
    });
  };

  const resendVerificationMail = async (params) => {
    return new Promise(async (resolve) => {
      try {
        const data = await apiClient.post(RESEND_VERIFICATION_EMAIL, params);
        resolve(Success(data));
      } catch (error) {
        resolve(generateError(error));
      }
    });
  };

  return {
    signupUser,
    verifyUser,
    loginUser,
    forgetPassword,
    verifyOtpEmail,
    socialLogin,
    forgotPasswordReset,
    updateUserProfile,
    resendVerificationMail,
  };
}
