import React, { useState } from "react";
import { D12 } from "../../../assets/images";
import PreTravelQuizHOC from ".";
import { LABELS, TEXT } from "../../../utils/constants";
import { ROUTES } from "../../../utils/constants/routes";
import Button from "../../../components/common/button";
import { useDispatch, useSelector } from "react-redux";
import { savePreTravelQuizData } from "../../../state/travel/actions";
import { useNavigate } from "react-router-dom";
import { getUserId } from "../../../utils/utilityFunctions";
import Image from "../../../components/common/image";

const SelectDays = () => {
  const userId = getUserId();
  const { preTravelQuizData } = useSelector((state) => state?.travel);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [days, setDays] = useState(preTravelQuizData?.travelDays || 1);
  const handleIncrease = () => {
    setDays(days + 1);
  };
  const handleDecrease = () => {
    days > 1 ? setDays(days - 1) : setDays(1);
  };
  const handleContinue = () => {
    dispatch(savePreTravelQuizData({ travelDays: days }));
    navigate(ROUTES?.travelBudget);
  };

  return (
    <>
      <PreTravelQuizHOC
        label={LABELS.SELECT_DAYS}
        image={D12}
        path={
          userId
            ? preTravelQuizData?.travelStyle?.value === "solo"
              ? ROUTES?.travelStyle
              : ROUTES?.travelSelect
            : ROUTES?.travelStyle
        }
      >
        <div className="quiz-mobile-content-wrap">
          <Image src={D12} alt="" className="quiz-mob-img" />
        </div>
        <div className="description quiz-mobile-description">
          {TEXT.ON_SELECT_DAYS}
        </div>
        <div className="quiz-copy-option align-items-center mt-4">
          <p className="mb-0"> Days </p>
          <div className="quiz-numbers">
            <button disabled={days === 1} onClick={handleDecrease}>
              <div className="quiz-numbers-item">
                {" "}
                <span> - </span>{" "}
              </div>
            </button>
            <span className="quiz-number-text">{days}</span>
            <button onClick={handleIncrease}>
              <div className="quiz-numbers-item">
                {" "}
                <span> + </span>{" "}
              </div>
            </button>
          </div>
        </div>
        {days > 10 && (
          <div style={{ marginTop: 20 }}>
            <strong>Note: </strong>
            {TEXT.SELECT_DAYS_NOTE}
          </div>
        )}
        <Button onClick={handleContinue} text={"Continue"} />
      </PreTravelQuizHOC>
    </>
  );
};

export default SelectDays;
