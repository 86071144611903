import apiClient from "../network/restAPI";

export const Success = (data, ...rest) => {
  return { isSuccess: true, data, message: "success", ...rest };
};

export const Error = (message, data) => {
  return { isSuccess: false, data, message };
};

export const generateError = (error) => {
  if (error?.response?.data) {
    return Promise.resolve(
      Error(error?.response?.data?.message, error?.response?.data?.error)
    );
  } else {
    return Promise.resolve(Error(error, null));
  }
};

export default function useCommonSerivce() {
  const getService = (url, params) => {
    return new Promise(async (resolve, reject) => {
      try {
        const data = await apiClient.get(url, { params });
        resolve(Success(data?.message, data?.data));
      } catch (error) {
        reject(Error(error, null));
      }
    });
  };

  const postService = (url, params) => {
    return new Promise(async (resolve, reject) => {
      try {
        const data = await apiClient.post(url, { params });
        resolve(Success("successfull", data?.data));
      } catch (error) {
        //reject(Error(error, null));
      }
    });
  };

  return { getService, postService };
}
