import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PreTravelQuizHOC from "..";
import { D18 } from "../../../../assets/images";
import Button from "../../../../components/common/button";
import Input from "../../../../components/common/input";
import { savePreTravelQuizData } from "../../../../state/travel/actions";
import { LABELS, TEXT } from "../../../../utils/constants";
import { ROUTES } from "../../../../utils/constants/routes";
import Image from "../../../../components/common/image";
import "./slider.scss";
import { MSG } from "../../../../utils/constants";

const TravelBudget = () => {
  const { preTravelQuizData } = useSelector((state) => state?.travel);

  // const [values, setValues] = useState(
  //   preTravelQuizData?.travelBudget || [500, 1800]
  // );
  const [miniumBudget, setMiniumBudget] = useState(
    preTravelQuizData?.travelBudget?.[0] || 100
  );
  const [maximumBudget, setMaximumBudget] = useState(
    preTravelQuizData?.travelBudget?.[1]
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleValidation = () => {
    if (miniumBudget < 100) {
      toast.info(MSG.MINIMUM_BUDGET_WARNING);
      return;
    } else if (miniumBudget > maximumBudget) {
      toast.info(MSG.MAXIMUM_BUDGET_WARNING);
      return;
    }
    handleContinue();
  };
  const handleContinue = () => {
    dispatch(
      savePreTravelQuizData({
        travelBudget: [miniumBudget, maximumBudget],
        travelCost: `$${miniumBudget}-$${maximumBudget}`, // this key use in api payload
      })
    );
    navigate(ROUTES?.travelLocation);
  };

  // const handleChange = (data) => {
  //   const result = [];
  //   result[0] = roundToNearestValue(data[0]);
  //   result[1] = roundToNearestValue(data[1]);
  //   setValues(result);
  // };
  return (
    <>
      <PreTravelQuizHOC
        label={LABELS.SELECT_BUDGET}
        description={TEXT?.ON_SELECT_BUDGET}
        image={D18}
        path={ROUTES?.travelDays}
      >
        <div className="quiz-mobile-content-wrap">
          <Image src={D18} alt="" className="quiz-mob-img" />
          {/* <h4 className="quiz-question">{LABELS.PRICE_RANGE}</h4> */}
          <div className="quiz-mobile-description mb-3">
            {TEXT?.ON_SELECT_BUDGET}
          </div>
        </div>
        {/* <div className="quiz-copy-option">{`$${values[0]}-$${values[1]}`}</div>
        <ReactSlider
          className="horizontal-slider"
          thumbClassName="example-thumb"
          trackClassName="example-track"
          defaultValue={preTravelQuizData?.travelBudget || [500, 1800]}
          ariaLabel={["Lower thumb", "Upper thumb"]}
          ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
          renderThumb={(props, state) => <div {...props}></div>}
          minDistance={100}
          min={500}
          max={20000}
          pearling
          onChange={handleChange}
        /> */}
        <Input
          id="miniumBudget"
          label="Minimum Budget"
          name="miniumBudget"
          value={miniumBudget}
          type="number"
          placeholder="100"
          onChange={(e) => setMiniumBudget(parseInt(e?.target?.value))}
        />
        <Input
          id="maximumBudget"
          label="Maximum Budget"
          name="maximumBudget"
          value={maximumBudget}
          type="number"
          placeholder="20000"
          onChange={(e) => setMaximumBudget(parseInt(e?.target?.value))}
        />
        <Button
          text={"Continue"}
          onClick={handleValidation}
          disabled={!(miniumBudget && maximumBudget)}
        />
      </PreTravelQuizHOC>
    </>
  );
};

export default TravelBudget;
