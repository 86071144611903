import React, { useEffect, useState } from "react";
import { Logo } from "../../../assets/icons";
import SignUpForm from "./signupform";
import { Link, useNavigate } from "react-router-dom";
import useSignup from "../../../services/auth";
import { MSG } from "../../../utils/constants";
import { toast } from "react-toastify";
import AllAuth from "../../../components/common/auth";
import { Container, Row } from "react-bootstrap";
import { ROUTES } from "../../../utils/constants/routes";
import { isUserExist } from "../../../utils/utilityFunctions";

const SignUp = () => {
  const { socialLogin } = useSignup();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const getAccessToken = (data, provider) => {
    return data?.[provider === "google" ? "access_token" : "accessToken"];
  };

  const handleLoginSuccess = (data, provider) => {
    if (getAccessToken(data, provider)) {
      socialLogin(getAccessToken(data, provider), provider)
        .then((response) => {
          if (response?.isSuccess) {
            toast.success(MSG?.ON_LOGIN_SUCCESS);
            if (response?.data?.data?.response?.isFirstLogin) {
              setLoading(false);
              return setTimeout(() => {
                navigate(ROUTES?.personality);
              }, 1000);
            }
            setTimeout(() => {
              navigate(ROUTES?.dashboard);
            }, 1000);
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
    setLoading(false);
  };

  const handleLoginStart = () => {
    setLoading(true);
  };

  const handleLoginError = (error) => {
    setLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    isUserExist(navigate);
  }, []);
  return (
    <Container fluid>
      <Row>
        <div className="col-md-6 Form-content left-content">
          <div className="px-4 mt-3">
            <Link to="/">
              <Logo />
            </Link>
          </div>
          <div className="Form-content__signUp">
            <h1> Unlock your personal Travel Profile </h1>

            <div className="Form-content__GoogleLogin mt-4">
              <AllAuth
                handleLoginStart={handleLoginStart}
                handleLoginSuccess={handleLoginSuccess}
                handleLoginError={handleLoginError}
              />
            </div>
            <div>
              <SignUpForm loadingSocialSignup={loading} />
            </div>
          </div>
          <div className="px-4">
            <p> © Ethoventures 2023</p>
          </div>
        </div>
        <div className="col-md-6 SignUp-wrapper"></div>
      </Row>
    </Container>
  );
};

export default SignUp;
