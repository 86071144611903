import React from "react";
import { ItinerarysliderInner } from "./style";
import { Col, Row } from "react-bootstrap";
import { ChevLeft } from "../../../assets/icons";

import Image from "../../../components/common/image";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../utils/constants/routes";
import { getInProgressImages } from "../../../utils/utilityFunctions";

const Itineraryslider = () => {
  const navigate = useNavigate();
  return (
    <ItinerarysliderInner>
      <div className="tr-slider-wrapper">
        <div className="tr-slider-info-area">
          <div className="tr-slider-back-mob">
            <ChevLeft onClick={() => navigate(ROUTES?.dashboard)} />
          </div>
          <span className="tr-slider-title">Your group trip</span>
        </div>
        <div className="tr-itinerary-slider-box">
          <Row>
            <Col>
              <div className="container">
                <div className="slider-img-box slider-img-box--mob">
                  <Image
                    src={getInProgressImages()}
                    alt="Loading.."
                    width={"100%"}
                    className="img-fluid"
                    isHideName={true}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </ItinerarysliderInner>
  );
};

export default Itineraryslider;
