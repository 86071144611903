import { Success, generateError } from "../useCommonSerivce";
import apiClient from "../../network/restAPI";
import { USER_PROFILE } from "../../network/restAPI/apiRoutes";
import { FILE_UPLOAD } from "../../network/restAPI/apiRoutes";
import axios from "axios";
export default function useProfile() {
  const updateUserProfile = async (id, params) => {
    return new Promise(async (resolve) => {
      try {
        const data = await apiClient.put(`${USER_PROFILE}/${id}`, params);
        resolve(Success(data));
      } catch (error) {
        resolve(generateError(error));
      }
    });
  };

  const getUserDetails = async (id) => {
    return new Promise(async (resolve) => {
      try {
        const data = await apiClient.get(`${USER_PROFILE}/${id}`);
        resolve(Success(data));
      } catch (error) {
        resolve(generateError(error));
      }
    });
  };
  const uploadSignedImage = async (params) => {
    return new Promise(async (resolve) => {
      try {
        const data = await apiClient.post(FILE_UPLOAD, params);
        resolve(Success(data));
      } catch (error) {
        resolve(generateError(error));
      }
    });
  };
  const updateImage = async (url, params) => {
    return new Promise(async (resolve) => {
      try {
        const formData = new FormData();
        formData.append("file", params);
        const data = await axios.put(url, params);
        resolve(Success(data));
      } catch (error) {
        resolve(generateError(error));
      }
    });
  };

  return {
    updateUserProfile,
    getUserDetails,
    updateImage,
    uploadSignedImage,
  };
}
