import styled from "styled-components";

export const ItinerarysliderInner = styled.div`
  .tr-slider-wrapper {
    margin-top: 30px;
    padding: 0;
    @media (max-width: 767px) {
      margin-top: 0;
      .tr-slider-info-area {
        display: flex;
        gap: 12px;
      }
    }
    .tr-slider-back-mob {
      display: none;
      @media (max-width: 767px) {
        display: block;
      }
    }
    .tr-slider-title {
      color: ${({ theme }) => theme.colors.light.titleClr};
      font-family: var(--secondary-font);
      font-size: ${({ theme }) => theme.fontSizes.font48};
      font-style: normal;
      font-weight: 700;
      line-height: 60px;
      letter-spacing: -0.96px;
      @media (max-width: 767px) {
        font-size: ${({ theme }) => theme.fontSizes.font32};
        font-style: normal;
        font-weight: 600;
        line-height: 44px;
        letter-spacing: -0.64px;
      }
    }
    .tr-itinerary-slider-box {
      margin: 50px 0 80px;
      @media (max-width: 767px) {
        margin: 32px 0 50px;
      }
      .slick-prev,
      .slick-next {
        top: auto;
        bottom: -113px;
        width: 48px;
        height: 48px;
        @media (max-width: 767px) {
          display: none !important;
        }
      }
      .slick-prev {
        left: auto;
        right: 63px;
      }
      .slick-next {
        right: 0;
      }
      .slick-list {
        height: 450px;
        @media (min-width: 768px) and (max-width: 1024px) {
          height: 420px;
        }
        @media (max-width: 767px) {
          height: 405px;
        }
        .slick-slide {
          margin-left: 32px;
          @media (max-width: 767px) {
            margin-left: 8px;
          }
          .slider-img-box {
            @media (max-width: 767px) {
              display: none;
            }
            img {
              width: 100%;
            }
          }
          .slider-img-box.slider-img-box--mob {
            display: none;
            @media (max-width: 767px) {
              display: block;
            }
            img {
              width: 100%;
            }
          }
        }
      }
      .slick-dots {
        text-align: left;
        bottom: -75px;
        width: auto;
        @media (max-width: 767px) {
          display: none !important;
        }
        li {
          width: 8px;
          height: 8px;
          margin: 0 4px;
          vertical-align: middle;
          &:first-child {
            margin-left: 0;
          }
          button {
            width: 8px;
            height: 8px;
            &:before {
              width: 8px;
              height: 8px;
              line-height: 1;
              opacity: 1;
              color: #b2ccff;
            }
          }
        }
        li.slick-active {
          button {
            &:before {
              opacity: 1;
              color: ${({ theme }) => theme.colors.light.PrimaryThemeClr};
            }
          }
        }
      }
    }
  }
`;

export const ItineraryWrapper = styled.div`
  padding: 50px 0;
  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 80px 30px;
  }
  .Back-btn {
    color: ${({ theme }) => theme.colors.light.lightBlueText};
    display: flex;
    align-items: center;
    margin-top: 0;
    svg {
      path {
        stroke: ${({ theme }) => theme.colors.light.lightBlueText};
      }
    }
    @media (max-width: 767px) {
      display: none;
    }
  }
`;

export const ItineraryInprogressInner = styled.div`
  /* padding: 80px 0; */
  @media (max-width: 767px) {
    padding: 24px 20px;
  }
  .tr-itinerary-inprogress-wrap {
    text-align: center;
    .tr-itinerary-inprogress-title {
      color: ${({ theme }) => theme.colors.light.titleClr};
      text-align: center;
      font-family: var(--secondary-font);
      font-size: ${({ theme }) => theme.fontSizes.font48};
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      @media (max-width: 767px) {
        font-size: ${({ theme }) => theme.fontSizes.font32};
        letter-spacing: -0.32px;
        padding-bottom: 24px;
        border-bottom: 1px solid
          ${({ theme }) => theme.colors.border.bdrLightClr2};
        width: 100%;
        display: block;
        text-align: left;
      }
    }
    .progressbar {
      width: auto;
      margin-bottom: 45px;
    }
    .tr-copy-input-wrap {
      margin: 20px auto 30px;
      max-width: 450px;
      text-align: left;
      .tr-copy-input-inner {
        border: 1px solid #ddd;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        border-radius: 8px;
        input {
          background-color: #fff;
          border: 0;
          width: 100%;
          margin-right: 20px;
          &:active,
          &:focus {
            box-shadow: none;
            outline: none;
          }
        }
        span {
          cursor: pointer;
        }
      }
      label {
        color: var(--gray-700, #344054);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 7px;
      }
    }
    .tr-itinerary-inprogress-card {
      border-radius: 8px;
      border: 1px solid var(--gray-200, #eaecf0);
      background: ${({ theme }) => theme.colors.light.bgClr1};
      padding: 32px;
      position: relative;
      margin-bottom: 24px;
      @media (min-width: 768px) and (max-width: 1024px) {
        padding: 16px;
      }
      .tr-label {
        display: inline-flex;
        padding: 2px 8px;
        justify-content: center;
        align-items: center;
        border-radius: 16px;
        text-align: center;
        font-family: var(--secondary-font);
        font-size: ${({ theme }) => theme.fontSizes.font12};
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        position: absolute;
        right: 32px;
        top: auto;
        @media (min-width: 768px) and (max-width: 1024px) {
          right: 16px;
        }
        @media (max-width: 767px) {
          right: 16px;
        }
      }
      .tr-label-sent {
        background: var(--primary-50, #f9f5ff);
        color: var(--primary-700, #6941c6);
      }
      .tr-label-finished {
        background: var(--success-200, #abefc6);
        color: var(--success-600, #079455);
      }
      .tr-user-info-area {
        display: flex;
        gap: 17px;
        .tr-user-img {
          width: 64px;
          height: 64px;
          border-radius: 4px;
          img {
            width: 64px;
            height: 64px;
            object-fit: cover;
            border-radius: 6px;
            border: 2px solid rgb(13, 110, 253, 0.41);
          }
        }
        .tr-user-info {
          text-align: left;
          .tr-username {
            display: block;
            color: var(--gray-900, #101828);
            font-family: var(--secondary-font);
            font-size: ${({ theme }) => theme.fontSizes.font18};
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
            text-transform: capitalize;
            max-width: 135px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
          .tr-user-location {
            color: var(--gray-900, #101828);
            font-family: var(--secondary-font);
            font-size: ${({ theme }) => theme.fontSizes.font12};
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
            max-width: 135px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            display: inline-block;
          }
        }
      }
      .user-profile-text {
        background-color: ${({ theme }) => theme.colors.light.lightBlueText};
        height: 64px;
        width: 64px;
        border-radius: 6px;
        font-size: ${({ theme }) => theme.fontSizes.font18};
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${({ theme }) => theme.colors.light.textwhiteClr};
        @media (max-width: 767px) {
          font-size: 13px;
        }
      }
      .tr-user-desc {
        color: ${({ theme }) => theme.colors.light.DescClr};
        font-family: var(--secondary-font);
        font-size: ${({ theme }) => theme.fontSizes.font16};
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-top: 16px;
        text-align: justify;
        overflow: hidden;
        padding-right: 6px;
        -webkit-line-clamp: 8;
        -webkit-box-orient: vertical;
        display: -webkit-box;
      }
      @media (max-width: 767px) {
        padding: 16px;
        margin-bottom: 48px;
      }
    }
  }
`;
