import { useDeferredValue, useState } from "react";
import { useDispatch } from "react-redux";
import { Success, generateError } from "../useCommonSerivce";
import apiClient from "../../network/restAPI";
import { USER_DETAIL } from "../../network/restAPI/apiRoutes";
import { updateUserDetails, clearUserDetails } from "../../state/user/actions";
import {
  removePersonalityId,
  getPersonalityId,
  storePersonalityId
} from "../../utils/utilityFunctions";

export default function useUserData() {
  const [isLoading, setIsLoading] = useState(false);
  const isLoadingDeffered = useDeferredValue(isLoading);
  const dispatch = useDispatch();

  const getUser = async (id, params) => {
    return new Promise(async (resolve) => {
      try {
        setIsLoading(true);
        const { data } = await apiClient.get(USER_DETAIL(id));
        if (data.hasOwnProperty("_id")) {
          dispatch(clearUserDetails());
        }
        // if (data?.personalityType?._id && getPersonalityId()) {
        //   removePersonalityId();
        // }
        if (data?.personalityType?._id) {
          storePersonalityId(data.personalityType._id)
        }
        setIsLoading(false);
        resolve(Success(data));
        dispatch(updateUserDetails(data));
      } catch (error) {
        setIsLoading(false);
        resolve(generateError(error));
      }
    });
  };

  return {
    getUser,
    isLoading: isLoadingDeffered,
  };
}
