import styled from "styled-components";

export const ButtonStyled = styled.button`
    color: ${({ borderbtn, theme }) =>
    borderbtn ? "" : theme.colors.light.lightBlue};
    width: 100%;
    padding: 10px 12px;
    border-radius: 8px;
    margin-top: 20px;
    color: ${({ theme }) => theme.colors.light.textwhiteClr};
    background-color: ${({ theme }) => theme.colors.light.PrimaryThemeClr};
    border: 1px solid ${({ theme }) => theme.colors.border.bdrPrimaryClr};
    font-weight: ${({ theme }) => theme.fontWeight.fontWeight600};
  &:hover {
    background-color: #0077ffe6;
  }
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
  cursor: ${({ disabled }) => (disabled ? "no-drop" : "pointer")};
  text-transform: capitalize;
  .light-blue-btn {
        background-color:${({ theme }) => theme.colors.light.lightBlueClr1};
        border: 1px solid ${({ theme }) => theme.colors.border.bdrLightBlue};
        color: ${({ theme }) => theme.colors.light.PrimaryThemeClr};
        font-weight: ${({ theme }) => theme.fontWeight.fontWeight700};
  }
`;
