// =================================================
// export your images here
// =================================================
export const demoListItem = require("./demo_list_item.png");
export const LoginBg = require("./login-bg.png");
export const SignUpBg = require("./signup-bg.png");
export const CTABg = require("./CTA-bg.png");
export const BannerSection = require("./hero/Herosection.png");
export const BannerBgRes = require("./banner-bg-res.png");
export const FeatureSection = require("./featureImg.png");
export const FeatureImg1 = require("./feature1.png");
export const FeatureImg2 = require("./feature2.png");
export const AboutBg = require("./About.png");
export const ArrowIcon = require("./arrowIcon.png");
export const SlideIcon1 = require("./slide1.png");
export const SlideIcon2 = require("./slide2.png");
export const SlideIcon3 = require("./slide3.png");
export const SlideIcon4 = require("./slide4.png");
export const SlideIcon5 = require("./slide5.png");
export const SlideIcon6 = require("./slide6.png");
export const cardLayer = require("./cards.png");
export const TestimonialImg = require("./testimonial.png");
export const Quizimg = require("./quiz-img.png");
export const Quizmobimg = require("./quiz-mob-img.png");
export const ExplainerBanner = require("./explainer-banner.png");
export const ExplainermobBanner = require("./explainer-mob-banner.png");
export const DashboardBanner = require("./dashboard-banner-lg.jpeg");
export const ItineraryHero = require("./itinerary-hero-img.png");
export const EditProfileBanner = require("./edit-profile-banner.jpeg");
export const loaderBg = require("./loader-bg.png");
export const BannerSlide1 = require("./hero/bannerSlide1.png");
export const BannerSlide2 = require("./hero/bannerSlide2.png");
export const BannerSlide3 = require("./hero/bannerSlide3.png");
export const BannerSlide4 = require("./hero/bannerSlide4.png");
export const BannerSlide5 = require("./hero/bannerSlide5.png");
export const BannerSlideDesk1 = require("./hero/bannerSlide11.png");
export const BannerSlideDesk2 = require("./hero/bannerSlide21.png");
export const BannerSlideDesk3 = require("./hero/bannerSlide31.png");
export const BannerSlideDesk4 = require("./hero/bannerSlide41.png");
export const BannerSlideDesk5 = require("./hero/bannerSlide51.png");
export const SidebarmobImg = require("./sidebar-mob-img.png");
export const TravelStyleImg = require("./travelStyle.png");
export const GroupTravel = require("./groupTravel.png");
export const D12 = require("./d12.png");
export const D18 = require("./d18.png");
export const perOverviewBg = require("./per-bg.png");
export const PaymentCompleted = require("./payment-complet.png");
export const PaymentCancelBg = require("./payment-bg.png");
export const ItiSliderHero = require("./itinerary-slider-hero-img.png");
export const ItiSliderMobHero = require("./slider-img-mob.png");
export const UserProfile = require("./user-profile.png");
export const TravelLocation = require("./unsplash_KTdzeb28jyo.png");
export const WhySignupBg = require("./why-sginup.png");
export const InvitUserBG = require("./invite-user.png");
export const placeholderIcon = require("./Loader.gif");
export const UserIconPlace = require("./profile-image.jpg");
export const ActivityIcon = require("./activityIcon.png");
export const RestaurantsIcon = require("./restaurantIcon.png");
export const AccommodationIcon = require("./accommodationIcon.png");
export const FrameBg = require("./Frame-bg.png");
export const PageNot = require("./pagenot.png");
export const ItinerariesPageNot = require("./Itineraries-page-not.png");
export const AccountUserIcon1 = require("./AccountUser.png");
export const AccountUserIcon2 = require("./PreferencesIcon.png");
export const AccountUserIcon3 = require("./AccountUser3.png");
export const PostSignupBg = require("./postSignup.png");
/*
:: Usage ::
-----------------------------
<div>
    <img src={nikeShoes} alt="" />
</div>
-----------------------------
*/
