import React from "react";
import { Container, Row } from "react-bootstrap";
import { TermsStyle } from "./style";

const TermsAndConditions = () => {
  return (
    <TermsStyle>
      <Container>
        <Row>
          <div className="top-header">
            <h1> Terms And Conditions </h1>
          </div>

          <div className="body-content">
            <h2> Introduction </h2>

            <p>
              {" "}
              In order to request your data to be deleted, please contact as{" "}
              <a href="mailto:admin@ethoventures.com">admin@ethoventures.com </a>{" "}
            </p>

            <p>
              {" "}
              These terms and conditions apply between you, the User of this
              Website (including any sub-domains, unless expressly excluded by
              their own terms and conditions), and Ethoventures, the owner and
              operator of this Website. Please read these terms and conditions
              carefully, as they affect your legal rights. Your agreement to
              comply with and be bound by these terms and conditions is deemed
              to occur upon your first use of the Website. If you do not agree
              to be bound by these terms and conditions, you should stop using
              the Website immediately.
            </p>

            <p>
              In these terms and conditions, User or Users means any third party
              that accesses the Website and is not either (i) employed by
              Ethoventures and acting in the course of their employment or (ii)
              engaged as a consultant or otherwise providing services to
              Ethoventures and accessing the Website in connection with the
              provision of such services.
            </p>

            <p>
              You must be at least 18 years of age to use this Website. By using
              the Website and agreeing to these terms and conditions, you
              represent and warrant that you are at least 18 years of age.
            </p>

            <h2>Intellectual property and acceptable use</h2>

            <ul className="mt-3">
              <li>
                {" "}
                <span className="count">1.</span>All Content included on the
                Website, unless uploaded by Users, is the property of
                Ethoventures, our affiliates or other relevant third parties. In
                these terms and conditions, Content means any text, graphics,
                images, audio, video, software, data compilations, page layout,
                underlying code and software and any other form of information
                capable of being stored in a computer that appears on or forms
                part of this Website, including any such content uploaded by
                Users. By continuing to use the Website you acknowledge that
                such Content is protected by copyright, trademarks, database
                rights and other intellectual property rights. Nothing on this
                site shall be construed as granting, by implication, estoppel,
                or otherwise, any license or right to use any trademark, logo or
                service mark displayed on the site without the owner’s prior
                written permission
              </li>

              <li>
                <span className="count">2.</span>You must not otherwise
                reproduce, modify, copy, distribute or use for commercial
                purposes any Content without the written permission of
                Ethoventures.
              </li>
            </ul>

            <h2> Prohibited use </h2>

            <ul>
              {" "}
              <li>
                <span className="count">3.</span> You may not use the Website
                for any of the following purposes:{" "}
              </li>
            </ul>

            <ul className="inner-list">
              <li>
                <span className="count">1.</span> in any way which causes, or
                may cause, damage to the Website or interferes with any other
                person’s use or enjoyment of the Website;
              </li>
              <li>
                <span className="count">2.</span> in any way which is harmful,
                unlawful, illegal, abusive, harassing, threatening or otherwise
                objectionable or in breach of any applicable law, regulation,
                governmental order;
              </li>
              <li>
                {" "}
                <span className="count">3.</span> making, transmitting or
                storing electronic copies of Content protected by copyright
                without the permission of the owner.
              </li>
            </ul>

            <h2>Registration</h2>
            <ul>
              <li>
                {" "}
                <span className="count"> 4.</span> You must ensure that the
                details provided by you on registration or at any time are
                correct and complete.
              </li>

              <li>
                {" "}
                <span className="count"> 5.</span> You must inform us
                immediately of any changes to the information that you provide
                when registering by updating your personal details to ensure we
                can communicate with you effectively.
              </li>

              <li>
                {" "}
                <span className="count"> 6.</span> We may suspend or cancel your
                registration with immediate effect for any reasonable purposes
                or if you breach these terms and conditions.
              </li>

              <li>
                {" "}
                <span className="count"> 7.</span> You may cancel your
                registration at any time by informing us in writing to the
                address at the end of these terms and conditions. If you do so,
                you must immediately stop using the Website. Cancellation or
                suspension of your registration does not affect any statutory
                rights.
              </li>
            </ul>

            <h2> Links to other websites </h2>

            <ul>
              <li>
                <span className="count"> 8.</span>This Website may contain links
                to other sites. Unless expressly stated, these sites are not
                under the control of Ethoventures or that of our affiliates.
              </li>
              <li>
                <span className="count"> 9.</span>We assume no responsibility
                for the content of such Websites and disclaim liability for any
                and all forms of loss or damage arising out of the use of them.{" "}
              </li>

              <li>
                <span className="count">10.</span>The inclusion of a link to
                another site on this Website does not imply any endorsement of
                the sites themselves or of those in control of them.
              </li>
            </ul>

            <h2> Privacy Policy and Cookies Policy </h2>
            <ul>
              <li>
                <span className="count">11.</span>Use of the Website is also
                governed by our Privacy Policy and Cookies Policy, which are
                incorporated into these terms and conditions by this reference.
                To view the Privacy Policy, please click on the following:{" "}
                <a href="https://ethoventures.com/terms">
                  https://ethoventures.com/terms
                </a>{" "}
                to see it.
              </li>
            </ul>

            <h2> Availability of the Website and disclaimers </h2>

            <ul>
              <li>
                <span className="count"> 12.</span>Any online facilities, tools,
                services or information that Ethoventures makes available
                through the Website (the Service) is provided “as is” and on an
                “as available” basis. We give no warranty that the Service will
                be free of defects and/or faults. To the maximum extent
                permitted by the law, we provide no warranties (express or
                implied) of fitness for a particular purpose, accuracy of
                information, compatibility and satisfactory quality.
                Ethoventures is under no obligation to update information on the
                Website.
              </li>
              <li>
                <span className="count">13.</span>Whilst Ethoventures uses
                reasonable endeavours to ensure that the Website is secure and
                free of errors, viruses and other malware, we give no warranty
                or guaranty in that regard and all Users take responsibility for
                their own security, that of their personal details and their
                computers.
              </li>
              <li>
                <span className="count">14.</span> Ethoventures accepts no
                liability for any disruption or non-availability of the Website.
              </li>
              <li>
                <span className="count">15.</span> Ethoventures reserves the
                right to alter, suspend or discontinue any part (or the whole
                of) the Website including, but not limited to, any products
                and/or services available. These terms and conditions shall
                continue to apply to any modified version of the Website unless
                it is expressly stated otherwise.
              </li>
            </ul>

            <h2>Limitation of liability</h2>
            <ul>
              <li>
                <span className="count">16.</span> Nothing in these terms and
                conditions will: (a) limit or exclude our or your liability for
                death or personal injury resulting from our or your negligence,
                as applicable; (b) limit or exclude our or your liability for
                fraud or fraudulent misrepresentation; or (c) limit or exclude
                any of our or your liabilities in any way that is not permitted
                under applicable law.
              </li>

              <li>
                <span className="count">17.</span> To the extent that the
                Website and Content are provided free of charge, we will not be
                liable to you for any loss or damage of any kind.
              </li>

              <li>
                <span className="count">18.</span> We will not be liable to you
                in respect of any losses arising out of events beyond our
                reasonable control.
              </li>

              <li>
                <span className="count">19.</span> To the maximum extent
                permitted by law, Ethoventures accepts no liability for any of
                the following:
                <ul className="mt-3">
                  <li>
                    <span className="count">1.</span> any business losses, such
                    as loss of profits, income, revenue, anticipated savings,
                    business, contracts, goodwill or commercial opportunities;
                  </li>

                  <li>
                    <span className="count">2.</span>loss or corruption of any
                    data, database or software;
                  </li>
                  <li>
                    <span className="count">3.</span> any special, indirect or
                    consequential loss or damage.
                  </li>
                </ul>
              </li>
            </ul>
            <h2>General</h2>
            <ul>
              <li>
                {" "}
                <span className="count">20.</span> You may not transfer any of
                your rights under these terms and conditions to any other
                person. We may transfer our rights under these terms and
                conditions where we reasonably believe your rights will not be
                affected.
              </li>
              <li>
                {" "}
                <span className="count">21.</span> These terms and conditions
                may be varied by us from time to time. Such revised terms will
                apply to the Website from the date of publication. Users should
                check the terms and conditions regularly to ensure familiarity
                with the then current version.
              </li>
              <li>
                {" "}
                <span className="count">22.</span> These terms and conditions
                together with the Privacy Policy contain the whole agreement
                between the parties relating to its subject matter and supersede
                all prior discussions, arrangements or agreements that might
                have taken place in relation to the terms and conditions.
              </li>
              <li>
                {" "}
                <span className="count">23.</span> The Contracts (Rights of
                Third Parties) Act 1999 shall not apply to these terms and
                conditions and no third party will have any right to enforce or
                rely on any provision of these terms and conditions.
              </li>
              <li>
                {" "}
                <span className="count">24.</span> If any court or competent
                authority finds that any provision of these terms and conditions
                (or part of any provision) is invalid, illegal or unenforceable,
                that provision or part-provision will, to the extent required,
                be deemed to be deleted, and the validity and enforceability of
                the other provisions of these terms and conditions will not be
                affected.
              </li>
              <li>
                {" "}
                <span className="count">25.</span> Unless otherwise agreed, no
                delay, act or omission by a party in exercising any right or
                remedy will be deemed a waiver of that, or any other, right or
                remedy.
              </li>
              <li>
                {" "}
                <span className="count">26.</span> This Agreement shall be
                governed by and interpreted according to the law of England and
                Wales and all disputes arising under the Agreement (including
                non-contractual disputes or claims) shall be subject to the
                exclusive jurisdiction of the English and Welsh courts.
              </li>
            </ul>

            <h2>Ethoventures details</h2>
            <p>
              <span className="count">27.</span>Ethoventures is a company
              incorporated in Canada with registered number 12927993 whose
              registered address is 12688 Highway 118, Haliburton ON K0M 1S0,
              Canada and it operates the Website{" "}
              <a href="https://ethoventures.com.">https://ethoventures.com.</a>
            </p>

            <p>
              You can contact Ethoventures by email on{" "}
              <a href="mailto:admin@ethoventures.com">admin@ethoventures.com</a>
            </p>

            <h2>Attribution </h2>

            <p>
              These terms and conditions were created using a document from
              Rocket Lawyer{" "}
              <a href="https://www.rocketlawyer.co.uk/">
                (https://www.rocketlawyer.co.uk/).
              </a>
            </p>
          </div>
        </Row>

        <Row className="py-3">
          <div className="top-header">
            <h1> Privacy Policy </h1>
          </div>

          <div className="body-content">
            <p>
              {" "}
              This privacy policy applies between you, the User of this Website
              and Ethoventures, the owner and provider of this Website.
              Ethoventures takes the privacy of your information very seriously.
              This privacy policy applies to our use of any and all Data
              collected by us or provided by you in relation to your use of the
              Website.{" "}
            </p>
            <p>
              {" "}
              This privacy policy should be read alongside, and in addition to,
              our Terms and Conditions, which can be found at:{" "}
              <a href="https://ethoventures.com/terms ">
                https://ethoventures.com/terms{" "}
              </a>
            </p>

            <p>Please read this privacy policy carefully.</p>

            <h2>Definitions and interpretation</h2>

            <ul>
              <li>
                <span className="count">1.</span>In this privacy policy, the
                following definitions are used:
              </li>
            </ul>

            <table className="table-bordered table">
              <tbody>
                <tr>
                  <td> Data </td>
                  <td>
                    Collectively all information that you submit to Ethoventures
                    via the Website. This definition incorporates, where
                    applicable, the definitions provided in the Data Protection
                    Laws;
                  </td>
                </tr>
                <tr>
                  <td> Cookies </td>
                  <td>
                    a small text file placed on your computer by this Website
                    when you visit certain parts of the Website and/or when you
                    use certain features of the Website. Details of the cookies
                    used by this Website are set out in the clause below
                    (Cookies)
                  </td>
                </tr>
                <tr>
                  <td> Data Protection Laws </td>
                  <td>
                    any applicable law relating to the processing of personal
                    Data, including but not limited to the Directive 96/46/EC
                    (Data Protection Directive) or the GDPR, and any national
                    implementing laws, regulations and secondary legislation,
                    for as long as the GDPR is effective in the UK;
                  </td>
                </tr>
                <tr>
                  <td> GDPR </td>
                  <td>the General Data Protection Regulation (EU) 2016/679;</td>
                </tr>
                <tr>
                  <td> Ethoventures, or us </td>
                  <td>
                    Ethoventures, a company incorporated in Canada with
                    registered number 12927993 whose registered address is 12688
                    Highway 118, Haliburton ON K0M 1S0, Canada
                  </td>
                </tr>
                <tr>
                  <td> UK and EU Cookie Law </td>
                  <td>
                    {" "}
                    the Privacy and Electronic Communications (EC Directive)
                    Regulations 2003 as amended by the Privacy and Electronxic
                    Communications (EC Directive) (Amendment) Regulations 2011;
                  </td>
                </tr>
                <tr>
                  <td> User or you </td>
                  <td>
                    any third party that accesses the Website and is not either
                    (i) employed by Ethoventures and acting in the course of
                    their employment or (ii) engaged as a consultant or
                    otherwise providing services to Ethoventures and accessing
                    the Website in connection with the provision of such
                    services; and
                  </td>
                </tr>
                <tr>
                  <td> Website </td>
                  <td>
                    the website that you are currently using,{" "}
                    <a href="https://ethoventures.com">
                      https://ethoventures.com
                    </a>
                    , and any sub-domains of this site unless expressly excluded
                    by their own terms and conditions.
                  </td>
                </tr>
              </tbody>
            </table>

            <ul>
              <li>
                <span className="count">2. </span> In this privacy policy,
                unless the context requires a different interpretation:
                <ul>
                  <li>
                    <span className="count">1.</span> the singular includes the
                    plural and vice versa;
                  </li>
                  <li>
                    <span className="count">2.</span> references to sub-clauses,
                    clauses, schedules or appendices are to sub-clauses,
                    clauses, schedules or appendices of this privacy policy;
                  </li>
                  <li>
                    <span className="count">3.</span> a reference to a person
                    includes firms, companies, government entities, trusts and
                    partnerships;
                  </li>
                  <li>
                    <span className="count">4.</span> “including” is understood
                    to mean “including without limitation”;
                  </li>
                  <li>
                    <span className="count">5.</span> reference to any statutory
                    provision includes any modification or amendment of it;
                  </li>
                  <li>
                    <span className="count">6.</span> the headings and
                    sub-headings do not form part of this privacy policy.
                  </li>
                </ul>
              </li>
            </ul>

            <h2>Scope of this privacy policy</h2>

            <ul>
              <li>
                {" "}
                3. This privacy policy applies only to the actions of
                Ethoventures and Users with respect to this Website. It does not
                extend to any websites that can be accessed from this Website
                including, but not limited to, any links we may provide to
                social media websites.
              </li>
              <li>
                4. For purposes of the applicable Data Protection Laws,
                Ethoventures is the “data controller”. This means that
                Ethoventures determines the purposes for which, and the manner
                in which, your Data is processed.
              </li>
            </ul>

            <h2>Data collected</h2>

            <ul>
              <li>
                <span className="count">5. </span> We may collect the following
                Data, which includes personal Data, from you:
                <ul>
                  <li>
                    <span className="count">a.</span> name;
                  </li>
                  <li>
                    <span className="count">b.</span> gender;
                  </li>
                  <li>
                    <span className="count">c.</span> Profession;
                  </li>
                  <li>
                    <span className="count">d.</span> Date of birth;
                  </li>
                  <li>
                    <span className="count">e.</span> contact Information such
                    as email addresses and telephone numbers;
                  </li>
                  <li>
                    <span className="count">f.</span> IP address (automatically
                    collected);
                  </li>
                  <li>
                    <span className="count">g.</span> web browser type and
                    version (automatically collected);
                  </li>
                  <li>
                    <span className="count">h.</span> operating system
                    (automatically collected);
                  </li>
                  <li>
                    <span className="count">i.</span> a list of URLs starting
                    with a referring site, your activity on this Website, and
                    the site you exit to (automatically collected);
                  </li>
                  <li>
                    <span className="count">j.</span>in each case, in accordance
                    with this privacy policy.
                  </li>
                </ul>
              </li>
            </ul>

            <h2>How we collect Data</h2>

            <ul>
              <li>
                <span className="count">6. </span> We collect Data in the
                following ways:
                <ul>
                  <li>
                    <span className="count">a.</span> data is given to us by
                    you; and
                  </li>
                  <li>
                    <span className="count">b.</span> data is collected
                    automatically.
                  </li>
                </ul>
              </li>
            </ul>

            <h2>Data that is given to us by you</h2>
            <ul>
              <li>
                <span className="count">7. </span> Ethoventures will collect
                your Data in a number of ways, for example:
                <ul>
                  <li>
                    <span className="count">a.</span> when you contact us
                    through the Website, by telephone, post, e-mail or through
                    any other means;
                  </li>
                  <li>
                    <span className="count">b.</span> when you register with us
                    and set up an account to receive our products/services;
                  </li>
                  <li>
                    <span className="count">c.</span> when you complete surveys
                    that we use for research purposes (although you are not
                    obliged to respond to them);
                  </li>
                  <li>
                    <span className="count">d.</span> when you enter a
                    competition or promotion through a social media channel;
                  </li>
                  <li>
                    <span className="count">e.</span> when you make payments to
                    us, through this Website or otherwise;
                  </li>
                  <li>
                    <span className="count">f.</span> when you elect to receive
                    marketing communications from us;
                  </li>
                  <li>
                    <span className="count">g.</span> when you use our services;
                  </li>
                </ul>
              </li>
            </ul>
            <p>in each case, in accordance with this privacy policy.</p>

            <h2>Data that is collected automatically</h2>
            <ul>
              <li>
                <span className="count">8. </span> To the extent that you access
                the Website, we will collect your Data automatically, for
                example:
                <ul>
                  <li>
                    <span className="count">a.</span> we automatically collect
                    some information about your visit to the Website. This
                    information helps us to make improvements to Website content
                    and navigation, and includes your IP address, the date,
                    times and frequency with which you access the Website and
                    the way you use and interact with its content.
                  </li>
                  <li>
                    <span className="count">b.</span> we will collect your Data
                    automatically via cookies, in line with the cookie settings
                    on your browser.
                  </li>
                </ul>
              </li>
            </ul>

            <h2>Our use of Data</h2>
            <ul>
              <li>
                <span className="count">9. </span> Any or all of the above Data
                may be required by us from time to time in order to provide you
                with the best possible service and experience when using our
                Website.Specifically, Data may be used by us for the following
                reasons:
                <ul>
                  <li>
                    <span className="count">a.</span> internal record keeping;
                  </li>
                  <li>
                    <span className="count">b.</span> improvement of our
                    products / services;
                  </li>
                  <li>
                    <span className="count">c.</span> transmission by email of
                    marketing materials that may be of interest to you;
                  </li>
                  <li>
                    <span className="count">d.</span> contact for market
                    research purposes which may be done using email,telephone,
                    fax or mail. Such information may be used to customise or
                    update the Website;
                  </li>
                </ul>
              </li>
            </ul>
            <p>in each case, in accordance with this privacy policy.</p>

            <ul>
              <li>
                <span className="count">10. </span> We may use your Data for the
                above purposes if we deem it necessary to do so for our
                legitimate interests. If you are not satisfied with this, you
                have the right to object in certain circumstances (see the
                section headed “Your rights” below).
              </li>

              <li>
                <span className="count">11. </span> For the delivery of direct
                marketing to you via e-mail, we’ll need your consent, whether
                via an opt-in or soft-opt-in:
                <ul>
                  <li>
                    <span className="count">a.</span> soft opt-in consent is a
                    specific type of consent which applies when you have
                    previously engaged with us (for example, you contact us to
                    ask us for more details about a particular product/service,
                    and we are marketing similar products/services). Under “soft
                    opt-in” consent, we will take your consent as given unless
                    you opt-out.
                  </li>
                  <li>
                    <span className="count">b.</span> for other types of
                    e-marketing, we are required to obtain your explicit
                    consent; that is, you need to take positive and affirmative
                    action when consenting by,for example, checking a tick box
                    that we’ll provide.
                  </li>
                  <li>
                    <span className="count">c.</span> if you are not satisfied
                    about our approach to marketing, you have the right to
                    withdraw consent at any time. To find out how to withdraw
                    your consent, see the section headed “Your rights” below.
                  </li>
                </ul>
              </li>

              <li>
                <span className="count">12. </span> When you register with us
                and set up an account to receive our services, the legal basis
                for this processing is the performance of a contract between you
                and us and/or taking steps, at your request, to enter into such
                a contract.
              </li>

              <li>
                <span className="count">13. </span> We may use your Data to show
                you Ethoventures adverts and other content on other websites. If
                you do not want us to use your data to show you Ethoventures
                adverts and other content on other websites, please turn off the
                relevant cookies (please refer to the section headed “Cookies”
                below).
              </li>
            </ul>

            <h2>Who we share Data with</h2>
            <ul>
              <li>
                <span className="count">14. </span> We may share your Data with
                the following groups of people for the following reasons:
                <ul>
                  <li>
                    <span className="count">a.</span> our employees, agents
                    and/or professional advisors – to obtain advice from
                    professional advisers;
                  </li>
                  <li>
                    <span className="count">b.</span> relevant authorities – to
                    facilitate the detection of crime or the collection of taxes
                    or duties;
                  </li>
                </ul>
              </li>
            </ul>
            <p>in each case, in accordance with this privacy policy.</p>

            <h2>Keeping Data secure</h2>

            <ul>
              <li>
                <span className="count">15. </span> We will use technical and
                organisational measures to safeguard your Data, for example:
                <ul>
                  <li>
                    <span className="count">a.</span> access to your account is
                    controlled by a password and a user name that is unique to
                    you.
                  </li>
                  <li>
                    <span className="count">b.</span> we store your Data on
                    secure servers.
                  </li>
                </ul>
              </li>

              <li>
                <span className="count">16. </span> Technical and organisational
                measures include measures to deal with any suspected data
                breach. If you suspect any misuse or loss or unauthorised access
                to your Data, please let us know immediately by contacting us
                via this e-mail address:
                <a href="mailto:admin@ethoventures.com">admin@ethoventures.com</a>.
              </li>

              <li>
                <span className="count">17. </span> If you want detailed
                information from Get Safe Online on how to protect your
                information and your computers and devices against fraud,
                identity theft, viruses and many other online problems, please
                visit www.getsafeonline.org. Get Safe Online is supported by HM
                Government and leading businesses.
              </li>
            </ul>

            <h2>Data retention</h2>

            <ul>
              <li>
                <span className="count">18. </span> Unless a longer retention
                period is required or permitted by law, we will only hold your
                Data on our systems for the period necessary to fulfil the
                purposes outlined in this privacy policy or until you request
                that the Data be deleted.
              </li>

              <li>
                <span className="count">19. </span> Even if we delete your Data,
                it may persist on backup or archival media for legal, tax or
                regulatory purposes.
              </li>
            </ul>

            <h2>Your rights</h2>
            <ul>
              <li>
                <span className="count">20. </span> You have the following
                rights in relation to your Data:
                <ul>
                  <li>
                    <span className="count">a.</span> Right to access – the
                    right to request (i) copies of the information we hold about
                    you at any time, or (ii) that we modify, update or delete
                    such information. If we provide you with access to the
                    information we hold about you, we will not charge you for
                    this, unless your request is “manifestly unfounded or
                    excessive.” Where we are legally permitted to do so, we may
                    refuse your request. If we refuse your request, we will tell
                    you the reasons why.
                  </li>

                  <li>
                    <span className="count">b.</span> Right to correct – the
                    right to have your Data rectified if it is inaccurate or
                    incomplete.
                  </li>

                  <li>
                    <span className="count">c.</span> Right to erase – the right
                    to request that we delete or remove your Data from our
                    systems.
                  </li>

                  <li>
                    <span className="count">d.</span> Right to restrict our use
                    of your Data – the right to “block” us from using your Data
                    or limit the way in which we can use it.
                  </li>

                  <li>
                    <span className="count">e.</span> Right to data portability
                    – the right to request that we move, copy or transfer your
                    Data
                  </li>

                  <li>
                    <span className="count">f.</span> Right to object – the
                    right to object to our use of your Data including where we
                    use it for our legitimate interests.
                  </li>
                </ul>
              </li>

              <li>
                <span className="count">21. </span> To make enquiries, exercise
                any of your rights set out above, or withdraw your consent to
                the processing of your Data (where consent is our legal basis
                for processing your Data), please contact us via this e-mail
                address:{" "}
                <a href="mailto:admin@ethoventures.com">admin@ethoventures.com.</a>
              </li>

              <li>
                <span className="count">22. </span> If you are not satisfied
                with the way a complaint you make in relation to your Data is
                handled by us, you may be able to refer your complaint to the
                relevant data protection authority. For the UK, this is the
                Information Commissioner’s Office (ICO). The ICO’s contact
                details can be found on their website a{" "}
                <a href="https://ico.org.uk/"> https://ico.org.uk/.</a>
              </li>

              <li>
                <span className="count">23. </span> It is important that the
                Data we hold about you is accurate and current. Please keep us
                informed if your Data changes during the period for which we
                hold it.
              </li>
            </ul>

            <h2>Transfers outside the European Economic Area</h2>

            <ul>
              <li>
                <span className="count">24. </span> Data which we collect from
                you may be stored and processed in and transferred to countries
                outside of the European Economic Area (EEA). For example, this
                could occur if our servers are located in a country outside the
                EEA or one of our service providers is situated in a country
                outside the EEA. We also share information with our group
                companies, some of which are located outside the EEA.
              </li>

              <li>
                <span className="count">25. </span>We will only transfer Data
                outside the EEA where it is compliant with data protection
                legislation and the means of transfer provides adequate
                safeguards in relation to your data, eg by way of data transfer
                agreement, incorporating the current standard contractual
                clauses adopted by the European Commission, or by signing up to
                the EU-US Privacy Shield Framework, in the event that the
                organisation in receipt of the Data is based in the United
                States of America.
              </li>

              <li>
                <span className="count">26. </span>To ensure that your Data
                receives an adequate level of protection, we have put in place
                appropriate safeguards and procedures with the third parties we
                share your Data with. This ensures your Data is treated by those
                third parties in a way that is consistent with the Data
                Protection Laws.
              </li>
            </ul>

            <h2>Links to other websites</h2>

            <ul>
              <li>
                <span className="count">27. </span> This Website may, from time
                to time, provide links to other websites. We have no control
                over such websites and are not responsible for the content of
                these websites. This privacy policy does not extend to your use
                of such websites. You are advised to read the privacy policy or
                statement of other websites prior to using them.
              </li>
            </ul>

            <h2>Changes of business ownership and control</h2>
            <ul>
              <li>
                <span className="count">28. </span>Ethoventures may, from time
                to time, expand or reduce our business and this may involve the
                sale and/or the transfer of control of all or part of
                Ethoventures Data provided by Users will, where it is relevant
                to any part of our business so transferred, be transferred along
                with that part and the new owner or newly controlling party
                will, under the terms of this privacy policy, be permitted to
                use the Data for the purposes for which it was originally
                supplied to us.
              </li>

              <li>
                <span className="count">29. </span>We may also disclose Data to
                a prospective purchaser of our business or any part of it.
              </li>

              <li>
                <span className="count">30. </span>In the above instances, we
                will take steps with the aim of ensuring your privacy is
                protected.
              </li>
            </ul>
            <h2>General</h2>

            <ul>
              <li>
                <span className="count">31. </span>You may not transfer any of
                your rights under this privacy policy to any other person. We
                may transfer our rights under this privacy policy where we
                reasonably believe your rights will not be affected.
              </li>

              <li>
                <span className="count">32. </span>If any court or competent
                authority finds that any provision of this privacy policy (or
                part of any provision) is invalid, illegal or unenforceable,
                that provision or part-provision will, to the extent required,
                be deemed to be deleted, and the validity and enforceability of
                the other provisions of this privacy policy will not be
                affected.
              </li>

              <li>
                <span className="count">33. </span>Unless otherwise agreed, no
                delay, act or omission by a party in exercising any right or
                remedy will be deemed a waiver of that, or any other, right or
                remedy.
              </li>

              <li>
                <span className="count">34. </span>This Agreement will be
                governed by and interpreted according to the law of England and
                Wales. All disputes arising under the Agreement will be subject
                to the exclusive jurisdiction of the English and Welsh courts.
              </li>
            </ul>

            <h2> Changes to this privacy policy </h2>
            <ul>
              <li>
                <span className="count">35. </span>Ethoventures reserves the
                right to change this privacy policy as we may deem necessary
                from time to time or as may be required by law. Any changes will
                be immediately posted on the Website and you are deemed to have
                accepted the terms of the privacy policy on your first use of
                the Website following the alterations.You may contact
                Ethoventures by email at{" "}
                <a href="mailto:admin@ethoventures.com">admin@ethoventures.com.</a>
              </li>
            </ul>

            <h2>Attribution</h2>
            <ul>
              <li>
                <span className="count">36. </span>This privacy policy was
                created using a document from Rocket Lawyer{" "}
                <a href="https://www.rocketlawyer.co.uk">
                  (https://www.rocketlawyer.co.uk).
                </a>
              </li>
            </ul>
            <h4>18 Dec 2023</h4>
          </div>
        </Row>
      </Container>
    </TermsStyle>
  );
};

export default TermsAndConditions;
